import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Ad728_90Footer from "../../Components/Ad728_90Footer";
import Ad300_250 from "../../Components/Ad300_250";

const GardeningData = ({ gardeningAllData, targetRef, handleScroll }) => {
  const { id } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  const handleClick = (link) => {
    if (link) {
      navigate("/" + link);
      handleScroll();
    }
  };

  const remainingObjects = gardeningAllData.slice(30);
  const gardenData = gardeningAllData?.find((item) => item?.link === id);

  const firstPart = remainingObjects.slice(
    0,
    Math.ceil(remainingObjects.length / 3)
  );
  const secondPart = remainingObjects.slice(
    Math.ceil(remainingObjects.length / 3),
    Math.ceil((remainingObjects.length * 2) / 3)
  );
  const thierdPart = remainingObjects.slice(
    Math.ceil((remainingObjects.length * 2) / 3)
  );
  return (
    <>
      <Ad300_250 />

      <div
        className="d-flex justify-content-center garden-data container"
        ref={targetRef}
      >
        <div className="home_div my-2">
          <div
            className="row mx-0"
            style={{ marginBottom: "110px", marginTop: "40px" }}
          >
            <div className="col-lg-9 col-md-12 col-sm-12 all-content">
              {gardenData ? (
                <div
                  className="d-flex justify-content-center home-inner-div"
                  style={{ paddingRight: "30px" }}
                >
                  <div className="home-main-div" style={{ width: "100%" }}>
                    <div className="d-flex justify-content-center pt-1">
                      <div
                        className="blog-div text-center"
                        style={{ width: "90%" }}
                      >
                        <h1 className="single-title text-center mt-2 my-4">
                          {gardenData?.blog_title}
                        </h1>
                        <h5 className="blog mt-1" style={{ lineHeight: "1.4" }}>
                          {gardenData?.blog_description}
                        </h5>
                      </div>
                    </div>
                    <div className="content-div mt-4" style={{ width: "100%" }}>
                      <div className="image-div text-center my-3 mb-5">
                        {gardenData?.blog_image && (
                          <img
                            src={gardenData?.blog_image}
                            className="image h-100"
                            alt={gardenData?.blog_image}
                          />
                        )}
                      </div>
                      <div className="d-flex justify-content-center mt-4">
                        <div className="blog-div" style={{ width: "95%" }}>
                          <h5
                            className="blog-desc"
                            style={{ lineHeight: "1.4" }}
                          >
                            {gardenData?.blog_desc}
                          </h5>
                          <h5
                            className="blog-desc"
                            style={{ lineHeight: "1.4" }}
                          >
                            <b>{gardenData?.blog_bold_desc}</b>
                          </h5>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-center align-items-center flex-column">
                      {gardenData?.list?.map((item, index) => (
                        <div
                          key={index}
                          className="d-flex justify-content-center"
                        >
                          <div className="contents-div mt-4">
                            <div className="image-div">
                              <h1 className="content-title mb-0">
                                {item?.title}
                              </h1>
                              {item?.image && (
                                <div className="image my-4 mb-5">
                                  <img
                                    src={item?.image}
                                    className="h-100 w-100"
                                    alt={item?.image}
                                  />
                                </div>
                              )}
                            </div>
                            <div className="d-flex mt-3">
                              <div
                                className="blog-div"
                                style={{ maxWidth: "100%" }}
                              >
                                <p className="blog-desc">
                                  {item?.description && (
                                    <h5
                                      className="mb-3"
                                      style={{ lineHeight: "1.4" }}
                                    >
                                      {item?.description}
                                    </h5>
                                  )}
                                  {item?.moreDesc && (
                                    <h5
                                      className="mb-3"
                                      style={{ lineHeight: "1.4" }}
                                    >
                                      <b>{item?.moreDesc}</b>
                                    </h5>
                                  )}
                                  <ul>
                                    {item?.list_Data &&
                                      item?.list_Data?.map((a, idx) => (
                                        <li key={idx} className="mb-1">
                                          {a}
                                        </li>
                                      ))}
                                  </ul>
                                  {item?.additional_desc && (
                                    <h5
                                      className="mb-3"
                                      style={{ lineHeight: "1.4" }}
                                    >
                                      {item?.additional_desc}
                                    </h5>
                                  )}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="not_found_page">
                  <h2 className="error_404">Oops! Record Not Found.</h2>
                  <span
                    className="goBack_button d-flex justify-content-center align-items-center"
                    onClick={() => navigate("/organic-gardening")}
                  >
                    Go Back
                  </span>
                </div>
              )}
            </div>
            <div className="col-lg-3 col-md-4 col-sm-12 listing_col">
              <div className="listing_div py-2">
                <div className="m-3">
                  <ul>
                    {firstPart?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleClick(item?.link)}
                        style={{
                          padding: "5px",
                          fontSize: "17px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {item?.blog_title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="listing_div mt-3 py-2">
                <div className="m-3">
                  <ul>
                    {secondPart?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleClick(item?.link)}
                        style={{
                          padding: "5px",
                          fontSize: "17px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {item?.blog_title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="listing_div mt-3 py-2">
                <div className="m-3">
                  <ul>
                    {thierdPart?.map((item, index) => (
                      <li
                        key={index}
                        onClick={() => handleClick(item?.link)}
                        style={{
                          padding: "5px",
                          fontSize: "17px",
                          fontWeight: "500",
                          cursor: "pointer",
                        }}
                      >
                        {item?.blog_title}
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Ad728_90Footer />
      </div>
    </>
  );
};

export default GardeningData;
