import React from "react";
import { useNavigate } from "react-router-dom";
import Ad728_90Footer from "../Ad728_90Footer";
const Footer = ({ gardeningAllData, handleScroll }) => {
  const navigate = useNavigate();
  const handleFeedBackClick = () => {
    navigate("/feedback");
    window.scrollTo({
      top: window.innerWidth > 600 ? 0 : 0,
      behavior: "smooth",
    });
  };

  const first25GardeningData = gardeningAllData.slice(0, 30);

  const handleClick = (link) => {
    if (link) {
      navigate("/" + link);
      handleScroll();
    }
  };

  return (
    <>
      <Ad728_90Footer />
      <div className="footer-main-div">
        <div className="second-footer-div d-flex  mx-5 py-5">
          <div className="footer-logo">
            {" "}
            <img
              src="https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/gardening logo-03.webp"
              className="d-flex"
              style={{ height: "64px" }}
              onClick={() => navigate("/")}
            />
          </div>
          <div>
            <div class="container text-left">
              <div className="row  row-cols-2 row-cols-lg-5 g-2 g-lg-3">
                {first25GardeningData?.map((item, index) => (
                  <div
                    className="col-2"
                    key={index}
                    onClick={() => handleClick(item?.link)}
                    style={{
                      fontWeight: "500",
                      fontSize : '14px',
                      cursor: "pointer",
                    }}
                  >
                    {item?.blog_title}
                  </div>
                ))}
              </div>
            </div>

            <h6 className="text-center link mt-5">
              Copyright 2024 © Gardening. All rights reserved
            </h6>
          </div>
          <div className="d-flex footer-inner-div">
            <h6 className="ms-3 mb-0" onClick={() => navigate("/about-us")}>
              About Us
            </h6>
            <h6 className="ms-3 mb-0" onClick={handleFeedBackClick}>
              FeedBack
            </h6>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
