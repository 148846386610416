import React from 'react'
import Banner from '../Home/Banner'
import { Outlet } from 'react-router-dom'

const Layout = ({gardeningAllData,handleScroll}) => {
    return (
        <>
            <Banner gardeningAllData={gardeningAllData} handleScroll={handleScroll}/>
            <main style={{marginTop:"-35px"}}><Outlet /></main>
        </>
    )
}

export default Layout