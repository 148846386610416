import React from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
  const navigate= useNavigate()
  return (
    <div>
      <div className='not_found_page' style={{height:"calc(100vh - 11.5rem)"}}>
        <h1 className='blog_404'>404</h1>
        <h2 className='error_404'>Oops! Page Not Found.</h2>
        <p className='content_404 mb-1'>Sorry, the page you are looking for does not exist.</p>
        <button className='goBack_button' onClick={() => navigate("/organic-gardening")}>Go Back</button>
      </div>
    </div>
  )
}

export default NotFound