import React, { useEffect } from "react";

const Ad728_90 = () => {
  useEffect(() => {
    const loadGoogleAdsScript = () => {
      const script = document.createElement("script");
      script.src = "https://www.googletagservices.com/tag/js/gpt.js";
      script.async = true;
      script.onload = initializeGoogleAds; // Call initializeGoogleAds after script is loaded
      document.head.appendChild(script);
    };

    const initializeGoogleAds = () => {
      if (window.googletag && typeof window.googletag === "object") {
        window.googletag.cmd.push(() => {
          // Define ad slots and display ads here
          let adSlot = window.googletag.defineSlot(
            "/22035701067/game_336_280_1",
            [336, 280],
            "div-gpt-ad-1717568015632-0"
          );
          adSlot.addService(window.googletag.pubads());
          window.googletag.enableServices();
          window.googletag.display("div-gpt-ad-1717568015632-0");
        });
      } else {
        console.error(
          "googletag is not defined. Google Ad Manager library might not be loaded."
        );
      }
    };

    loadGoogleAdsScript(); // Call function to load GAM script
  }, []);

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        backgroundColor: "#fff",
        overflow:"hidden"
      }}
    >
      <div id="div-gpt-ad-1717568015632-0">
        {/* This is the container where your ad will be displayed */}
      </div>
    </div>
  );
};

export default Ad728_90;
