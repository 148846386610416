const gardeningAllData = [
  {
    blog_title: "Organic Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_organic.webp",
    blog_description: "Monitoring normal assets and staying away from contamination and well-being dangers are significant components of the idea of natural cultivating. A key component is dependence on composts and manures obtained exclusively from creature or plant remains.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/organic_garden.webp",
    blog_desc: "A deep-rooted and economical strategy of planting, Natural Cultivating has as of late been seen getting back in the game to the Planting domain. This comes from creating and encouraging a better way of life. In opposition to mainstream thinking, natural planting is one of the less difficult and more possible ways of developing yields that are liberated from synthetically actuated ills.",
    blog_bold_desc: "Here is a rundown of simple tasks that you can follow to kick off  organic gardening:",
    link: "organic-gardening",
    list: [
      {
        title: "Establishing Bed",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-bed.webp",
        description: "A deep-rooted and reasonable strategy of cultivating, Natural Planting has as of late been seen getting back in the saddle to the Cultivating domain. This comes from creating and cultivating a better way of life. In opposition to prevalent thinking, natural planting is one of the easier and more feasible ways of developing harvests that are liberated from synthetically actuated ills.",
        moreDesc: "Remember the accompanying while picking a space to establish your nursery:",
        list_Data: [

          "Your ideal cultivating region ought to likewise be presented to at least 7 hours of daylight consistently",
          "If you live in a space where the accessibility of normal soil patches is scant, you might attempt a raised bed or a holder garden"
        ]
      },
      {
        title: "Most Significant Tools",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/GardeningTools.webp",
        description: "Various devices are significant for each natural landscaper to have. Every one of these instruments fills an alternate need, and will not go to squander as you begin constructing your most memorable natural nursery.",
        list_Data: [

          "Handcart",
          "Scoop",
          "Rake",
          "Broadfork",
          "Establishing Blade",
          "Estimating Tape",
          "Hose or watering can"
        ]
      },
      {
        title: "Where to Put Your Nursery",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Garden-img.webp",
        description: "Various devices are significant for each natural landscaper to have. Every one of these instruments fills an alternate need, and will not go to squander as you begin constructing your most memorable natural nursery.",
        moreDesc: "To decide the best spot to put your nursery, begin by noticing the microclimates of your yard. Ask yourself:",
        list_Data: [

          "Where is there full daylight during the day?",
          "What is the most reasonable and available area for your nursery?",
          "What is the nearest spot to a water source (nozzle or hose)?",
          "What are the temperature changes around here in your yard?"
        ]
      },
      {
        title: "Planning Your Nursery",
        description: "Beginning a natural nursery can super energize you. A natural nursery is a venture for a long time to come, so you need to ensure your persistent effort isn't to no end. It's additionally useful to grasp the essentials of natural planting so you can genuinely have a ball and the your rewards for all the hard work.",

      },
      {
        title: "Raised Beds",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Raised-Bed.webp",
        description: "to develop plants or yields in regions with scant normal soil fixes the raised beds can be your smartest option. It is an extraordinary choice for beginner landscapers and offers a touch of imagination. To get everything rolling, you can make a line utilizing normal woods, blocks, or even stones to isolate your nursery fix from expected foreign substances. The line you plan ought to be something like 16 inches high to guarantee that your nursery has safeguarded roots.",

      },
      {
        title: "What-To-Plant (Seeds)",
        description: "After getting ready suitable circumstances for developing (picking an ideal parcel of soil, bed, or holder) one fundamental component remains - picking plants and yields that fill best in your district and climatic circumstances",
        moreDesc: "Additionally, consider friend plants to develop close to your essential plants. These can act advantageous for your yield in two ways-",
        list_Data: [
          "They can give fundamental supplements to advance better developmentThey can provide essential nutrients to promote better growth",
          "May have practical experience in repulsing bugs"
        ]
      },
      {
        title: "Care and Support",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_maintenance.webp",
        description: "If you decide to water your nursery physically as opposed to utilizing a soaker hose or trickle framework, then you ought to water your nursery promptly in the first part of the day. Thus, how much water your plants or yields hold will expand and will likewise lessen the possibilities of hindering buildup framing on your harvests.",
        additional_desc: "Keep up with the PH level of your plants in the scope of 6.5-7.0 and keep a steady check to keep up with high and sound yield. Keep a consistent beware of your nursery for weed sprouts. Consider mulching your nursery with natural material, for example, grass from your patio or leaves from your trees to forestall the development of weeds",
      },
    ]
  },
  {
    blog_title: "Terrace Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_tarrace.webp",
    blog_description: "Terrace cultivating alludes to the act of developing plants, vegetables, spices, or blossoms on a housetop or porch space. A type of metropolitan planting utilizes accessible space in metropolitan regions where customary nurseries are probably impossible.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/terrace_garden.webp",
    blog_desc: "A Terrace garden is a green space made on the housetop or porch of a structure. A type of metropolitan cultivating uses accessible space in metropolitan regions to develop plants, vegetables, spices, or blossoms. Patio nurseries can be laid out in compartments, raised beds, or straightforwardly in the ground, contingent upon the space and underlying contemplations. They give a scope of advantages, including adding vegetation to metropolitan conditions, delivering new produce, further developing air quality, and making a space for unwinding and happiness. With legitimate preparation, upkeep, and care, porch nurseries can flourish and add to a better and more maintainable metropolitan way of life. In this broad aid, we will let you know all that you want to be aware of building your patio garden without any preparation.",
    blog_bold_desc: "Here is a rundown of simple tasks that you can follow to kick off natural planting:",
    link: "terrace-gardening",
    list: [
      {
        title: "Benefits of Terrace Garden",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/benefits_tg.webp",
        description: "terrace gardens offer plenty of advantages, making them an alluring choice for metropolitan inhabitants hoping to interface with nature and improve their living spaces. Here are a portion of the critical advantages of porch planting:",

      },
      {
        title: "Expanding Space",
        description: "Terrace gardens use unused roof or Terrace space, permitting metropolitan occupants to capitalize on accessible land in thickly populated regions where customary nurseries may not be possible.",

      },
      {
        title: "Further developed Air Quality",
        description: "The plants in Terrace gardens go about as regular air purifiers, retaining carbon dioxide and delivering oxygen. They additionally sift through poisons and particulate matter from the air, prompting further developed air quality in metropolitan conditions."
      },
      {
        title: "Metropolitan Intensity Island Alleviation",
        description: "The vegetation in patio gardens mitigates the metropolitan intensity island impact by giving shade, decreasing surface temperatures, and advancing evapotranspiration, subsequently cooling the encompassing region.",

      },
      {
        title: "Ecological Protection",
        description: "Terrace gardening adds to ecological protection endeavors by decreasing the carbon impression related to food creation and transportation. It additionally advances biodiversity by giving natural surroundings to pollinators and other advantageous bugs."
      },
      {
        title: "Water Preservation",
        description: "With appropriate plans and executives, porch nurseries can help catch and hold water, diminishing stormwater overflow and disintegration while saving water assets. Procedures, for example, dribble water systems and water-effective practices further limit water utilization.",

      },
      {
        title: "Significant Tools",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/GardeningTools.webp",
        description: "Roof planting, otherwise called roof cultivating or roof cultivation, includes developing plants on the roof of a structure. A type of metropolitan cultivating utilizes accessible space in metropolitan regions for developing plants, vegetables, spices, or blossoms. Here are the fundamentals of housetop cultivating",
      },
      {
        title: "Underlying Contemplations",
        description: "Before beginning a housetop garden, it's fundamental to evaluate the underlying honesty of the roof to guarantee it can uphold the heaviness of the nursery, soil, holders, and water. Talk with a primary designer or building proficient if vital.",
      },
      {
        title: "Daylight Prerequisites",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sunlight_tg.webp",
        description: "Guarantee that the roof garden gets sufficient daylight for plant development. Most eatable plants, like vegetables and spices, expect no less than six hours of daylight each day. Position holders or bring beds up in the radiant region of the housetop to expand sun openness.",
      },
      {
        title: "Maintenance",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/plant_tg.webp",
        description: "Ordinary upkeep assignments for roof planting incorporate watering, treating, pruning, bother control, and eliminating weeds. Check plants consistently for indications of stress, vermin, or infection, and address any issues instantly to guarantee solid development.",
      },
      {
        title: "Terrace Vegetable Gardening",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/vegetable_tg.webp",
        description: "Terrace vegetable gardening alludes to the act of developing vegetables in holders, raised beds, or other reasonable designs on a porch or housetop. A famous type of metropolitan planting permits people to develop new, natural produce in restricted space.",
      },

    ]
  },
  {
    blog_title: "Flower Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_fg.webp",
    blog_description: "Blossom cultivating is the craftsmanship and practice of developing and supporting blooming plants for their stylish excellence, aroma, and visual allure. It includes choosing, planting, and focusing on a wide assortment of blossoms to make brilliant and dynamic showcases in gardens, scenes, holders, or bloom beds.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/flower_gardening.webp",
    blog_desc: "Bloom planting is a wonderful and satisfying movement that includes developing and sustaining a different cluster of blossoming plants for their magnificence, scent, and visual allure. Whether in a rambling terrace garden, a comfortable metropolitan gallery, or a little window box, bloom cultivating permits people to make bright, dynamic, and welcoming outside spaces that elevate the soul and enjoyment of the faculties.",
    blog_bold_desc: "Here is a rundown of really simple tasks that you can follow to kick off bloom planting:",
    link: "flower-gardening",
    list: [
      {
        title: "Pick Your Space",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/choose_space_fg.webp",
        description: "Select a reasonable area for your blossom garden. Consider factors like daylight openness, soil quality, seepage, and admittance to water. Most blossoming plants expect no less than 6-8 hours of daylight each day, so pick a spot that gets sufficient daylight.",
        moreDesc: "Remember the accompanying while picking a space to bloom your nursery:",
        list_Data: [
          "Most blossoms need daylight to flourish, so pick a spot that gets no less than 6 hours of daylight each day.",
        ]
      },
      {
        title: "Set up the Dirt",
        description: "Set up the dirt by eliminating any weeds, rocks, or flotsam and jetsam from the area. Relax the dirt with a digging tool or nursery fork to further develop air circulation and seepage. Consolidate natural matter, like fertilizer or matured compost, into the dirt to give supplements to establish development.",
      },
      {
        title: "Select Blossoming Plants",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/select_flower_fg.webp",
        description: "Pick blooming plants that are appropriate to your environment, soil type, and daylight conditions. Consider factors, for example, blossom time, level, variety, and aroma while choosing plants for your nursery. You can pick a blend of annuals, perennials, bulbs, and bushes to make a different and beautiful nursery over time.",
      },
      {
        title: "Plan the Format And Planting",
        description: "Plan the format of your blossom garden, considering the full-grown size of the plants, their development propensities, and their dispersing prerequisites. Bunch plants with comparative water and daylight need together to make watering and upkeep more straightforward. Consider making central focuses, pathways, or lines inside the nursery to add visual interest",
        additional_desc: "Plant your blossoming plants as per their particular dispersing and profundity prerequisites. Dig establishing openings marginally bigger than the root bundle of each plant and tenderly relax the roots before planting. Refill the openings with soil and water completely to settle the plants set up."
      },
      {
        title: "Mulching And Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_fg.webp",
        description: "Apply a layer of natural mulch, for example, destroyed bark, wood chips, or manure, around the foundation of your plants to assist with holding dampness, smother weeds, and manage soil temperature. Keep a mulch layer of 2-3 inches thick, getting it far from the stems of the plants to forestall spoiling.",
        additional_desc: "Water your blossom garden consistently, particularly during droughts or blistering climates. Utilize a watering can, hose, or trickle water system framework to convey water straightforwardly to the root zone of the plants. Water profoundly and rarely supports profound root development and dry season resistance."
      },
      {
        title: "Treating And Upkeep",
        description: "Treat your blossom garden intermittently to give fundamental supplements to establish development and sprouting. Utilize a fair compost or manure explicitly planned for blossoming plants. Adhere to the directions on the manure bundle for application rates and recurrence.",
        additional_desc: "Keep up with your blossom garden by deadheading spent blossoms, eliminating weeds, and checking for bugs and sicknesses. Prune and trim plants on a case-by-case basis to advance solid development and keep up with their shape. Consistently examine your nursery for indications of stress, supplement lacks, or different issues, and address any issues immediately.."
      },
      {
        title: "Partake in Your Nursery",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/enjoying_fg.webp",
        description: "Find an opportunity to partake in your bloom garden and value the magnificence and quietness it brings to your outside space. Invest energy unwinding, perusing, or engaging in your nursery, and offer its excellence to loved ones.",
      },
    ]
  },
  {
    blog_title: "Herb Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_hg.webp",
    blog_description: "Spice planting at home is a magnificent method for having new, tasty spices readily available for cooking, making, or simply partaking in their magnificence and scent.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/herb_gardning.webp",
    blog_desc: "Spice planting is the workmanship and practice of developing spices for culinary, restorative, fragrant, or elaborate purposes. It's a great method for interfacing with nature, whether you have an open outside garden or only a couple of pots on a radiant windowsill.",
    blog_bold_desc: "Here is a rundown of simple tasks that you can follow to kick off Spice planting:",
    link: "herb-gardening",
    list: [
      {
        title: "Choosing Spices",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/select_herb_hg.webp",
        description: "Spice cultivating starts with picking which spices to develop. There's a huge swath of spices to consider, going from culinary staples like basil, parsley, and thyme, to restorative spices like lavender and chamomile, to sweet-smelling spices like mint and rosemary. Pick spices that suit your preferences, needs, and developing circumstances.",
      },
      {
        title: "Arranging and Planning",
        description: "Plant your spice seeds or seedlings in the holders as per the established guidelines for every spice. Establishing profundity and dividing may fluctuate depending on the spice assortment.",
        additional_desc: "Before you begin planting, consider the format and plan of your spice garden. Conclude whether you'll establish straightforwardly in the ground, in raised beds, or holders. Plan the course of action of your spices given their daylight and water necessities, as well as their development propensities and stylish allure."
      },
      {
        title: "Setting up the Dirt and planting",
        description: "Great soil is fundamental for solid spice development. Set up the dirt by slackening it with a nursery fork or turner and eliminating any weeds or trash. Consolidate natural matter, for example, fertilizer or matured compost to further develop soil design and fruitfulness.",
        additional_desc: "When the dirt is ready, now is the ideal time to establish your spices. You can begin with seeds, seedlings, or nursery-purchased plants, contingent upon your inclination and accessibility. Adhere to the establishing guidelines for every spice, considering separating, profundity, and any extraordinary necessities"
      },
      {
        title: "Focusing on Your Spices",
        description: "Appropriate consideration is significant for the outcome of your spice garden. This incorporates giving sufficient daylight, water, and supplements. Screen your spices consistently for indications of nuisances, infections, or supplement lacks, and make a proper move to resolve any issues that emerge.",
      },
      {
        title: "Pruning and Gathering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/herb.webp",
        description: "Routinely prune your spices to support shaggy development and keep them from becoming leggy. Gather your spices now and again to elevate new developments and appreciate new spices in your cooking."
      },
      {
        title: "Occasional Support",
        description: "Change your planting works as per the evolving seasons, giving shade during blistering summers and security from ice in winter.",
        additional_desc: "Routinely water your spices, particularly during dry periods, and give incidental treatment natural supplements. Prune spices to energize thick development and reap routinely to advance new development and keep up with flavor."
      },
      {
        title: "Ceaseless Learning and Trial and error",
        description: "Spice planting is an excursion of nonstop learning and trial and error. Every spice has its one-of-a-kind qualities and necessities, and there's continuously a new thing to find. Embrace the course of experimentation, and don't hesitate for even a moment to attempt new spices or methods",
      },
      {
        title: "Partaking in Your Rewards for all the hard work",
        description: "spice cultivating is tied in with partaking in the magnificence, flavors, and fragrances of your local spices. Whether you're relishing a newly picked twig of basil in your pasta sauce or savoring the experience of seeing energetic lavender blossoms in your nursery, carve out an opportunity to see the value in your rewards for so much hard work and the association with nature that spice planting gives."
      }
    ]
  },
  {
    blog_title: "Vegetable Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_vg.webp",
    blog_description: "Vegetable cultivating comprises choosing a site, arranging the nursery, setting up the dirt, picking the seeds and plants, establishing a yield, and sustaining the plants until they are prepared for gather. The outcome is new produce to eat, offer, or sell.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/vagetable_gardening.webp",
    blog_desc: "Vegetable planting in India can be a fulfilling and economical method for becoming your new produce. whether you're in a metropolitan or country region, is a remunerating try that can furnish you with new, nutritious produce and a more profound association with nature.",
    blog_bold_desc: "Here is a rundown of simple tasks that you can follow to kick off Vegetable planting:",
    link: "vegetable-gardening",
    list: [
      {
        title: "Choosing Area",
        description: "Pick an area for your vegetable nursery that gets adequate daylight (something like 6-8 hours every day) and has very much depleted soil. If you're cultivating in a restricted space, think about utilizing compartments or raised beds. Likewise, guarantees that the area is effectively available for watering and support."
      },
      {
        title: "Picking Vegetables",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/vegetable_tg.webp",
        description: "Select vegetables that are appropriate to the environment and developing circumstances in your district of India. A few famous decisions incorporate tomatoes, okra (bhindi), eggplant (brinjal), stew peppers, spinach (palak), unpleasant gourd (karela), bottle gourd (lauki), and beans. Research which vegetables flourish in your space and begin with a couple of assortments that you appreciate eating."
      },
      {
        title: "Setting up the Dirt",
        description: "Set up the dirt by releasing it with a nursery fork or turner and eliminating any weeds, rocks, or flotsam and jetsam. Consolidate natural matter like fertilizer, matured excrement, or leaf mulch to further develop soil ripeness, construction, and dampness maintenance. Hold back nothing acidic to a nonpartisan pH level (around 6.0 to 7.0) for most vegetables.",
      },
      {
        title: "Planting And Watering",
        description: "Adhere to the establishing directions for every vegetable, taking into account factors like dispersing, profundity, and timing. In India, numerous vegetables can be developed all year, however, it's fundamental to plant them brilliantly founded on the nearby environment and occasional varieties. You can begin with seeds or seedlings, contingent upon your inclination and accessibility.",
        additional_desc: "Water your vegetable nursery routinely, particularly during dry periods or when the plants are youthful and laying out roots. Plan to keep the dirt reliably wet however not waterlogged. Utilize a watering can, hose, or dribble water system framework to convey water straightforwardly to the foundation of the plants and limit vanishing."
      },
      {
        title: "Treating And Mulching",
        description: "Vegetables are weighty feeders and advantage over ordinary treatment. Utilize natural manures like fertilizer, very much decayed compost or natural compost mixes to give fundamental supplements to your plants. Apply manure as indicated by the guidelines on the bundle and abstain from over-preparing, which can prompt supplement uneven characters or consume the plants.",
        additional_desc: "Mulching is advantageous for monitoring soil dampness, smothering weeds, and directing soil temperature. Utilize natural mulches like straw, grass clippings, or destroyed passes to cover the dirt around your vegetable plants. Mulch ought to be applied a couple of inches thick however avoid the stems to forestall decaying."
      },
      {
        title: "Gathering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvesting_vg.webp",
        description: "Gather your vegetables when they arrive at the ideal size, variety, and development. Normal gathering supports persistent creation and keeps vegetables from becoming overripe or ruined. Utilize perfect, sharp scissors or pruners to reap foods grown from the ground, and handle them cautiously to abstain from swelling or harm."
      },
      {
        title: "Nonstop Learning and Variation",
        description: "Vegetable planting is an educational experience, and there's continuously a genuinely new thing to find or refine. Focus on your plants' development and conduct, explore different avenues regarding various assortments and strategies, and be available to adjust your cultivating rehearses in light of involvement and neighborhood conditions."
      }
    ]
  },
  {
    blog_title: "Container Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-container.webp",
    blog_description: "Container gardening is a way to grow plants in a small space by growing them in containers like pots, planters, boxes, or even bags. Because it is a flexible and manageable method for cultivating a variety of plants, this strategy is particularly popular in urban areas and locations with limited outdoor space.",
    blog_image:" https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container-garden.webp",
    blog_desc: "Using well-draining potting mix to ensure adequate aeration and moisture retention, selecting the right containers based on plant size and root system, and providing the right amount of sunlight and water for the chosen plants are the keys to successful container gardening. This method makes it simple to change the designs of gardens, so you can make beautiful displays on balconies, patios, rooftops, or even inside.",
    blog_bold_desc: "Here is a rundown of simple tasks that you can follow to kick off  Container Gardening:",
    link: "container-gardening",
    list: [
      {
        title: "Advantages of container gardening",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/advantages-container.webp",
        description: "Container gardening is a method of gardening that is adaptable and uses little space, and it has numerous benefits. It is ideal for urban dwellers because it lets gardeners grow plants in small spaces like balconies, patios, and yards. Compartments give better command over soil quality, seepage, and vermin the board, prompting better plants with diminished upkeep. They likewise offer portability, empowering grounds-keepers to modify designs or move plants inside during outrageous climate. By creating individual microclimates, containers also make it easier to experiment with various plant varieties. Generally, compartment planting is a helpful, open, and pragmatic answer for cultivating fans of all experience levels."
      },
      {
        title: "Container Selection",
        description: "First, choose containers that are appropriate for your plants' needs. Make sure they have enough holes for drainage to avoid waterlogging, which can cause root rot and other problems. Consider the size and profundity of holders to oblige sound root development."
      },
      {
        title: "Potting Mix",
        description: "Choose a high-quality container gardening potting mix. Various plants have shifting soil prerequisites, so pick a blend that suits your plants' requirements, for example, well-depleting for succulents or dampness holding for vegetables. Consistently check soil dampness levels to guarantee plants get satisfactory hydration."
      }, {
        title: "Area and Daylight",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sunlight-container.webp",
        description: "Position your compartments in an area that gets the proper measure of daylight for your plants. A few plants flourish in full sun, while others lean toward halfway shade or shade. Screen daylight designs over the course of the day and change holder arrangement on a case by case basis to improve development."
      },
      {
        title: "Watering Schedule",
        description: "Foster a watering routine in light of your plants' dampness needs and natural circumstances. When the top inch of soil feels dry, water thoroughly and frequently to check the soil's moisture. Overwatering can result in fungal diseases and root suffocation."
      },
      {
        title: "Treatment",
        description: "Prepare your compartment plants consistently with a fair manure or one intended for the requirements of your plants. Follow the prescribed measurements and recurrence to stay away from supplement lacks or overabundances, which can hurt plant wellbeing."
      },
      {
        title: "Seasonal Care",
        description: "Change how you care for your container gardens according to the seasons. In colder months, safeguard plants from ice by moving them inside or giving protection. Shade or mulch can help keep the soil moist and prevent heat stress in hot weather."
      },
      {
        title: "Design and Companion Planting",
        description: "If you want to make the most of your space and keep your plants healthy, think about planting in pairs. Pick establishes that complete one another as far as development propensities, soil requirements, and nuisance opposition. Consider color, texture, and height variations when arranging the containers for a pleasing appearance."
      },
      {
        title: "Repotting and Root Health",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/reporting-container.webp",
        description: "To avoid root-bound conditions, monitor root growth and repot plants as necessary. Assess pulls during repotting for indications of congestion, root decay, or nuisances. For healthy root development, use fresh potting mix and containers of the right size."
      }
    ]
  },
  {
    blog_title: "Succulent Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Succulent.webp" ,
    blog_description: "Succulent and cactus gardening is an attractive and low-maintenance method of cultivating plants that are known for their ability to retain water and striking appearances. These plants are popular choices for both indoor and outdoor gardens because they are well-suited to dry climates and prized for their distinctive shapes, textures, and vibrant colors.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Succulent_garden.webp" ,
    blog_desc: "Understanding the specific care requirements of succulents and cacti is one of the most important aspects of gardening. These plants flourish in well-depleting soil to forestall root decay, and they favor brilliant daylight, settling on them great decisions for radiant windowsills or outside spaces with a lot of daylight openness. To mimic their natural habitat, watering should be done sparingly and allowed to dry out between applications.",
    link: "succulent-&-cactus-gardening",
    list: [
      {
        title: "Choose a container",
        description: "with drainage holes to avoid waterlogging and guarantee healthy root growth. Pick earthenware or fired pots for their breathability and feel, which likewise add a rural appeal to your nursery."
      },
      {
        title: "Potting Mix",
        description: " To ensure proper aeration and moisture management, use a well-draining mix made for succulents and cacti. Stay away from standard nursery soil, which can be excessively thick and lead to root decay."
      },
      {
        title: "Daylight Prerequisites",
        description: "Spot your plants in an area with a lot of daylight, as they flourish in brilliant, circuitous light or full sun relying upon the species. Guarantee they get somewhere around 6 hours of daylight day to day to help their development and blossoming."
      },
      {
        title: "Watering Recurrence",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-succulent.webp",
        description: "Water sparingly, permitting the dirt to dry out between waterings to forestall root decay. Water succulents and cacti only when the soil is completely dry to the touch because they are drought-tolerant and prefer dry conditions."
      },
      {
        title: "During the growing season",
        description: "use a balanced, diluted fertilizer to provide essential nutrients without overfeeding. During the busy growing season, fertilize every 2-4 weeks to encourage healthy growth and vivid colors."
      },
      {
        title: "Bother The board",
        description: "Screen for normal vermin like mealybugs and scale bugs, treating invasions quickly with regular or natural cures. Regularly examine plants for evidence of pest activity, such as growth distortion or sticky residue."
      }, {
        title: "Soil pH Levels",
        description: "Keep up with somewhat acidic to impartial soil pH for ideal development and supplement retention. Check the acidity of the soil on a regular basis with a pH meter, and if necessary, amend the soil with a pH-balancing product."
      }, {
        title: "Size of the Container and Grouping",
        description: "Plants should be placed in groups according to how much light and water they need, and in containers that allow for root growth. To make arrangements that are appealing to the eye and easier to care for, group plants with similar requirements together."
      },
      {
        title: "Seasonal Changes",
        description: "Change the way you water and take care of your plants based on when the temperature and amount of sunlight change. Provide additional protection during extreme weather by reducing the frequency of watering in the winter and increasing it during the hot summer months."
      },
      {
        title: "Succulents as Outside Annuals",
        description: "To involve succulents as open air annuals, pick heat-lenient assortments like Echeveria and Sedum. After the last frost, plant them in soil that drains well and gives them six to eight hours of sunlight each day. Water sparingly, permitting soil to dry between watering. Gravel mulch can keep the soil moist and prevent weeds. Lightly fertilize, remove dead leaves, and keep an eye out for pests. Throughout the season, take pleasure in their vibrant, low-maintenance beauty."
      }
    ]
  },
  {
    blog_title: "Raised Bed Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-raised-bed.webp",
    blog_description: "Raised bed cultivating is a famous and productive technique for growing various plants in raised soil beds. Wood, stone, or metal are typically used to build these beds, which are then filled with high-quality soil for better control over soil composition, drainage, and weed management. Raised beds come in a variety of sizes and heights, making gardening easier for people with mobility issues and reducing the amount of bending required.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/raised-beds.webp" ,
    blog_desc: "One of the critical benefits of raised bed planting is the capacity to establish an ideal developing climate. The better soil design and waste advance sound root improvement and decrease the gamble of soil-borne infections. Moreover, raised beds comfortable up quicker in the spring, expanding the developing season and empowering prior planting.",
    link: "raised-bed-gardening",
    list: [
      {
        title: "Picking the Right Area",
        description: "Select an area that gets no less than 6-8 hours of daylight everyday. Guarantee the site is level and has great seepage. A water source's proximity can also be advantageous. Make sure the bed is placed where it won't be shaded by buildings or large trees, and think about how easy it will be to harvest and maintain it."
      },
      {
        title: "Designing and Building the Beds",
        description: "Decide on the dimensions of your raised bed, which should be four feet wide to make it easy to access from both sides. Utilize long-lasting materials like untreated stone, metal, and wood. In order to provide sufficient soil depth for root growth, make sure the beds are at least 12 inches high. To withstand the pressure of the soil, secure the corners and reinforce the sides."
      },
      {
        title: "Soil Readiness",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/soil-raised_bed.webp",
        description: "Fill the beds with an excellent soil blend. To improve drainage and fertility, mix organic matter and compost into garden soil. You should go for a texture that is loamy and both holds in moisture and lets water run off. Check the pH of your soil on a regular basis and adjust it as necessary to grow your plants."
      },
      {
        title: "Plant Determination and Position",
        description: "Pick plants fit to your environment and the season. Plan your nursery format by gathering plants with comparative water, light, and supplement needs. Consider sidekick planting to amplify space and backing plant wellbeing. Plan crop rotation and make sure taller plants don't shade shorter ones to keep the soil healthy."
      },
      {
        title: "Watering Methods",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_raised-bed.webp",
        description: "Use soaker hoses or a drip irrigation system to direct consistent moisture to the root zone. Mulch the dirt surface with natural material like straw or wood chips to hold dampness and lessen vanishing. Drink water early in the day to cut down on water loss from evaporation and lower your risk of fungal diseases."
      },
      {
        title: "Preparation and Soil The executives",
        description: "Routinely add manure and natural composts to keep up with soil fruitfulness. Use fertilizers that are balanced and apply them at the recommended rates. Top-dress with compost and organic matter on a regular basis to get nutrients back into the soil and make it stronger. Crop rotation prevents pest accumulation and nutrient depletion."
      },
      {
        title: "Nuisance and Infectious prevention",
        description: "Carry out coordinated bug the board (IPM) rehearses. Utilize actual obstructions like column covers to safeguard plants from bugs. Planting flowers and herbs that attract pollinators and predators will encourage beneficial insects. Plants should be inspected on a regular basis for signs of pests and diseases, and problems should be fixed right away."
      },
      {
        title: "Weed Administration",
        description: "Apply mulch to stifle weed development and hold soil dampness. Hand-weed consistently to keep weeds from dominating. Consider utilizing scene texture or biodegradable weed boundaries under the dirt blend for extra weed control. Throughout the growing season, make sure the mulch layer stays in good condition."
      }
    ]
  },
  {
    blog_title: "Indoor Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_indoor-gardening.webp" ,
    blog_description: "Indoor planting is the act of developing plants inside a home or encased space, permitting landscapers to appreciate vegetation and new produce all year, paying little heed to outside conditions. This strategy is especially useful for those living in metropolitan regions or locales with brutal environments.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/indoor-gardening.webp" ,
    blog_desc: "Growing herbs, vegetables, and flowers as well as maintaining larger plants like dwarf fruit trees and decorative foliage are all options for indoor gardening. It improves the quality of the air inside, makes things look better, and it can be a relaxing hobby.",
    link: "indoor-gardening",
    list: [
      {
        title: "Choosing the Right Plants",
        description: "Choose pothos, snake plants, peace lilies, and other plants that do well in indoor environments. These assortments are known for their low support and capacity to endure low light circumstances. Consider your home's lighting conditions and the particular consideration prerequisites of each plant."
      },
      {
        title: "Lighting conditions",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/lighting-indoor-garden.webp",
        description: "For photosynthesis, indoor plants need enough light. They should be placed close to windows so they can get natural light, but avoid direct sunlight because it can burn the leaves. Consider using grow lights that mimic the spectrum of the sun in rooms with insufficient natural light to ensure that your plants receive the necessary light for growth."
      },
      {
        title: "Watering Practices",
        description: " Appropriate watering is critical for indoor plants. Root rot can result from excessive watering, and stress can result from submersion. Use pots with seepage openings to keep water from amassing at the base. When the top inch of soil feels dry to the touch, give your plants a thorough watering and allow any excess water to drain completely."
      },
      {
        title: "Soil and Preparing Blend",
        description: "Utilize a great preparing blend that gives great air circulation and waste. Keep away from garden soil, which can be excessively thick for compartment plants. For explicit plants, for example, desert flora and succulents, utilize a blend intended for their one of a kind requirements. Invigorate the preparing blend occasionally to guarantee ideal development conditions."
      },
      {
        title: "Levels of Humidity",
        description: "Many indoor plants thrive in humid conditions. Mist your plants frequently or place a humidity tray with water and pebbles near them to increase humidity, especially in dry indoor conditions. Gathering plants can likewise make a microenvironment with higher mugginess."
      },
      {
        title: "Temperature Control",
        description: "Keep a stable indoor temperature for your plants, commonly somewhere in the range of 65°F and 75°F (18°C and 24°C). Plants should not be placed near doors, windows, or heating/cooling vents that are susceptible to drafts or temperature fluctuations. Take into consideration that some tropical plants may require slightly warmer conditions."
      },
      {
        title: "Treatment",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/furtilization-indoor-garden.webp",
        description: "Feed your indoor plants with a decent, water-solvent compost during the developing season (spring and summer). Follow the suggested measurement on the compost bundle, as over-treatment can harm the roots. Decrease or quit treating during the torpid period (fall and winter)."
      },
      {
        title: "Picking the Right Compartments",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container-indoor-garden.webp",
        description: "Select holders that give sufficient room to root development and have seepage openings to forestall waterlogging. Take into consideration the material of the pots: ceramic and terracotta pots allow for airflow, whereas plastic pots are lighter and retain moisture for a longer period of time. Make sure the pot is the same size as the plant."
      },
      {
        title: "Air Circulation",
        description: "A well-ventilated environment aids in the prevention of fungal diseases and encourages robust growth. Plants should be placed where there is a lot of airflow, but they should not be directly in the way of strong air currents from fans or vents. Utilizing a little fan on a low setting can assist with further developing air development in stale regions."
      },
      {
        description: "Hydroponics and aquaponics are alternatives to using soil for indoor gardening that use nutrient-rich water instead of soil. These frameworks can be exceptionally proficient and space-saving, making them ideal for little indoor spaces. Research the particular prerequisites and arrangement processes for these techniques to decide whether they are reasonable for your indoor planting objectives."
      }
    ]
  },
  {
    blog_title: "Community Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-community.webp" ,
    blog_description: "Community gardening is when a group of people work together to cultivate a shared garden space, usually in an urban or suburban area. These gardens are a way to bring people together, improve green spaces, and encourage sustainable living. They can be found in schools, community centers, parks, and vacant lots. Local area gardens offer various advantages, including admittance to new deliver, open doors for active work, and the cultivating of social associations.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/community-garden.webp" ,
    blog_desc: "By encouraging organic methods, promoting local food production, and reducing food miles, community gardening also contributes to environmental sustainability. It decorates neighborhoods, upholds biodiversity, and can give environments to pollinators. Community gardens improve quality of life and strengthen community ties by instilling a sense of ownership and pride among participants.",
    
    link: "community-gardening",
    list: [
      {
        title: "Choosing an Appropriate Location",
        description: "A community garden's success depends on its location. Find a spot that gets a lot of sunlight, has good soil, and has access to water. It ought to be strategically placed for local area individuals to effortlessly get to. Make sure the land is clean and has the right permissions to be used for gardening."
      },
      {
        title: "Organizing the Community",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/choosing-location-community.webp",
        description: "For a garden committee, gather members of the community who are interested. This gathering will be liable for arranging, sorting out, and dealing with the nursery. Set up regular gatherings to discuss objectives, delegate responsibilities, and settle issues collectively. In order to ensure smooth operation and conflict resolution, clear rules and bylaws should be established."
      },
      {
        title: "Planning the Garden Layout",
        description: "Make a plan for the garden layout to use as little space as possible. Think about the number and size of plots, pathways, common regions, and extra rooms. Incorporate regions for fertilizing the soil, instrument capacity, and potentially a seating region for local meetings. Ensure the plan is open for all individuals, incorporating those with handicaps."
      },
      {
        title: "Preparation and Enhancement of the Soil",
        description: "Measure the pH and nutrient content of the soil. Change the dirt depending on the situation with fertilizer, natural matter, and other soil conditioners to establish a rich developing climate. Raised beds can be a great way to improve drainage and soil quality. Routinely adding fertilizer and natural matter will keep up with soil wellbeing over the long haul."
      },
      {
        title: "Plant Selection and Crop Planning",
        description: "Select a selection of plants that are appropriate for the climate and soil of the area. Plant a variety of flowers, herbs, fruits, and vegetables to increase biodiversity and attract beneficial insects. To guarantee continuous harvests throughout the year, plan for seasonal planting. To add variety to the garden's offerings, encourage members of the community to share seeds and plants."
      },
      {
        title: "Watering Frameworks and Practices",
        description: "Introduce a productive watering framework, for example, dribble water system or soaker hoses, to ration water and guarantee plants get predictable dampness. Reduce evaporation and maintain soil moisture by covering plants with mulch. Urge individuals to utilize downpour barrels or different strategies to gather and involve water for the nursery."
      },
      {
        title: "Management of Pests and Diseases",
        description: "Use integrated pest management (IPM) techniques to naturally control pests and diseases. Energize the utilization of advantageous bugs, buddy planting, and natural bug control strategies. Keep an eye on your plants on a regular basis for signs of diseases and pests and act quickly to stop outbreaks. Teach people group individuals about IPM practices to guarantee aggregate endeavors in keeping up with plant wellbeing."
      },
      {
        title: "Engagement and Education in the Community",
        description: "Hold workshops, classes, and events to teach people in the community about gardening methods, sustainable practices, and seasonal tips. Utilize the nursery as a learning space for schools and youth gatherings to show nature, sustenance, and the climate. By organizing social events, potlucks, and garden tours, you can build a sense of community."
      },
      {
        title: "Financing and Assets",
        description: "Distinguish potential money sources to help the local area garden, like awards, gifts, and raising support occasions. Connect with nearby organizations, associations, and government offices for sponsorship and backing. Make a spending plan to oversee costs for seeds, devices, framework, and continuous upkeep."
      },
    ]
  },
  {
    blog_title: "Water Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-water-garden.webp" ,
    blog_description: "Water cultivating is an enrapturing type of cultivating that spotlights on developing plants in oceanic conditions, for example, lakes, water highlights, or holders loaded up with water. This kind of cultivating envelops many oceanic plants, including water lilies, lotus blossoms, drifting plants like water hyacinths, and lowered plants like hornwort or anacharis.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/water-garden.webp",
    blog_desc: "The way to effective water cultivating lies in making a fair biological system inside the water, which incorporates legitimate filtration, air circulation, and upkeep of water quality. Pumps, filters, and natural additives are often used by water gardeners to keep the water clear and healthy for plants and aquatic life.",
    link: "water-gardening",
    list: [
      {
        title: "Choosing the Right Type of Water Garden",
        description: " Water gardens can be small ponds in containers or big ponds in the ground. Decide the sort that accommodates your space, spending plan, and inclinations. Holder lakes are great for decks and overhangs, while in-ground lakes offer more space for various plants and fish. In your planning, think about things like size, depth, and location."
      },
      {
        title: "Choosing a Good Location",
        description: "For aquatic plants to thrive, choose a spot that gets 4-6 hours of sunlight per day. To prevent leaf litter from entering the water, the garden should not be located close to large trees. For easy maintenance and enjoyment, make sure the site is on level ground with good visibility. Admittance to a water hotspot for filling and it is likewise crucial for top off the lake."
      },
      {
        title: "Planning and Building the Water Nursery",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/design-water-garden.webp",
        description: "Plan your water garden with a blend of shallow and profound regions to oblige different plant types and give natural surroundings to oceanic life. Use lake liners, preformed lakes, or cement to develop the base. Add shelves or ledges for plant pots and a sloping edge to make it easy for wildlife to get to them. For aeration and aesthetic appeal, incorporate features like fountains or waterfalls"
      },
      {
        title: "Choosing and Establishing Sea-going Plants",
        description: " Pick different amphibian plants, including drifting plants (e.g., water lilies), lowered plants (e.g., anacharis), and negligible plants (e.g., cattails). These plants add to a reasonable environment, giving oxygen, shade, and filtration. Plant them in suitable holders or straightforwardly in the lake substrate, and guarantee they are safely secured."
      },
      {
        title: "Introducing Fish and Aquatic Life",
        description: "If you intend to include fish, choose species like koi, goldfish, or mosquito fish that are suitable for your pond's size and climate. Present fish progressively and screen their wellbeing. Guarantee the lake has an adequate number of plants and concealing spots to give shade and insurance. Try not to overload the lake to keep a reasonable environment."
      },
      {
        title: "Keeping an Ecosystem in Balance",
        description: "The interactions between fish, plants, and microorganisms are what keep an ecosystem in balance in a water garden. Chemical treatments that can harm beneficial bacteria should be avoided. Control green growth development with regular strategies like adding more plants, utilizing grain straw, or introducing UV clarifiers. To prevent overcrowding and ensure proper circulation, regularly prune and thin plants."
      },
      {
        title: "Seasonal Care and Maintenance",
        description: "Change the way you care for your water garden each season. In spring, clean the lake, really take a look at gear, and begin preparing plants. In the summer, keep an eye on the water's level and quality and, if necessary, provide shade. Remove fallen leaves in the fall and prepare fish and plants for the winter. In winter, utilize a lake radiator or de-icer to keep a little opening in the ice for gas trade in the event that you have fish."
      },
      {
        title: "Safety Tips",
        description: "Make sure pets and children are safe around the water garden. Introduce a solid wall or boundary if important. Utilize non-harmful, eco-accommodating materials to try not to defile the water. To avoid accidental drownings, regularly check and maintain the depth of the water. Teach relatives about water garden wellbeing and set clear rules for cooperation with the lake."
      }
    ]
  },
  {
    blog_title: "House gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-house-garden.webp" ,
    blog_description: "House gardening, also known as indoor gardening or houseplant cultivation, is the art of growing plants in residential settings like homes, condos, or apartments. People can use this kind of gardening to bring nature inside, improve the quality of the air inside, and make living spaces that are green and inviting.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/house-garden.webp",
    blog_desc: "House gardening encompasses a wide range of plants, including pothos and ferns, decorative foliage plants, flowering plants like African violets and orchids, culinary herbs like mint and basil, and even small fruiting plants like dwarf citrus trees. Plants can be chosen by gardeners based on their preferences, available space, and home lighting.",
    link: "house-gardening",
    list: [
      {
        title: "Surveying Space and Light",
        description: "Assess your indoor space to decide appropriate regions for planting. Consider factors like normal light accessibility, room temperature, and mugginess levels. Pick sufficiently bright regions close to windows or under counterfeit develop lights for plants that require adequate daylight. Shade-tolerant plants like pothos and snake plants can thrive in low-light settings."
      },
      {
        title: "Choosing Plants",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/select-plant-house.webp",
        description: "Pick indoor plants in light of your space and inclinations. For beginners, select low-maintenance varieties like spider plants, peace lilies, philodendrons, and succulents. Think about the size of mature plants and guarantee they fit their assigned space. Research each plant's consideration prerequisites in regards to water, light, soil, and stickiness prior to buying."
      },
      {
        title: "Choosing Containers",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container-house-garden.webp",
        description: "To avoid waterlogging, choose containers that have drainage holes. To help the soil drain, use pots made of porous materials like clay or ceramic. Saucers should be included in all pots to catch excess water and protect surfaces. Choose containers that have room for plant growth and complement your home's decor."
      },
      {
        title: "Planning Preparing Blend",
        description: "Set up a well-depleting preparing blend reasonable for indoor plants. Buy a commercial potting mix or mix peat moss, perlite, and vermiculite together. Try not to utilize garden soil, as it tends to be excessively thick and may contain bugs or illnesses. Consider adding natural matter like fertilizer for supplement rich soil."
      },
      {
        title: "Practices for Watering",
        description: "Water indoor plants in accordance with their particular requirements. Regularly check the level of moisture in the soil and water when the top inch of soil feels dry. Use room temperature water and guarantee careful watering, permitting overabundance water to empty out. Avoid drowning, which can cause stress and wilting, and overwatering, which can result in root rot."
      },
      {
        title: "Lighting Solutions",
        description: "Plants in low-light areas can benefit from artificial grow lights in addition to natural light. Pick full-range Drove or glaring lights intended for indoor plants. Position lights at the right distance and length in view of plant light prerequisites. Change lighting power and term as per occasional changes and plant development stages."
      },
      {
        title: "During the growing season",
        description: "During the growing season (spring and summer), fertilize indoor plants with a balanced liquid fertilizer. To avoid over-fertilization, adhere to the recommended dosage on the fertilizer label. Decrease or quit treating during the lethargic period (fall and winter) when plant development eases back. Utilize natural manures for a more manageable and delicate methodology."
      },
      {
        title: "Maintenance and Pruning",
        description: "Prune indoor plants on a regular basis to remove dead or yellowing leaves, encourage new growth, and keep the shape you want. Clean leaves with a clammy material to eliminate dust and further develop photosynthesis. Actually look at plants for vermin and sicknesses, and go to fitting lengths like utilizing insecticidal cleanser or neem oil for bug control."
      },
      {
        title: "Repotting and Transplanting",
        description: "When indoor plants outgrow their containers or when the soil becomes compacted, they should be replanted. The plant should be gently transplanted into a pot that is slightly larger and filled with fresh potting mix. If necessary, loosen the roots. Repotting revives plants and gives space to root extension. Screen establishes intently subsequent to repotting to guarantee they change well."
      },
    ]
  },
  {
    blog_title: "Fruit Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-fruit-garden.webp" ,
    blog_description: "Natural product planting is a fulfilling and delightful type of planting zeroed in on developing organic product bearing plants for local harvests. This kind of cultivating envelops an extensive variety of natural product species, including apples, oranges, berries, melons, citrus organic products, stone organic products like peaches and plums, and extraordinary natural products like kiwi or figs.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/fruit-garden.webp",
    blog_desc: "Natural product cultivating offers various advantages, including admittance to new, delightful natural products liberated from pesticides and synthetic substances, cost reserve funds contrasted with locally acquired produce, and the fulfillment of developing food at home. It likewise advances natural maintainability by diminishing food miles and supporting nearby biodiversity.",
    link: "fruit-gardening",
    list: [
      {
        title: "Choosing Natural product Assortments",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/selecting_fruit.webp",
        description: "Pick organic product assortments that are appropriate to your environment, soil type, and nursery space. Consider factors like chill hours (for deciduous natural product trees), fertilization prerequisites, and sickness opposition. Normal organic product choices incorporate apples, pears, peaches, cherries, citrus natural products, berries (strawberries, blueberries, raspberries), and figs."
      },
      {
        title: "Site Determination",
        description: "Select a bright area with no less than 6-8 hours of direct daylight day to day for ideal natural product creation. Guarantee great air flow and keep away from regions inclined to ice pockets or waterlogging. Test soil pH and ripeness, altering depending on the situation for explicit organic product necessities. Consider the developed size of natural product trees and their closeness to structures or different plants."
      },
      {
        title: "Soil Readiness",
        description: "Set up the dirt by relaxing it to a profundity of no less than 12 inches and integrating natural matter like fertilizer or very much decayed compost. Further develop seepage assuming soil is weighty mud by adding sand or perlite. Test soil supplements and change levels with adjusted composts to meet organic product tree needs."
      },
      {
        title: "Establishing Natural product Trees",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/nutural-fruit-garden.webp",
        description: "Plant natural product trees in late-winter or fall when the dirt is serviceable and temperatures are gentle. Dig establishing openings two times as wide and somewhat more profound than the root ball. Position trees at similar profundity as they were in the nursery compartment. Inlay with changed soil, water completely, and mulch around the base to preserve dampness."
      },
    
      {
        title: "Natural product Tree Pruning",
        description: "Prune natural product trees yearly to keep up with shape, advance wind stream, and eliminate dead or ailing branches. Prune in pre-spring or late-winter before new development starts. Utilize sharp, clean devices to make exact cuts and try not to harm tree husk. Observe pruning rules intended for each natural product tree type for best outcomes."
      },
      {
        title: "Preparation",
        description: "Treat organic product trees with a fair manure in late-winter before new development begins. Utilize natural choices like manure, matured compost, or slow-discharge manures to progressively give fundamental supplements. Screen tree development and change manure applications in light of soil test results and natural product tree wellbeing."
      },
      {
        title: "Watering Routine",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_fruit_garden.webp",
        description: "Lay out a reliable watering plan for natural product trees, particularly during droughts or times of dynamic development. Give profound, inconsistent watering to energize profound root advancement. Use dribble water system or soaker hoses to convey water straightforwardly to the root zone and lessen dissipation. Change watering in light of occasional weather patterns."
      },
      {
        title: "Fertilization Contemplations",
        description: "Guarantee sufficient fertilization for organic product set, particularly for self-pollinating and cross-pollinating organic product trees. Plant viable pollinators close by or use procedures like hand fertilization to further develop natural product yield. Know about blossoming times and pollinator fascination with expand fertilization achievement."
      },
    ]
  },
  {
    blog_title: "Balcony Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_balcony_garden.webp" ,
    blog_description: "Overhang planting is an imaginative and space-proficient way to deal with developing plants in little outside spaces, like galleries, porches, or decks. This type of planting permits people living in condos or metropolitan settings to appreciate vegetation, develop spices or vegetables, and improve their open air living regions",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/balcony_garden.webp" ,
    blog_desc: "Key contemplations in gallery cultivating incorporate picking plants reasonable for restricted space and light circumstances, choosing suitable holders or vertical planting frameworks, and giving fundamental consideration like watering, treating, and bug control. Grounds-keepers can use hanging crates, railing grower, vertical plant walls, and minimal pots to expand space and make outwardly engaging showcases.",
    link: "balcony-garden",
    list: [
      {
        title: "Surveying Overhang Space",
        description: "Assess your gallery space regarding size, daylight openness, and primary limit. Decide the weight-bearing limit with regards to compartments and nursery structures. Consider factors like breeze openness, gallery direction (north, south, east, west), and any limitations or rules from building the executives."
      },
      {
        title: "Holder Determination",
        description: "Pick appropriate compartments in light of gallery size and plant prerequisites. Select lightweight, climate safe materials like plastic, fiberglass, or gum for simplicity of development and solidness. Guarantee holders have seepage openings to forestall waterlogging and use saucers to safeguard overhang surfaces."
      },
      {
        title: "Choosing Plants",
        description: "Select plants that flourish in holders and match your overhang's light circumstances. Pick a blend of spices, vegetables, blossoms, and decorative plants for assortment and visual allure. Consider plant size, development propensities, and similarity while arranging courses of action. Research each plant's consideration needs with respect to water, daylight, soil, and temperature."
      },
      {
        title: "Preparing Blend and Soil",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/potting-balcony-garden.webp",
        description: "Utilize a well-depleting preparing blend explicitly figured out for holder planting. Try not to utilize garden soil, as it tends to be excessively thick and may contain nuisances or illnesses. Integrate natural matter like manure or peat greenery to further develop soil construction and ripeness. Top-dress holders with mulch to monitor dampness and control soil temperature."
      },
      {
        title: "Daylight and Shade The executives",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sunlight-balcony-garden.webp",
        description: "Comprehend daylight designs on your overhang over the course of the day and seasons. Position sun-cherishing plants (full sun) where they get 6-8 hours of direct daylight everyday. Place conceal lenient plants (halfway shade to full shade) in regions with restricted daylight or use conceal fabric to channel extreme daylight during hot periods."
      },
      {
        title: "Watering Schedule",
        description: "Lay out a reliable watering plan in light of plant needs and natural circumstances. Check soil dampness consistently by embedding a finger into the dirt or utilizing a dampness meter. Water completely until abundance water channels from the holders, and void saucers to forestall water development. Change watering recurrence because of weather conditions changes and plant development."
      },
      {
        title: "Preparation Practices",
        description: "Treat holder plants with a fair, water-solvent compost as indicated by bundle guidelines. Begin preparing after plants have laid out roots, normally 2 a month subsequent to planting. Utilize natural manures for a delicate, supportable methodology and abstain from over-treating, which can prompt supplement uneven characters and plant pressure."
      },
      {
        title: "Occasional Consideration",
        description: "Change care schedules in view of occasional changes and plant development stages. Shield plants from outrageous temperatures, solid breezes, or weighty downpours utilizing impermanent asylums or covers. Give conceal during heatwaves and bring delicate plants inside during ice or chilly spells. Prune and deadhead plants depending on the situation for ideal development and appearance."
      },
    
    ]
  },
  {
    blog_title: "Vertical Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_vartical_gardening.webp" ,
    blog_description: "Vertical cultivating offers a progressive method for coordinating plant life into present day residing spaces, particularly in metropolitan conditions where level cultivating space is restricted. By using walls, walls, and vertical designs, this strategy considers the development of plants in an upward direction, taking advantage of accessible space. Besides the fact that this approach upgrades the stylish allure of any area by changing uncovered walls into rich, green living materials, however it additionally further develops air quality by retaining toxins and delivering oxygen",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/vertical_garden.webp" ,
    blog_desc: "Additionally, vertical planting can altogether affect metropolitan microclimates by giving shade, decreasing the intensity island impact, and making territories for valuable bugs and birds. It offers an open cultivating answer for people with versatility issues, as plants are situated at eye level, diminishing the requirement for twisting or bowing.",
    link: "vertical-gardening",
    list: [
      {
        title: "Space Improvement",
        description: " Vertical cultivating is great for augmenting the utilization of restricted space. By developing plants uVersatility in Plant DecisionsVertical nurseries can uphold a wide assortment of plants, including vegetables, spices, blossoms, and little natural products. This flexibility permits grounds-keepers to make assorted and dynamic nursery plans customized to their particular necessities and inclinations. From decorative plants to eatable harvests, the conceivable outcomes are vast.pwards on walls, lattices, or vertical designs, you can change little or unused regions into lavish, useful nurseries. This approach is especially valuable for metropolitan tenants with limited ground space, permitting them to make lively green spaces in overhangs, decks, and even inside."
      },
      {
        title: "Enhanced Aesthetics",
        description: "Vertical nurseries can essentially improve the visual allure of any space. They can transform dull walls into dazzling green settings, adding a bit of nature to metropolitan conditions. Living walls, made of different plants, can make a creative and dynamic presentation, giving both magnificence and a feeling of serenity."
      },
      {
        title: "Further developed Air Quality",
        description: "Plants normally channel the air by retaining poisons and delivering oxygen. Vertical nurseries can further develop indoor and open air quality, making the climate better. They can assist with lessening indoor air contaminations like unpredictable natural mixtures (VOCs) and carbon dioxide, adding to a fresher, cleaner climate."
      },
      {
        title: "Simple entry and Upkeep",
        description: "Vertical planting decreases the actual strain related with conventional cultivating. Plants are raised, making undertakings like watering, pruning, and gathering more open and less work serious. This is especially helpful for old nursery workers or those with versatility issues, permitting them to appreciate planting without inconvenience."
      },
      {
        title: "Better Vermin and Illness The executives",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/design-vertical-garden.webp",
        description: "Developing plants upward can assist with lessening the gamble of soil-borne nuisances and sicknesses. Raising plants off as far as possible their openness to these dangers. Moreover, further developed air flow around upward developed plants forestalls contagious contaminations and different issues, advancing better development."
      },
      {
        title: "Better return Per Square Foot",
        description: "Vertical cultivating can prompt better returns from a more modest impression. Climbing plants like tomatoes, cucumbers, and beans flourish in vertical courses of action, creating more organic product contrasted with when developed evenly. This expanded efficiency is particularly important in little gardens or metropolitan settings."
      },
      {
        title: "Versatility in Plant Decisions",
        description: "Vertical nurseries can uphold a wide assortment of plants, including vegetables, spices, blossoms, and little natural products. This flexibility permits grounds-keepers to make assorted and dynamic nursery plans customized to their particular necessities and inclinations. From decorative plants to eatable harvests, the conceivable outcomes are immense."
      },
       {
        title: "Efficient Water Use",
        description: "Vertical cultivating can advance more effective water use. Trickle water system frameworks or self-watering grower can be utilized to guarantee that plants get satisfactory dampness without squandering water. This strategy focuses on the root zones straightforwardly, diminishing water misfortune through vanishing and spillover."
      },
    ]
  },
  {
    blog_title: "Hydroponic Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_hydroponic.webp" ,
    blog_description: "Aqua-farming cultivating is a creative technique for developing plants without soil, using supplement rich water answers for convey fundamental minerals straightforwardly to the plant roots. This method offers various benefits, including quicker plant development and better returns, on account of the immediate and effective supplement conveyance framework. Aquaculture frameworks are profoundly water-productive, utilizing essentially less water than conventional soil-based planting by reusing water in shut circle frameworks.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/hydroponic_garden.webp" ,
    blog_desc: "This technique is great for metropolitan conditions and little spaces, as tank-farming arrangements can be set up upward or on a level plane, boosting accessible space. Moreover, aquaculture cultivating considers exact command over the developing climate, including temperature, dampness, and light, guaranteeing ideal circumstances for plant wellbeing. By diminishing the requirement for soil, this method limits the gamble of soil-borne illnesses and irritations, frequently prompting better plants with a decreased requirement for pesticides and herbicides.",
    link: "hydroponic-gardening",
    list: [
      {
        title: "soil Developing",
        description: "Tank-farming cultivating is a strategy for developing plants without soil. All things being equal, plants are filled in a supplement rich water arrangement. This method dispenses with the requirement for conventional soil and takes into consideration exact command over supplement conveyance, advancing ideal plant development and wellbeing."
      },
      {
        title: "Water Proficiency",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/water-hydroponic-garden.webp",
        description: "Aqua-farming frameworks are profoundly water-effective. They utilize something like 90% less water than conventional soil-based cultivating on the grounds that water is recycled and reused inside the framework. This pursues aqua-farming a superb decision for regions with restricted water assets or for grounds-keepers hoping to moderate water."
      },  {
        title: "Quicker Development Rates",
        description: "Plants developed hydroponically frequently experience quicker development rates contrasted with those filled in soil. The immediate admittance to supplements and oxygen permits plants to assimilate what they need all the more productively, prompting speedier turn of events and prior harvests. This can bring about numerous developing cycles soon."
      },

      {
        title: "Higher Yields",
        description: "Tank-farming planting can deliver better returns than conventional cultivating. The streamlined developing circumstances and constant accessibility of supplements permit plants to arrive at their maximum capacity, frequently bringing about bigger and more plentiful harvests. This is especially worthwhile for business cultivators trying to amplify efficiency."
      },
      {
        title: "Space Effectiveness",
        description: "Aqua-farming frameworks can be intended to utilize vertical space, making them exceptionally space-proficient. This is great for metropolitan planting or indoor developing, where level space might be restricted. Vertical tank-farming arrangements consider the development of an enormous number of plants in a generally little region."
      },
      {
        title: "Nutrient Control",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Nutrient-control-hydroponic.webp",
        description: "Aqua-farming cultivating gives exact command over the supplements conveyed to plants. Nursery workers can fit supplement answers for the particular necessities of each plant, guaranteeing ideal development and wellbeing. This degree of control can prompt prevalent plant quality and expanded efficiency."
      },
      {
        title: "Natural Supportability",
        description: " Aquaculture planting can be more earth economical than conventional cultivating. The decreased water use, limited pesticide necessities, and effective utilization of room add to a lower ecological impression. Tank-farming frameworks can likewise be incorporated with environmentally friendly power hotspots for considerably more prominent supportability."
      },
      {
        title: "Innovative Advances",
        description: " Aquaculture planting frequently integrates creative innovations, for example, computerized supplement conveyance frameworks, environment control, and Drove develop lights. These headways can additionally enhance plant development and improve on garden the board, making tank-farming an interesting and ground breaking way to deal with cultivating."
      },
      {
        title: "Educational Open doors",
        description: "Aquaculture frameworks offer significant instructive open doors, especially in STEM (Science, Innovation, Designing, and Arithmetic) fields. They give an involved method for finding out about plant science, science, and natural science. Schools and instructive establishments can utilize aquaculture to show understudies economical agribusiness and creative cultivating procedures."
      },
    ]
  },
  {
    blog_title: "Aquaponic Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-aquaponic-garden.webp" ,
    blog_description: "Aquaponic cultivating is a dynamic and eco-accommodating framework that consolidates hydroponics and tank-farming, making a harmonious connection among fish and plants. In this shut circle climate, fish squander fills in as a supplement hotspot for plants, which thusly channel and sanitize the water for the fish. This normal cycle wipes out the requirement for compound composts, advancing natural development and maintainability. Aquaponic frameworks are profoundly water-effective, making them ideal for water protection in both metropolitan and country settings.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/aquaponic-garden.webp" ,
    blog_desc: "The coordinated idea of hydroponics considers quicker plant development, numerous harvests of new vegetables and fish, and exact command over natural variables like water quality and temperature. With low upkeep necessities and instructive open doors, aquaponic planting addresses a ground breaking way to deal with food creation that boosts assets while limiting waste and ecological effect.",
    link: "aquaponic-gardening",
    list: [
      {
        title: "Coordinated Environment",
        description: "Aquaponic cultivating joins hydroponics (fish cultivating) and tank-farming (soilless plant development) in a cooperative framework. Fish squander gives supplements to endlessly establishes assist with cleansing water for fish, making a fair and practical biological system."
      },
      {
        title: "Supplement Reusing",
        description: "In hydroponics, fish squander, wealthy in nitrogen and different supplements, fills in as normal manure for plants. The plants retain these supplements, filtering the water for the fish. This shut circle framework limits squander and boosts asset proficiency."
      },
      {
        title: "Water Preservation",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-aquaponic.webp",
        description: "Aquaponic frameworks utilize altogether less water than conventional soil-based planting. Water is recycled between the fish tank and plant beds, with negligible misfortune through vanishing or spillover. This goes with hydroponics an eco-accommodating decision for water-cognizant landscapers."
      },
      {
        title: "Quicker Plant Development",
        description: "Plants filled in aquaponic frameworks frequently experience sped up development rates because of the supplement rich water and ideal developing circumstances. This takes into consideration faster reaps and numerous harvest cycles consistently, expanding generally speaking efficiency."
      },
      {
        title: "Fish Creation",
        description: "Hydroponics produces plants as well as takes into consideration fish cultivating. Fish like tilapia, trout, and dupe can flourish in aquaponic frameworks. This double reason approach gives both new produce and protein-rich fish, improving independence and supportability."
      },
      {
        title: "Diminished Substance Sources of info",
        description: "Contrasted with conventional cultivating strategies, aquaponic frameworks require less synthetic data sources like manures and pesticides. The regular equilibrium of the environment limits the requirement for outside added substances, bringing about cleaner and better produce."
      },
      {
        title: "Space Effectiveness",
        description: "Aquaponic frameworks can be intended to amplify space use. Vertical developing pinnacles, stacked beds, or minimized arrangements are great for little spaces like galleries, metropolitan nurseries, or indoor conditions, making hydroponics appropriate for metropolitan cultivating."
      },
      {
        title: "Low Upkeep",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Upkeep-aquaponic.webp",
        description: "Once settled, aquaponic frameworks require generally low support. Oneself supporting nature of the environment, joined with mechanized frameworks for water flow and supplement the executives, improves on cultivating undertakings and diminishes work."
      },
      {
        title:" Instructive Worth",
        description:"Hydroponics offers significant instructive open doors, particularly in STEM (Science, Innovation, Designing, and Math) training. It gives active finding out about environments, science, water science, and economical horticulture, making it a connecting with device for schools and instructive projects."
      }
    ]
  },
  {
    blog_title: "Office Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_office_garden.webp" ,
    blog_description: "Office cultivating is a training that brings nature into indoor work areas, offering various advantages for the two representatives and the workplace climate. By integrating plants going from little work area plants to bigger indoor gardens or green walls, workplaces can appreciate further developed air quality as plants go about as normal air purifiers, decreasing poisons and expanding oxygen levels. This can prompt decreased feelings of anxiety among representatives and a more good workplace.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/office_garden.webp",
    blog_desc: "Office planting lines up with biophilic plan standards, encouraging an association among people and nature, which has been connected to expanded efficiency, inventiveness, and generally prosperity. It additionally advances manageability by empowering the utilization of regular components and decreasing the ecological effect of indoor spaces. By and large, office planting is a comprehensive way to deal with working environment health that incorporates nature, further develops the workplace, and improves the general insight for representatives.",
    link: "office-gardening",
    list: [
      {
        title: "Upgraded Office Climate",
        description: "Office cultivating includes integrating plants into the working environment, working on the general vibe and making a more charming and welcoming air for representatives and guests."
      },
      {
        title: "Air Quality Improvement",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/air-quality-office-garden.webp",
        description: "Indoor plants go about as regular air purifiers, sifting through contaminations and poisons from the air. This prompts cleaner and better indoor air quality, diminishing the gamble of respiratory issues and improving worker prosperity."
      },

      {
        title: "Stress Decrease",
        description: "Office cultivating has been displayed to diminish feelings of anxiety among workers. The presence of plants and vegetation can make a quieting impact, advancing unwinding, concentration, and efficiency in the working environment."
      },
      {
        title: "Biophilic Plan",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Biophilic-office-garden.webp",
        description: "Integrating plants into office spaces lines up with biophilic plan standards, which underscore the association among people and nature. This plan approach encourages a feeling of concordance and equilibrium, adding to a more amicable workplace."
      },
      {
        title: "Efficiency Lift",
        description: "Studies have demonstrated that office conditions with plants can prompt expanded efficiency and inventiveness among workers. Plant life in the working environment can motivate advancement, further develop temperament, and improve mental capability."
      },
      {
        title: "Space Use",
        description: "Vertical cultivating methods, like living walls or hanging grower, can augment space use in workplaces with restricted floor space. This considers plant life to be consolidated even in smaller or occupied workspaces."
      },
      {
        title: "Adaptable Plans",
        description: "Office cultivating takes into consideration adjustable plans to suit the particular design and style of the workplace. From little pruned plants on work areas to huge plant establishments, there are different choices to make a customized and effective green space."
      },
    ]
  },
  {
    blog_title: "Japanese Zen Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-japanese-zen.webp" ,
    blog_description: `Japanese Harmony cultivating, otherwise called "karesansui," is a thoughtful and moderate style of nursery configuration established in the Harmony Buddhist way of thinking. Frequently tracked down in sanctuaries, homes, and public spaces, these nurseries are painstakingly created to summon a feeling of serenity, congruity, and care.`,
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/zen-garden.webp" ,
    blog_desc: "At the core of Japanese Harmony Gardens is the standard of effortlessness. They commonly highlight rock or sand fastidiously raked to make swells representing water, with painstakingly positioned shakes and stones addressing mountains or islands. These components are organized reasonably and agreeably, following standards of deviation and negative space to welcome reflection and consideration.",
    link: "japanese-zen-gardening",
    list: [
      {
        title: "Moderation",
        description: "Japanese Harmony gardens embrace moderation, utilizing straightforward components to make peaceful and quiet scenes. They frequently include rock or sand raked to address water and shakes representing islands or mountains."
      },
      {
        title: "Shakes and Rock",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/shake-rock-zen-gardening.webp",
        description: "Rocks are major in Harmony Gardens, representing steadiness, perseverance, and regular excellence. Rock or sand is raked to make designs addressing water streams, and waves, or waves, inspiring a feeling of development and quietness."
      },
      {
        title: "Imagery",
        description: "Each component in a Harmony garden conveys representative significance. Rocks represent mountains or islands, rocks or sand represent water, and greenery or plants represent life and essentialness. These components consolidate to summon a feeling of nature smaller than usual."
      },
      {
        title: "Equilibrium and Amicability",
        description: "Harmony gardens underline equilibrium and agreement in plan and organization. The plan of rocks, rock examples, and plantings is painstakingly adjusted to create a feeling of harmony and quietness."
      },
      {
        title: "Care",
        description: `Making and keeping a Harmony garden requires care and meticulousness. Raking the rock or sand in unambiguous examples, known as "raked rock designs" or "kare-sansui," is a reflective practice that advances concentration and smoothness.`
      },
      {
        title: "Simplicity",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Simplicity-zen-garden.webp",
        description: `Harmony gardens encapsulate the rule of "toning it down would be ideal." They are deliberately kept basic, with a restricted variety range, moderate plantings, and cleaned-up spaces, considering thought and reflection.`
      },
      {
        title: "Seasonal Changes",
        description: "Harmony gardens mirror the evolving seasons, with plantings and courses of action acclimated to blend with nature's cycles. Cherry blooms in spring, maple leaves in harvest time, and evergreen plants give occasional varieties in variety and surface."
      },
      {
        title: "Water Components",
        description: "While conventional Harmony gardens are dry scenes, water components like tsukubai (stone water bowls) or little lakes might be incorporated for representative purposes. These components summon virtue, reflection, and the progression of life."
      },
      {
        title: "Harmony Nursery Adornments",
        description: "Extras, for example, stone lamps, bamboo walls, and wooden extensions might be integrated into Harmony Nurseries to upgrade the general climate and taste. These components add social importance and visual interest."
      },
      
    ]
  },
  {
    blog_title: "Rock Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-rock-garden.webp" ,
    blog_description: "A stone nursery, otherwise called a rockery and previously as a rockwork, is a nursery, or all the more frequently a piece of a nursery, with a finishing structure of rocks, stones, and rock, with planting suitable to this setting. Typically these are little High plants that need moderately little soil or water. Western stone nurseries are frequently isolated into high gardens, scree gardens on looser, more modest stones, and other stone nurseries.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/rock-gardenig.webp",
    blog_desc: "Some stone nurseries are established around regular outcrops of rock, maybe with some counterfeit arranging, yet most are altogether fake, with the two shakes and plants got. Some are planned and worked to seem to be normal outcrops of bedrock. Stones are adjusted to recommend a sheet material plane, and plants are frequently used to cover the joints between said stones. This sort of rockery was well known in Victorian times and for the most part made by proficient scene draftsmen. A similar methodology is in some cases utilized in business or current grounds finishing however can likewise be applied in more modest confidential nurseries.",
    link: "rock-gardening",
    list: [
      {
        title: "Naturalistic Setting",
        description: "Rock gardens intend to duplicate the normal natural surroundings of elevated and bumpy locales, making smaller than usual scenes with rough outcrops, fissure, and slants."
      },
      {
        title: "All around Depleted Soil",
        description: "Rock gardens require very much depleted soil with great air circulation, mirroring the gravelly and rough soils tracked down in elevated regions. Adding coarse sand or rock to the dirt blend further develops waste and forestalls waterlogging."
      },
      {
        title: "Rock Choice",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/rock-choice-rock-garden.webp",
        description: "The determination and position of rocks are urgent in rock planting. Rocks of differing sizes, shapes, and surfaces are organized to make naturalistic stone developments, giving establishing pockets and visual interest."
      },
        {
          title: "Plant Choice",
          description: "Rock gardens highlight a different scope of elevated and rock-cherishing plants, including bantam bushes, perennials, succulents, bulbs, and ground covers. These plants are adjusted to cruel circumstances like unfortunate soil, high height, and outrageous climate."
        },
        {
          title: "Microclimates",
          description: "Rock gardens make microclimates inside the nursery, offering different developing circumstances for different plant species. Concealed regions, bright slants, and protected pockets support an extensive variety of plant variety."
        },
        {
          title: "Erosion Control",
          description: "The essential arrangement of rocks forestalls soil disintegration in rock gardens. Rocks go about as obstructions, holding soil and forestalling overflow, particularly on slants and grades."
        },
        {
          title: "Crevice Cultivating",
          image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Crevice-rock_garden.webp",
          description: "Hole cultivating is a strategy utilized in rock gardens, where plants are filled in tight cleft between rocks. This strategy expands space, gives phenomenal seepage, and makes a naturalistic look."
        },
        {
          title: "Dry season Resilience",
          description: "Many plants in rock gardens are dry spell open minded and adjusted to bone-dry circumstances. These plants have profound underground roots and waxy foliage that diminish water misfortune, making them appropriate for low-water cultivating."
        },
        {
          title: "Seasonal Interest",
          description: "Rock gardens offer all year interest with plants that blossom at various seasons. Spring-blossoming bulbs, summer-sprouting perennials, and evergreen ground covers give persistent variety and surface."
        },
    ]
  },
  {
    blog_title: "Bonsai Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-bonsai-garden.webp" ,
    blog_description: "Bonsai planting is a loved fine art that includes the development of small trees, carefully molded and sustained to make living show-stoppers. This antiquated work, starting in China and refined in Japan, typifies standards of persistence, equilibrium, and amicability with nature.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/bonsai-garden.webp",
    blog_desc: "Vital to bonsai planting is the cautious pruning and molding of trees to imitate the presence of their regular partners, frequently addressing normal scenes or old trees. Every bonsai tree is developed in a little holder, decided to supplement its size and style, and the dirt and watering routine is fastidiously figured out how to guarantee the tree's wellbeing and vitality. Beyond the specialized viewpoints, bonsai cultivating is established in stylish and philosophical standards. The imaginativeness lies in making a feeling old enough, excellent, and equilibrium inside the bound space of a bonsai pot, exemplifying ideas of lopsidedness, extent, and concordance.",
    link: "bonsai-gardening",
    list: [
      {
        title: "History and Beginnings",
        description: "Bonsai cultivating began in China quite a long time ago and was subsequently refined and promoted in Japan. It mirrors the Japanese stylish standards of straightforwardness, equilibrium, and concordance with nature."
      },
      {
        title: "Scaled down Trees",
        description: "Bonsai trees are small adaptations of standard trees, developed in shallow pots to limit their development. They can emulate different tree species, including pine, maple, juniper, ficus, and cherry bloom."
      },
      {
        title: "Pruning and Preparing",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning-bonsai-garden.webp",
        description: "Bonsai trees are carefully pruned and prepared to accomplish wanted shapes, sizes, and styles. Methods like wiring, managing, and leaf pruning are utilized to control development and make imaginative structures."
      },
      {
        title: "Containers and Preparing",
        description: `Bonsai compartments, known as "pots," come in different shapes, sizes, and materials like fire, earth, or porcelain. The decision to pot supplements the tree's style and upgrades its visual allure.`
      },
      {
        title: "Styles and Structures",
        description: "Bonsai trees are arranged into various styles or structures in light of their shape and trunk direction, like conventional upstanding, casual upstanding, inclining, overflow, semi-overflow, and desolate."
      },
      {
        title: "Root Pruning and Repotting",
        description: "Bonsai trees require normal root pruning and repotting to keep up with sound root foundations and forestall pot-bound conditions. Repotting spans fluctuate in light of the tree species and development rate."
      },
      {
        title: "Soil and Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-bonsai-garden.webp",
        description: "Bonsai soil is well-depleting and vaporous, advancing root wellbeing and oxygenation. Watering procedures, like dousing, moistening, or utilizing watering jars, are fundamental for keeping up with appropriate dampness levels."
      },
      {
        title: "Fertilization and Nourishment",
        description: "Bonsai trees need adjusted treatment to help sound development and foliage. Slow-discharge composts or natural bonsai food are applied during the developing season to give fundamental supplements."
      },
    ]
  },
  {
    blog_title: "Green Roof Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-roof-garden.webp" ,
    blog_description: "Green rooftop cultivating, otherwise called housetop planting, changes underutilized roof spaces into flourishing green regions, offering various ecological, financial, and tasteful advantages. By developing plants on building housetops, green rooftops assist with alleviating the metropolitan intensity island impact, diminishing energy utilization for warming and cooling while at the same time further developing air quality by separating poisons. They oversee stormwater spillover, diminishing flood gambles, and lightening tension on seepage frameworks.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/roof-garden.webp",
    blog_desc: "Green rooftops upgrade metropolitan biodiversity by giving natural surroundings to plants, bugs, and birds, and deal tasteful allure by changing dull roofs into lively green spaces. These nurseries can likewise act as sporting facilities for unwinding and mingling, and they support metropolitan farming, advancing nearby food creation and food security.",
    link: "green-roof-garden",
    list: [
      {
        title: "Types of Green Rooftops",
        description: "Green rooftops are classified into broad, serious, and semi-escalated types. Broad green rooftops have shallow soil and low-upkeep plants like sedums and grasses. Serious green rooftops have further soil, supporting various plants, including bushes and trees. Semi-concentrated green rooftops are a blend of both, offering moderate plant variety and upkeep necessities."
      },
      {
        title: "Ecological Advantages",
        description: "Green rooftops work on metropolitan conditions by diminishing the metropolitan intensity island impact, upgrading air quality, and advancing biodiversity. They make green spaces in thickly populated regions, giving territories to birds, bugs, and other untamed life."
      },
      {
        title: "Energy Productivity",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/energy-roof_garden.webp",
        description: "Green rooftops offer amazing protection, diminishing energy utilization for warming and cooling structures. The vegetation layer goes about as a characteristic protector, keeping structures cooler in summer and hotter in winter, prompting lower energy bills."
      },
      {
        title: "Stormwater The board",
        description: "Green rooftops assimilate and hold water, lessening spillover and relieving the gamble of metropolitan flooding. The plants and soil go about as normal wipes, sifting and dialing back the water stream, which facilitates the weight on waste frameworks."
      },
      {
        title: "Further developed Air Quality",
        description: "Green rooftops add to cleaner air by catching airborne poisons, residue, and carbon dioxide. The plants likewise produce oxygen, upgrading air quality and establishing a better metropolitan climate."
      },
      {
        title: "Metropolitan Horticulture",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/horticulture-roof-grden.webp",
        description: "Concentrated green rooftops can be utilized for metropolitan farming, and developing organic products, vegetables, and spices. This advances neighborhood food creation, lessens the carbon impression of food transportation, and supports metropolitan maintainability."
      },
      {
        title: "Wellbeing and Prosperity",
        description: "Green rooftops give sporting spaces and green conditions that advance mental and actual prosperity. Admittance to green spaces has been displayed to decrease pressure, further develop temperament, and empower actual work."
      },
      

    ]
  },
  {
    blog_title: "Terrarium Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-terranium-garden.webp" ,
    blog_description: "Terrarium planting is the specialty of developing plants inside a straightforward compartment, making a small-scale biological system that can flourish inside. These encased conditions, frequently produced using glass, consider the development of various plants, commonly those that flourish in sticky circumstances. Terrariums are both improving and practical, adding a hint of plant life to indoor spaces while requiring negligible support.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/terranium-garden.webp" ,
    blog_desc: "At the core of a terrarium's allure is its self-supporting nature. The shut climate reuses dampness through buildup and vanishing, giving a reliable water supply to the plants. This makes terrariums especially appropriate for occupied people or those new to planting, as they require less successive watering contrasted with conventional houseplants.",
    link: "terrarium-gardening",
    list: [
      {
        title: "Definition and Idea",
        description: "Terrarium cultivating is the act of developing plants in encased or semi-encased glass holders. These smaller-than-usual nurseries make self-supporting biological systems that can emulate common habitats, offering an interesting method for getting a charge out of indoor planting."
      },
      {
        title: "Sorts of Terrariums",
        description: "There are two principal kinds of terrariums: open and shut. Open terrariums are reasonable for plants that lean toward drier circumstances, like succulents and desert flora. Shut terrariums, with covers or encased holders, are great for mugginess cherishing plants like greeneries and greeneries."
      },
      {
        title: "Holder Determination",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container-terrarium.webp",
        description: "The decision of compartment is critical for terrarium cultivating. Holders can go from basic glass containers and fish tanks to expanded glass vaults and hanging spheres. The holder ought to be straightforward to permit light in and give a reasonable perspective on the plants inside."
      },
      {
        title: "Layering",
        description: "Legitimate layering is fundamental for a solid terrarium. Layers normally incorporate waste materials (like rock or stones), initiated charcoal to channel pollution, an obstruction layer (like lattice or scene texture), and a reasonable preparing blend. This layering guarantees great waste and forestalls root decay."
      },
      {
        title: "Plant Determination",
        description: "Picking the right plants is basic for an effective terrarium. It would be ideal for plants to be little, slow-developing, and viable in the natural states of the terrarium. Famous decisions incorporate greeneries, greeneries, succulents, air plants, and small tropical plants."
      },
      {
        title: "Light Prerequisites",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/lighting-terrarium-garden.webp",
        description: "Terrariums need sufficient light to flourish. They ought to be set in brilliant, roundabout light to forestall overheating and consuming the plants. Counterfeit lighting, for example, develops lights and can be utilized assuming regular light is deficient."
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-terrarium.webp",
        description: "Watering needs fluctuate among open and shut terrariums. Shut terrariums establish a damp climate and require negligible watering, while open terrariums need more incessant watering. It's vital to keep away from overwatering to forestall shape and root decay."
      },
      {
        title: "Plan and Feel",
        description: "The plan of a terrarium takes into consideration innovativeness and individual articulation. Utilizing different plant shapes, tones, and surfaces, alongside improving components like rocks, puppets, and greenery, can make outwardly engaging and one-of-a-kind scenes."
      },
    ]
  },
  {
    blog_title: "Butterfly Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-butterfly-garden.webp" ,
    blog_description: "Butterfly planting is the act of planning and developing nurseries explicitly to draw in and support butterflies. This sort of cultivating centers around establishing various nectar-rich blossoms and host establishes that give food and living space to butterflies at various phases of their life cycle. Key to a fruitful butterfly garden is the consideration of an assorted cluster of plants that sprout at various times, guaranteeing a consistent inventory of nectar from spring through fall. Local plants are especially significant as they are appropriate to the neighborhood environment and give recognizable food sources to nearby butterfly species.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Butterfly-garden.webp" ,
    blog_desc: "Butterfly gardens give a safe haven to butterflies as well as improve the biodiversity and biological strength of the area. They offer advantages of past styles, for example, supporting fertilization and filling in as instructive devices for finding out about environments and the significant job of pollinators. Highlights like bright spots, protected regions, and shallow water sources are integrated to make enticing natural surroundings for butterflies.",
   
    link: "butterfly-gardening",
    list: [
      {
        title: "Reason and Advantages",
        description: "Butterfly planting means drawing in and supporting butterflies by furnishing them with important assets. Benefits incorporate advancing biodiversity, upgrading the garden feel, and offering instructive and sporting open doors."
      },
      {
        title: "Choosing the Right Plants",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Choosing-Plants-butterfly.webp",
        description: "Picking the right plants is critical for an effective butterfly garden. Center around nectar-rich blossoms for grown-up butterflies and host plants for caterpillars. Models incorporate milkweed, coneflowers, lantana, and butterfly shrub."
      },
      {
        title: "Nectar Plants",
        description: "Nectar plants give food to grown-up butterflies. Decide on different nectar-rich blossoms that sprout all through the developing season to guarantee a constant food source. Famous decisions incorporate zinnias, asters, and verbena."
      },
      {
        title: "Host Plants",
        description: "Host plants are fundamental for butterflies to lay their eggs and for caterpillars to benefit from. Different butterfly species have explicit host plants. For example, rulers lean toward milkweed, while dark swallowtails favor parsley and dill."
      },
      {
        title: "Making a Territory",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Territory-butterfly.webp",
        description: "Plan your nursery to give safe house and rearing locales. Incorporate a blend of blossoming plants, bushes, and grasses to offer security from hunters and cruel climate. Thick plantings and key arrangement of taller plants can make microhabitats."
      },
      {
        title: "Water Sources",
        description: "Butterflies need water to remain hydrated. Give shallow water sources like water basins, shallow dishes, or puddling regions with soggy sand or soil. These water sources ought to be shallow enough for butterflies to securely get to."
      },
      {
        title: "Daylight and Shade",
        description: "Butterflies are wanton and depend on daylight to control their internal heat level. Guarantee your nursery has bright regions for lounging and a few concealed spots for rest. Setting level stones in bright regions gives lolling destinations."
      },
      {
        title: "Garden Design",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/garden-design-butterfly.webp",
        description: "Plan the nursery format to expand permeability and availability for butterflies. Bunch plants in groups to make it simpler for butterflies to find nectar sources. Pathways and open spaces can improve the nursery's style and usefulness."
      },
    ]
  },

  {
    blog_title: "Edible Flower Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-edible-garden.webp" ,
    blog_description: "Eatable bloom planting is the act of developing blossoms that are ok for utilization and can add exceptional flavors, varieties, and surfaces to culinary dishes. This sort of cultivating consolidates feel with reasonableness, as these blossoms improve the nursery as well as upgrade dinners with their lively petals and fragile flavors. Famous consumable blossoms incorporate nasturtiums, pansies, violets, calendulas, and marigolds, each offering unmistakable preferences going from peppery to sweet, and citrusy to hot.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/edible-garden.webp" ,
    blog_desc: "Developing eatable blossoms requires cautious choice of plant assortments and adherence to natural cultivating practices to guarantee the blossoms are liberated from hurtful synthetic compounds. These nurseries frequently integrate different plants to give a nonstop sprout all through the developing season, supporting both culinary use and pollinator wellbeing. Consumable blossom planting advances biodiversity draws in helpful bugs and improves the general excellence of the nursery.",
    link: "edible-flower-garden",
    list: [
      {
        title: "Picking Eatable Blossoms",
        description: "While choosing blossoms for a palatable nursery, it's fundamental to guarantee they are protected to consume. Well-known eatable blossoms incorporate nasturtiums, pansies, marigolds, violas, calendulas, and chamomile. Every one of these blossoms offers special flavors and uses in culinary applications."
      },
      {
        title: "Developing Circumstances",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/developing -edible.webp",
        description: "Different palatable blossoms have explicit developing circumstances. Most require all-around depleted soil, satisfactory daylight, and standard watering. Understanding the necessities of each blossom type guarantees solid development and bountiful sprouts."
      },
      {
        title: "Beginning from Seeds or Transfers",
        description: "Eatable blossoms can be begun from seeds or bought as youthful plants. Beginning from seeds takes into consideration a more prominent assortment of blossoms, while transfers give a speedier course to sprouting plants. Adhere to the particular establishing guidelines for each sort of blossom."
      },
      {
        title: "Soil Arrangement",
        description: "Setting up the dirt is critical for fruitful consumable bloom cultivating. Advance the dirt with natural matter, for example, manure to give fundamental supplements. Guarantee the dirt has great waste to forestall waterlogging, which can hurt the plants."
      },
      {
        title: "Friend Planting",
        description: "Consumable blossoms can be developed close by vegetables and spices in a buddy-establishing framework. Numerous palatable blossoms, similar to marigolds, repulse bother and draw in advantageous bugs, working on the well-being and yield of the whole nursery."
      },
      {
        title: "Nuisance and Infection The board",
        description: "To keep your consumable blossoms solid, utilize natural vermin and sickness the executives rehearse. Try not to utilize synthetic pesticides, as they can make the blossoms hazardous to eat. Utilize normal cures like neem oil, insecticidal cleanser, and gainful bugs to oversee bothers."
      },
      {
        title: "Harvesting Eatable Blossoms",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvesting-edible-garden.webp",
        description: "Reap blossoms in the first part of the day after the dew has dried however before the intensity of the day. Pick blossoms that are completely open and dynamic. Delicately wash them to eliminate any soil or bugs and use them right away or store them in a cool, dry spot"
      },
    ]
  },
  {
    blog_title: "Permaculture Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-permaculture.webp" ,
    blog_description: "Permaculture cultivating is an all-encompassing and maintainable way to deal with planting that looks to impersonate regular environments to make useful and versatile food frameworks. This technique incorporates standards of biology, planning, and agribusiness to make self-maintaining and regenerative frameworks that benefit the two people and the climate.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/permaculture-garden.webp" ,
    blog_desc: "At its center, permaculture cultivating underlines working with nature as opposed to against it, advancing biodiversity, soil well-being, water protection, and asset productivity. Key standards incorporate noticing and collaborating with regular frameworks, utilizing sustainable assets, limiting waste, and esteeming variety in plants and creatures.",
    link: "permaculture-garden",
    list: [
      {
        title: "Environment Mimicry",
        description: "Permaculture gardens are intended to imitate regular biological systems, integrating different plant species, natural life living spaces, and natural cycles. By noticing and gaining from normal frameworks, permaculture tries to make strong and useful food-creating scenes."
      },
      {
        title: "Regenerative Practices",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Regenerative-permaculture.webp",
        description: "Permaculture underlines regenerative practices that reestablish and work on the strength of the land after some time. Methods, for example, fertilizing the soil, mulching, water preservation, and soil building are fundamental to permaculture planting, cultivating soil richness and environment essentialness."
      },
      {
        title: "Food Timberlands",
        description: "Permaculture cultivates frequently highlight food woodlands, where eatable plants, natural product trees, bushes, and perpetual harvests are organized in layered, multi-practical plans. Food woods mirror regular woodland environments, giving a reasonable wellspring of food while supporting biodiversity."
      },
      {
        title: "Polyculture Planting",
        description: "Permaculture empowers polyculture planting, where numerous plant species are interplanted in assorted blends. This approach builds flexibility to bugs and illnesses, further develops soil well-being, and augments yield variety."
      },
      {
        title: "Water Reaping",
        description: "Permaculture utilizes water gathering strategies like water assortment, swales, lakes, and shape planting to effectively catch and store water. This assists ration with watering, recharging groundwater, and supporting plants during dry periods."
      },
      {
        title: "Friend Planting",
        description: "Buddy planting is a typical practice in permaculture gardens, where plants with commonly gainful connections come together. For instance, nitrogen-fixing plants might be interplanted with yields to further develop soil ripeness."
      },
      {
        title: "Enduring Plants",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/enduring_permaculture.webp",
        description: "Permaculture underlines the utilization of lasting plants, which require less support than yearly yields. Perennials, for example, natural product trees, perpetual vegetables, and spices give long-haul yields and dependability to the nursery biological system."
      },
      {
        title: "Squander Decrease",
        description: "Permaculture advances squander decrease through treating the soil, reusing, and reusing materials inside the nursery. Natural waste is transformed into important fertilizer, shutting the supplement circle and diminishing dependence on outside inputs."
      },
    ]
  },
  {
    blog_title: "Winter Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-winter-garden.webp" ,
    blog_description: "Winter planting is the act of developing plants during the colder months, guaranteeing that new produce and vegetation are accessible all year. This approach frequently includes choosing strong vegetables and spices that can endure low temperatures, like kale, spinach, carrots, and Brussels sprouts. Nursery workers could likewise utilize methods like virus casings, nurseries, and column covers to shield plants from ice and expand the developing season.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/winter-garden.webp",
    blog_desc: "The way to fruitful winter cultivating is arranging and planning. Picking the right plant assortments and giving sufficient security to the components are pivotal. Cold casings and nurseries make microclimates that trap intensity and safeguard plants from brutal climate, while mulching protects the dirt and holds dampness. A few nursery workers likewise use indoor development arrangements with developed lights to develop spices and mixed greens.",
    link: "winter-gardening",
    list: [
      {
        title: "Cold-Solid Plants",
        description: "Pick cold-tough plants that can endure ice and cold temperatures. Models incorporate winter vegetables like kale, spinach, carrots, and Brussels sprouts, as well as chilly open-minded blossoms like pansies, hellebores, and winter jasmine."
      },
      {
        title: "Defensive Designs",
        description: "Utilize defensive designs like virus outlines, column covers, and cloches to protect plants from unforgiving weather patterns. These designs give protection and assist with keeping a more steady microclimate around plants."
      },
      {
        title: "Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/mulching-winter-garden.webp",
        description: "Apply a layer of mulch around plants to protect the dirt and safeguard roots from frigid temperatures. Mulch additionally holds dampness and decreases weed development, advancing better plants during winter."
      },
      {
        title: "Indoor Planting",
        description: "Bring cultivating inside by developing spices, microgreens, and little vegetables on windowsills or in indoor holders. Use developed lights to enhance normal light and give sufficient brightening to establish development."
      },
      {
        title: "Winter Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/prunng-winter-garden.webp",
        description: " Perform winter pruning to eliminate dead or harmed branches and shape trees and bushes. Pruning empowers sound development and diminishes the gamble of infection and vermin invasions during winter."
      },
      {
        title: "Soil Planning",
        description: "Set up the dirt ahead of time by adding natural matter like fertilizer or matured compost. Very much corrected soil holds supplements and further develops waste, establishing a better-developing climate for winter plants."
      },
      {
        title: "Watering",
        description: "Change watering practices to suit winter conditions. Water plants in the first part of the day permit an abundance of dampness to dissipate during the day and lessen the gamble of ice harm. Keep away from overwatering, as chilly temperatures can prompt waterlogged soil."
      }
    ]
  },
  {
    blog_title: "Hanging Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-hanging-garden.webp" ,
    blog_description: "Hanging cultivating is an imaginative and space-effective approach to developing plants upward, particularly in regions with restricted space like overhangs, little gardens, or indoor spaces. It includes suspending plants in compartments or crates from different designs like snares, railings, or above radiates.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/hanging-garden.webp" ,
    blog_desc: "This cultivating strategy not only adds a dash of vegetation to your environmental factors but also considers a different scope of plants, from blooming delights like petunias and geraniums to reasonable spices like basil and mint, to flourish in a minimal and outwardly engaging way. With legitimate thoughtfulness regarding watering, daylight, and plant determination, hanging nurseries can change any space into an energetic and rich desert garden.",
    link: "hanging-gardening",
    list: [
      {
        title: "Picking Appropriate Plants",
        description: "Select plants that flourish in changing conditions, for example, following plants like pothos, greeneries, bug plants, or brilliant blossoming plants like petunias or fuchsias. Consider how much daylight your hanging garden gets and pick plants in like manner."
      },
      {
        title: "Proper Compartments",
        description: "Utilize lightweight holders made of materials like plastic, sap, or texture that can deal with the heaviness of soil and plants without being excessively weighty for hanging. Guarantee the holders have appropriate seepage to forestall waterlogging."
      },
      {
        title: "Quality Preparing Blend",
        description: "Utilize a well-depleting preparing blend appropriate for your plants' requirements, whether they incline toward dampness retentive soil, or quick depleting combinations. Consistently check the dirt's dampness levels to keep up with ideal developing circumstances."
      },
      {
        title: "Watering Cautiously",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-hanging.webp",
        description: "Water your hanging garden routinely yet avoid overwatering, which can prompt root decay. Change watering recurrence given plant types, weather patterns, and holder materials."
      },
      {
        title: "Treating Routinely",
        description: "Give supplements to your plants through normal preparation. Pick a decent manure or one customized to explicit plant needs, and keep the prescribed application rules to advance solid development."
      },
      {
        title: "Pruning and Managing",
        description: "Keep your hanging garden clean by pruning and managing plants depending on the situation. Eliminate dead or yellowing leaves, trim congested stems, and advance bushier development by squeezing back specific plants."
      },
      {
        title: "Sunlight Necessities",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sunlight-hanging-garden.webp",
        description: "Spot your draping garden in an area with sufficient daylight for the plants' requirements. Screen daylight openness over the day and change hanging positions if important to upgrade development."
      },
      {
        title: "Pest and Illness The board",
        description: "Watch out for bugs like aphids, mealybugs, or bug parasites, as well as indications of sicknesses like contagious contaminations or shrinking. Go to preventive lengths like involving insecticidal cleansers or natural pesticides depending on the situation."
      },
    ]
  },
  {
    blog_title: "Alpine Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-alpine-garden.webp" ,
    blog_description: "Snow-capped cultivating is a particular type of planting that spotlights on making and keeping up with plant assortments from high-elevation hilly districts, normally over the timberline. This kind of planting imitates the unforgiving circumstances tracked down in high conditions, like rough territory, outrageous temperatures, solid breezes, and restricted soil profundity.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/alpine-garden.webp",
    blog_desc: "Snow-capped cultivating is a particular type of planting that spotlights on making and keeping up with plant assortments from high-elevation hilly districts, normally over the timberline. This kind of planting imitates the unforgiving circumstances tracked down in high conditions, like rough territory, outrageous temperatures, solid breezes, and restricted soil profundity.",
    link: "alpine-gardening",
    list: [
      {
        title: "Grasping Snow-capped Conditions",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container-alpine-garden.webp",
        description: "Elevated cultivating includes developing plants in conditions like high-height mountain areas, described by cooler temperatures, well-depleting soil, and frequently cruel weather patterns. Grasp the particular difficulties and benefits of high conditions before beginning your nursery."
      },
      {
        title: "Exploring Reasonable Plants",
        description: "Exploration and pick establishes that are very much adjusted to snow-capped conditions, for example, high blossoms like gentians, edelweiss, saxifrages, and elevated grasses. Consider local species that flourish in rough, supplement unfortunate soils, and are lenient toward cold temperatures."
      },
      {
        title: "Choosing a Site",
        description: "Pick a site for your snow-capped garden that gets more than adequate daylight, ideally pointing toward the south or southeast to expand daylight openness. Guarantee the area has a great air course and insurance from solid breezes, which can be harmful to elevated plants."
      },
      {
        title: "Planning Great Depleting Soil",
        description: "Set up the dirt by consolidating a lot of coarseness, sand, and natural make a difference to further develop seepage and copy the rough, permeable soils of elevated districts. Keep away from weighty, mud-rich soils that hold water, as they can prompt root decay in snow-capped plants."
      },
      {
        title: "Preparing Fittingly",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/procedures-alpine.webp",
        description: "Utilize a decent compost or a particular recipe for high plants to give fundamental supplements without overwhelming development. Apply manure sparingly and as per the particular requirements of the plants, particularly during the developing season."
      },
      {
        title: "Establishing Methods",
        description: "Plant snow-capped species with care, guaranteeing they are situated at the right profundity and dividing as indicated by their particular prerequisites. Utilize an establishing blend customized for snow-capped plants to advance sound root improvement and development."
      },
      {
        title: "Watering Practices",
        description: "Water elevated plants sparingly, imitating the normal dampness examples of snow-capped conditions. Keep away from overwatering, particularly during winter when abundant dampness can prompt ice harm. Screen soil dampness consistently and change water on a case-by-case basis."
      },
      {
        title: "Safeguarding from Outrageous Climate",
        description: "Shield high plants from outrageous atmospheric conditions like ice, snow, or extraordinary daylight. Consider utilizing ice fabrics, conceal mesh, or cold casings to safeguard plants during temperature limits and give cover from unforgiving breezes"
      },
    ]
  },
  {
    blog_title: "Fairy Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-fairy-garden.webp" ,
    blog_description: "Pixie cultivating is a capricious and captivating type of small-scale planting that permits you to make little, mysterious scenes. It commonly includes planning and developing limited-scope gardens utilizing scaled-down plants, embellishments, and enhancements to bring out a feeling of imagination and marvel.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/fairy-garden.webp",
    blog_desc: "In pixie cultivating, the emphasis is on causing a beguiling and nitty gritty situation that could incorporate smaller than usual houses, pixie dolls, little furnishings, pathways, spans, and other mystical components like pixie lights or scaled-down lakes. These nurseries are in many cases housed in holders like pots, growers, or terrariums, making them appropriate for both indoor and outdoor settings.",
    link: "fairy-gardening",
    list: [
      {
        title: "Creative mind and Innovativeness",
        description: "Pixie planting starts with a flash of creative mind and imagination. Imagine smaller-than-usual universes where pixies, little persons, and supernatural animals abide amid minuscule plants and unconventional extras."
      },
      {
        title: "Choosing Appropriate Compartments",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container-fairy-garden.webp",
        description: "Select holders like pots, boxes, or shallow dishes that give adequate space to make your pixie garden. Guarantee the holder has waste openings to forestall waterlogging."
      },
      {
        title: "Choosing Smaller than usual Plants",
        description: "Pick little plants that suit the size of your pixie garden. Settle on little assortments of succulents, spices, greeneries, and bantam blossoms like smaller-than-expected roses, minuscule daisies, or scaled-down plants."
      },
      {
        title: "Making a Point of Convergence",
        description: "Assign a point of convergence for your pixie garden, for example, a small pixie house, palace, or charmed tree. This point of convergence fills in as the highlight around which you'll organize different components."
      },

      {
        title: "Adding Pixie Adornments",
        description: "Upgrade the supernatural environment by consolidating pixie embellishments like small pixie dolls, pixie entryways, pixie lights, scaled-down furnishings, capricious extensions, and beguiling pathways."
      },  {
        title: "Building Little Scenes",
        // image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/little-scane-fairy-garden.webp",
        description: "Plan scaled-down scenes utilizing little shakes, rocks, sand, or rock to make pathways, streams, and regular elements. Orchestrate these components to mirror regular scenes on a more limited size."
      },
      {
        title: "Establishing Procedures",
        description: "Plant small plants cautiously, guaranteeing they have sufficient room to develop and flourish. Utilize a well-depleting preparing blend reasonable for your picked plants and water them tenderly to try not to upset the dirt."
      },
     
    ]
  },
  {
    blog_title: "Historical Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-historical-garden.webp" ,
    blog_description: "Authentic planting alludes to the act of developing plants, scenes, and nursery plans that are well documented or enlivened by unambiguous periods ever. It includes exploring and reproducing the cultivating styles, plant determinations, and green methods that were predominant during various authentic periods.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/historical-garden.webp" ,
    blog_desc: "One part of authentic planting is the protection and rebuilding of legacy gardens, which are gardens that have verifiable importance because of their age, plan, or relationship with striking individuals or occasions. These nurseries frequently include period-proper plantings, compositional components, and format plans that mirror the cultivating patterns of the past.",
    link: "historical-gardening",
    list: [
      {
        title: "Exploring Verifiable Nurseries",
        description: "Start by investigating authentic nurseries from different periods and societies. Investigate garden plans, plant decisions, and arranging procedures that were famous during antiquated civilizations, bygone eras, Renaissance periods, and Victorian times, and the sky is the limit from there."
      },
      {
        title: "Choosing a Verifiable Topic",
        description: "Select a particular verifiable subject or time for your nursery, for example, an old Roman nursery with old-style models and formal designs, a middle-age garden highlighting spices and restorative plants, or a Victorian nursery with intricate botanical showcases and fancy designs."
      },
      {
        title: "Understanding Nursery Plan Standards",
        description: "Study the nursery plan standards applicable to your picked verifiable subject. Find out about components like evenness, balance, central focuses, pathways, and nooks, which were necessary to plant formats in various verifiable periods."
      },
      {
        title: "Choosing Legitimate Plants",
        description: "Pick establishes that are genuine to your chosen verifiable time or style. Research plant species that were generally developed during that time, including treasure assortments, local plants, and species with social importance."
      },
      {
        title: "Integrating Verifiable Elements",
        description: "Consolidate authentic highlights, for example, formal parterres, tie gardens, shrubberies, water highlights like wellsprings or lakes, pergolas, lattices, and period-suitable nursery structures like gazebos or structures."
      },
      {
        title:"Using Customary Materials",
        image:"https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Materials-historical.webp",
        description:"Utilize conventional materials and development strategies to reproduce the genuine look and feel of verifiable nurseries. Use materials like stone, block, earthenware, fashioned iron, and wood for pathways, boundaries, edging, and building components."
      },
      {
        title:"Keeping up with Realness",
        description:"Endeavor to keep up with credibility in your verifiable nursery by focusing on subtleties, for example, plant arrangement, variety plans, garden trimmings, and hardscape components. Integrate authentic cultivating rehearses like buddy planting, crop pivot, and normal vermin control strategies."
      }
    ]
  },
  {
    blog_title: "Green House Gardening",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_greenHouse.webp" ,
    blog_description: "Nursery cultivating alludes to the act of developing plants inside a controlled climate given by a nursery or a comparable construction. Nurseries are intended to establish an ideal developing climate by controlling variables like temperature, dampness, light levels, and ventilation.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/greenhouse.webp" ,
    blog_desc: "In nursery cultivating, a wide assortment of plants can be developed, including blossoms, vegetables, spices, organic products, and ornamentals. The controlled circumstances inside the nursery broaden the developing season, considering the all-year development and security of plants from unfavorable atmospheric conditions, nuisances, and sicknesses.",
    link: "green-house-garden",
    list: [
      {
        title: "Choosing the Right Nursery",
        description: "Select a nursery structure that suits your space, spending plan, and cultivating needs. Choices range from little leisure activity nurseries to bigger designs with cutting-edge environment control frameworks. Consider factors like size, materials (like glass or polycarbonate), ventilation choices, and protection."
      },
      {
        title: "Optimizing Light and Temperature",
        description: "Spot your nursery in an area that gets adequate daylight over the course of the day. Introduce concealing or blinds to control light power and forestall overheating during top daylight hours. Use radiators, fans, or cooling frameworks to keep up with ideal temperature ranges for various plant species."
      },
      {
        title: "Selecting Appropriate Plants",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Plants-green-house.webp",
        description: "Pick establishes that flourish in nursery conditions, like tropical plants, spices, vegetables, blooming plants, and fragile species that require security from ice or outrageous temperatures. Tailor plant determination to your environment zone and nursery conditions."
      },
      {
        title: "Creating Microclimates",
        description: "Use various regions inside your nursery to make microclimates fit explicit plant needs. For instance, place heat-adoring plants close to the southbound side for most extreme sun openness, while situating conceal lenient plants in shadier regions."
      },
      {
        title: "Implementing Water System Frameworks",
        description: "Introduce a trickle water system, clouding frameworks, or watering mats to give water to your plants proficiently. Consider utilizing clocks or computerized water system regulators to keep up with steady dampness levels and lessen manual watering endeavors."
      },
      {
        title: "Managing Dampness Levels",
        description: "Screen and control mugginess levels inside the nursery, particularly during times of high moistness or buildup. Use dehumidifiers, legitimate ventilation, and division between plants to forestall contagious sicknesses and advance solid development."
      },
      {
        title: "Nuisance and Illness The executives",
        description: "Carry out coordinated bug-the-board (IPM) procedures to forestall and oversee vermin and sicknesses in your nursery. Utilize regular hunters, advantageous bugs, natural controls, and natural vermin control strategies to limit synthetic medications."
      },
      {
        title: "Regular Upkeep and Observing ",
        description: "Lead normal support undertakings like cleaning nursery surfaces, checking for breaks or harm, examining gear (like fans, radiators, and water system frameworks), and observing plant wellbeing. Track plant development, bug sightings, and natural circumstances for better administration."
      },

    ]
  },
  {
    blog_title: "Vermi Compost",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_vcg.webp",
    blog_description: "Vermicompost, otherwise called worm manure or worm castings, is a supplement-rich natural compost and soil conditioner created through the decay of natural material by night crawlers.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/vermi_compost_vcg.webp",
    blog_desc: "Utilizing vermicompost in cultivating is a fabulous method for further developing soil well-being and advancing sound plant development.",
    link: "vermi-compost",
    list: [
      {
        title: "Set up your vermicompost",
        description: "Guarantee that your vermicompost is completely developed and has a dull, brittle surface. On the off chance that it's excessively wet or contains huge bits of natural matter, you might need to allow it to dry out or filter it to accomplish a better consistency."

      },
      {
        title: "Blend into the dirt",
        description: "There are multiple ways of utilizing vermicompost in cultivating, including blending it into the dirt, applying it as a top dressing, involving it as mulch, or making manure tea.",
        additional_desc: "Before planting, integrate vermicompost into your nursery soil. Dig it into the best not many creeps of soil utilizing a digging tool or nursery fork. Mean to spread a layer of vermicompost equitably across the establishing region and afterward blend it completely with the current soil. This will assist with further developing soil structure, water maintenance, and supplement levels.",

      },
      {
        title: "Topdressing And Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Mulching_vcg.webp",
        description: "In the wake of planting, you can apply vermicompost as a top dressing around the foundation of your plants. Spread a far layer (around 1/4 to 1/2 inch) of vermicompost over the dirt surface, being mindful so as not to cover the plant stems. Tenderly work it into the dirt utilizing a hand rake or your fingers. This strategy gives a sluggish arrival of supplements to your plants as the vermicompost separates.",
        additional_desc: "Use vermicompost as mulch around your nursery plants to assist with holding dampness, smother weeds, and give a persistent wellspring of supplements. Apply a layer of vermicompost mulch (around 1 to 2 inches thick) around the foundation of your plants, leaving a couple of creeps of room around the stems to forestall decay. Try to renew the mulch layer depending on the situation all through the developing season."
      },
      {
        title: "Screen plant wellbeing",
        description: "Watch out for the well-being and development of your nursery plants after applying vermicompost. Search for indications of further developed power, expanded blooming, and by and large plant wellbeing. Change the application rate depending on the situation given your perceptions."
      }
    ]
  },
  {
    blog_title: "Summer vegetable",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_vsg.webp",
    blog_description: "Developing vegetables in the late spring can remunerate, as many harvests flourish in the warm climate and long days.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/summer_veg.webp",
    blog_desc: "The environment fluctuates generally from one area to another, so the best summer vegetables to develop can rely upon elements like temperature, moistness, and precipitation designs.",
    blog_bold_desc: "In any case, here are some well-known summer vegetables that will generally flourish in many pieces of India:",
    link: "summer-vegetable",
    list: [
      {
        title: "Tomatoes",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/tomato_vsg.webp",
        description: "With their flexibility and high return, tomatoes are the most loved summer vegetable in India. They can be filled in pots, compartments, or straightforwardly in the ground, and there are numerous assortments fit to various environments.",
      },
      {
        title: "Cucumbers",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Cucumbers_vsg.webp",
        description: "Cucumbers are reviving and simple to fill in the Indian summer. They require standard watering and a lot of daylight. Assortments like the Indian dosa cucumber or the English cucumber are famous decisions."
      },
      {
        title: "Okra",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Okra_vsg.webp",
        description: "Okra cherishes the intensity and is a staple in numerous Indian dishes. It has moderately low support and creates plentiful harvests all through the mid-year. Okra plants require all-around depleted soil and a lot of daylight.",
      },
      {
        title: "Unpleasant Gourd (Karela)",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Bittergourd_vsg.webp",
        description: "Unpleasant gourd is an intense open-minded vegetable that is well known in Indian cooking for its exceptional flavor and medical advantages. It requires warm temperatures and standard watering to flourish.",

      },
      {
        title: "Cabbage",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Cabbage_vsg.webp",
        description: "Cabbage is a cool-season crop however can be filled in the cooler locales of India throughout the late spring months. It requires all-around depleted soil and standard watering to keep the heads from parting.",
      }
    ]
  },
  {
    blog_title: "Coriander",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_corrinder.webp",
    blog_description: "Coriander, otherwise called cilantro or dhania in certain districts, is a flexible and sweet-smelling spice that is generally utilized in cooking styles all over the planet",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/coriander.webp",
    blog_desc: "Be that as it may, new coriander isn't the most straightforward to track down on the lookout. Even though Indian vegetable merchants wouldn't fret throwing in some free coriander with different vegetables you get, it may not be the freshest or best. We accept we can be in charge of the credibility of whatever goes in our body by developing it in-house. Here is an extensive manual for planting coriander. Whether inside or out, in little spaces or a ranch, the developing aid ahead will prepare you to develop a coriander plant in all situations. Cheerful developing!",
    blog_bold_desc: "Here is a fledgling's manual for developing Coriander:",
    link: "coriander",
    list: [
      {
        title: "Seed and Spot Choice",
        description: "Start your coriander development excursion by choosing top-notch seeds. Search for seeds that are stout, firm, and sweet-smelling, as these demonstrate newness and suitability. You can find coriander seeds at garden focuses, nurseries, or online stores.",
        additional_desc: "Conclude whether you need to develop coriander in holders inside or outside your nursery. Assuming that you pick holders, guarantee they have seepage openings to forestall waterlogging. For outside planting, select a spot that gets halfway shade to full daylight."
      },
      {
        title: "Soil Arrangement",
        description: "Set up the dirt or prepare a blend for establishing coriander. Coriander flourishes in well-depleting soil with a pH level somewhere in the range of 6.0 and 7.0. You can make a reasonable blend by joining gardening soil with natural fertilizer or very much spoiled excrement. Guarantee the dirt is free and brittle for ideal root development."
      },
      {
        title: "Sowing Seeds and Watering",
        description: "Plant the coriander seeds straightforwardly into the dirt or compartments. Sow the seeds at a profundity of about ¼ to ½ inch (0.6 to 1.3 cm) and space them roughly 6 inches (15 cm) separated. Cover the seeds softly with soil and water delicately to settle them set up..",
        additional_desc: "Keep the dirt reliably soggy yet not waterlogged. Water the coriander plants consistently, particularly during dry periods or sweltering climates. Keep away from overwatering, as it can prompt root decay. Consider utilizing a watering can with a fine rose connection to give a delicate shower of water."
      },
      {
        title: "Preparation And Gathering",
        description: "Feed your coriander plants with a fair compost each 2 a month to advance incredible development and plentiful foliage. You can utilize natural manures like manure tea or fluid kelp compost for a characteristic and practical way to deal with taking care of your plants.",
        additional_desc: "Coriander leaves can be collected when they are youthful and delicate, normally 3 a month after planting. Utilize sharp scissors to cut the leaves from the external piece of the plant, passing on the inward passes to develop."
      },
      {
        title: "Diminishing and Support",
        description: "Coriander plants thrive in environments with ample sunlight. Place your containers in a location where they receive at least 4-6 hours of sunlight daily.",
        additional_desc: "When the coriander seedlings arise, flimsy them out to guarantee appropriate dispersing and empower sound development. Leave the most grounded and best seedlings while eliminating any powerless or packed ones. As the plants develop, keep the region around them liberated from weeds and trash to forestall rivalry for supplements and water."
      }
    ]
  },
  {
    blog_title: "Calendula Officinalis",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_calendula.webp",
    blog_description: "Calendula officinalis, usually known as calendula or pot marigold, is a brilliant and flexible herbaceous plant that has a place with the Asteraceae family. With its energetic and merry blossoms, calendula isn't just a wonderful expansion to gardens yet in addition holds critical social, restorative, and culinary significance.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/calendula_garden.webp",
    blog_desc: "Calendula officinalis is a fleeting fragrant herbaceous enduring, developing to 80 cm tall, with inadequately spread careless or erect stems. The leaves are elliptical lanceolate, 5-17 cm long, bushy on the two sides, and with edges whole or every so often waved or pitifully toothed.",
    blog_bold_desc: "Here is a fledgling's manual for developing calendula:",
    link: "calendula-officinalis",
    list: [
      {
        title: "Choosing Seeds or Seedlings",
        list_Data: [
          "Calendula seeds are broadly accessible at garden focuses, nurseries, and online stores. Search for excellent seeds from respectable providers.",
          "On the other hand, you can begin with seedlings from a nursery if you favor an early advantage."
        ]
      },
      {
        title: "Picking an Establishing Area",
        list_Data: [
          "Calendula flourishes in full sun to halfway shade. Pick a spot in your nursery or gallery that gets no less than 4-6 hours of daylight day to day.",
          "Guarantee the dirt is well-depleting and rich. Calendula isn't excessively finicky about soil types yet favors loamy, well-depleting soil."
        ]
      },
      {
        title: "Sowing Seeds or Seedlings",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seed_cg.webp",
        list_Data: [
          "If sowing seeds straightforwardly outside, sow them in late winter or late summer. Dissipate the seeds over pre-arranged soil and daintily press them into the ground. Cover them with a slight layer of soil.",
          "In the case of beginning seeds inside, plant them in biodegradable pots or seed plates loaded up with a prepared blend. Keep the dirt sodden and warm until the seeds grow, then, at that point, relocate the seedlings outside after the last ice date."
        ]
      },
      {
        title: "Watering and Upkeep",
        list_Data: [
          "Keep the dirt reliably soggy yet not waterlogged, particularly during the germination time frame.",
          "Once settled, calendula is generally low-support. Water the plants consistently, especially during droughts.",
          "Deadhead spent blossoms to energize persistent sprouting and forestall self-cultivating on the off chance that you like.",
          "Mulch around the plants to moderate dampness and smother weeds."
        ]
      },
      {
        title: "Treatment And Gathering",
        list_Data: [
          "Calendula by and large doesn't need weighty preparation. Notwithstanding, on the off chance that your dirt is poor, you can change it with natural manure or a decent compost before planting.",
          "On the other hand, feed the plants with a weakened fluid manure once per month during the developing season.",
          "Calendula blossoms can be collected when completely open for use in natural arrangements, teas, or as palatable embellishments.",
          "To collect, essentially squeeze off the blossoms at their base. Gather consistently to energize more blossoms."
        ]
      },
      {
        title: "Saving Seeds",
        list_Data: [
          "Permit a few blossoms to develop and dry on the plant. When dry, gather the seeds and store them in a cool, dry spot for future planting"
        ]
      }
    ]
  },
  {
    blog_title: "Peppermint",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_peppermint.webp",
    blog_description: "Developing peppermint at home in India can be a remunerating experience, offering not just a new stockpile of this flexible spice yet in addition the delight of sustaining a plant from seed to collect.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pepparmint.webp",
    blog_desc: "Peppermint is a mixture type of mint, a hybrid of watermint, and spearmint. Native to Europe and the Center East, the plant is currently broadly spread and developed in numerous locales of the world. It is every so often tracked down in the wild with its parent species.",
    blog_bold_desc: "Here is a fledgling's manual for developing Peppermint:",
    link: "peppermint",
    list: [
      {
        title: "Select an Area And Get ready for the Soil",
        description: "Peppermint flourishes in daylight, so pick a spot that gets no less than 4-6 hours of daylight every day. On the off chance that you're developing inside, place your peppermint pot close to a bright window or underdeveloped lights.",
        additional_desc: "Utilize a well-depleting preparing blend or a mix of nursery soil and manure for establishing peppermint. Guarantee the dirt remaining parts are reliably clammy and not waterlogged."
      },

      {
        title: "Establishing Peppermint",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/plant_pg.webp",
        description: "You can begin peppermint from seeds, however it's more straightforward and quicker to develop from cuttings or transfers. Get peppermint seedlings from a nursery or engender cuttings from a current plant. Plant the seedlings or cuttings in the pre-arranged soil, leaving around 18-24 creeps of room between each plant"
      },
      {
        title: "Watering And Treating",
        description: "Peppermint plants require ordinary watering to keep the dirt equitably clammy. Water at whatever point the top inch of soil feels dry to the touch. Keep away from overwatering, as peppermint is helpless to root decay.",
        additional_desc: "Peppermint doesn't require weighty treatment. Feed your plants with a decent fluid compost weakened to half-strength once every month during the developing season."
      }, {
        title: "Winter Care",
        description: "In colder districts, peppermint might pass on back in winter however as a rule regrows in spring. If you're developing peppermint inside, guarantee it gets adequate light and warmth during the colder months."
      },
      {
        title: "Peppermint tea recipe",
        description: "Bubble 2 cups of water and add 6-10 newly reaped peppermint passes on to the water. Cover with top and steep for 4-5 mins. Add honey or lemon according to taste. A without caffeine tea before sleep time for your loosening up rest is prepared!"
      },
      {
        title: "Benefits of Peppermint",
        list_Data: [
          "Peppermint has a reviving and fortifying flavor profile, with a cooling vibe that makes it a famous fix in teas, drinks, confections, and treats.",
          "Peppermint is hostile to queasiness properties and can assist with alleviation from movement infection, morning disorder during pregnancy, and sickness related to chemotherapy.",
          "The smell of peppermint has been displayed to upgrade mental execution, further develop fixation, and lift mental sharpness. It tends to be valuable for contemplating, working, or at whatever point you want a psychological shot in the arm.",
          "Partake in the new smell and kind of peppermint by involving its leaves in teas, drinks, mixed greens, and pastries. Peppermint leaves can likewise be dried for some time in the future or utilized new in cooking."
        ]
      }
    ]
  },
  {
    blog_title: "Chamomile",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_chamomile.webp",
    blog_description: "Chamomile is not difficult to develop from seed-sow directly in a pre-arranged seedbed in harvest time, or inside from Spring, dissipating the seed over the outer layer of dampness, without peat seed fertilizer.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/chamomile.webp",
    blog_desc: "Chamomile, with its sensitive blossoms and quieting scent, isn't just a well-known homegrown cure yet in addition a magnificent expansion to any garden. Developing chamomile in India can be a compensating experience, offering an abundance of advantages from restorative purposes to fragrant teas. Whether you're a fledgling landscaper or an accomplished horticulturist, this thorough aide will walk you through the course of effectively developing chamomile in the different environments of India.",
    blog_bold_desc: "Here is a novice's manual for developing chamomile:",
    link: "chamomile",
    list: [
      {
        title: "Pick Area And Plan Soil",
        description: "Chamomile flourishes in very depleted soil and full daylight, making it ideal for development in different districts of India. Select an area in your nursery that gets something like 6-8 hours of daylight day to day. Guarantee the dirt is free, fruitful, and well-depleting to forestall waterlogging, which can prompt root decay.",
      },
      {
        title: "Prepareing the Dirt",
        description: "Before establishing chamomile seeds or seedlings, set up the dirt by slackening it with a nursery fork or turner. Integrate natural matter, for example, fertilizer or all-around decayed compost to further develop soil richness and seepage. Hold back nothing acidic to a nonpartisan pH level, in a perfect world somewhere in the range of 5.6 and 7.5, for ideal development."
      },
      {
        title: "Sowing Seeds or Seedlings",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seeding_chg.webp",
        description: "Chamomile can be grown from seeds or seedlings, depending on your preference and availability. Sow seeds directly into the prepared soil after the last frost date, spacing them 6-12 inches apart. Alternatively, transplant seedlings into the garden, ensuring they are planted at the same depth as they were in their containers.Chamomile can be developed from seeds or seedlings, contingent upon your inclination and accessibility. Plant seeds straightforwardly into the pre-arranged soil after the last ice date, dispersing them 6-12 inches separated. On the other hand, relocate seedlings into the nursery, guaranteeing they are planted at similar profundity as they were in their holders."
      },
      {
        title: "Watering and Upkeep",
        description: "Once planted, water chamomile routinely to keep the dirt reliably damp yet not waterlogged. Abstain from above watering to forestall contagious illnesses, rather than settling on a trickle water system or watering at the foundation of the plants. Mulch around the foundation of chamomile plants to save dampness and smother weeds."
      },
      {
        title: "Preparation And Reaping",
        description: "Chamomile regularly doesn't need weighty treatment, particularly assuming the dirt is advanced with natural matter. In any case, you can apply a reasonable manure, like a 10-10-10 NPK definition, sparingly during the developing season to advance solid development and blooming.",
        additional_desc: "Reap chamomile blossoms when they are completely open however before they start to wither or become brown. Cut the blossoms with scissors or pruning shears, leaving a couple of creeps of stem connected. Dry the gathered blossoms in a very much-ventilated region away from direct daylight until they are totally dry and fresh."
      },
      {
        title: "Using Chamomile",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/skin_care_chg.webp",
        description: "Once dried, chamomile blossoms can be utilized new or put away in water/airproof holders for some time later. Chamomile is normally fermented into a mitigating tea known for its unwinding and stomach-related benefits. Furthermore, chamomile blossoms can be mixed into oils, added to bathwater, or utilized in hand-crafted skincare items."
      }
    ]
  },
  {
    blog_title: "Lemon Balm",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_lemon_balm.webp",
    blog_description: "The lemon ointment is a lasting herbaceous plant in the mint family and local to south-focal Europe, the Mediterranean Bowl, Iran, and Focal Asia, however presently naturalized somewhere else. It develops to the greatest level of 1 m. The leaves have a gentle lemon fragrance.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/lemon-balm.webp",
    blog_desc: "Lemon ointment, with its invigorating citrus fragrance and heap therapeutic properties, is a superb expansion to any home nursery. Whether you're an accomplished landscaper or a beginner lover, developing lemon emollient at home is a fulfilling and direct undertaking. In this exhaustive aid, we will investigate all that you want to be aware of to effectively develop, gather, and use lemon emollient in your lawn.",
    blog_bold_desc: "Here is a fledgling's manual for developing Peppermint:",
    link: "lemon-balm",
    list: [
      {
        title: "Grasping Lemon Ointment",
        description: "Lemon emollient (Melissa officinalis) is an individual from the mint family famous for its lemony scent and relieving properties. Starting from the Mediterranean area, lemon salve is currently developed overall for its culinary, restorative, and fancy purposes. This strong lasting spice highlights serrated, heart-formed leaves and groups of little white or pale pink blossoms that draw in pollinators to the nursery."
      },
      {
        title: "Picking Area And Setting up the Dirt",
        description: "Lemon ointment flourishes in very much depleted soil and is inclined toward full daylight, making it reasonable for development in a large number of environments. Select a bright spot in your nursery or overhang where the plant will get no less than 4-6 hours of daylight day to day. While lemon medicine can endure fractional shade, it will in general create stronger development and rejuvenating balms in sunnier areas.",
        additioal_desc: "Before planting lemon balm, prepare the soil by loosening it with a garden fork or tiller. Lemon balm prefers moist, fertile soil with a slightly acidic to neutral pH level (6.0-7.5). Incorporate organic matter such as compost or well-rotted manure into the soil to improve fertility and drainage, ensuring optimal growing conditions for the herb."
      },
      {
        title: "Establishing Lemon Emollient",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_lbg.webp",
        description: "The lemon ointment can be proliferated from seeds, cuttings, or divisions, contingent upon your inclination and accessibility. In the case of beginning from seeds, sow them straightforwardly into the pre-arranged soil after the last ice date, covering them daintily with a slender layer of soil. On the other hand, relocate seedlings or divisions into the nursery, separating them 12-18 inches to consider more than adequate development.",

      },
      {
        title: "Watering and Support",
        description: "Once planted, water lemon emollient consistently to keep the dirt uniformly soggy but not waterlogged. Stay away from overwatering, as exorbitant dampness can prompt root decay and other parasitic infections. Mulch around the foundation of the plants to save dampness, stifle weeds, and manage soil temperature. Lemon salve is somewhat low-support and doesn't need weighty preparation. Notwithstanding, you can apply fair manure sparingly during the developing season to support sound development."
      },
      {
        title: "Bug and Illness The executives",
        description: "Lemon ointment is somewhat impervious to nuisances and illnesses, yet periodic issues might happen, especially in sticky environments. Look out for aphids, bug parasites, and whiteflies, which can be controlled with insecticidal cleanser or neem oil. Forestall parasitic illnesses, for example, fine mold by giving sufficient air dissemination and abstaining from above watering."
      },
      {
        title: "Using Lemon Ointment",
        description: "The lemon ointment is valued for its culinary, restorative, and fragrant properties, making it a flexible spice in the kitchen and then some. New lemon salve leaves can be utilized to season teas, mixed greens, soups, sauces, and treats, conferring a splendid citrusy flavor to dishes. The leaves can likewise be dried for some time in the future or mixed into oils, vinegar, and syrups for culinary and corrective purposes. Therapeutically, a lemon emollient is esteemed for its quieting and stomach-related properties, and it very well may be fermented into a mitigating tea or applied topically as a characteristic solution for skin disturbances and bug chomps."
      },
      {
        title: "Lemon emollient tea recipe",
        description: "To make lemon ointment tea at home, basically steep a modest bunch of new lemon emollient leaves or 1-2 teaspoons of dried leaves in steaming hot water for 5-10 minutes. Strain, and improve with honey whenever wanted, and appreciate it!"
      }
    ]
  },
  {
    blog_title: "Microgreens",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_microgreen.webp",
    blog_description: "Microgreens are youthful, consumable greens that are collected at the beginning phase of development, normally when the main genuine leaves have been created. They are gathered somewhere in the range of 7 to 21 days after germination, contingent upon the particular plant assortment. Microgreens are valued for their energetic varieties, extreme flavors, and thought supplement content.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/microgreens.webp",
    blog_desc: "Developing microgreens is a phenomenal and fledgling accommodating method for beginning cultivating, regardless of whether you have restricted space or experience",
    blog_bold_desc: "Here is a fledgling's manual for developing microgreen:",
    link: "microgreens",
    list: [
      {
        title: "Pick Your Seeds",
        description: "You can develop microgreens from various seeds, including broccoli, radish, kale, arugula, and spinach, and that's just the beginning. Ensure you buy seeds explicitly named for microgreen creation, as they are untreated and protected to eat."
      },
      {
        title: "Select a Developing Compartment",
        description: "You can utilize plates, shallow pots, or even reuse things like old plastic compartments or seedling plates. Simply ensure the compartment has waste openings to forestall waterlogging."
      },
      {
        title: "Set up the Developing Medium",
        description: "Fill your picked holder with a clean, soilless developing medium like peat greenery, coco coir, or a business seed beginning blend. Dampen the medium somewhat before planting seeds."
      },
      {
        title: "Plant the Seeds",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seeds_mg.webp",
        description: "Disperse the seeds equitably over the outer layer of the developing medium. You can plant them thickly since microgreens don't require a lot of room to develop. Tenderly press the seeds into the medium to guarantee great contact. "
      },
      {
        title: "Give Sufficient Water And light",
        description: "Water your microgreens tenderly, utilizing a shower bottle or a watering can with a fine rose connection. Keep the developing medium reliably damp, however not waterlogged, all through the developing system.",
        additional_desc: "Microgreens need a lot of light to appropriately develop. Place your developing holder in an area with brilliant, backhanded daylight or use develop lights on the off chance that normal light is restricted."
      },
      {
        title: "Reaping",
        description: "Microgreens are normally prepared to collect within 1-3 weeks, contingent upon the assortment. When the main genuine leaves show up and the greens are 1-3 inches tall, you can gather them. Utilize clean scissors to clip the greens simply over the dirt line"
      },
      {
        title: "Partake in Your Microgreens",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/enjoy_mg.webp",
        description: "Wash your reaped microgreens delicately under chilly water to eliminate any dirt particles, then wipe them off with paper towels. Use them promptly in servings of mixed greens, sandwiches, smoothies, or as a trimming for different dishes."
      }
    ]
  },
  {
    blog_title: "Tometoes",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_tometo.webp",
    blog_description: "Developing tomatoes at home in India can be a remunerating experience, particularly taking into account how flexible and flavorful new tomatoes are in Indian food.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/tometoes.webp",
    blog_desc: "Developing tomatoes at home can be a superb and compensating experience for groundskeepers, all things considered. With their dynamic tones, succulent surface, and rich flavor, local tomatoes add a scrumptious touch to different Indian dishes, from curries to plates of mixed greens and chutneys. Whether you have a roomy terrace or a comfortable overhang, developing tomatoes permits you to enjoy the newness of ready natural products picked directly from the plant.",
    blog_bold_desc: "Here is a fledgling's manual for developing Tomatoes:",
    link: "tometoes",
    list: [
      {
        title: "Assortment Choice",
        description: "Start by choosing tomato assortments that suit your nearby environment and developing circumstances. Think about variables like the size of your nursery or compartments, the length of your developing season, and your culinary inclinations. In India, famous assortments incorporate Cherry Tomatoes for their productive fruiting, Roma Tomatoes for their substantial surface ideal for sauces, and cross-breed assortments like Pusa Ruby or Arka Vikas, known for their illness opposition and versatility to the Indian climate.",

      },
      {
        title: "Ideal Developing Circumstances",
        description: "Tomatoes flourish in full daylight, so pick a bright area in your nursery or overhang that gets no less than 6-8 hours of direct daylight every day. Guarantee the dirt is well-depleting and wealthy in natural matter. On the off chance that your dirt is poor or compacted, change it with fertilizer, all-around decayed excrement, or coco peat to further develop ripeness and surface. Go for the gold acidic soil pH of 6.0-6.8, which is great for tomato development.",

      },
      {
        title: "Planting and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_tog.webp",
        description: "In the case of beginning from seeds, start inside around 6 two months before the last ice date in your district. Use a seedling plate or little pots loaded up with a seed-beginning blend. Relocate seedlings into the nursery or compartments whenever they have fostered their most memorable genuine leaves and the weather conditions have heated up. Space plants 2-3 feet separated to take into consideration legitimate air course and development. Offer help like stakes, enclosures, or lattices to keep the plants upstanding as they develop.",
        additional_desc: "Water profoundly at the foundation of the plants to keep the dirt reliably soggy, and prepare consistently with a reasonable manure or natural fertilizer to give fundamental supplements."
      },
      {
        title: "Upkeep and Bug The executives",
        description: "Screen your tomato plants consistently for indications of irritations and illnesses like aphids, whiteflies, early scourge, or fine mold. Eliminate any impacted leaves immediately and consider utilizing natural nuisance control techniques, for example, neem oil shower or insecticidal cleanser. Prune away any suckers that arise between the principal stem and branches to advance better air flow and natural product improvement. Mulch around the foundation of the plants to save dampness, smother weeds, and keep up with even soil temperature."
      },
      {
        title: "Gathering and Delight",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/enjoying_tometo.webp",
        description: "Gather ready tomatoes when they arrive at their full tone and size, commonly 60-80 days in the wake of relocating. Tenderly turn the natural products off the plant or use scissors to cut them. Permit green tomatoes to mature inside by setting them in a warm, radiant area. Flush collected tomatoes under chilly water to eliminate any trash and appreciate them new in servings of mixed greens, sandwiches, sauces, or cooked dishes. Share the overflow with loved ones, commending the delight of local goodness directly from your nursery or overhang."
      }
    ]
  },
  {
    blog_title: "Lettuce",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_lettuce.webp",
    blog_description: "Lettuce, experimentally known as Lactuca sativa, is a verdant green vegetable that is generally developed and delighted around the world for its fresh surface, gentle flavor, and flexibility in the kitchen.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/lettuce.webp",
    blog_desc: "Lettuce, the quintessential verdant green, remains a persevering image of newness, essentialness, and culinary flexibility. As a staple in kitchens around the world, this unassuming vegetable holds a unique spot in the hearts and palates of endless people, commended for its fresh surface, sensitive flavor, and dynamic tones. From the delicate passes on of butterhead assortments to the generous smash of romaine, lettuce offers a range of culinary encounters that take care of different preferences and inclinations.",
    blog_bold_desc: "Here is a novice's manual for developing Lettuce:",
    link: "lettuce",
    list: [
      {
        title: "Choosing Appropriate Assortments",
        description: "Pick lettuce assortments that are appropriate to your environment and developing circumstances. Think about variables like the length of your developing season, the force of daylight, and temperature vacillations. In India, where temperatures can fluctuate generally, search for heat-open-minded assortments like Summer Fresh, Buttercrunch, or Red Serving of mixed greens Bowl. These assortments are bound to flourish in hotter circumstances than normal in numerous districts of India."
      },
      {
        title: "Setting up the Establishing Region",
        description: "Set up a reasonable establishing region for your lettuce. Pick an area that gets in complete shade, particularly during the most blazing piece of the day, to keep the lettuce from darting (going to rashly seed). Guarantee the dirt is well-depleting and wealthy in natural matter. Slacken the dirt to a profundity of around 6-8 inches, eliminating any stones, garbage, or weeds. Correct the dirt with fertilizer or all-around decayed compost to work on its surface and fruitfulness."
      },
      {
        title: "Planting and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sowing_lettuce.webp",
        description: "Plant lettuce seeds straightforwardly into the pre-arranged soil. Sow the seeds shallowly. For free leaf lettuce, space seeds around 4-6 inches separated in columns or blocks. Keep the dirt reliably soggy yet not waterlogged, watering delicately with a watering can or hose spout to try not to upset the seeds.",
        additional_desc: "Consider covering the dirt with a slender layer of mulch to assist with holding dampness and stifle weed development. Screen the plants routinely for indications of vermin or illnesses, and address any issues instantly utilizing natural nuisance control techniques if necessary."
      },
      {
        title: "Support and Collecting",
        description: "As your lettuce plants develop, give steady consideration to guarantee sound development and advancement. Meager out packed seedlings to guarantee appropriate dispersing, permitting each plant space to create. Water the plants consistently, particularly during droughts, and prepare gently with a decent compost each 3 a month. Collect lettuce leaves when they arrive at the ideal size, commonly around 4-6 inches long for free leaf assortments or when the heads are firm for head-framing types.",
        additional_desc: "Use scissors to cut leaves from the external edges, permitting the middle passes to keep developing for future harvests. Appreciate new, local lettuce in plates of mixed greens, sandwiches, wraps, or as a topping for different dishes."
      }
    ]
  },
  {
    blog_title: "Carrots",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_carrots.webp",
    blog_description: "Carrots, experimentally known as Daucus carota, are one of the most famous and generally developed root vegetables around the world. Known for their dynamic tone, sweet flavor, and flexibility in the kitchen, carrots have been developed for millennia and are delighted in various culinary dishes all over the planet.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/carrots.webp",
    blog_desc: "Carrots are root vegetables that have a place with the Apiaceae family, which likewise incorporates parsley, celery, and fennel. They are known for their lively orange tone, even though they can likewise be found in different shades like purple, red, yellow, and white, contingent upon the assortment.",
    blog_bold_desc: "Here is a fledgling's manual for developing Carrots:",
    link: "carrots",
    list: [
      {
        title: "Choosing the Right Assortments",
        description: "Begin by picking carrot assortments that are appropriate to your environment and soil conditions. In India, assortments like Nantes, Danvers, and Imperator are well-known decisions. Consider factors like the length of your developing season and the sort of soil you have. Carrots lean toward free, well-depleting soil with a pH of 6.0-6.8."
      },
      {
        title: "Setting up the Dirt",
        description: "Carrots flourish in free, well-emptying soil that is free of rocks and clusters. Before planting, set up the dirt by relaxing it to a profundity of no less than 12 inches. Eliminate any trash and correct the dirt with natural matter, for example, fertilizer or very much spoiled compost to further develop richness and design.",
      },
      {
        title: "Planting the Seeds",
        description: "Carrot seeds are little and fragile, so handle them with care. Plant the seeds straightforwardly into the pre-arranged soil, separating them around 2 inches separated in columns that are 12-18 inches separated. Sow the seeds shallowly, covering them with a slender layer of soil. Keep the dirt reliably soggy yet not waterlogged to help germination, which ordinarily requires 10-14 days."
      },
      {
        title: "Diminishing and Support",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/thinning_carrot.webp",
        description: "When the carrot seedlings have arisen and fostered their most memorable genuine leaves, slim them to guarantee appropriate dispersing. Carrots need sufficient space to develop, so slight them to stand 3-4 inches separated in the columns. Weed the region consistently to forestall contests for supplements and water. Give steady dampness all through the developing season, meaning to keep the dirt uniformly soggy."
      },
      {
        title: "Collecting and Stockpiling",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvest_carrot.webp",
        description: "Carrots are typically ready to harvest 60-80 days after sowing, depending on the variety. Check the size of the carrots by gently pulling one from the soil. Harvest carrots when they reach the desired size, usually around 1/2 to 3/4 inch in diameter. Use a garden fork or trowel to loosen the soil around the carrots before gently pulling them up. Trim off the tops and wash the carrots thoroughly before storing them in a cool, dark place. Carrots can be stored in the refrigerator for several weeks or preserved by freezing or canning."
      }
    ]
  },
  {
    blog_title: "Spinach",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Spinach.webp",
    blog_description: "Spinach is a verdant green vegetable known for its energetic green tone and wholesome wealth. It has a place with the Amaranthaceae family and is experimentally known as Spinacia oleracea. Spinach is local to focal and southwestern Asia yet is presently developed around the world.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/spinach.webp",
    blog_desc: "Spinach is valued for its high happy of nutrients, minerals (like iron, calcium, and magnesium), cell reinforcements, and other useful phytochemicals. Spinach is ordinarily consumed crudely in plates of mixed greens, cooked in different dishes, for example, soups, stews, sautés, and pasta sauces, and mixed into smoothies. Its gentle flavor and delicate surface make it a flexible fix in many culinary manifestations. Spinach is commended for its various medical advantages, including advancing eye well-being, supporting bone wellbeing, helping processing, and lessening the gamble of persistent infections like coronary illness and specific kinds of disease.",
    blog_bold_desc: "Here is a fledgling's manual for developing spinach:",
    link: "spinach",
    list: [
      {
        title: "Choosing the Right Assortment",
        description: "Begin by choosing the proper assortment of spinach for your environment and developing circumstances. There are a few kinds of spinach, including savoy, semi-savoy, and level-leafed spinach. Think about elements like your nearby environment, accessible space, and favored gathering time. In locales with cooler temperatures, decide on assortments that are more open-minded toward heat, like Tyee or Bloomsdale Well established. For hotter environments, consider assortments like Malabar or New Zealand spinach, which are more intensity-safe."
      },
      {
        title: "Setting up the Dirt",
        description: "Spinach flourishes in well-depleting, ripe soil with a somewhat acidic to impartial pH level (around 6.0-7.0). Before planting, set up the dirt by releasing it to a profundity of around 6-8 inches. Eliminate any weeds, rocks, or flotsam and jetsam, and revise the dirt with natural matter, for example, fertilizer or very much decayed compost to work on its surface and ripeness. Guarantee the dirt is soggy however not waterlogged."
      },
      {
        title: "Planting the Seeds",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_spinach.webp",
        description: "Plant spinach seeds straightforwardly into the pre-arranged soil, dividing them as indicated by the assortment's necessities. Sow the seeds about ½ inch down and 2-4 inches separated in lines divided 12-18 inches separated. Cover the seeds daintily with soil and tenderly pat them down. Water the dirt completely in the wake of planting to guarantee great seed-to-soil contact and to start germination."
      },
      {
        title: "Giving Consideration and Support",
        description: "Keep the dirt reliably sodden all through the developing season, watering routinely to keep it from drying out. Mulch around the foundation of the plants to assist with holding dampness, smother weeds, and control soil temperature. Slim out stuffed seedlings whenever they have laid out evident leaves, leaving the most grounded plants separated around 6-8 inches. Prepare the plants with a decent manure or fertilizer tea every 3 a month to give fundamental supplements to solid development."
      },
      {
        title: "Reaping",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvest_spinach.webp",
        description: "Spinach is normally prepared for reap 6 two months after planting, contingent upon the assortment and developing circumstances. Reap spinach leaves when they arrive at the ideal size, typically around 4-6 inches long. Use scissors to cut the external leaves from the plant, passing on the inward passes on to keep developing for future harvests.",
        additional_desc: "Reap spinach consistently to support constant creation all through the developing season. Wash collected spinach passes on under cool water to eliminate any trash, then, at that point, wipe them off with a perfect towel before putting away them in the fridge."
      }
    ]
  },
  {
    blog_title: "Capsicum",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Banner_capsicum.webp",
    blog_description: "Capsicum, otherwise called chime pepper or sweet pepper, is a brilliant and flexible vegetable that has a place with the nightshade family Solanaceae. Famous for its fresh surface, lively varieties, and sweet flavor, capsicum is a cherished fix in cooking styles all over the planet.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/capsicum.webp",
    blog_desc: "Capsicum arrives in different tones, including green, red, yellow, orange, and, surprisingly, purple. It has an unmistakable chime shape with an empty inside and is normally portrayed by three or four curves. Capsicum ranges in size from little, reduced down peppers to huge, hearty ones, contingent upon the assortment.",
    blog_bold_desc: "Here is a novice's manual for developing capsicum:",
    link: "capsicum",
    list: [
      {
        title: "Choosing the Right Assortment",
        description: "Capsicum, otherwise called ringer peppers, come in different tones like green, red, yellow, and orange, each with its flavor profile and pleasantness level. Think about variables like the length of your developing season, accessible space, and wanted pepper size. A few well-known assortments of capsicum incorporate California Marvel, Sweet Chocolate, and Lord of the North. Pick an assortment that suits your taste inclinations and environment."
      },
      {
        title: "Setting up the Establishing Region",
        description: "Capsicum plants flourish in warm, bright circumstances and well-depleting soil. Select a radiant area in your nursery or gallery that gets no less than 6-8 hours of direct daylight each day.",
        additional_desc: "Guarantee the dirt is rich, free, and well-depleting, with a pH level somewhere in the range of 6.0 and 7.0. On the off chance that your dirt is weighty or compacted, alter it with natural matter like fertilizer, all-around decayed compost, or coconut coir to work on its surface and richness."
      },
      {
        title: "Planting and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seeding_capsicum.webp",
        description: "Plant capsicum seeds or seedlings straightforwardly into the pre-arranged soil after the risk of ice has passed and the dirt has warmed up to no less than 70°F (21°C). Space the plants around 18-24 inches separated in columns or bunches to consider satisfactory air dissemination and development. Water the dirt completely after planting to guarantee great seed-to-soil contact and to start germination.",
        additional_desc: "Keep the dirt reliably clammy but not waterlogged all through the developing season, particularly during hot and dry periods. Treat the plants with a fair manure or natural fertilizer each 4 month and a half to give fundamental supplements to sound development and natural product creation."
      },
      {
        title: "Watering and Treatment",
        description: "Capsicum plants require standard watering to flourish. Water the plants profoundly and equitably, keeping the dirt reliably damp yet not waterlogged. Try not to wet the foliage to forestall the gamble of parasitic infections",
        additional_desc: "Furthermore, prepare the plants consistently with a fair manure or fertilizer tea each 4 month and a half to give fundamental supplements to sound development and organic product creation."
      },
      {
        title: "Reaping and Stockpiling",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvest_capsicum.webp",
        description: "Capsicum peppers are normally prepared for gathering 60-90 days in the wake of planting, contingent upon the assortment and developing circumstances. Collect the peppers when they arrive at the ideal size and variety, ordinarily when they are firm, gleaming, and completely shaded. Use scissors or pruners to cut the peppers from the plant, leaving a short stem connected.",
        additional_desc: "Reap the peppers routinely to support consistent organic product creation all through the developing season. Store gathered peppers in the cooler for as long as multi week or freeze them for longer-term capacity."
      },
    ]
  },
  {
    blog_title: "Radish",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_radish.webp",
    blog_description: "Developing radishes at home is simple and fast, giving new, fresh vegetables in only half a month. Plant radish seeds in all around depleted soil, keeping them divided around 1 inch, and water every time to keep up with soggy soil. Flimsy seedlings to 2 inches separated once they grow a couple of inches tall, and collect radishes when they arrive at around 1 inch in breadth",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/radish.webp",
    blog_desc: "Developing tomatoes at home can be a wonderful and remunerating experience for groundskeepers, all things considered. With their dynamic tones, succulent surface, and rich flavor, local tomatoes add a heavenly touch to various Indian dishes, from curries to servings of mixed greens and chutneys. Whether you have an open terrace or a comfortable gallery, developing tomatoes permits you to enjoy the newness of ready natural products picked directly from the plant.",
    blog_bold_desc: "Here is a novice's manual for developing Radish:",
    link: "radish",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "Begin by choosing the proper assortment of radishes for your inclinations and developing circumstances. Radishes come in different shapes, sizes, and varieties, going from round and red to prolonged and white. Think about elements like the length of your developing season, accessible space, and wanted radish type. Famous assortments incorporate Cherry Beauty, French Breakfast, White Icicle, and Hidden Treat. Pick an assortment that suits your taste inclinations and environment."
      },
      {
        title: "Setting up the Dirt",
        description: "Radishes lean toward free, well-depleting soil with a pH level somewhere in the range of 5.5 and 6.8. Before planting, set up the dirt by relaxing it to a profundity of around 6-8 inches. Eliminate any stones, flotsam and jetsam, or weeds, and correct the dirt with natural matter like fertilizer, very much spoiled compost, or coconut coir to work on its surface and richness. Guarantee the dirt is wet however not waterlogged."
      },
      {
        title: "Planting and Separating",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seeding_radish.webp",
        description: "Plant radish seeds straightforwardly into the pre-arranged soil in lines or bunches, dispersing them as per the assortment's necessities. Plant the seeds about ½ inch down and 1-2 inches separated in lines divided 12-18 inches separated. Cover the seeds softly with soil and tenderly pat them down. Water the dirt completely after planting to guarantee great seed-to-soil contact and to start germination."
      },
      {
        title: "Care and Upkeep",
        description: "Keep the dirt reliably wet all through the developing season, particularly during hot and dry periods. Water the radishes consistently, intending to keep the dirt equitably sodden yet not waterlogged. Dainty outpacked seedlings whenever they have laid out obvious leaves, leaving the most grounded plants dispersed around 2-3 inches separated",
        additional_desc: "Mulch around the foundation of the plants to assist with holding dampness, stifle weeds, and direct soil temperature. Prepare the radishes with a fair manure or natural fertilizer every 3 a month to give fundamental supplements to sound development and root improvement."
      },
      {
        title: "Reaping and Stockpiling",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvest_radish.webp",
        description: "Radishes are normally prepared to gather 3 a month in the wake of planting, contingent upon the assortment and developing circumstances. Reap the radishes when they arrive at the ideal size, for the most part around 1 inch in distance across for round assortments and 4-6 inches long for stretched assortments.",
        additional_desc: "Utilize a nursery fork or scoop to relax the dirt around the radishes before pulling them up delicately. Flush gathered radishes under chilly water to eliminate any dirt flotsam and jetsam, then, at that point, trim off the tops and store them in the fridge for as long as multi week. Appreciate new, local radishes in servings of mixed greens, sandwiches, sautés, or as a crunchy nibble."
      },
    ]
  },
  {
    blog_title: "Green Chillies",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_chillies.webp",
    blog_description: "Growing tomatoes at home in India can be a rewarding experience, especially considering how versatile and delicious fresh tomatoes are in Indian cuisine.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/chillies.webp",
    blog_desc: "Growing tomatoes at home can be a delightful and rewarding experience for gardeners of all levels. With their vibrant colors, juicy texture, and rich flavor, homegrown tomatoes add a delicious touch to a variety of Indian dishes, from curries to salads and chutneys. Whether you have a spacious backyard or a cozy balcony, cultivating tomatoes allows you to savor the freshness of ripe fruits picked straight from the vine.",
    blog_bold_desc: "Here's a beginner's guide to growing Tometoes:",
    link: "green-chillies",
    list: [
      {
        title: "Selecting the Right Variety",
        description: "Begin by selecting the appropriate variety of green chilies based on your preferences and growing conditions. There are numerous varieties of green chilies available, each with its own flavor profile, heat level, and growth habits. Consider factors such as the length of your growing season, available space, and desired level of spiciness. Some popular varieties of green chilies include Jalapeno, Serrano, Thai Green, and Cayenne. Choose a variety that suits your taste preferences and climate."
      },
      {
        title: "Preparing the Planting Area",
        description: "Green chilies thrive in warm, sunny conditions and well-draining soil. Select a sunny location in your garden or balcony that receives at least 6-8 hours of direct sunlight per day. Ensure the soil is fertile, loose, and well-draining, with a pH level between 6.0 and 7.0. If your soil is heavy or compacted, amend it with organic matter such as compost, well-rotted manure, or coconut coir to improve its texture and fertility."
      },
      {
        title: "Planting and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_chillies.webp",
        description: " Plant green chili seeds directly into the prepared soil after the danger of frost has passed and the soil has warmed up to at least 70°F (21°C). Sow the seeds about ¼ inch deep and 12-18 inches apart in rows or clusters. Water the soil thoroughly after planting to ensure good seed-to-soil contact and to initiate germination. ",
        additional_desc: "Keep the soil consistently moist but not waterlogged throughout the growing season, especially during hot and dry periods. Fertilize the plants with a balanced fertilizer or organic compost every 4-6 weeks to provide essential nutrients for healthy growth and fruit production."
      },
      {
        title: "Maintenance and Harvesting",
        description: "As your green chili plants grow, provide regular care and maintenance to ensure optimal growth and productivity. Thin out overcrowded seedlings once they have established true leaves, leaving the strongest plants spaced about 12-18 inches apart. Stake or cage the plants to provide support and prevent them from falling over under the weight of the fruit. Monitor the plants regularly for signs of pests or diseases, and address any issues promptly using organic pest control methods if needed.",
        additional_desc: "As your green chili plants grow, provide regular care and maintenance to ensure optimal growth and productivity. Thin out overcrowded seedlings once they have established true leaves, leaving the strongest plants spaced about 12-18 inches apart. Stake or cage the plants to provide support and prevent them from falling over under the weight of the fruit. Monitor the plants regularly for signs of pests or diseases, and address any issues promptly using organic pest control methods if needed."
      },
    ]
  },
  {
    blog_title: "Heliotrope",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Heliotrope.webp",
    blog_description: "Heliotrope, otherwise called 'Heliotropism' in organic terms, alludes to the peculiarity shown by certain plants where they arrange themselves towards the sun. The term 'Heliotrope' is derived from the Greek words 'helios,' the importance sun, and 'tropos,' significance turn. This regular way of behaving is generally seen in an assortment of plant-animal types, especially those with adaptable stems or leaves..",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/heliotrope.webp",
    blog_desc: "Heliotropes are valued for their groups of little, fragrant blossoms that sprout in shades of purple, violet, or white, contingent upon the species and cultivar. These blossoms frequently have a sweet, vanilla-like fragrance, making them well-known decisions for decorative nurseries and decorative layouts. Notwithstanding their stylish allure, heliotropes are additionally known for their appealing, spear-molded passes and their capacity to flourish in bright, very depleted areas.",
    blog_bold_desc: "Here is a novice's manual for developing Heliotrope:",
    link: "heliotrope",
    list: [
      {
        title: "Choosing Area and Soil Readiness",
        description: "Heliotrope flourishes in warm, radiant areas with well-depleting soil. Pick a spot in your nursery or overhang that gets full sun for no less than 6-8 hours per day. Guarantee the dirt is ripe, well-depleting, and marginally acidic to unbiased with a pH level around 6.0-7.0. Set up the dirt by releasing it to a profundity of around 8-10 inches, eliminating any weeds, rocks, or garbage. Correct the dirt with natural matter like fertilizer, very much spoiled compost, or coconut coir to work on its surface and fruitfulness."
      },
      {
        title: "Planting and Care",
        description: "Plant heliotrope seedlings or cuttings in the pre-arranged soil after the risk of ice has passed in the spring. Space the plants around 12-18 inches separated to take into consideration sufficient air course and development. Water the plants completely after planting to assist with settling the dirt and support the root foundation.",
        additional_desc: "Keep the dirt reliably wet but not waterlogged all through the developing season, particularly during hot and dry periods. Water the heliotrope plants profoundly at the base, trying not to wet the foliage to forestall parasitic sicknesses. Mulch around the foundation of the plants with natural mulch, for example, bark chips or straw to assist with holding dampness, stifle weeds, and control soil temperature."
      },
      {
        title: "Pruning and Support",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning_heliotrope.webp",
        description: "Prune the heliotrope plants routinely to advance rugged development and eliminate spent blossoms to energize consistent sprouting. Squeeze off the tips of the stems to empower fanning and more blossom creation. Deadhead blurred blossoms consistently to delay the sprouting time frame and keep the plant from setting seeds. Screen the plants for indications of bugs or sicknesses, like aphids, bug vermin, or fine buildup. Address any issues quickly utilizing natural vermin control strategies, for example, insecticidal cleanser or neem oil splash. Guarantee great air course around the plants by dispersing them enough and abstaining from congestion."
      },
      {
        title: "Treatment and watering",
        description: "Prepare heliotrope establishes each 4 a month and a half during the developing season with a decent, water-solvent compost formed for blossoming plants, weakened by bundle guidelines, and apply it around the foundation of the plant.",
        additional_desc: "Water routinely, planning to keep the dirt reliably clammy yet not waterlogged, watering profoundly no less than one time each week, ideally in the early morning, and checking soil dampness consistently to change watering recurrence depending on the situation in light of ecological circumstances."
      },
      {
        title: "advantages of heliotrope",
        list_Data: [
          "Heliotrope produces bunches of little, fragrant roses in shades of purple, blue, or white, contingent upon the assortment. The sweet, vanilla-like fragrance of the blossoms draws in pollinators like honey bees and butterflies, making heliotrope a superb expansion to any garden.",
          "Heliotrope plants include rich, dull green leaves with a smooth surface, adding profundity and differentiation to blossom beds, boundaries, and holders. The foliage is frequently sweet-smelling, producing a charming fragrance when brushed or squashed",
          "Heliotrope is generally low support and simple to develop, requiring just normal watering, infrequent treatment, and light pruning to keep up with its shape and empower rugged development.",
          "The fragrant blossoms of heliotrope draw in helpful bugs, for example, honey bees and butterflies, which assume a significant part in fertilization and keeping a solid biological system in the nursery.",
          "Heliotrope is appropriate for compartment cultivating, permitting you to partake in its magnificence and aroma on porches, galleries, or decks. It adds a dash of polish and appeal to open-air living spaces and can be joined with different annuals or perennials to make shocking compartment shows."
        ]
      },
    ]
  },
  {
    blog_title: "Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_rose.webp",
    blog_description: "Roses, frequently viewed as the embodiment of magnificence and polish in the realm of blossoms, hold an extraordinary spot in nurseries, flower bundles, and societies all over the planet. With their dazzling scent, dynamic tones, and different structures, roses have been appreciated for a long time, motivating writers, craftsmen, and groundskeepers the same.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/rose.webp",
    blog_desc: "One famous choice is to develop little rose assortments in compartments or pots put on windowsills, overhangs, or indoor nurseries. These minimal roses are appropriate for indoor development, offering a nonstop showcase of blossoms in a scope of varieties and structures. With legitimate consideration and thoughtfulness regarding lighting and watering needs, small-scale roses can flourish inside, adding a hint of appeal to any room.",
    blog_bold_desc: "Here is a novice's manual for developing Rose:",
    link: "rose",
    list: [
      {
        title: "Choosing the Right Assortment",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/selection_Rose.webp",
        list_Data: [
          "Pick rose assortments that are appropriate to your environment and developing circumstances. Consider factors, for example, daylight openness, soil type, and accessible space.",
          "Famous sorts of roses for home nurseries incorporate half-and-half tea roses, floribunda roses, bush roses, and climbers. Research various assortments to track down ones that meet your inclinations and necessities."
        ],
      },
      {
        title: "Picking a Reasonable Area",
        list_Data: [
          "Select a bright area for establishing roses, as they flourish in full sun, getting no less than 6 hours of direct daylight every day.",
          "Guarantee the establishing site has well-depleting soil with great ripeness. Change weighty dirt soil with natural matter, for example, fertilizer or matured compost to further develop waste and soil structure."
        ],
      },
      {
        title: "Setting up the Dirt",
        list_Data: [
          "Before planting, set up the dirt by slackening it to a profundity of 12-18 inches and eliminating any weeds, rocks, or flotsam and jetsam.",
          "Consolidate natural matter like fertilizer, all-around decayed compost, or peat greenery into the dirt to advance it with supplements and work on its surface."
        ],
      },
      {
        title: "Establishing Roses",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Garden-Roses.webp",
        list_Data: [
          "Dig an opening that is somewhat more extensive and more profound than the root bundle of the rose plant. Make a little hill of soil in the focal point of the opening to help the roots.",
          "Cautiously eliminate the rose plant from its compartment and delicately release the roots. Place the plant in the focal point of the opening, guaranteeing that the united association (the enlarged region where the stems meet the roots) is simply over the dirt level.",
          "Refill the opening with soil, delicately firming it around the roots. Water the plant completely in the wake of planting to settle the dirt and eliminate any air pockets."
        ],
      },
      {
        title: "Watering and Mulching",
        list_Data: [
          "Keep the dirt uniformly damp but not waterlogged, particularly during the foundation time frame. Water the roses profoundly something like one time per week, giving sufficient dampness to arrive at the roots.",
          "Apply a 2-3 inch layer of natural mulch, for example, wood chips, bark, or straw around the foundation of the plants to assist with holding soil dampness, stifle weeds, and control soil temperature."
        ],
      },
      {
        title: "Treating and Pruning",
        list_Data: ["Treat roses consistently during the developing season with a reasonable compost formed for blooming plants. Adhere to the producer's guidelines for application rates and recurrence.",
          "Prune roses yearly during the lethargic season (generally pre-spring or late winter) to eliminate dead, sick, or harmed wood, as well as to shape the plants and advance fiery development and blooming."

        ],
      },
      {
        title: "Bug and Sickness The executives",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/disease_rose.webp",
        list_Data: [
          "Screen your roses routinely for indications of vermin like aphids, bug parasites, or Japanese insects, as well as illnesses like fine mold, dark spots, or rust.",
          "Treat any nuisance or sickness issues expeditiously utilizing natural strategies, for example, insecticidal cleanser, neem oil, or fungicides supported for use on roses."

        ],
        additional_desc: "By following these definite advances and giving legitimate consideration, you can effectively develop roses at home, partaking in their lovely sprouts and inebriating scent for a large number of seasons. With persistence, commitment, and a tad of information, you can make a dazzling rose garden that will give pleasure and magnificence to your open-air space long into the future."
      }
    ]
  },
  {
    blog_title: "Peonies Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-ponies.webp",
    blog_description: "Peonies are lavish and fragrant blossoms that are darling for their huge, delicate petals and lively tones. They have a place with the sort Paeonia and are local to Asia, Europe, and North America. Peonies come in different shades like pink, white, red, and, surprisingly, yellow, for certain assortments highlighting various tones in a single sprout. They ordinarily blossom in pre-summer to late spring, filling gardens with their enthralling excellence.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Peonies.webp",
    blog_desc: "These blossoms are frequently connected with sentiment, thriving, and favorable luck, pursuing well-known decisions for weddings, flower bundles, and decorative designs. Peonies are additionally known for their life span, for certain assortments going on for quite a long time when focused on appropriately.",
    link: "pronics-rose",
    list: [
      {
        title: "Site Determination",
        description: "Pick an establishing site that gets no less than 6 hours of daylight day to day. Peonies flourish in full sun but can endure halfway shade. Ensure the dirt is very much depleted and wealthy in natural matter. Sandy topsoil or loamy soils with great seepage are great for peonies. Avoid establishing in low-lying regions or areas inclined to waterlogging, as over-the-top dampness can prompt root decay."
      },
      {
        title: "Soil Arrangement",
        description: "Test the dirt pH to guarantee it falls inside the ideal scope of 6.5 to 7.0. Change acidic soils with lime to raise pH if needed. Improve soil ripeness by consolidating all-around matured fertilizer, matured compost, or natural matter into the establishing region. This improves soil structure and gives supplements to solid development."
      },
      {
        title: "Planting",
        description: "Peonies are normally established in the fall (September to October) or late winter (Walk to April) while the dirt is still workable. Dig an opening that is 2 feet wide and 2 feet down. Release the dirt at the base and sides of the hole. Place the peony root (likewise called a tuber or crown) in the focal point of the opening with the eyes or buds looking vertically. The eyes ought to be 1-2 creeps underneath the dirt surface. Backfill the opening with soil, tenderly firming it around the root to eliminate air pockets. Water completely after planting to settle the dirt."
      },
      {
        title: "Spacing And Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Spacing-peonies.webp",
        description: "Space peony plants 3 to 4 feet separated to take into account sufficient wind stream and forestall stuffing as they mature. Give reliable dampness to recently established peonies, particularly during droughts. Water profoundly supports root growth. Once laid out, peonies are reasonably dry spell open-minded however benefit from standard watering, particularly during sweltering summers."
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch layer of natural mulch, like destroyed bark or straw, around the foundation of the plants. Mulch holds soil dampness, smothers weeds, and controls soil temperature."
      },
      {
        title: "Support",
        description: "Tall peony assortments or those with enormous sprouts might expect backing to keep the stems from bowing or breaking under the heaviness of the blossoms. Use peony rings or stakes to help the plants depending on the situation."
      },
      {
        title: "Fertilizing",
        description: "Apply a fair compost, for example, a 10-10-10 plan, in late winter before new development shows up. Keep away from extreme nitrogen, as it can advance foliage development to the detriment of blossoms."
      },
      {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Pruning-Peonies.webp" ,
        description: "Deadhead spent blossoms by scaling them back to a leaf hub to advance constant blooming. In pre-winter or late fall, after the principal ice, slice back the foliage to ground level and eliminate any flotsam and jetsam from the nursery bed. This lessens the gamble of illness over winter."
      },
      {
        title: "Pests and Infections",
        description: "Screen peonies for normal bugs like aphids, insect parasites, and thrips. Treat pervasions immediately with an insecticidal cleanser or neem oil. Watch for indications of parasitic sicknesses like botrytis scourge (dim shape) or fine mold. Give great air dissemination, abstain from above watering, and eliminate tainted plant parts to forestall spread."
      },
    ]
  },
  {
    blog_title: "Camellias Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-camellias-rose.webp" ,
    blog_description: "Camellias are flawless blossoming plants known for their rich and energetic sprouts. They have a place with the family Camellia, which incorporates a few animal types and cultivars. Beginning from East Asia, especially Japan, and China, camellias have become well-known fancy plants around the world.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Camellias-rose.webp" ,
    blog_desc: "Camellia blossoms differ in variety, including shades of white, pink, red, and now and again yellow. They frequently have rich, polished green foliage that supplements their sprouts delightfully. The blossoms can be single, semi-twofold, or twofold petaled, making a different cluster of shapes and textures. These blossoms commonly sprout in pre-spring to late winter, adding an eruption of variety to gardens during the cooler months. They are valued for their versatility and capacity to flourish in different environments, making them appropriate for both indoor and open-air development.",
    link: "camellias-rose",
    list: [
      {
        title: "Choose the Right Assortment",
        description: "There are numerous assortments of camellias, including Camellia japonica, Camellia sasanqua, and Camellia reticulata. Pick an assortment that is appropriate to your environment and developing circumstances."
      },
      {
        title: "Select a Reasonable Area",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/select-area-Camellias.webp"  ,
        description: "Camellia's incline toward fractional shade or separated daylight. Plant them where they will get morning sun and evening conceal, particularly in more blazing regions. Ensure the establishing region has all-around depleted soil. Camellias could do without waterlogged soil however require predictable dampness."
      },
      {
        title: "Soil Planning",
        description: "Test the dirt pH, as camellias favor somewhat acidic soil with a pH of 6.0 to 6.5. Revise basic soil with sulfur or peat greenery to bring down pH on the off chance that needed. Improve soil waste by adding natural matter like fertilizer or very much matured compost. Blend it into the main few crawls of soil before planting."
      },
      {
        title: "Planting",
        description: " Plant camellias in the fall or late winter when the weather conditions are gentle and the dirt is workable. Dig an opening two times as wide and similarly as profound as the root chunk of the camellia plant. Place the camellia in the opening, ensuring the highest point of the root ball is level with the dirt surface. Backfill with soil and water completely to settle the dirt around the roots."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably wet, particularly during the plant's foundation time frame. Water profoundly yet stay away from waterlogging. Mulch around the foundation of the camellia with natural mulch like bark chips or pine straw to hold soil dampness and control temperature."
      },
      {
        title: "Fertilizing",
        description: "Feed camellias with a reasonable, slow-discharge compost figured out for corrosive cherishing plants. Apply compost in late winter before new development starts and again in pre-summer or early summer. Avoid unreasonable nitrogen, as it can prompt lavish foliage to the detriment of sprouts."
      },
      {
        title: "Pruning",
        description: "Prune camellias after they are done blossoming to shape the plant and eliminate dead or unhealthy branches. Abstain from weighty pruning, as camellias sprout on old wood. Remove spent blossoms to support constant blossoming and forestall seed arrangement."
      },
      {
        title: "Protection from Ice",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/snow-Camellias.webp" ,
        description: "If you live in a colder environment, shield camellias from ice or frigid temperatures by covering them with an ice fabric or bringing pruned plants inside during winter."
      },
      {
        title: "Pests and Illnesses",
        description: "Screen camellias for normal bugs like aphids, scale bugs, and tea parasites. Treat invasions with insecticidal cleanser or neem oil. Watch for parasitic sicknesses like leaf spots or root decay."
      },  
    ]
  },
  {
    blog_title: "Ranunculus Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Ranunculus.webp" ,
    blog_description: "Ranunculus blossoms, otherwise called buttercups, are enchanting and sensitive sprouts that arrive in various energetic tones. They have a place with the sort Ranunculus, which incorporates many species dispersed all over the planet. Ranunculus blossoms are famous for their layered petals and striking appearance, making them #1 among landscapers and flower vendors the same.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Ranunculus.webp" ,
    blog_desc: "These blossoms can be tracked down in shades of white, yellow, orange, pink, red, and purple, for certain assortments displaying various varieties in a solitary sprout. The petals are frequently unsettled or bordered, adding to their charm and giving them a rich, finished look. Ranunculus blossoms normally show up in spring and late spring, making a brilliant presentation in gardens, decorative layouts, and flower bundles. They are known for their dependable quality while cut, making them a fabulous decision for flower plans and embellishments.",
    link: "ranunculus-rose",
    list: [
      {
        title: "Establishing Time",
        description: "Ranunculus bulbs are best established in the fall, ordinarily from September to November, for spring blossoms. If you're utilizing tubers or corms, plant them in pre-spring or late winter after the last ice."
      },
      {
        title: "Daylight and Area",
        description: "Pick an establishing site that gets full sun to halfway shade. Ranunculus plants flourish in sufficiently bright regions with something like 6 hours of daylight day to day, particularly in cooler environments."
      },
      {
        title: "Soil Planning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Soil Readiness-raniums.webp" ,
        description: "Set up the dirt by guaranteeing it is very much depleted and prolific. Correct weighty or mud soils with natural matter like fertilizer, peat greenery, or very much decayed compost to further develop waste and soil structure. Aim for a somewhat acidic to nonpartisan pH scope of 6.0 to 7.0 for ideal development."
      },
      {
        title: "Planting Ranunculus Bulbs",
        description: "While establishing bulbs, place them in the dirt with the paw-formed side confronting and the gathered side confronting together. Bulbs ought to be separated 4-6 inches separated and established 2-3 inches deep. Ensure the dirt is clammy but not waterlogged during planting."
      },
      {
        title: "Planting Ranunculus Tubers or Corms",
        description: "If utilizing tubers or corms, absorb them in water for a couple of hours before planting to rehydrate and invigorate growth.Plant tubers or corms 1-2 inches deep with the developing places (eyes) confronting upwards. Space them 4-6 inches separated to take into consideration legitimate development and dividing."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably soggy all through the developing season, particularly during droughts or sweltering climates. Water profoundly and completely, guaranteeing the root zone gets satisfactory dampness without waterlogging. Avoid above watering to forestall parasitic illnesses and spotlight watering at the foundation of the plants."
      },
      {
        title: "Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Mulching-ranunculus.webp" ,
        description: "Apply a layer of natural mulch, like straw, destroyed bark, or fertilizer, around the foundation of the plants to hold soil dampness, stifle weeds, and manage soil temperature. Mulching likewise safeguards the roots during temperature vacillations."
      },
      {
        title: "Fertilizing",
        description: "Apply a decent, slow-discharge compost or manure planned for blossoming bulbs in late winter as new development arises. Adhere to bundle directions for application rates in light of the size of your establishing region."
      },
      {
        title: "Support",
        description: "Ranunculus stems might need help as they develop and create blossoms. Introduce stakes or use plant upholds from the get-go in the season to forestall bowing or breaking of stems, particularly in breezy circumstances."
      },
      {
        title: "Deadheading and Pruning",
        description: "Consistently eliminate spent blossoms (deadhead) to advance ceaseless sprouting and forestall seed arrangement. Trim back any yellowing or harmed foliage to keep up with plant well-being and appearance. Prune back spent blossom stalks to the foundation of the plant whenever they have completed the process of sprouting."
      },
    ]
  },
  {
    blog_title: "Dianthus Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/baneer-dianthus-rose.webp" ,
    blog_description: `Dianthus, normally known as "pinks" or "carnations," are wonderful blooming plants that have a place with the class Dianthus. They are local to Europe and Asia yet are presently developed overall for their alluring sprouts and wonderful scent. Dianthus plants are known for their reduced development propensity and wealth of brilliant blossoms.`,
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Dianthus.webp",
    blog_desc: "The blossoms of Dianthus arrive in a large number of varieties, including shades of pink, red, white, purple, and some of the time bi-hued assortments. They have sensitive, bordered petals that give them an enchanting and rich appearance. Dianthus sprouts can be single or twofold, mixing up and allure of these plants. These blossoms normally sprout in spring and summer, albeit a few assortments might bloom irregularly consistently. They are often utilized in borders, rock nurseries, and compartments, adding a sprinkle of variety and scent to open-air spaces.",
    link: "dianthus-rose",
    list: [
      {
        title: "Choose the Right Assortment",
        description: "Select Dianthus assortments that fit your environment and nursery conditions. Normal sorts incorporate Dianthus barbatus (Sweet William), Dianthus chinensis (China pink), and Dianthus caryophyllus (Carnation)."
      },
      {
        title: "Daylight and Area",
        description: "Plant Dianthus in an area that gets full sun to halfway shade. They flourish in all-around depleted soil and expect something like 6 hours of daylight day to day for ideal development and blooming."

      },
      {
        title: "Soil Arrangement",
        description: "Set up the dirt by guaranteeing it is very much depleted, prolific, and marginally antacid to impartial (pH 6.0 to 7.5). Alter weighty or mud soils with natural matter like fertilizer or matured compost to further develop waste and soil structure."
      },
      {
        title: "Planting",
        description: "Plant Dianthus in the spring or late summer. Space plants 6-12 inches separated, contingent upon the assortment and development habit. Dig an opening somewhat bigger than the root wad of the plant. Place the Dianthus plant in the red, refill with soil, and water completely."
      },
      {
        title: "Watering",
        description: "Water Dianthus routinely to keep the dirt equitably wet but not waterlogged. Permit the dirt to dry somewhat between waterings, particularly in warm climates."
      },
      {
        title: "Mulching",
        description: "Apply a layer of natural mulch, for example, straw or destroyed bark, around the foundation of the plants to moderate dampness, stifle weeds, and manage soil temperature. Mulching likewise safeguards the roots during outrageous temperatures."
      },
      {
        title: "Fertilizing",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Fertilizing-Dianthus-roses.webp" ,
        description: "Feed Dianthus with a fair, slow-discharge compost in late winter as new development arises. Adhere to bundle directions for application rates given the size of your establishing area. Avoid over-preparing, as exorbitant nitrogen can prompt rich foliage to the detriment of blossoms."
      },
      {
        title: "Support",
        description: "Tall assortments of Dianthus might profit from marking or support to forestall the twisting or breaking of stems, particularly in breezy circumstances. Eliminate spent blossoms (deadhead) consistently to advance nonstop sprouting and forestall seed arrangement. Trim back any leggy or congested stems to keep up with plant shape and life."
      },
      {
        title: "Pest and Infection The executives",
        description: "Screen Dianthus plants for normal bugs like aphids, thrips, and bug parasites. Treat invasions instantly with an insecticidal cleanser or neem oil.Watch for indications of contagious sicknesses like fine buildup or rust. Give great airflow, abstain from above watering, and apply fungicides if fundamental. In locales with cold winters, shield Dianthus plants from ice by covering them with a layer of mulch or utilizing ice material. Water sparingly during winter lethargy to forestall root decay."
      },
    ]
  },
  {
    blog_title: "Zinnias Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Zinnias.webp" ,
    blog_description: "Zinnias are energetic and bright blossoming plants that have a place with the variety Zinnia, local to North and South America. They are famous decisions for nurseries and scenes because of their splendid blossoms, simple development, and capacity to draw in pollinators like butterflies and honey bees.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Zinnias-rose.webp" ,
    blog_desc: "Zinnia blossoms arrive in a wide cluster of varieties, including red, orange, yellow, pink, purple, and white, for certain assortments highlighting multi-hued sprouts. They have an exemplary daisy-like appearance with a noticeable focal circle encompassed by petals, making a beautiful and eye-getting display. These blossoms sprout bountifully from pre-summer to fall, giving a persistent eruption of variety all through the developing season. They are adaptable plants that flourish in bright areas with well-depleting soil, making them ideal for borders, bloom beds, and compartments.",
    link: "zinnias-rose",
    list: [
      {
        title: "Choose the Right Assortment",
        description: "Zinnias come in different sizes and tones, so pick an assortment that suits your nursery space and plan inclinations. Normal assortments incorporate Zinnia elegans (normal Zinnia), Zinnia angustifolia (thin-leaved Zinnia), and Zinnia haageana (Mexican Zinnia). Think about the diminutive person, medium, or tall sorts given your nursery's requirements."
      },
      {
        title: "Daylight and Area",
        description: "Zinnias flourish in full sun, so select an establishing area that gets no less than 6-8 hours of direct daylight every day. They can endure some shade yet blossom best in splendid sunlight. Ensure the establishing region has very much depleted soil. Zinnias are versatile to different soil types yet favor fruitful, loamy soil with great seepage."
      },
      {
        title: "Soil Arrangement",
        description: "Set up the dirt by adding natural matter like fertilizer or very spoiled excrement. This further develops soil richness, construction, and seepage, establishing an ideal climate for Zinnias to flourish. Hold back nothing acidic to a nonpartisan pH level somewhere in the range of 6.0 and 7.5. Test the dirt and change it depending on the situation to accomplish the best pH."
      },
      {
        title: "Planting",
        description: "Plant Zinnia seeds straightforwardly into the nursery bed after the last ice date in your space. On the other hand, begin seeds inside 4 a month and a half before the last ice to get an early advantage on the developing season. Sow Zinnia seeds at a profundity of ¼ to ½ inch in good-to-go soil. Space seeds or seedlings as indicated by the particular assortment's suggested dispersing, are normally 6-12 inches separated depending upon their developed size."
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Watering-Zinnias.webp" ,
        description: "Water Zinnias consistently to keep the dirt equitably damp yet not waterlogged. Predictable watering is critical, particularly during droughts or sweltering climates, to help solid development and flowering. Water at the foundation of the plants promptly in the first part of the day to permit foliage to dry during the day, lessening the gamble of parasitic sicknesses."
      },
      {
        title: "Fertilizing",
        description: "Feed Zinnias with a fair, water-solvent manure or a granular compost formed for blooming plants. Apply manure as indicated by bundle directions, ordinarily every 4 a month and a half during the developing season to give fundamental supplements to powerful development and bountiful sprouts."
      },
      {
        title: "Support",
        description: "Tall assortments of Zinnias might profit from marking or support to forestall bowing or tumbling of stems, particularly in blustery circumstances or weighty downpours. Introduce stakes or use plant upholds from the get-go in the developing season to give strength."
        
      },
      {
      title: "Mulching",
      image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Mulching-Zinnias.webp" ,
      description: "Apply a layer of natural mulch, like straw, destroyed bark, or manure, around the foundation of the Zinnias. Mulching assists moderate with ruining dampness, stifles weeds, and directs soil temperature, advancing by and large plant wellbeing."
    },
      {
        title: "Deadheading and Pruning",
        description: "Eliminate spent blossoms (deadhead) consistently to energize constant sprouting and forestall seed arrangement. Deadheading diverts the plant's energy into creating new blossoms, dragging out the sprouting period. Trim back any leggy or congested stems to keep up with plant shape and empower rugged development. Pruning additionally further develops airflow around the plants, lessening the gamble of sickness."
      },
      {
        title: "Pest and Infection The board",
        description: "Screen Zinnias for normal nuisances like aphids, caterpillars, and bug parasites. Utilize insecticidal cleanser, neem oil, or other reasonable strategies for bother control as needed. Watch for indications of parasitic illnesses, for example, fine buildup or leaf spot. Give great air course by separating plants sufficiently and abstaining from congestion. Apply fungicides if vital, adhering to item guidelines cautiously."
      },
    ]
  },
  {
    blog_title: "Geraniums Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-geraniums.webp" ,
    blog_description: "Geraniums are famous blossoming plants known for their beautiful sprouts, unmistakable foliage, and adaptability in nurseries and scenes. They have a place with the variety Pelargonium and are local to South Africa, even though they are broadly developed all over the planet.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/geraniums-rose.webp" ,
    blog_desc: "Geranium blossoms come in different shades of red, pink, purple, white, and bi-colors, for certain assortments having designed petals. The blossoms are regularly bunched together in umbels or groups, making a thick and eye-getting show. Geranium foliage is frequently fragrant and may change in shape and surface contingent on the species or cultivar. These plants sprout plentifully from spring to fall, for certain assortments blooming discontinuously consistently. They favor well-depleting soil and bright to some degree concealed areas, making them appropriate for borders, compartments, hanging containers, and bloom beds.",
    link: "geraniums-rose",
    list: [
      {
        title: "Choose the Right Geranium Assortment",
        description: "Geraniums come in different kinds, including zonal geraniums (Pelargonium x hortorum), ivy geraniums (Pelargonium peltatum), and scented-leaf geraniums (Pelargonium spp.). Select an assortment that suits your nursery's daylight and space prerequisites."
      },
      {
        title: "Sunlight and Area",
        description: "Plant geraniums in an area that gets full sun to fractional shade. They flourish in splendid, roundabout daylight and expect no less than 4-6 hours of daylight daily. Ensure the establishing region has very much depleted soil to forestall waterlogging, as geraniums favor somewhat dry circumstances between waterings."
      },
      {
        title: "Soil Readiness",
        description: "Set up the dirt by changing it with natural matter like fertilizer or matured compost. This further develops soil fruitfulness, construction, and waste, establishing an ideal climate for geraniums to thrive. Aim for a marginally acidic to impartial pH level somewhere in the range of 6.0 and 7.5, as geraniums favor soils inside this reach."
      },
      {
        title: "Planting Geraniums",
        description: "Geraniums can be planted from seeds, cuttings, or nursery-purchased plants. Plant them after the last ice date in your space or when soil temperatures have warmed up. Dig openings are somewhat bigger than the root bundles of the geranium plants. Space plants 12-18 inches separated, contingent upon the assortment's adult size. Gently place the geranium plants in the openings, refill with soil, and water completely to settle the dirt around the roots."
      },
      {
        title: "Watering",
        description: "Water geraniums consistently to keep the dirt equitably clammy but not waterlogged. Permit the dirt to dry somewhat between waterings, particularly in blistering weather. Water is at the foundation of the plants in the first part of the day to forestall contagious illnesses and permit foliage to dry during the day."
      },
      {
        title: "Geranium bloom leaves",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/leaves-Geraniums.webp" ,
        description: "Geranium blossom leaves are ordinarily profoundly lobed and can shift in shape contingent upon the species. They are frequently roundish, with a scalloped or serrated edge, and may have a somewhat bristly surface. The leaves are typically a rich green tone, yet a few assortments can have variegated designs with shades of white, yellow, or even red. Furthermore, geranium leaves are sweet-smelling and can transmit an unmistakable fragrance when scoured or squashed."
      },
      {
        title: "Mulching",
        description: "Apply a layer of natural mulch, for example, straw or destroyed bark, around the foundation of the geranium plants. Mulching holds soil dampness, smothers weeds, and directs soil temperature."
      },
      {
        title: "Fertilizing",
        description: "Feed geraniums with a decent, water-solvent compost or manure explicitly figured out for blossoming plants. Apply manure every 4 months and a half during the developing season, adhering to bundle directions for application rates."
      },
      {
        title: "Deadheading and Pruning",
        description: "Deadhead spent blossoms routinely to advance consistent sprouting and eliminate any blurred or harmed foliage to keep up with plant wellbeing and appearance. Prune back leggy or congested stems to energize shaggy development and further develop the air course around the plants."
      },
      {
        title: "Vermin and Illness The board",
        description: "Screen geraniums for normal bugs like aphids, insect parasites, and whiteflies. Utilize insecticidal cleanser, neem oil, or other natural techniques to control pests. Watch for indications of contagious infections like fine mold or botrytis. Give great airflow, abstain from congestion, and water at the foundation of the plants to lessen sickness risk."
      },
    ]
  },
  {
    blog_title: "Lisianthus Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Lisianthus.webp" ,
    blog_description: "Lisianthus, otherwise called Eustoma, is a delightful and exquisite blossoming plant that is respected for its fragile and rose-like sprouts. Local to the warm districts of the Southern US, Mexico, the Caribbean, and South America, Lisianthus has turned into a well-known decision for gardens and decorative layouts around the world.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Lisianthus.webp" ,
    blog_desc: "The blossoms of Lisianthus arrive in various varieties, including white, pink, purple, blue, and bi-hued blends. They have numerous layers of delicate, unsettled petals that give them a refined and heartfelt appearance. The blossoms can be single or twofold, and they regularly develop on lengthy, thin stems. Lisianthus plants sprout from pre-summer to late-summer, giving a long time of brilliant showcase. They favor very much depleted soil and full sun to fractional shade, making them appropriate for bloom beds, boundaries, and holders. These plants are moderately low-upkeep however benefit from normal watering and deadheading to energize consistent blossoming.",
    link: "lisianthus-rose",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "Lisianthus comes in different tones and sizes, including single and twofold blossomed assortments. Select an assortment that suits your nursery plan and environmental conditions."
      },
      {
        title: "Sunlight and Area",
        description: "Plant Lisianthus in an area that gets full sun. They flourish with no less than 6-8 hours of direct daylight daily. Ensure the establishing site has all-around depleted soil to forestall waterlogging, as Lisianthus favors somewhat dry circumstances between waterings."
      },
      {
        title: "Soil Planning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_Lisianthus.webp" ,
        description: "Set up the dirt by consolidating natural matter like fertilizer or matured compost. This improves soil fruitfulness, design, and waste, giving an ideal developing climate to Lisianthus. Aim for a dirt pH of 6.5 to 7.0, as Lisianthus flourishes in somewhat acidic to unbiased soil."
      },
      {
        title: "Starting Seeds Inside",
        description: "Begin Lisianthus seeds within 10-12 weeks before the last expected ice date in your space, as they have a long germination period. Sow seeds on the outer layer of a well-depleting seed-beginning blend and gently press them into the dirt. Try not to cover the seeds, as they need light to germinate. Maintain a reliable temperature of 70-75°F (21-24°C) and keep the dirt soggy but not waterlogged."
      },
      {
        title: "Transplanting Seedlings",
        description: " Relocate seedlings outside after the risk of ice has passed and when they have no less than two arrangements of genuine leaves. Harden off seedlings by progressively presenting them to open-air conditions for more than seven days before establishing them in the garden. Space plants 6-8 inches separated to consider legitimate air dissemination and development."
      },
      {
        title: "Watering",
        description: "Water Lisianthus routinely to keep the dirt equally wet, particularly during dry periods. Try not to above water to forestall parasitic diseases. Water at the foundation of the plants in the first part of the day to permit foliage to dry during the day."
      },
      {
        title: "Mulching",
        description: "Apply a layer of natural mulch, for example, straw or destroyed bark, around the foundation of the plants to hold soil dampness, smother weeds, and manage soil temperature."
      },
      {
        title: "Fertilizing",
        description: "Feed Lisianthus with reasonable, water-solvent manure or a sluggish delivery granular compost. Apply manure every 4 months and a half during the developing season, adhering to bundle guidelines for application rates."
      },
      {
        title: "Staking and Backing",
        description: "Offer help for taller assortments of Lisianthus by marking or utilizing plant supports to keep comes from twisting or breaking, particularly in blustery circumstances. Eliminate spent blossoms (deadhead) routinely to support consistent sprouting and forestall seed arrangement. This diverts the plant's energy into creating new flowers. Prune back any leggy or congested stems to keep up with plant shape and further develop the air course."
      },
      {
        title: "Pest and Sickness The board",
        description: "Screen Lisianthus for normal nuisances like aphids, thrips, and insect vermin. Utilize insecticidal cleanser, neem oil, or other natural strategies to control pests. Watch for indications of parasitic sicknesses like fine buildup or botrytis. Give great air dissemination, abstain from above watering, and apply fungicides if important."
      },
    ]
  },
  {
    blog_title: "Floribunda Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Floribunda.webp" ,
    blog_description: "Floribunda roses are a gathering of roses known for their productive sprouting and dynamic bunches of roses. They were created by crossing crossover tea roses with polyantha roses, joining the best qualities of both: the excellence and scent of half-breed teas with the solidness and bountiful sprouts of polyanthas.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/floribunda-rose.webp" ,
    blog_desc: "Floribunda roses arrive in a wide exhibit of varieties, including red, pink, yellow, white, and orange, for certain assortments displaying bi-hued or multi-shaded petals. The blossoms are for the most part more modest than those of crossover tea roses yet show up in huge, conspicuous groups, making a striking visual effect in gardens and landscapes. These roses regularly sprout from pre-summer to ice, giving a persistent presentation of variety all through the developing season. They are solid and infection-safe, making them number one among landscapers who look for low-support yet outwardly engaging plants. Floribunda roses flourish in all-around depleted soil and full sun, making them appropriate for lines, supports, and blended bloom beds.",
    link: "floribunda-rose",
    list: [
      {
        title: "Choosing the Right Assortment",
        description: "Lisianthus comes in different tones and sizes, including single and twofold bloomed assortments. Select an assortment that suits your nursery plan and environmental conditions."
      },
      {
        title: "Sunlight and Area",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sunlight-Floribunda.webp" ,
        description: "Plant Lisianthus in an area that gets full sun. They flourish with somewhere around 6-8 hours of direct daylight daily. Ensure the establishing site has very much depleted soil to forestall waterlogging, as Lisianthus favors marginally dry circumstances between waterings."
      },
      {
        title: "Soil Readiness",
        description: "Set up the dirt by integrating natural matter like fertilizer or matured compost. This upgrades soil ripeness, design, and waste, giving an ideal developing climate to Lisianthus. Aim for a dirt pH of 6.5 to 7.0, as Lisianthus flourishes in somewhat acidic to impartial soil."
      },
      {
        title: "Starting Seeds Inside",
        description: "Begin Lisianthus seeds within 10-12 weeks before the last expected ice date in your space, as they have a long germination period. Sow seeds on the outer layer of a well-depleting seed-beginning blend and delicately press them into the dirt. Try not to cover the seeds, as they need light to germinate. Maintain a steady temperature of 70-75°F (21-24°C) and keep the dirt soggy yet not waterlogged."
      },
      {
        title: "Transplanting Seedlings",
        description: "Relocate seedlings outside after the risk of ice has passed and when they have something like two arrangements of genuine leaves. Harden off seedlings by slowly presenting them to open-air conditions for more than seven days before establishing them in the garden. Space plants 6-8 inches separated to consider appropriate air dissemination and development."
      },
      {
        title: "Watering",
        description: "Water Lisianthus consistently to keep the dirt equitably soggy, particularly during dry periods. Try not to above water to forestall parasitic diseases. Water at the foundation of the plants in the first part of the day to permit foliage to dry during the day."
      },
      {
        title: "Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/mulching-Floribunda.webp" ,
        description: "Apply a layer of natural mulch, for example, straw or destroyed bark, around the foundation of the plants to hold soil dampness, stifle weeds, and manage soil temperature."
      },
      {
        title: "Fertilizing",
        description: "Feed Lisianthus with a decent, water-dissolvable compost or a sluggish delivery granular manure. Apply compost every 4 months and a half during the developing season, adhering to bundle directions for application rates. Offer help for taller assortments of Lisianthus by marking or utilizing plant supports to keep comes from bowing or breaking, particularly in blustery circumstances."
      },
      {
        title: "Deadheading and Pruning",
        description: "Eliminate spent blossoms (deadhead) consistently to support constant sprouting and forestall seed arrangement. This diverts the plant's energy into delivering new flowers. Prune back any leggy or congested stems to keep up with plant shape and further develop air dissemination."
      },
    ]
  },
  {
    blog_title: "French Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-French-rose.webp" ,
    blog_description: "The French rose, something else called Rosa gallica, is one of the most prepared created sorts of roses and is famous for its unquestionable significance and charming heavenliness. Local to southern and focal Europe, the French rose has been valued for a long time for its fragrant blossoms and restorative properties.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/French rose.webp",
    blog_desc: "French rose blossoms come in shades of pink, red, and purplish tints, frequently highlighting semi-twofold to twofold sprouts with a superb, sweet scent. The petals are delicate and marginally unsettled, giving the blossoms a beguiling and rich appearance. The sprouts ordinarily measure around 3-4 creeps in distance across and fill in bunches, adding to their visual appeal. French roses have authentic and social importance, frequently representing adoration, energy, and magnificence. They have been utilized in perfumery, beauty care products, and customary medication for their sweet-smelling and restorative properties. The rose hips of Rosa gallica are likewise plentiful in L-ascorbic acid and have been utilized in natural cures.",
    link: "french-rose",
    list: [
      {
        title: "Selecting the Right Assortment",
        description: "French roses (Gallica roses) arrive in a scope of varieties from profound pinks to purples and reds. Famous assortments incorporate 'Rosa gallica officinalis' (Pharmacist's Rose) and 'Rosa Mundi.'"
      },
      {
        title: "Sunlight and Area",
        description: "Plant French roses in an area that gets full sun, preferably something like 6-8 hours of direct daylight every day. They flourish best in brilliant, bright locations. Ensure the establishing site has very much depleted soil to forestall waterlogging, which can prompt root decay. "
      },
      {
        title: "Soil Planning",
        description: "French roses favor marginally acidic to impartial soil with a pH somewhere in the range of 6.0 and 7.0. Revise the dirt with natural matter, for example, fertilizer or very much decayed compost to further develop richness and drainage. Conduct a dirt test to decide the pH and supplement levels. Change on a case-by-case basis to make the ideal developing circumstances."
      },
      {
        title: "Planting French Rose",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-French-rose.webp" ,
        description: " The best opportunity to establish French roses is in the spring or when temperatures are mild. Dig an opening that is two times as wide and a similar profundity as the root ball. This permits the roots to spread easily. Position the rose so the join association (the enlarged part where the rose is united onto the rootstock) is at or just underneath the dirt level in colder environments, and around 1-2 creeps over the dirt level in hotter climates. Fill the opening with soil, firming it delicately around the roots to take out air pockets. Water completely to settle the dirt."
      },
      {
        title: "Watering",
        description: " Water the roses routinely to keep the dirt uniformly damp yet not waterlogged. Profound watering supports profound root growth. Water at the foundation of the plant to try not to wet the foliage, which can prompt parasitic sicknesses. Early daytime watering is desirable to permit foliage to dry during the day."
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch layer of natural mulch, for example, bark chips or manure, around the foundation of the roses. Mulching holds soil dampness, smothers weeds, and controls soil temperature.Utilize a reasonable, slow-discharge manure or a rose-explicit compost. Adhere to bundle directions for application rates. Fertilize in late winter when new development starts, after the main blossom cycle, and again in mid-summer. Keep away from late-season treatment to forestall delicate development that could be harmed by ice."
      },
      {
        title: "Pruning",
        description: "Prune French roses in late winter before new development begins. Eliminate any dead, harmed, or infected wood. Cut back the leftover sticks to shape the plant and energize solid development. Make cuts simply above outward-confronting buds to advance an open development propensity.Eliminate spent blossoms to urge constant sprouting and to keep the plant from setting seed. Cut bloom stems back to a five-handout leaf or a solid parallel bud."
      },
    
    ]
  },
  {
    blog_title: "Hybrid Tea Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Hybrid Tea.webp" ,
    blog_description: "Breed tea roses are a work of art and a cherished kind of rose, celebrated for their rich and enormous blossoms. These roses are known for their long stems, which make them ideal for cutting and showing in jars. Each bloom ordinarily includes a highly focused bloom with 30 to 50 petals, making an ideal and rich structure that is requested by rose lovers.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Hybrid-Tea.webp",
    blog_desc: "Accessible in a great many tones, from lively reds and pinks to quiet whites and yellows, half-breed tea roses can likewise show a striking variety of slopes or bi-hued petals. Large numbers of these roses are fragrant, offering aromas that reach from light and sweet to solid and inebriating. They blossom more than once from spring to fall, giving constant magnificence all through the developing season.",
    link: "hybrid-tea-rose",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Half-breed tea roses flourish in full sun, expecting no less than 6 hours of direct daylight day to day. Pick a spot in your nursery that gets adequate daylight, as lacking light can prompt unfortunate development and fewer blossoms. These roses favor very much depleted soil with a pH somewhere in the range of 6.0 and 6.5. Direct a dirt test to decide the pH and correct it as needed. Integrate natural matter like fertilizer, very much decayed excrement, or leaf shape into the dirt to work on its construction and fruitfulness."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Hybrid-Tea.webp" ,
        description: "The best times to establish half-breed tea roses are in the spring after the last ice or in the fall no less than about a month and a half before the main ice. This timing permits the roots to lay out before outrageous temperatures put in. Space the roses around 2-3 feet aside. Legitimate dispersing guarantees great airflow, which forestalls infections, for example, fine mold and dark spots. Dig an opening wide and profound enough to oblige the roots. Place the rose in the opening, guaranteeing the bud association (the enlarged part where the rose was united) is at or simply over the dirt level in warm environments, or 1-2 crawls underneath in colder environments. This situating shields the united association from outrageous temperatures and disease. Fill the opening most of the way with soil, tenderly firming it around the roots to kill air pockets. Water completely, permitting the dirt to settle, and afterward fill the opening with the excess soil. Water again to guarantee the dirt is very much settled."
      },
      {
        title: "Watering",
        description: "Half breed tea roses need reliable dampness, particularly during their most memorable developing season. Water profoundly one time per week, guaranteeing the dirt remaining parts are sodden yet not waterlogged. During hot, droughts, increment the recurrence to forestall stress. Water at the foundation of the plant as opposed to above to try not to wet the foliage, which can prompt parasitic infections. Utilizing soaker hoses or trickle water system frameworks can be a powerful strategy to convey water straightforwardly to the root zone."
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch layer of natural mulch, for example, fertilizer, bark chips, or straw, around the foundation of the plant. Mulch holds soil dampness, smothers weeds, and manages soil temperature. Replenish the mulch layer on a case-by-case basis, particularly after weighty downpours or solid breezes that might uproot it. Guarantee the mulch is kept a couple of inches away from the stem to forestall decay and nuisance invasions."
      },
      {
        title: "Feeding",
        description: "Breed tea roses are weighty feeders. Utilize a reasonable rose manure or a particular rose food as per bundle guidelines. Start taking care of in late winter when new development shows up and proceed with each 4 a month and a half until pre-fall. Try not to prepare in that frame of mind to forestall new development that could be harmed by frost. Organic manures like fertilizer, very much spoiled compost, or fish emulsion give a sluggish delivery wellspring of supplements. These choices further develop soil well-being and back gainful microorganisms."
      },
      {
        title: "Pruning",
        description: "Prune crossover tea roses in pre-spring or late winter before new development begins. Pruning during torpidity helps shape the plant and empowers energetic growth. Start by eliminating dead, harmed, or sick wood. Scale back the primary sticks to around 12-24 inches, making cuts simply above outward-confronting buds. This empowers an open, jar-like shape that advances air course and light penetration. Use sharp, clean pruning shears to make clean cuts and forestall sickness spread. Clean the devices with an answer of one section blanch to nine sections of water between cuts."
      },
      {
        title: "Pest and Infectious prevention",
        description: "Consistently review your roses for normal vermin like aphids, bug parasites, and illnesses like dark spots and fine buildup. Early discovery takes into account brief treatment. Use an insecticidal cleanser or neem oil to control bugs. For parasitic infections, apply fitting fungicides and practice great nursery cleanliness by tidying up fallen leaves and flotsam and jetsam. Try not to above water to diminish stickiness around the plants."
      },
      {
        title: "Winter Assurance",
        description: "In colder environments, hill soil or mulch around the foundation of the plant to safeguard the roots from freezing. Apply a thick layer of mulch, around 8-12 inches high, to protect the roots. Use burlap or rose cones to shield the plants from unforgiving winter winds and temperature variances. Secure the covers appropriately to keep them from blowing ceaselessly."
      },
    ]
  },
  {
    blog_title: "Buttercup  Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Buttercup.webp" ,
    blog_description: "Buttercup blossoms, experimentally known as Ranunculus, are cherished for their splendid, lustrous petals and beguiling cup-like shape. These blossoms normally highlight 5 to 7 gleaming petals and are most ordinarily tracked down in energetic shades of yellow, however, they can likewise show up in white, pink, orange, and red. Blossoming in spring and late spring, buttercups develop to levels of 12 to 24 inches and frequently structure thick groups that make a striking showcase.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Buttercup-rose.webp" ,
    blog_desc: "Their dazzling green, finely separated foliage adds to their fragile appearance. Buttercups flourish in very much depleted soil with full sun to fractional shade, and they can be tracked down in glades, fields, and wet regions like stream banks. While they are generally simple to develop and can spread rapidly, it is essential to take note that buttercup blossoms are poisonous whenever ingested, so care ought to be taken while establishing them in regions open to pets and kids.",
    link: "buttercup-rose",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Buttercups need full sun for no less than 6 hours per day. Pick a spot in your nursery that gets more than adequate daylight to guarantee sound development and bountiful blooms. Buttercups favor very much depleted soil rich in natural matter. Assuming your dirt is weighty mud or excessively sandy, change it with fertilizer or very much decayed excrement to work on its construction and ripeness."
      },
      {
        title: "Planting",
        description: `Plant buttercup corms in the fall for spring sprouts, or in late winter for summer blossoms. In locales with gentle winters, fall planting is ideal as it permits the corms to lay out roots. Space the corms around 6-8 inches separated. Legitimate dispersing guarantees a great air course and diminishes the gamble of contagious diseases. Plant the corms 2-3 inches deep with the "hooks" looking lower. Cover them with soil and water well to assist them with settling.`
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-Buttercup.webp" ,
        description: "Keep the dirt reliably sodden yet not waterlogged. Water the plants routinely, particularly during droughts, to guarantee they don't dry out. Water at the foundation of the plants to try not to wet the foliage, which can prompt parasitic infections. Utilizing a soaker hose or trickle water system framework is a powerful method for giving steady dampness."
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch layer of natural mulch, like fertilizer, straw, or destroyed leaves, around the plants. Mulch holds soil dampness, smothers weeds, and manages soil temperature. Replenish the mulch on a case-by-case basis, particularly after weighty downpours or solid breezes that might uproot it. Guarantee the mulch is kept a couple of inches away from the stems to forestall decay."
      },
      {
        title: "Feeding",
        description: "Buttercups benefit from ordinary taking care of with a fair compost. Apply a sluggish delivery granular manure or fluid manure weakened to half strength each 4 a month and a half during the developing season. Incorporate natural matter like fertilizer or very much decayed excrement into the dirt at establishing time. You can likewise utilize fish emulsion or kelp removal as a natural compost during the developing season."
      },
      {
        title: "Supporting Development",
        description: "Buttercup blossoms can now and then become cumbersome, particularly in breezy regions. Use stakes or plant supports to keep the stems upstanding and keep them from breaking."
      },
      {
        title: "Pest and Infectious prevention",
        description: "Consistently investigate your buttercups for normal irritations like aphids and bug parasites, and illnesses, for example, fine buildup and root decay. Early discovery considers brief treatment. Use an insecticidal cleanser or neem oil to control bothers. For parasitic illnesses, apply suitable fungicides and guarantee great air dissemination by dispersing plants appropriately and abstaining from above watering."
      },
      {
        title: "Winter Consideration",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/winter-Buttercup.webp" ,
        description: "In colder environments, apply a thick layer of mulch (around 4-6 inches) over the establishing region to protect the corms and shield them from frigid temperatures. In locales with unforgiving winters, you can lift the corms after the foliage has passed on back in the fall. Perfect and dry the corms, then, at that point, store them in a cool, dry spot until you replant them in the spring."
      },
      {
        title: "Encouraging Blossoms",
        description: "Eliminate spent blossoms routinely to support ceaseless sprouting and keep the plants from setting seed. After the blossoming season, permit the foliage to kick the bucket back normally. This assists the corms with putting away energy for the following developing season."
      },
    ]
  },
  {
    blog_title: "Damask Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Damask.webp",
    blog_description: "The Damask rose, is a famous and noteworthy rose assortment praised for its dazzling scent and immortal excellence. This rose is known for its enormous, semi-twofold to twofold blossoms, which ordinarily highlight a rich pink to light red tone. The blossoms are exceptionally fragrant, radiating an exemplary rose fragrance that is both extreme and enamoring.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Damask-rose.webp",
    blog_desc: "Blossoming fundamentally in pre-summer to late spring, for certain assortments likewise offering a second flush in fall, Damask roses develop on tall, curving sticks that can arrive at levels of 4 to 7 feet. The foliage is a delicate, grayish-green, giving a wonderful difference to the lively sprouts. Local to the Center East, the Damask rose has been developed for a long time and is valued for its excellence as well as for its utilization in perfumery and culinary applications, especially rose water and rose oil.",
    link: "damask-rose",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Damask roses flourish in full sun, expecting no less than 6 hours of direct daylight every day. Pick an area in your nursery that gets adequate daylight to advance solid development and plentiful blossoming. These roses favor very much depleted, loamy soil wealthy in natural matter. The dirt pH ought to preferably be somewhere in the range of 6.0 and 6.5. Assuming your dirt is weighty mud or excessively sandy, alter it with fertilizer or very much decayed excrement to further develop waste and ripeness."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Damask-rose.webp",
        description: "Plant Damask roses in the spring after the last ice or in the fall something like a month and a half before the primary ice. This timing permits the roots to lay out before outrageous temperatures put in. Space the plants around 3-4 feet apart to take into consideration great air dissemination and to diminish the gamble of disease. Dig an opening wide and profound enough to oblige the roots. Place the rose in the opening, guaranteeing the bud association (the enlarged part where the rose was joined) is at or simply over the dirt level in warm environments, or 1-2 crawls underneath in colder environments. Fill the opening most of the way with soil, delicately firming it around the roots to take out air pockets. Water completely, permitting the dirt to settle, and afterward fill the opening with the excess soil. Water again to guarantee the dirt is very much settled."
      },
      {
        title: "Watering",
        description: "Water profoundly one time each week, guaranteeing the dirt remaining parts are sodden but not waterlogged. Increment the recurrence during hot, droughts to keep the plants from drying out. Water at the foundation of the plant to try not to wet the foliage, which can prompt parasitic sicknesses. Utilizing soaker hoses or trickle water system frameworks can be compelling techniques to convey water straightforwardly to the root zone."
      },
      {
        title: "Feeding",
        description: "Utilize a reasonable rose compost or a specific rose food as indicated by bundle directions. Begin taking care of them in late winter when new development starts and proceed with each 4 a month and a half until pre-fall. Try not to prepare in that frame of mind to forestall new development that could be harmed by frost. Compost, all-around decayed excrement, or fish emulsion can be utilized as natural compost. These choices further develop soil well-being and back gainful microorganisms."
      },
      {
        title: "Pruning",
        description: "Prune Damask roses in pre-spring or late winter before new development begins. Pruning during torpidity helps shape the plant and energizes lively growth. Remove dead, harmed, or infected sticks. Scale back any intersection branches to empower great air dissemination and eliminate spent blossoms to support reblooming."
      },

      {
        title: "Support",
        description: "Contingent upon the assortment and development propensity, Damask roses might profit from some help. Use stakes or lattices to assist with keeping the sticks upstanding and keep them from hanging."
      },
      {
        title: " Vermin and Illness The board",
        description: "Look out for normal rose bothers like aphids, bug parasites, and rose slugs. Treat invasions quickly with insecticidal cleanser or neem oil. Monitor for sicknesses like dark spots, fine buildup, and rust. Pick sickness-safe assortments if conceivable and eliminate and obliterate any impacted foliage."
      },
    ]
  },
  {
    blog_title: "Gardenias Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Gardenias.webp" ,
    blog_description: "Gardenias are impeccable blossoming plants known for their dazzling aroma and rich appearance. Their huge, smooth white or ivory-shaded blossoms, frequently singular or grouped at the finishes of branches, brag a particular pipe or star-molded structure with covering petals. Shiny, dim green leaves supplement the blossoms, furnishing an alluring background with their weathered, circular oval shape. These evergreen bushes can grow up to 1 to 2 meters in level, highlighting a thick, shaggy development propensity.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Gardenias.webp",
    blog_desc: "Gardenias produce areas of strength for a, and exciting fragrance, suggestive of jasmine or a mix of jasmine and orange blooms, which escalates at night. They favor acidic, well-depleting soil wealthy in natural matter and flourish in full to halfway shade, requiring steady dampness and security from unforgiving noontime sun. Gardenias are generally filled in nurseries and scenes, as well as inside in holders, treasured for their excellence and aroma, and utilized in decorative designs because of their social importance in representing immaculateness, love, and refinement.",
    link: "gardenias-rose",
    list: [
      {
        title: "Choosing an Area",
        description: "Gardenias incline toward brilliant, circuitous light or fractional shade. They flourish in regions with morning sun and evening conceal, particularly in more sweltering climates. Gardenias lean toward warm temperatures during the day (65-70°F/18-21°C) and cooler evenings (55-60°F/13-16°C). They are the most ideal for USDA toughness zones 8-11."
      },
      {
        title: "Soil Readiness",
        description: "Gardenias incline toward acidic soil with a pH somewhere in the range of 5.0 and 6.0. If your dirt isn't normally acidic, you can bring down the pH by blending in peat greenery, treating the soil leaves, or an acidic fertilizer. Ensure the dirt is well-depleting to forestall root decay. Sandy topsoil or loamy soil with great natural substance is great."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Gardenias.webp" ,
        description: "Plant gardenias in the spring after the last ice date in your space Dig an opening that is two times as wide and similarly as profound as the root chunk of your gardenia plant. The top of the root ball ought to be level with the dirt surface. The top of the root ball ought to be level with the dirt surface."
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch (5-7.5 cm) layer of natural mulch, for example, pine bark or manure, around the foundation of the plant. Mulching holds dampness, controls soil temperature, and smothers weeds."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably wet, yet at the same not waterlogged. Water profoundly and consistently, particularly during blistering, dry climates. Try not to wet the foliage to forestall parasitic sicknesses."
      },
      {
        title: "Fertilizing",
        description: "Utilize a compost figured out for corrosive cherishing plants, for example, one for azaleas or camellias. Feed gardenias in late winter before new development starts and again in mid-summer, adhering to bundle guidelines."
      },
      {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/prune-Gardenias.webp" ,
        description: "Prune gardenias gently after they have completed the process of sprouting to keep up with their shape and empower new growth. Remove dead, harmed, or crossing branches. Squeeze off spent blossoms to advance more sprouts."
      },
      {
        title: "Pest and Infection The executives",
        description: "Watch for normal bugs like aphids, scale bugs, and bug parasites. Treat invasions instantly with an insecticidal cleanser or neem oil. Monitor for illnesses like fine buildup, dingy shape, and root decay. Further, develop air dissemination around the plant and abstain from above watering."
      },
      {
        title: "Winter Consideration",
        description: "Gardenias are delicate to cold temperatures. In colder environments, consider developing them in compartments that can be moved inside during winter. Mulch around the foundation of the plant to shield the roots from frosty temperatures."
      },
    ]
  },
  {
    blog_title: "Touch-me-not Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Touch-me-not.webp" ,
    blog_description: "Contact me-not, otherwise called Impatiens or Resin, is an intriguing blossoming plant described by its remarkable seed dispersal component and sensitive blossoms. The plant ordinarily grows up to 1 meter in level, highlighting dazzling green, serrated leaves and slim stems. The blossoms are little, around 1 to 2 centimeters in measurement, and come in different shades of pink, purple, white, and orange. Each bloom has five petals, with a particular prod at the back.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Touch-me-not.webp",
    blog_desc: `What makes contact me-not especially intriguing is its seed units. At the point when ready, the smallest touch makes the units detonate, projecting seeds a few meters away. This unstable dispersal instrument brings about the normal name "contact me-not" or "Impatiens," mirroring the plant's aversion to contact.`,
    link: "touch-me-not-rose" ,
    list: [
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Touch-me-not.webp" ,
        description: "Plant contact me-nots after the last ice date in spring. They develop rapidly and blossom persistently until ice in fall.Space plants 8-12 inches (20-30 cm) separated, contingent upon the varietyPlant them at similar profundity they were in their holders. Firm the dirt around them and water well in the wake of planting."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably soggy yet not waterlogged. Contact me-nots are delicate to dry soil, so water them consistently, particularly during hot, dry periods. Contact me-not blossoms flourish in inclined toward full shade. They lean toward brilliant, backhanded light as opposed to coordinate sun, particularly in more sweltering climates.Well-depleting, ripe soil is great. Add natural matter like fertilizer to further develop soil construction and ripeness."
      },
      {
        title: "Fertilizing and Mulching",
        description: "Apply a reasonable, water-solvent compost each 2 a month during the developing season. Adhere to the bundle guidelines for weakening and application rates. Apply a 2-3 inch (5-7.5 cm) layer of natural mulch, for example, destroyed leaves or bark, around the plants to hold dampness and stifle weeds."
      },
      {
        title: "Pruning and Deadheadin",
        description: "Squeeze off spent blossoms to support ceaseless sprouting. Trim back leggy development to advance thick, minimized plants. Watch for normal bugs like aphids and insect vermin. Treat pervasions with insecticidal cleanser or neem oil.Avoid above watering to forestall parasitic infections. Further develop air course around plants if essential."
      },
      {
        title: "Partaking in the Blossoms",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Partaking-Touch-me-not.webp" ,
        description: "Contact me-nots sprout plentifully from spring to fall, delivering brilliant blossoms in shades of pink, red, purple, white, and orange.Use them as bedding plants, in holders, or as edging along obscure nursery ways. Contact me-nots are annuals, so they will kick the bucket back with the principal ice. Gather seeds from spent blossoms to establish the accompanying spring, or permit them to self-seed."
      },
     

    ]
  }, 
   {
    blog_title: "White Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-white-rose.webp" ,
    blog_description: "Contact me-not, otherwise called Impatiens or Resin, is an intriguing blossoming plant described by its remarkable seed dispersal component and sensitive blossoms. The plant ordinarily grows up to 1 meter in level, highlighting dazzling green, serrated leaves and slim stems. The blossoms are little, around 1 to 2 centimeters in measurement, and come in different shades of pink, purple, white, and orange. Each bloom has five petals, with a particular prod at the back.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/white-rose.webp",
    blog_desc: `What makes contact me-not especially intriguing is its seed units. At the point when ready, the smallest touch makes the units detonate, projecting seeds a few meters away. This unstable dispersal instrument brings about the normal name "contact me-not" or "Impatiens," mirroring the plant's aversion to contact.`,
    link: "white-rose",
    list: [
      {
        title: "Planting",
        description: "Plant contact me-nots after the last ice date in spring. They develop rapidly and blossom persistently until ice in fall.Space plants 8-12 inches (20-30 cm) separated, contingent upon the varietyPlant them at similar profundity they were in their holders. Firm the dirt around them and water well in the wake of planting."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably soggy yet not waterlogged. Contact me-nots are delicate to dry soil, so water them consistently, particularly during hot, dry periods. Contact me-not blossoms flourish in inclined toward full shade. They lean toward brilliant, backhanded light as opposed to coordinate sun, particularly in more sweltering climates.Well-depleting, ripe soil is great. Add natural matter like fertilizer to further develop soil construction and ripeness."
      },
      {
        title: "Fertilizing And Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Fertilizing-White Rose.webp" ,
        description: "Apply a reasonable, water-solvent compost each 2 a month during the developing season. Adhere to the bundle guidelines for weakening and application rates. Apply a 2-3 inch (5-7.5 cm) layer of natural mulch, for example, destroyed leaves or bark, around the plants to hold dampness and stifle weeds."
      },
      {
        title: "Pruning and Deadheading",
        description: "Squeeze off spent blossoms to support ceaseless sprouting. Trim back leggy development to advance thick, minimized plants. Watch for normal bugs like aphids and insect vermin. Treat pervasions with insecticidal cleanser or neem oil.Avoid above watering to forestall parasitic infections. Further develop air course around plants if essential."
      },{
        title: "Partaking in the Blossoms",
        description: "Contact me-nots sprout plentifully from spring to fall, delivering brilliant blossoms in shades of pink, red, purple, white, and orange.Use them as bedding plants, in holders, or as edging along obscure nursery ways. Contact me-nots are annuals, so they will kick the bucket back with the principal ice. Gather seeds from spent blossoms to establish the accompanying spring, or permit them to self-seed."
      },

    ]
  },
   {
    blog_title: "Cabbage Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-cabbage-rose.webp" ,
    blog_description: "The Cabbage Rose, otherwise called Rosa × centifolia, is a work of art and exceptionally fragrant rose assortment that has been loved for a long time. It is famous for its enormous, attractive blossoms and extreme, sweet aroma. The blossoms normally range in variety from shades of pink to light red and, surprisingly, white. Each bloom has various petals, giving it a full, cabbage-like appearance, which represents its unexpected name.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Cabbage-Rose.webp",
    blog_desc: "Cabbage roses are hearty bushes with dull green foliage and prickly stems. They can develop to be very huge, frequently arriving at levels of up to 1.5 meters. The leaves are partitioned into a few little pamphlets and have a reflexive surface. The blossoms are borne separately or in little bunches and sprout lavishly in pre-summer and late spring.",
    link: "cabbage-rose",
    list: [
      {
        title: "Watering",
        description: " Keep the dirt reliably wet, yet not waterlogged. Water profoundly and routinely, particularly during sweltering, dry climates. Try not to wet the foliage to forestall contagious infections. Cabbage roses lean toward full sun, and that implies they need no less than 6 hours of direct daylight each day. Nonetheless, they can endure some light shade, particularly in warm climates. Well-depleting, fruitful soil is great for cabbage roses. They favor soil that is wealthy in natural matter. A somewhat acidic to nonpartisan pH (around 6.0 to 7.0) is ideal."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Planting-Cabbage-rose.webp" ,
        description: "Plant cabbage roses in the late winter or pre-winter, after the last ice date in your space. Dig an opening that is two times as wide and similarly as profound as the root wad of your rose plant. The bud association (enlarged region where the sticks meet the roots) ought to be at or just underneath the dirt level. Water profoundly in the wake of planting to settle the dirt around the roots."
      },
      {
        title: "Mulching And Preparing",
        description: "Apply a 2-3 inch (5-7.5 cm) layer of natural mulch, like destroyed bark or fertilizer, around the foundation of the plant. Mulching holds dampness, manages soil temperature, and smothers weeds. Utilize a reasonable compost figured out for roses, adhering to the producer's directions. Apply in late winter before new development starts and again in mid-summer"
      },
      {
        title: "Nuisance and Illness The board",
        description: "Watch for normal vermin like aphids, bug parasites, and rose slugs. Treat invasions expeditiously with insecticidal cleanser or neem oil. Monitor for infections like dark spots, fine buildup, and rust. Pick sickness-safe assortments assuming conceivable and practice great nursery cleanliness."
      },{
        title: "Pruning",
        description: "Prune cabbage roses daintily in pre-spring or late winter before new development starts. Eliminate dead, harmed, or crossing branches. Shape the plant as wanted."
      },
      {
        title: "Winter Care",
        description: "In colder environments, safeguard the flower bramble from cruel winter conditions by applying a thick layer of mulch around the foundation of the plant. Consider covering the shrub with burlap or a rose cone to shield it from winter winds and ice. Cabbage roses produce enormous, full blossoms with a magnificent scent. Partake in their excellence in your nursery and think about cutting them for new flower bundles."
      },
    ]
  },
  {
    blog_title: "Begonia Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-begonia.webp" ,
    blog_description: "Begonias are a different gathering of enduring blossoming plants known for their decorative foliage and vivid sprouts. They have a place with the family Begoniaceae, which incorporates north of 1,800 distinct species. Begonias are broadly developed as both indoor and open-air plants because of their alluring leaves and blossoms.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/begonia-rose.webp" ,
    blog_desc: "The leaves of begonias can shift broadly in shape, size, and variety, frequently going from green to red, with different examples and surfaces like spots, stripes, or twistings. The blossoms are commonly brilliant and gaudy, coming in shades of pink, red, white, and yellow. They can be single or twofold petaled, and a few animal categories produce groups of blossoms.",
    link: "begonia-rose",
    list: [
      {
        title: "Partaking in the Blossoms",
        description: "Begonias sprout from spring to fall, creating vivid blossoms in shades of red, pink, white, yellow, and orange. Enjoy their energetic sprouts in garden beds, borders, hanging crates, or compartments. In colder environments, begonias are frequently developed as annuals or can be uncovered and put away inside for the winter. Protect plants from ice and cold temperatures by bringing compartments inside or covering garden plants with a layer of mulch."
      },
      {
        title: "Nuisances and Infections",
        description: "Watch for normal irritations like aphids, insect vermin, and fine mold. Treat pervasions quickly with an insecticidal cleanser or neem oil. Ensure great air dissemination around plants to forestall parasitic infections."
      },
      {
        title: "Pruning and Deadheading",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Pruning-begonia.webp" ,
        description: "Eliminate spent blossoms (deadhead) routinely to empower nonstop blooming. Pinch back leggy development to advance rugged, smaller plants. Apply a 2-3 inch (5-7.5 cm) layer of natural mulch, for example, destroyed bark or manure, around the plants to hold dampness and stifle weeds."
      },
      {
        title: "Fertilizing And Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-begonia.webp" ,
        description: "Utilize a reasonable, water-dissolvable compost weakened to half strength every 2 a month during the developing season. Avoid over-preparing, as this can prompt extreme foliage development to the detriment of blossoms. Keep the dirt equally damp, yet all the same not waterlogged. Water begonias at the foundation of the plant to try not to wet the foliage, which can prompt parasitic diseases.Water all the more regularly during blistering, dry climates."
      },{
        title: "Establishing Begonias",
        description: "Plant begonias after the last ice date in your space, commonly in the spring.Depending on the assortment, space begonias 6 to 12 inches (15-30 cm) apart. Begonias favor well-depleting soil wealthy in natural matter. A dirt pH around 6.0 to 6.5 is ideal for plant begonias at similar profundity as they were in their holders. Tenderly firm the dirt around the plants."
      },
      {
        title: "Picking Begonias",
        description: "There are various sorts of begonias, including tuberous begonias, sinewy established begonias, and rex begonias. Pick the sort that suits your nursery conditions and preferences. Begonias by and large lean toward separated daylight or halfway shade. They can endure morning sun, yet lean toward security from the sweltering evening sun."
      },
    ]
  },
  {
    blog_title: "Noisette Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-noisette-rose.webp",
    blog_description: "Noisette roses are a beguiling gathering of roses known for their sensitive magnificence and productive blooming. They regularly highlight bunches of little to medium-sized blossoms that sprout in shades of white, pink, yellow, or apricot. These roses are much of the time tracked down in climbing or following structures, settling on brilliant decisions for lattices, arbors, or walls. Noisette roses are famous for their sweet scent, which adds to their charm in the nursery.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/noisette-rose.webp",
    blog_desc: "They are generally simple to develop, favoring bright areas with very much depleted soil. With legitimate consideration, including normal watering, taking care of, and pruning, Noisette roses can flourish and reward nursery workers with a staggering showcase of blossoms all through the developing",
    link: "noisette-rose",
    list: [
      {
        title: "Choose the Right Area",
        description: " Noisette roses favor a radiant area with no less than 6 hours of direct daylight each day. Ensure the dirt is very much depleted and prolific."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-noisette.webp" ,
        description: "Plant your Noisette rose in late winter or fall. Dig an opening two times as wide and profound as the root ball. Mix fertilizer or matured excrement into the dirt before planting. Plant the rose so the bud association (enlarged region where the sticks meet the roots) is simply over the dirt level."
      },
      {
        title: "Watering,Fertilizing And Pruning",
        description: "Water profoundly more than once per week, contingent upon rainfall. Avoid above watering to forestall sicknesses. Treat in late winter with a fair manure (10-10-10 or similar). Repeat preparing in late spring. Prune Noisette roses in late winter before new development starts. Remove dead, harmed, or crossing canes. Shape the hedge to energize the wind stream."
      },
      {
        title: "Disease and Irritation Control",
        description: "Watch out for normal rose infections like fine buildup and dark spots. Treat depending on the situation with suitable fungicides. Monitor for bothers like aphids and bug vermin and treat with insecticidal cleanser if vital."
      },{
        title: "Mulching and Winter Care",
        description: "Apply a layer of mulch around the foundation of the rose in pre-winter to safeguard the roots. Noisette roses are for the most part solid, yet in colder environments, you might have to furnish additional security with mulch or covering in winter."
      },

    ]
  },
  {
    blog_title: "Climbing Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-climbing-rose.webp" ,
    blog_description: "Climbing roses are a sort of flower hedge known for their long, following stems that can be prepared to move up lattices, walls, or different designs. These roses produce huge, garish blossoms that can arrive in different tones, including pink, red, white, and yellow. The blossoms normally have an exemplary rose shape and a sweet scent, adding both magnificence and fragrance to nurseries and scenes.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/climbing-rose.webp",
    blog_desc: "Climbing roses are frequently used to make dazzling vertical presentations, covering walls or pergolas with their rich foliage and plentiful blooms. They require pruning and support to empower development and blossoming, going with them a well known decision for landscapers hoping to add level and variety to their open air spaces.",
    link: "climbing-roses",
    list: [
      {
        title: "Choosing the Right Climbing Rose",
        description: "Select a climbing rose assortment reasonable for your environment and space. A few well known types incorporate David Austin English Roses, Half breed Teas, and Floribundas."
      },
      {
        title: "Planting Climbing Roses",
        description: "Pick a spot with no less than 6 hours of daylight each day. Guarantee there is adequate space for the rose to climb.Dig an opening two times as wide and profound as the root ball. Blend in fertilizer or matured excrement to enhance the soil.Plant the rose at a slight point, directing the sticks to the help structure. Cover the unite association (enlarged region where the sticks meet the roots) 2-3 creeps underneath the dirt level."
      },
      {
        title: "Support Design",
        description: "Utilize a lattice, arbor, pergola, or wall for help. Guarantee it's adequately strong to hold the rose's load as it develops. Tie the sticks freely to the help structure utilizing garden twine or delicate ties. Train them on a level plane or slantingly to empower sidelong growth.Prune in late-winter or pre-spring before new development starts. Eliminate dead, harmed, or crossing sticks. Abbreviate horizontal branches to support blossoming."
      },
      {
        title: "Watering and Taking care of",
        description: "Give ordinary, profound watering to keep the dirt equitably damp. Abstain from above watering to forestall diseases.Fertilize in late-winter with a fair manure. Rehash in late-spring to support ceaseless blossoming. Screen for infections like fine mold or dark spot. Treat immediately with fungicides if needed.Control irritations like aphids or bug vermin with insecticidal cleanser."
      },{
        title: "Mulching and Winter Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/mulching-climbing-rose.webp" ,
        description: "Apply a layer of mulch around the foundation of the rose to ration dampness and safeguard roots.In colder environments, give winter insurance by mulching around the base and wrapping the sticks with burlap."
      },

    ]
  },
  {
    blog_title: "Bourbon Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-bourbon-rose.webp" ,
    blog_description: "Whiskey roses are a particular gathering of roses that mix the appeal and scent of old nursery roses with the rehash blooming quality of present day roses. Beginning in the mid nineteenth hundred years on the Île Whiskey (presently Réunion), these roses are known for their rich, full-petaled blossoms that come in shades of pink, red, and white. The blossoms frequently have serious areas of strength for a, scent that is quite possibly of their most celebrated include.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/bourbon-rose.webp" ,
    blog_desc: "Whiskey roses regularly have an overwhelming development propensity, for certain assortments developing as climbers or enormous bushes. Their foliage is typically dim green and rugged, giving a striking difference to the dynamic sprouts. These roses flourish in very much depleted soil and full sun, and they benefit from standard pruning to keep up with their shape and support productive sprouting. Whiskey roses are valued in gardens for their authentic importance, excellence, and dazzling fragrance.",
    link: "bourbon-rose",
    list: [
      {
        title: "Picking the Right Area",
        description: "Pick an area that gets no less than 6 hours of direct daylight everyday. While they can endure incomplete shade, full sun is great for the best blooms.Bourbon roses favor all around depleted, prolific soil. Sandy topsoil or earth soil soils advanced with natural matter are great."
      },
      {
        title: "Planting",
        description: "Plant Whiskey roses in late-winter or decrease, keeping away from outrageous temperatures.Dig an opening that is two times as wide and profound as the root ball. Revise the dirt with fertilizer or very much decayed manure.Space the plants 2-3 feet separated to take into consideration great air flow, which forestalls sickness."
      },
      {
        title: "Watering And Feeding",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-Bourbon.webp" ,
        description: "Subsequent to planting, water profoundly to assist with laying out the roots.Water consistently, giving profound, exhaustive soakings. Go for the gold crawls of water each week, contingent upon precipitation. Subsequent to planting, water profoundly to assist with laying out the roots.Water consistently, giving profound, exhaustive soakings. Go for the gold crawls of water each week, contingent upon precipitation"
      },
      {
        title: "Pruning",
        description: "Prune following planting to empower ragged growth.Prune in late-winter before new development begins. Eliminate dead, harmed, or powerless sticks. Shape the plant to further develop air dissemination and energize sound growth.Regularly eliminate spent blossoms to support rehash blooming."
      },{
        title: "Mulching",
        description: "Apply a 2-3 inch layer of mulch around the foundation of the plant to preserve dampness, manage soil temperature, and smother weeds. In colder environments, add a thicker layer of mulch or soil around the base in pre-winter to safeguard the roots from freezing."
      },
      {
        title: "Disease and Vermin Control",
        description: "Whiskey roses can be helpless to infections like dark spot, fine buildup, and rust. Guarantee great air flow, abstain from above watering, and treat with fungicides if important. Screen for normal nuisances like aphids, bug parasites, and Japanese creepy crawlies. Utilize insecticidal cleanser or neem oil on a case by case basis Some Whiskey roses have a climbing propensity. Offer a solid help structure like a lattice or arbor. Tie sticks freely to the help to support up development."
      },
      {
        title: "Winter Consideration",
        description: "In colder districts, safeguard Whiskey roses from brutal winter conditions. Hill soil or mulch around the base, and think about covering the plant with burlap or a rose cone. Consistently examine the plants for indications of stress, sickness, or pests.Keep the region around the roses liberated from flotsam and jetsam and weeds to lessen the gamble of nuisances and illnesses."
      },
    ]
  },
  {
    blog_title: "Dahlia Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Dahlia.webp" ,
    blog_description: "Dahlia roses are not an authority class of roses yet rather a term that occasionally alludes to roses with sprouts looking like those of dahlias. These roses are praised for their huge, multifaceted blossoms that grandstand layers of unsettled petals, making a striking visual allure like dahlia blossoms. The sprouts can arrive in a wide exhibit of lively varieties, including profound reds, dazzling yellows, delicate pinks, and unadulterated whites",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Dahlia_rose.webp",
    blog_desc: "Like dahlias, these roses are frequently valued for their emotional and gaudy appearance, going with them a well known decision for decorative nurseries and decorative layouts. Dahlia-like roses flourish in all around depleted soil and full daylight, requiring ordinary watering and mind to keep up with their wellbeing and advance bountiful blossoming. Their special, eye-getting sprouts add a hint of tastefulness and appeal to any garden setting.",
    link: "dahlia-rose",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Dahlias flourish in full sun, expecting something like 6-8 hours of direct daylight every day. Pick a spot in your nursery that gets more than adequate sunlight.Dahlias favor very much depleted, fruitful soil. Sandy topsoil or dirt topsoil soils improved with natural matter, like fertilizer or all around spoiled excrement, are great. The dirt pH ought to be somewhat acidic to impartial (6.0-7.0)."
      },
      {
        title: "Planting",
        description: "Plant dahlia tubers in the spring after the last ice date in your space. Dahlias are delicate to ice and ought to possibly be planted when the dirt has warmed to around 60°F (15°C).Prepare the establishing region by relaxing the dirt to a profundity of 12 inches. Blend in fertilizer or very much spoiled compost to further develop soil ripeness and drainage.Dig an opening 6-8 inches down and spot the tuber on a level plane with the eye (where fledglings will arise) looking up. Cover with 2-3 crawls of soil, bit by bit filling in the red as the plant develops."
      },
      {
        title: "Watering",
        description: "Water the tubers gently subsequent to planting to settle the soil.Once the plants are laid out, water profoundly 2-3 times each week, contingent upon precipitation. Try not to above water to lessen the gamble of contagious infections."
      },
      {
        title: "Feeding",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/feeding-Dahlia-rose.webp" ,
        description: "While planting, blend a decent compost into the soil.Fertilize with a low-nitrogen manure (like 5-10-10) each 4 a month and a half during the developing season. An excessive amount of nitrogen can bring about lavish foliage yet less blossoms."
      },
      {
        title: "Pruning and Squeezing",
        description: "When the plants are around 12 inches tall, squeeze out the top development to support bushier development and more blooms.Regularly eliminate spent sprouts to empower ceaseless blossoming and forestall seed arrangement."
      },
      {
        title: "Staking",
        description: "Tall dahlia assortments might require marking to help their weighty blossoms and keep the stems from breaking. Place stakes or supports at establishing time to try not to harm the tubers later.Tie the stems to the stakes with delicate ties, adding extra ties as the plants develop."
      },
      {
        title: "Disease and Bug Control",
        description: "Dahlias can be vulnerable to parasitic illnesses like fine mold and botrytis. Guarantee great air dissemination, abstain from above watering, and apply fungicides if necessary.Monitor for normal vermin like aphids, slugs, and insect parasites. Utilize insecticidal cleanser or neem oil depending on the situation. Slug snare or hindrances can assist with safeguarding youthful plants."
      },
      {
        title: "Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/mulch-Dahlia.webp" ,
        description: "Apply a 2-3 inch layer of mulch around the foundation of the plants to ration dampness, control soil temperature, and smother weeds. In colder environments, dahlia tubers should be uncovered and put away for the colder time of year. After the primary ice, slice back the stems to around 6 creeps over the ground.Carefully uncover the tubers, dismiss abundance soil, and let them dry for a couple of days. Store them in a cool, dull, ice free spot in somewhat sodden peat greenery or sawdust."
      },
      {
        title: "Regular Support",
        description: "Routinely examine the plants for indications of stress, illness, or bugs. Keep the region around the dahlias liberated from garbage and weeds to decrease the gamble of irritations and illnesses."
      },
    ]
  },
  {
    blog_title: "Japanese Kerria Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-japanese-kerria.webp" ,
    blog_description: "Japanese kerria (Kerria japonica) is a deciduous bush eminent for its radiant yellow, pom-like blossoms that sprout plentifully in spring and frequently rebloom irregularly all through the mid-year. Local to China and Japan, this rich plant regularly develops to a level of 3 to 6 feet, with thin, curving green stems that stay appealing even in winter. Its spear-formed, serrated leaves turn an unmistakable yellow in the fall, adding occasional interest.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/japanese-kerria.webp" ,
    blog_desc: "Japanese kerria flourishes in very much depleted soil and favors inclined toward full shade, making it a fantastic decision for forest gardens or concealed borders. Its strength and low support needs, combined with its merry, buttercup-like blooms, make Japanese kerria a well-known elaborate bush that brings an explosion of variety and surface to nurseries and scenes.",
    link: "japanese-kerria-rose",
    list: [
      {
        title: "Planting",
        description: "Plant Japanese kerria in late winter or fall when the weather conditions are cool and clammy Set up the establishing site by eliminating weeds and releasing the dirt to a profundity of 12-18 inches. Consolidate fertilizer or very much spoiled excrement to further develop soil fruitfulness and drainage. Space the plants 3-6 feet separated to take into consideration their adult size and to guarantee great air course."
      },
      {
        title: "Choosing the Right Area",
        description: "Japanese kerria favors fractional shade yet can endure full sun in cooler environments. In more smoking locales, it benefits from evening shade to forestall leaf scorch. It fills best in very much depleted, respectably prolific soil. While it can endure an assortment of soil types, it leans towards loamy soil with great dampness maintenance."
      },
      {
        title: "Watering",
        description: "Water the plant completely after planting to assist with laying out the roots. Keep the dirt reliably clammy yet not waterlogged. Water profoundly and consistently, particularly during dry periods. Laid-out plants can endure some dry seasons yet perform best with standard dampness."
      },
      {
        title: "Feeding And Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/mulch-japanese-kerria.webp" ,
        description: "While planting, blend a reasonable compost into the soil. Fertilize in late winter with fair manure (10-10-10 or like) advanced sound development and blooming. An extra taking care of in mid-summer can be helpful. Apply a 2-3 inch layer of mulch around the foundation of the plant to ration dampness, manage soil temperature, and stifle weeds. Natural mulches like wood chips or bark are great."
      },
      {
        title: "Pruning",
        description: "Prune after the super blossoming period in pre-summer or late spring. Eliminate dead, harmed, or unhealthy branches. Slim out-packed stems to further develop air dissemination and shape the plant. Every few years, play out a revival pruning by scaling back 33% of the most seasoned stems to ground level. This supports new development and keeps up with the plant's power."
      },
      {
        title: "Disease and Vermin Control",
        description: "Japanese kerria is by and large impervious to most bugs and infections. Be that as it may, leafing spots and fine mold in damp conditions can be helpless. Guarantee great airflow and try not to above water to limit illness risk. Monitor for normal bugs like aphids and bug vermin. Utilize insecticidal cleanser or neem oil depending on the situation."
      },
      {
        title: "Winter Consideration",
        description: "Japanese kerria is strong and for the most part doesn't need unique winter assurance. Be that as it may, in regions with brutal winters, a layer of mulch around the base can safeguard the roots from frosty temperatures."
      },
      {
        title: "Propagation",
        description: "Gap mature plants in late winter or fall. Uncover the plant and separate the root bunches, guaranteeing every division has a few shoots and a sound underground root growth. Replant immediately. Take softwood cuttings in pre-summer or late spring. Plunge the cut finishes in establishing chemicals and plant them in a combination of sand and peat. Keep the cuttings sodden and in a concealed region until roots are created."
      },
      {
        title: "Regular Support",
        description: "Consistently investigate the plants for indications of stress, infection, or pests. Keep the region around the kerria liberated from trash and weeds to lessen the gamble of nuisances and illnesses. Utilize Japanese kerria as a line plant or in blended bush boundaries for a sprinkle of variety in spring. Plant in gatherings or as a support for protection and a dazzling botanical display.Ideal for naturalizing in forest gardens or concealed regions where it can spread and make a characteristic look."
      },

    ]
  },
  {
    blog_title: "Multiflora Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-multiflora.webp" ,
    blog_description: "The multiflora rose (Rosa multiflora) is a vivacious, deciduous bush known for its thick bunches of little, white to pinkish-white blossoms that sprout lavishly in pre-summer to summer. Beginning from East Asia, this rose species is portrayed by its angling sticks and compound leaves, which comprise a few little pamphlets. The blossoms are trailed by little, radiant red hips that endure into the colder time of year, giving visual interest and food to natural life.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/multiflora-rose.webp" ,
    blog_desc: "Multiflora roses are exceptionally versatile and can flourish in an assortment of soil types and natural circumstances, frequently tracked down in fields, woodlands, and alongside the road. While they can be an alluring expansion to scenes, they are additionally viewed as obtrusive in numerous districts because of their fast development and capacity to spread effectively, frequently out-competing local vegetation. Legitimate administration is fundamental to forestall their uncontrolled spread and keep up with biological equilibrium.",
    link: "multiflora-rose",
    list: [
      {
        title: "Environmental Contemplations",
        description: "Know that Multiflora rose is viewed as obtrusive in numerous locales. It spreads effectively by seed and vegetative means, frequently outcompeting local vegetation. Check with nearby augmentation administrations or preservation offices for rules on overseeing or controlling its spread in your space."
      },
      {
        title: "Regular Support",
        description: "Routinely assess the plants for indications of stress, infection, or pests. Keep the region around the roses liberated from flotsam and jetsam and weeds to decrease the gamble of nuisances and illnesses"
      },
      {
        title: "Winter Consideration",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/inter-multiflora.webp" ,
        description: "Multiflora rose is solid and for the most part doesn't need extraordinary winter assurance. Nonetheless, in colder environments, adding a layer of mulch around the base can safeguard the roots from frigid temperatures"
      },
      {
        title: "Supporting Designs",
        description: "On the off chance that you are preparing a Multiflora rose as a climber, offer a solid help construction like a lattice, wall, or arbor. Tie the sticks freely to the help with garden twine or delicate ties."
      },
      {
        title: "Disease and Bug Control",
        description: "Multiflora rose is by and large solid and impervious to numerous infections. Be that as it may, it very well may be vulnerable to rose rosette illness and fine buildup. Guarantee great airflow and try not to above water to limit illness risk. Monitor for normal bugs like aphids and bug vermin. Utilize insecticidal cleanser or neem oil depending on the situation."
      },
      {
        title: "Mulching And Feeding",
        description: "Apply a 2-3 inch layer of mulch around the foundation of the plant to monitor dampness, manage soil temperature, and stifle weeds. While planting, blend a reasonable compost into the soil. Fertilize in late winter with fair manure (10-10-10 or like) with advanced sound development and blooming. Extra care in late spring can be helpful."
      },
      {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Pruning-multiflora.webp" ,
        description: "Prune in pre-spring or late winter before new development starts. Eliminate dead, harmed, or unhealthy sticks. Slight out-packed sticks to further develop air circulation. Multiflora rose can be obtrusive, so ordinary pruning is crucial for controlling its spread. Eliminate suckers (new shoots from the base) to forcefully keep it from spreading as well."
      },
      {
        title: "Watering And Planting",
        description: "Water the plants completely in the wake of planting to assist with laying out the roots. Once laid out, Multhe tiflora rose is dry spell open-minded. Water profoundly and rarely, about once per week during dry periods. Plant Multiflora rose in late winter or fall when the weather conditions are cool and moist. Prepare the establishing site by eliminating weeds and slackening the dirt to a profundity of 12 inches. Consolidate natural matter, for example, fertilizer to further develop soil ripeness and drainage. Space the plants 6-10 feet separated to consider their rambling propensity and to guarantee great air flow."
      },
      {
        title: "Choosing the Right Area",
        description: "Multiflora rose flourishes in full sun to halfway shade. For best blossoming, pick an area that gets no less than 6 hours of direct daylight daily. It leans towards very much depleted, rich soil yet can endure an extensive variety of soil conditions, including poor and mud soils."
      },
  
    ]
  },
  {
    blog_title: "Carnations Rose",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Carnations.webp",
    blog_description: "Carnations (Dianthus caryophyllus) are famous, dependable blossoms known for their unsettled petals and sweet, clove-like aroma. These solid perennials arrive in a large number of varieties, including white, pink, red, yellow, and, surprisingly, green, for certain assortments showing striking bi-shaded designs. Carnations commonly have single or twofold sprouts on tough, slim stems, making them a most loved decision for flower bundles and decorative designs.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/carnation-rose.webp",
    blog_desc: "They flourish in all-around depleted soil and full daylight, requiring standard watering and periodic deadheading to advance constant sprouting. Known for their imagery of affection, interest, and differentiation, carnations are esteemed for their magnificence and scent as well as for their flexibility in nurseries, borders, and as cut blossoms in jars. Their hearty nature and distinctive blossoms make them a loved expansion to any botanical showcase",
    link: "carnations-rose",
    list: [
      {
        title: "Starting from Seeds",
        description: "Begin seeds inside 6 two months before the last expected ice date. Plant the seeds in a seed plate loaded up with a well-depleting seed beginning blend. Press the seeds gently into the dirt and cover them with a dainty layer of vermiculite. Keep the dirt reliably soggy and keep a temperature of 65-70°F (18-21°C). Seeds ought to sprout in 2-3 weeks. Transplant seedlings outside when they have no less than two arrangements of genuine leaves and all risk of ice has passed. Solidify the seedlings by slowly presenting them to open-air conditions for seven days."
      },
      {
        title: "Planting",
        description: " Plant carnations in late winter after the last ice or in pre-fall to late summer in milder climates. Prepare the establishing site by slackening the dirt to a profundity of 12-15 inches. Blend in fertilizer or very much spoiled compost to further develop soil ripeness and seepage. Space the plants 12-18 inches separated to guarantee great airflow and to diminish the gamble of sickness."
      },
      {
        title: "Choosing the Right Area",
        description: "Carnations flourish in full sun and expect something like 4-6 hours of direct daylight every day to advance lively development and plentiful blooms. They favor very much depleted, prolific soil with a somewhat basic pH (around 6.7-7.5). Sandy or loamy soils are great. Stay away from weighty dirt soils that hold a lot of dampness."
      },
      {
        title: "Starting from Cuttings",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/cuttings-Carnations.webp",
        description: "Take 4-6 inch cuttings from solid, non-blooming stems in pre-summer or early summer. Plant the cuttings in a blend of peat and sand or perlite. Plunge the cut closures by attaching chemicals before planting to empower root development. Keep the cuttings in a splendid, roundabout light area and keep up with high mugginess by covering them with a plastic vault or putting them in a propagator. Roots ought to be fostered in 3 months."
      },
      {
        title: "Watering",
        description: "Water completely after planting to settle the dirt around the roots.Water carnations profoundly yet inconsistently, permitting the dirt to dry out between waterings. Try not to above water to forestall parasitic illnesses. Water at the foundation of the plants to keep the foliage dry."
      },
      {
        title: "Feeding And Staking",
        description: "While planting, integrate a fair manure into the soil. Feed carnations each 6 two months during the developing season with a reasonable, water-solvent compost (e.g., 10-10-10) or a compost formed for blooming plants. Taller assortments might require marking to help the blossom stems and keep them from bowing or breaking. Use bamboo stakes or plant supports and tie the stems freely with garden twine or delicate ties."
      },
      {
        title: "Pruning and Squeezing",
        description: "Squeeze back the developing tips when the plants are 4-6 inches tall to energize bushier development and more blooms. Regularly eliminate spent blossoms to support consistent sprouting and forestall seed arrangement. Scale back the plants by 33% in pre-fall to advance a second flush of blossoms."
      },
      {
        title: "Disease and Nuisance Control",
        description: "Carnations can be powerless against parasitic sicknesses, for example, rust, leaf spot, and Fusarium wither. Guarantee a great air course, abstain from above watering, and treat with fungicides if necessary. Monitor for normal nuisances like aphids, thrips, and insect parasites. Utilize insecticidal cleanser or neem oil to control pervasions. Practice great nursery cleanliness by eliminating dead leaves and trash."
      },
      {
        title: "Winter Consideration",
        description: "In colder environments, carnations might require security from frosty temperatures. Mulch around the foundation of the plants with straw or evergreen branches to protect the roots. In freezing areas, consider lifting the plants and overwintering them inside. In milder environments, carnations can be developed as perennials with negligible winter security. Essentially slice back the plants to a couple of creeps over the ground after the main ice and mulch around the base."
      },
      {
        title: "Regular Support",
        description: "Routinely assess the plants for indications of stress, illness, or vermin. Keep the region around the carnations liberated from weeds and trash to diminish the gamble of bugs and illnesses. Apply mulch to ration dampness and direct soil temperature"
      },
      {
        title: "Landscape Purposes",
        description: "Use carnations as boundary plants or along garden edges for a sprinkle of color. Plant carnations in devoted cut blossom gardens for new, enduring blooms. Grow carnations in compartments on porches, decks, or overhangs for simple access and added excellence."
      },
    ]
  },
  {
    blog_title: "Ferns",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_ferns.webp",
    blog_description: "Greeneries are a different gathering of vascular plants having a place with the division Pteridophyta. They have been around for a long period and are found in different natural surroundings around the world, from tropical rainforests to mild forests and even deserts. Plants are famous for their unmistakable fronds, which are regularly made out of different pamphlets known as pinnae, organized along a focal stem called the rachis.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/ferns.webp",
    blog_desc: "Plants show a large number of shapes, sizes, and development propensities, with certain species framing low, spreading mats, while others develop into transcending tree greeneries. They assume urgent biological parts, filling in as food sources and territories for different creatures, balancing out soil, and adding to supplement cycling in environments..",
    blog_bold_desc: "Here is a novice's manual for developing Plants:",
    link: "ferns",
    list: [
      {
        title: "Picking the Right Area",
        list_Data: ["Select an area in your home that offers roundabout or separated light, as immediate daylight can sear greeneries' fragile fronds. Regions close to north-bound windows or rooms with dappled daylight are great.",
          "Guarantee the picked spot keeps a generally steady temperature and gives sufficient moisture. Restrooms, kitchens, or rooms with a humidifier are great areas for developing greeneries."]
      },
      {
        title: "Choosing the Right Pot and Soil",
        list_Data: ["Pick a pot with seepage openings to forestall waterlogging, as greeneries favor reliably damp however well-depleting soil. Earthenware or fired pots function admirably, as they consider wind current and water dissipation.",
          "Utilize a well-depleting blend planned for plants or make your own by joining a balance of peat greenery, perlite, and coarse sand. This blend gives the dampness maintenance and air circulation greeneries needed."]
      },
      {
        title: "Establishing Plants",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_feans.webp",
        list_Data: [
          "While establishing plants, guarantee the root ball is level with the dirt surface. Try not to cover the crown of the plant, as this can prompt decay. Tenderly pat the dirt around the roots to get the plant set up.",
          "Water the greenery completely in the wake of planting to settle the dirt and advance the root foundation. Keep the dirt reliably soggy yet not waterlogged, permitting an overabundance of water to empty unreservedly out of the pot."
        ]
      },
      {
        title: "Watering and Stickiness",
        list_Data: [
          "Keep up with reliable soil dampness by watering your greenery at whatever point the top inch of soil feels dry to the touch. Water at the foundation of the plant to try not to wet the foliage, which can prompt contagious illnesses.",
          "Increment stickiness around the greenery by clouding the foliage consistently, particularly during dry periods or when the indoor air is dry. On the other hand, place a plate loaded up with water and stones underneath the pot to make a muggy microclimate."
        ],
      },
      {
        title: "Preparation",
        list_Data: [
          "Treat your plant sparingly to abstain from overloading, as they are not weighty feeders. Utilize a reasonable, water-dissolvable manure weakened to half strength and apply it each 4 months and a half during the developing season.",
          "On the other hand, integrate natural matter, for example, manure or worm castings into the dirt a few times per year to continuously give supplements."
        ]
      },
      {
        title: "Support and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_feans.webp",
        list_Data: [
          "Prune dead or yellowing fronds routinely to advance new development and keep up with the plant's appearance. Utilize spotless, sharp scissors or pruning shears to try not to spread illnesses.",
          "Screen your plant for indications of vermin like aphids, scale bugs, or mealybugs. Treat any pervasions speedily utilizing natural techniques, for example, insecticidal cleanser or neem oil."
        ]
      }
    ]
  },
  {
    blog_title: "Sweet Pea",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_sweet_pea.webp",
    blog_description: "Sweet peas, deductively known as Lathyrus odoratus, are famous blossoming plants loved for their beautiful and fragrant sprouts. Having a place with the Fabaceae family, sweet peas are local toward the eastern Mediterranean district yet have been developed and hybridized broadly for elaborate purposes around the world.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sweet_pea.webp",
    blog_desc: "Sweet peas are generally simple to develop, requiring all-around depleted soil, more than adequate daylight, and standard watering to flourish. They are usually developed from seeds, which can be planted straightforwardly into the nursery soil or begun inside before relocating. Contingent upon the environment, sweet peas might be developed as winter or spring annuals, with blossoming commonly happening in pre-summer to summer early.",
    blog_bold_desc: "Here is a novice's manual for developing Sweet Pea:",
    link: "sweet-pea",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Pick a bright area for establishing sweet peas, guaranteeing they get something like 6 hours of daylight day to day. Be that as it may, in hotter environments, they might profit from fractional shade during the most blazing piece of the day.",
          "Sweet peas incline toward well-depleting soil with great fruitfulness. Set up the establishing region by slackening the dirt to a profundity of 6-8 inches and consolidating fertilizer or matured compost to work on its surface and supplement content."
        ],
      },
      {
        title: "Sowing Seeds or Seedlings",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seeding_pea.webp",
        list_Data: [
          "Sweet peas can be developed from seeds or seedlings. Assuming that sowing seeds straightforwardly into the nursery, sow them around 1 inch down and 2 inches separated. Water the dirt completely after planting.",
          "On the other hand, begin seeds inside 4 a month and a half before the last expected ice date. Relocate seedlings outside once the risk of ice has passed and the dirt has heated up.",
          "Space seedlings or transfers around 6 inches separated to take into account legitimate air dissemination and development."
        ],
      },
      {
        title: "Offering Help",
        list_Data: [
          "Sweet peas are climbing plants that need help for their rings to grip to as they develop. Introduce lattices, walls, or other vertical designs for the sweet peas to climb.",
          "Then again, you can establish sweet peas close to existing designs like walls, pergolas, or arbors. Guarantee the help is sufficiently solid to hold the heaviness of the plants when they are completely developed."
        ],
      },
      {
        title: "Watering and Treatment",
        list_Data: [
          "Keep the dirt reliably wet yet not waterlogged all through the developing season, particularly during dry periods. Water the plants at the base to try not to wet the foliage, which can prompt contagious sicknesses.",
          "Prepare sweet peas with a fair compost once every month to advance solid development and blooming. Keep away from high-nitrogen composts, as they can energize lavish foliage to the detriment of blossoms."
        ],
      },
      {
        title: "Pruning and Upkeep",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning_pea.webp",
        list_Data: [
          "Squeeze off the developing tips of sweet pea plants once they arrive at around 6 inches tall to empower stretching and bushier development. This will bring about additional blossoms.",
          "Deadhead spent blossoms routinely to delay the sprouting time frame and urge the plant to deliver more sprouts. Scale the blossoms back to simply over a bunch of leaves or a leaf hub."
        ],
      },
      {
        title: "Bug and Sickness The board",
        list_Data: [
          "Screen your sweet pea plants routinely for indications of nuisances like aphids, insect parasites, or fine buildup. Treat any invasions expeditiously utilizing natural techniques, for example, insecticidal cleanser or neem oil."
        ],
      },
    ]
  },
  {
    blog_title: "Daphne",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_daphne.webp",
    blog_description: "Daphne, frequently alluded to as Daphne odora or Daphne spp., is a variety of blooming bushes valued for its dazzling excellence and enthralling scent. Having a place with the Thymelaeaceae family, Daphne species are local to different districts across Europe, Asia, and North Africa, flourishing in calm environments.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Daphne.webp",
    blog_desc: "Daphne plants are portrayed by their shiny, evergreen foliage, which gives all-year interest in any event, when not in sprout. The leaves are normally dim green and rough, making a lavish background for the sensitive blossoms.",
    blog_bold_desc: "Here is a novice's manual for developing Daphne:",
    link: "daphne",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Pick an area in your nursery that offers halfway shade to separate daylight. Daphne plants favor assurance from the unforgiving evening sun, particularly in hotter environments.",
          "Guarantee the picked spot has well-depleting soil with great airflow. Abstain from establishing Daphne in low-lying regions inclined to waterlogging, as this can prompt root decay."
        ],
      },
      {
        title: "Setting up the Dirt",
        list_Data: [
          "Daphne flourishes in marginally acidic to nonpartisan soil with a pH of 6.0 to 7.0. Set up the establishing region by correcting the dirt with natural matter, for example, fertilizer or very much spoiled compost to further develop waste and ripeness.",
          "Work the natural matter into the dirt to a profundity of 12-18 creeps to make a free, brittle surface that permits roots to effectively infiltrate."
        ],
      },
      {
        title: "Establishing Daphne",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_daphne.webp",
        list_Data: [
          "Plant Daphne in the spring or fall when the weather conditions are gentle and the dirt is useful. Pick solid nursery examples with advanced underground roots.",
          "Dig an opening marginally bigger than the root chunk of the plant and spot it in the middle. Refill the opening with soil and delicately firm it around the foundation of the plant.",
          "Water the recently established Daphne completely to settle the dirt and eliminate any air pockets. Apply a layer of mulch around the foundation of the plant to save dampness and stifle weeds."
        ]
      },
      {
        title: "Watering and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_daphne.webp",
        list_Data: [
          "Keep the soil consistently moist, especially during the plant's establishment period. Water deeply at the base of the plant whenever the top inch of soil feels dry to the touch.",
          "Try not to above water to forestall parasitic sicknesses, as Daphne is powerless to root decay and other dampness-related issues.",
          "Screen the plant routinely for indications of irritations and sicknesses, like aphids, scale bugs, or fine mold. Treat any pervasions instantly utilizing natural techniques, for example, insecticidal cleanser or neem oil."
        ],
      },
      {
        title: "Pruning and Support",
        list_Data: [
          "Prune Daphne gently after blooming to eliminate dead or harmed branches and empower a minimized thick development propensity. Abstain from weighty pruning, as Daphne sprouts on old wood, and unnecessary cutting might lessen blossoming.",
          "Give winter assurance to Daphne in locales with cold temperatures or brutal atmospheric conditions. Mulch around the foundation of the plant to protect the roots and shield them from ice."
        ],
      },
    ]
  },
  {
    blog_title: "Angle Trumpet",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_angle_trumpet.webp",
    blog_description: "Heavenly messenger's Trumpet, deductively known as Brugmansia, is a staggering blossoming plant valued for its huge, trumpet-molded sprouts and inebriating scent. While it is many times filled outside in calm environments, Heavenly messenger's Trumpet can likewise be developed inside as a fabulous houseplant, carrying excellence and polish to any home climate.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/angle_trumpet.webp",
    blog_desc: "One of the most striking elements of Holy messenger's Trumpet is its blossoms, which can arrive at sizes of up to 12 inches long and arrive in various varieties, including white, yellow, pink, orange, and peach. The blossoms radiate a sweet, inebriating scent, particularly at night, drawing in pollinators like moths and hummingbirds.",
    blog_bold_desc: "Here is a novice's manual for developing Point Trumpet",
    link: "angle-trumpet",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Pick a splendid, radiant spot inside close to a south-bound window where the plant can get something like 6-8 hours of roundabout daylight everyday. On the other hand, develop Heavenly messenger's Trumpet outside in a shielded region with fractional shade, particularly in locales with blistering evening sun.",
          "Guarantee the indoor space has satisfactory ventilation to forestall dampness development, which can prompt parasitic issues."
        ]
      },
      {
        title: "Setting up the Dirt",
        description: "Utilize a well-depleting preparing blend wealthy in natural matter. You can make your own blend by consolidating equivalent parts fertilized soil, perlite, and manure. Guarantee the dirt pH is marginally acidic to nonpartisan (pH 6.0-7.0) for ideal development.",
      },
      {
        title: "Establishing Heavenly messenger's Trumpet",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container_angle_trumpet.webp",
        description: "In the event that establishing in a holder, pick an enormous pot with waste openings to forestall waterlogging. Plant the Heavenly messenger's Trumpet at a similar profundity it was filling in its nursery compartment. Tenderly firm the dirt around the plant and water completely subsequent to planting.",
      },
      {
        title: "Watering and Preparation",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Fertilize_angle.webp",
        list_Data: [
          "Keep the dirt reliably soggy, yet entirely not waterlogged. Water the plant profoundly at whatever point the top inch of soil feels dry to the touch, particularly during hot and dry periods.",
          "Prepare Holy messenger's Trumpet each 2-3 weeks during the developing season with a fair manure high in phosphorus to advance blossoming. On the other hand, utilize a manure explicitly formed for sprouting plants."
        ]
      },
      {
        title: "Pruning and Upkeep",
        list_Data: [
          "Prune Heavenly messenger's Trumpet routinely to empower ragged development and improve blooming. Eliminate any dead, harmed, or packed branches to further develop air dissemination and forestall illness.",
          "Deadhead blurred blossoms consistently to drag out the sprouting period and energize new sprouts. Furthermore, eliminate any suckers or side shoots that rise up out of the foundation of the plant to keep a clean appearance."
        ]
      }
    ]
  },
  {
    blog_title: "Papperwhites",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_papperWhites.webp",
    blog_description: "Paperwhites, experimentally known as Narcissus papyraceus, are a type of blooming bulbous plant having a place with the Amaryllidaceae family. Local to the Mediterranean district, especially the Iberian Promontory and North Africa, paperwhites are generally developed for their fragrant and sensitive white blossoms.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/papperWhites.webp",
    blog_desc: "Paperwhites are known for their simplicity of development and quick development, making them ideal possibility for indoor or outside planting. They require all-around depleted soil and more than adequate daylight to flourish, and they can be filled in holders, garden beds, or even water gardens. Paperwhite bulbs can be established in the fall for spring sprouts or constrained inside throughout the cold weather for a long time by giving them a time of cold treatment followed by warmth and light to invigorate blossoming.",
    blog_bold_desc: "Here is a fledgling's manual for developing Paperwhites:",
    link: "papperwhites",
    list: [
      {
        title: "Choosing Bulbs",
        list_Data: [
          "Pick solid Paperwhite bulbs from a legitimate nursery or nursery focus. Search for bulbs that are firm and liberated from shape or weaknesses.",
          "Paperwhite bulbs are normally accessible in pre-fall or late summer. Select bulbs that are huge and stout, as they will quite often create more blossoms."
        ]
      },
      {
        title: "Picking Holders",
        list_Data: [
          "Select shallow holders with seepage openings to establish your Paperwhite bulbs. Wide, shallow dishes or beautifying holders function admirably and take into account appropriate root advancement.",
          "Fill the compartments with gardening soil or rocks to help the bulbs and give soundness. Guarantee the holders are adequately enormous to oblige numerous bulbs without congestion."
        ],
      },
      {
        title: "Establishing Bulbs",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_papperwhite.webp",
        list_Data: [
          "Place the Paperwhite bulbs in the holders, dividing them equally. Press the bulbs softly into the dirt or rocks, allowing the tips to be uncovered.",
          "Add extra soil or rocks around the bulbs to secure them up. Water the compartments completely in the wake of planting to settle the dirt and start development."
        ],
      },
      {
        title: "Giving Light and Temperature",
        list_Data: [
          "Place the compartments in a brilliant area with circuitous daylight. Paperwhites lean toward cool temperatures between 50-65°F (10-18°C) during the developing time frame.",
          "Try not to put the compartments in direct daylight or close to warm sources, as the exorbitant intensity can make the bulbs dry out rashly."
        ],
      },
      {
        title: "Watering and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_papperwhite.webp",
        list_Data: [
          "Keep the dirt or stones equitably wet all through the developing period. Water the compartments at whatever point the top inch of soil feels dry to the touch.",
          "Screen the dampness level consistently and keep away from overwatering, as this can prompt bulb decay. Legitimate watering is fundamental for sound root improvement and hearty development."
        ],
      },
      {
        title: "Supporting Development and Blossoming",
        list_Data: [
          "As the Paperwhite bulbs develop, offer help by marking taller stems or tenderly integrating them with twine to forestall slumping.",
          "Partake in the scent and magnificence of Paperwhite blossoms as they arise, ordinarily inside 4 a month and a half after planting. You can delay the blossoming time frame by putting the compartments in a cool area away from direct daylight once the sprouts show up."
        ],
      },
    ]
  },
  {
    blog_title: "Monstera Swiss Cheese",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_monstera_swiss.webp",
    blog_description: "The Monstera deliciosa, normally known as the Swiss Cheddar Plant or basically Monstera, is a striking tropical plant commended for its notorious foliage and interesting appearance. Having a place with the Araceae family, this species is local to the rainforests of Focal America, where it climbs trees and different designs with its flying roots, displaying its great fenestrated leaves.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/monstera-swiss-cheese.webp",
    blog_desc: 'The name "Swiss Cheddar Plant" comes from the unmistakably punctured leaves that look like the openings tracked down in Swiss cheddar, giving the plant a particular and eye-getting tasteful. These openings known as fenestrations, create as the plant develops, with the leaves turning out to be all the more profoundly lobed and punctured over the long haul.',
    blog_bold_desc: "Here is a novice's manual for developing Monstera Swiss Cheddar:",
    link: "swiss-cheese",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Pick a splendid, backhanded light area for your Monstera Swiss Cheddar. Stay away from direct daylight, as it can burn the leaves. North or east-bound windows are great, or you can put the plant a couple of feet from a south or west-bound window.",
          "Monstera Swiss Cheddar can endure lower light circumstances, yet they might develop all the more leisurely and produce more modest leaves. Guarantee the picked spot has predictable temperatures between 65-85°F"
        ],
      },
      {
        title: "Picking the Right Pot and Soil",
        list_Data: [
          "Select a well-depleting pot with waste openings to forestall waterlogging, as Monstera Swiss Cheddar normally likes damp yet not waterlogged soil. Earthenware or clay pots function admirably, as they permit an abundance of dampness to dissipate.",
          "Utilize a well-depleting preparing blend rich in natural matter, like a combination of peat greenery, perlite, and coarse sand. Stay away from weighty soils that hold an excess of dampness, as this can prompt root decay."
        ],
      },
      {
        title: "Establishing Monstera Swiss Cheddar",
        list_Data: [
          "Plant Monstera Swiss Cheddar in a pot that is 1-2 inches bigger in breadth than the root ball. Guarantee the plant is situated at a similar profundity as it was in its past holder.",
          "Inlay the pot with preparing blend, delicately firming it around the roots. Water the plant completely after planting to settle the dirt and eliminate any air pockets."
        ],
      },
      {
        title: "Watering and Moistness",
        list_Data: [
          "Keep the dirt reliably wet however not waterlogged. Water your Monstera Swiss Cheddar at whatever point the top inch of soil feels dry to the touch, normally one time per week or when the passes on begin to somewhat hang.",
          "Keep up with high stickiness around the plant by moistening the leaves routinely or setting a plate loaded up with water and rocks underneath the pot. Gathering plants can likewise help make a moist microclimate."
        ],
      },
      {
        title: "Offering Help",
        list_Data: [
          "As Monstera Swiss Cheddar develops, offer help for its climbing or following plants. You can utilize a greenery post, lattice, or stakes to help the plant and support development.",
          "Train the plants to climb or trail along the help structure by tenderly binding them up with delicate plant ties or twine. Routinely check and change the ties as the plant develops."
        ],
      },
      {
        title: "Pruning and Support",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_monstera_swiss.webp",
        list_Data: [
          "Prune Monstera Swiss Cheddar routinely to eliminate dead, harmed, or yellowing leaves. Utilize perfect, sharp scissors or pruning shears to make clean cuts close to the foundation of the stem.",
          "To support bushier development and bigger leaves, prune back lengthy, leggy stems or plants. You can likewise spread the pruned cuttings to develop new plants."
        ],
      },
    ]
  },
  {
    blog_title: "Algerian Ivy",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_algerian-ivy.webp",
    blog_description: "Algerian Ivy, logically known as Hedera canariensis, is a famous decision for indoor development because of its appealing foliage and simple consideration necessities. This evergreen plant, local toward the western Mediterranean district, including Algeria, Morocco, and the Canary Islands, brings rich vegetation and a bit of nature into indoor spaces.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/algerian.webp",
    blog_desc: "As a houseplant, Algerian Ivy is valued for its lustrous, profoundly lobed passes that add surface and visual interest to the inside stylistic layout. The foliage goes from dark green to variegated structures with cream or yellow markings, considering flexibility in plan plans. Whether flowing from hanging crates, climbing lattices, or following from racks, Algerian Ivy adds a lively, regular component to any room.",
    blog_bold_desc: "Here is a novice's manual for developing Algerian Ivy:",
    link: "algerian-ivy",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Algerian Ivy flourishes in brilliant, circuitous light however can likewise endure lower light levels, making it reasonable for different indoor areas. Pick a spot close to a window where it can get brilliant, sifted daylight for a few hours per day.",
          "Try not to put Algerian Ivy in that frame of mind, as this can burn the leaves. It can likewise flourish in regions with counterfeit light, like workplaces or rooms with fluorescent lighting."
        ]
      },
      {
        title: "Picking the Right Compartment",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container_algerian.webp",
        list_Data: [
          "Select a well-depleting compartment with waste openings to forestall waterlogged soil, as Algerian Ivy usually likes wet but not saturated conditions.",
          "Guarantee the compartment is adequately enormous to oblige the root foundation and take into consideration development. A compartment with adequate space for the ivy to spread and the trail is great."
        ]
      },
      {
        title: "Setting up the Preparing Blend",
        list_Data: [
          "Utilize an excellent, well-depleting preparing blend formed for indoor plants, or blend your own by joining a balance of peat greenery, perlite, and vermiculite.",
          "Alternatively, correct the preparation and blend in with natural matter, for example, fertilizer or matured compost to improve the dirt and give supplements to sound development."
        ]
      },
      {
        title: "Establishing the Algerian Ivy",
        list_Data: [
          "Cautiously eliminate the Algerian Ivy plant from its nursery pot, taking into consideration not to harm the roots. Delicately release any compacted roots and keep them separated if essential.",
          "Place the ivy plant in the focal point of the pre-arranged holder and fill in around the roots with preparing blend, squeezing delicately to get the plant set up.",
          "Water the plant completely after planting to settle the dirt and guarantee great root-to-soil contact."
        ]
      },
      {
        title: "Watering and Dampness",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-algerian.webp",
        list_Data: [
          "Keep the dirt reliably clammy however not waterlogged, watering the Algerian Ivy when the top inch of soil feels dry to the touch.",
          "Stay away from overwatering, as this can prompt root decay. Permit overabundance of water to empty away openly out of the lower part of the compartment.",
          "Increment dampness around the plant by clouding the foliage routinely or putting the pot on a plate loaded up with stones and water. This forestalls dry indoor circumstances, particularly in warmed or cooled conditions."
        ]
      }, {
        title: "Upkeep and Care",
        list_Data: [
          "Prune the Algerian Ivy routinely to keep up with its shape and empower thick development. Eliminate any leggy or yellowing stems, as well as dead or harmed leaves.",
          "Prepare the ivy plant each 4 a month and a half during the developing season with fair fluid manure weakened to half-strength. Adhere to bundle directions for application rates.",
          "Screen the plant for irritations like bug vermin, aphids, or mealybugs, particularly in dry indoor conditions. Treat any invasions instantly with an insecticidal cleanser or neem oil."
        ]
      },
    ]
  },
  {
    blog_title: "Chives",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_chives.webp",
    blog_description: "Chives, deductively known as Allium schoenoprasum, are a famous culinary spice treasured for their fragile onion flavor and appealing appearance. Having a place with a similar family as onions, garlic, and leeks (Alliaceae), chives are local to Europe, Asia, and North America and have been developed for a long time for both culinary and restorative purposes.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/chives.webp",
    blog_desc: "Chives are described by their slim, empty leaves and lively green tone. They produce bunches of little, eatable roses in pre-summer to late spring, going in variety from pale lavender to profound purple. Both the leaves and blossoms are eatable and bestow a gentle onion flavor to dishes, making them flexible fixes in different cooking styles all over the planet.",
    blog_bold_desc: "Here is a fledgling's manual for developing Chives:",
    link: "chives",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Pick a bright spot in your nursery or gallery that gets something like 6-8 hours of daylight each day. Chives flourish in full sun however can endure halfway shade.",
          "In the case of developing inside, place the chives close to a southbound window where they can get more than adequate daylight. Then again, use developed lights to enhance normal light."
        ]
      },
      {
        title: "Setting up the Dirt",
        list_Data: [
          "Guarantee the dirt is well-depleting and ripe. Chives favor marginally acidic to nonpartisan soil with a pH of 6.0 to 7.0.",
          "Consolidate natural matter, for example, fertilizer or very much spoiled compost into the dirt to work on its surface and richness."
        ]
      },
      {
        title: "Establishing Chives",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_chives.webp",
        list_Data: [
          "Chives can be developed from seeds, transfers, or divisions of existing plants. In the case of beginning from seeds, sow them straightforwardly into the pre-arranged soil in late winter after the last ice date.",
          "Space the seeds or transfers around 6-8 inches separated to take into consideration satisfactory development. In the case of establishing divisions, space them as needs be, guaranteeing every division has adequate space to create.",
          "Sow the seeds or transfers at a profundity of ¼ to ½ inch and water completely after planting to settle the dirt."
        ]
      },
      {
        title: "Watering and Preparation",
        list_Data: [
          "Keep the dirt reliably damp yet not waterlogged. Water the chives at whatever point the top inch of soil feels dry to the touch, particularly during hot and dry periods.",
          "Try not to above water to forestall contagious sicknesses. All things considered, water at the foundation of the plants conveys dampness straightforwardly to the roots.",
          "Chives by and large don't need a lot of preparation. In any case, you can apply decent manure or fertilizer a few times during the developing season to advance solid development."
        ]
      },
      {
        title: "Support",
        list_Data: [
          "Trim the chives routinely to support rugged development and keep them from becoming leggy. Use scissors or nursery shears to cut off the external leaves on a case-by-case basis, passing on the inward passes to develop.",
          "Eliminate any bloom buds when they seem to delay the reaping time frame. While chive blossoms are palatable and appealing to pollinators, eliminating the buds helps channel the plant's energy into leaf creation.",
        ]
      },
      {
        title: "Collecting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvest_chives.webp",
        list_Data: [
          "You can begin collecting chives when they arrive at a level of 6-8 inches, normally 6 two months in the wake of planting.",
          "To gather, clip off the leaves at the foundation of the plant utilizing scissors or nursery shears. Leave something like 2 crawls of development over the dirt to permit the plant to regrow.",
          "Reap chives routinely all through the developing season, yet try not to eliminate more than each third of the plant in turn to guarantee proceeded with development and efficiency."
        ]
      },
    ]
  },
  {
    blog_title: "Majesty Palm",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_majesty_palm.webp",
    blog_description: "Magnificence Palms, deductively known as Ravenea rivularis, are shocking tropical plants that bring a hint of lavish vegetation and class to indoor spaces. With their elegant fronds and slim trunks, Magnificence Palms inspire the quiet excellence of tropical scenes, making them a well-known decision for inside design.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Majesty_Palm.webp",
    blog_desc: "Magnificence palms are local to Madagascar and flourish in warm, sticky conditions, making them appropriate for indoor development in homes and workplaces. Greatness Palms are described by their curving, feather-like fronds that rise out of a focal crown on a tall, slim trunk. When developed inside, they normally arrive at levels of 6 to 10 feet, adding vertical interest and a feeling of show to any room.",
    blog_bold_desc: "Here is a fledgling's manual for developing Grandness Palm:",
    link: "majesty-palm",
    list: [
      {
        title: "Choosing the Right Area",
        list_Data: [
          "Pick a splendid, backhanded light area for your Highness Palm, as it flourishes in sufficiently bright regions without direct daylight. A north or east-bound window is great, or you can put the plant a couple of feet from a south or west-bound window to keep away from unforgiving daylight.",
          "Guarantee the picked spot keeps a generally steady temperature between 65-80°F (18-27°C) all year. Grandness Palms are delicate to temperature variances and may endure whenever presented with cold drafts or abrupt temperature changes."
        ],
      },
      {
        title: "Picking the Right Pot and Soil",
        list_Data: [
          "Select a pot with seepage openings to forestall waterlogging, as Magnificence Palms usually like reliably wet however well-depleting soil. Pick a pot that is 1-2 inches bigger in breadth than the plant's root ball to consider legitimate root development.",
          "Utilize a well-depleting blend planned for palm trees or make your own by consolidating a balance of peat greenery, perlite, and coarse sand. This blend gives the dampness maintenance and air circulation that Magnificence Palms require."
        ],
      },
      {
        title: "Establishing Greatness Palms",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/potting_majesty-palm.webp",
        list_Data: [
          "Cautiously eliminate the Greatness Palm from its nursery holder, delicately prodding separated any orbiting roots. Place the plant in the focal point of the pot and refill with preparing blend, guaranteeing the highest point of the root ball is level with the dirt surface.",
          "Delicately firm the dirt around the foundation of the plant and water completely to settle the dirt and eliminate any air pockets. Try not to cover the crown of the plant, as this can prompt decay."
        ],
      },
      {
        title: "Watering and Moistness",
        list_Data: [
          "Keep the dirt reliably wet yet not waterlogged, permitting the top inch of soil to dry out somewhat between waterings. Water the Grandness Palm completely, guaranteeing that an abundance of water empties unreservedly out of the lower part of the pot.",
          "Keep up with high mugginess around the plant by moistening the foliage routinely or setting a plate loaded up with water and rocks underneath the pot. Gathering plants can likewise help make a moist microclimate."
        ],
      },
      {
        title: "Treatment",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Fertilize_majesty-palm.webp",
        list_Data: [
          "Prepare the Greatness Palm each 2-3 months during the developing season (spring and summer) with a reasonable, water-dissolvable compost formed for indoor plants. Adhere to the producer's guidelines for weakening and application rates.",
          "Stay away from overfertilizing, as unnecessary compost can cause salt development in the dirt and harm the underlying foundations of the plant. Flush the dirt with water intermittently to drain out any aggregated salts."
        ],
      },
      {
        title: "Upkeep and Care",
        list_Data: [
          "Prune dead or yellowing fronds consistently to advance new development and keep up with the Greatness Palm's appearance. Utilize spotless, sharp scissors or pruning shears to make clean cuts close to the foundation of the frond.",
          "Screen the plant for vermin like bug parasites, mealybugs, or scale bugs, and treat any pervasions quickly utilizing natural strategies, for example, insecticidal cleanser or neem oil."
        ],
        additional_desc: "By following these itemized advances and giving appropriate consideration, you can effectively develop Grandness Palms at home, partaking in their agile foliage and tropical charm in your indoor space. With their low-support nature and air-cleansing characteristics, Magnificence Palms make a shocking expansion to any home or office climate."
      }
    ]
  },
  {
    blog_title: "Anthurium",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_anthurium.webp",
    blog_description: "Anthuriums are tropical blossoming plants valued for their staggering sprouts and reflexive foliage. Having a place with the Araceae family, Anthuriums are local to the rainforests of Focal and South America, where they flourish in warm, sticky conditions. These plants are usually developed as ornamentals and are well-known decisions for indoor or nursery development because of their colorful appearance and simplicity of care.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Anthurium.webp",
    blog_desc: "Anthuriums are described by their unmistakable, heart-molded leaves and spadix-and-spathe blossoms. The spathe, which is many times brilliantly hued in shades of red, pink, orange, white, or green, encompasses the spadix, which contains the plant's small blossoms. The blossoms are regularly little and thickly stuffed along the spadix, making an outwardly striking showcase.",
    blog_bold_desc: "Here is a fledgling's manual for developing Anthurium:",
    link: "anthurium",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Pick a brilliant, backhanded light area for your Anthurium, as it flourishes in sufficiently bright regions without direct daylight. A north or east-bound window is great, or you can put the plant a couple of feet from a south or west-bound window to keep away from serious daylight. Anthuriums favor warm temperatures between 65-80°F (18-27°C) and high mugginess levels. They are delicate to cold drafts and temperature vacillations, so abstain from putting them close to cooling vents or warmers.",
      },
      {
        title: "Picking the Right Pot and Soil",
        description: "Select a pot with waste openings to forestall waterlogging, as Anthuriums favor reliably soggy however well-depleting soil. Pick a pot that is marginally bigger than the plant's root ball to consider root development. Utilize a well-depleting blend planned for tropical plants or make your own by joining peat greenery, perlite, and orchid bark. This blend gives the dampness maintenance and air circulation that Anthuriums require.",
      },
      {
        title: "Establishing Anthuriums",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_anthurium.webp",
        list_Data: [
          "Cautiously eliminate the Anthurium from its nursery holder, tenderly prodding separated any orbiting roots. Place the plant in the focal point of the pot and refill with preparing blend, guaranteeing that the highest point of the root ball is level with the dirt surface.",
          "Delicately firm the dirt around the foundation of the plant and water completely to settle the dirt and eliminate any air pockets. Try not to cover the crown of the plant, as this can prompt decay."
        ],
      },
      {
        title: "Watering and Moistness",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_anthurium.webp",
        list_Data: [
          "Keep the dirt equitably damp however not waterlogged, permitting the top inch of soil to dry out somewhat between waterings. Water the Anthurium completely, guaranteeing that an abundance of water empties openly out of the lower part of the pot.",
          "Keep up with high mugginess around the plant by moistening the foliage routinely or setting a plate loaded up with water and rocks underneath the pot. You can likewise utilize a humidifier to increment moistness levels inside."
        ],
      },
      {
        title: "Treatment",
        description: "Prepare Anthuriums each 4 a month and a half during the developing season (spring and summer) with decent, water-solvent manure formed for blossoming houseplants. Adhere to the producer's guidelines for weakening and application rates. Lessen preparation recurrence throughout the fall and cold weather months when the plant is lethargic.",
      },
      {
        title: "Upkeep and Care",
        list_Data: [
          "Prune dead or yellowing leaves routinely to advance new development and keep up with the Anthurium's appearance. Utilize spotless, sharp scissors or pruning shears to make clean cuts close to the foundation of the leaves.",
          "Screen the plant for nuisances like aphids, mealybugs, or bug parasites, and treat any invasions immediately utilizing natural techniques, for example, insecticidal cleanser or neem oil."
        ],
      },
    ]
  },
  {
    blog_title: "Gloxinia",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_gloxinia.webp",
    blog_description: "Gloxinia, deductively known as Sinningia speciosa, is a famous blossoming plant treasured for its perfect sprouts and rich foliage. While local to the tropical areas of South America, especially Brazil, Gloxinia can be filled effectively in home nurseries across India, given that they get satisfactory consideration and consideration.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/gloxinia.webp",
    blog_desc: "Gloxinia plants favor well-depleting soil that is wealthy in natural matter, like a combination of peat greenery, perlite, and fertilizer. It's significant to water the plant reliably, keeping the dirt equally soggy yet not waterlogged, as overwatering can prompt root decay. Permit the top layer of soil to dry out somewhat between waterings to forestall waterlogging.",
    blog_bold_desc: "Here is a fledgling's manual for developing Gloxinia:",
    link: "gloxinia",
    list: [
      {
        title: "Choosing Area",
        list_Data: [
          "Pick a brilliant, roundabout light area for your Gloxinia, as it flourishes in sufficiently bright regions without direct daylight. A north or east-bound window is great, or you can put the plant a couple of feet from a south or west-bound window to keep away from serious daylight.", "Gloxinias incline toward warm temperatures between 65-75°F (18-24°C) and high stickiness levels. They are delicate to cold drafts and temperature vacillations, so abstain from putting them close to cooling vents or warmers."
        ],
      },
      {
        title: "Picking the Right Pot and Soil",
        list_Data: [
          "Select a pot with seepage openings to forestall waterlogging, as Gloxinias favor reliably damp however well-depleting soil. Pick a pot that is marginally bigger than the plant's root ball to consider root development.",
          "Utilize a well-depleting preparing blend planned for blooming houseplants or make your own by consolidating peat greenery, perlite, and vermiculite. This combination gives the dampness maintenance and air circulation that Gloxinias requires."
        ],
      },
      {
        title: "Establishing Gloxinias",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_gloxinia.webp",
        description: "Cautiously eliminate the Gloxinia from its nursery holder, being careful not to harm the sensitive roots. Place the plant in the focal point of the pot and refill with preparing blend, guaranteeing that the highest point of the root ball is level with the dirt surface. Tenderly firm the dirt around the foundation of the plant and water completely to settle the dirt and eliminate any air pockets. Try not to cover the crown of the plant, as this can prompt decay.",
      },
      {
        title: "Watering and Dampness",
        list_Data: ["Keep the dirt uniformly sodden however not waterlogged, permitting the top inch of soil to dry out somewhat between waterings. Water the Gloxinia completely, guaranteeing that an overabundance of water empties openly out of the lower part of the pot.",
          "Keep up with high mugginess around the plant by moistening the foliage routinely or setting a plate loaded up with water and rocks underneath the pot. You can likewise utilize a humidifier to increment moistness levels inside."],
      },
      {
        title: "Preparation",
        description: "Treat Gloxinias each 4 a month and a half during the developing season (spring and summer) with a fair, water-solvent compost figured out for blooming houseplants. Adhere to the producer's guidelines for weakening and application rates. Lessen preparation recurrence throughout the fall and cold weather months when the plant is lethargic."
      },
      {
        title: "Support and Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_gloxinia.webp",
        list_Data: ["Remove spent blooms and yellowing leaves regularly to encourage continuous flowering and maintain the Gloxinia's appearance. Use clean, sharp scissors or pruning shears to make clean cuts near the base of the stems.", "Monitor the plant for pests such as aphids, spider mites, or mealybugs, and treat any infestations promptly using organic methods such as insecticidal soap or neem oil."]
      },
      {
        title: "Backing and Spread",
        list_Data: [
          "Offer help for tall or leggy Gloxinia stems by delicately marking them with bamboo stakes or dowels. This will assist with keeping the stems from twisting or breaking under the heaviness of the blossoms.",
          "Spread Gloxinias by division or leaf cuttings. Partition mature plants by cautiously isolating the rhizomes and establishing them in individual pots. On the other hand, take leaf cuttings from sound, mature leaves and spot them in clammy preparing blend to root"
        ],
        additional_desc: "By following these nitty gritty advances and giving legitimate consideration, you can effectively develop Gloxinias at home, partaking in their astonishing blossoms and rich foliage in your indoor space. With their striking appearance and somewhat low upkeep necessities, Gloxinias make a shocking expansion to any home or office climate."
      }
    ]
  },
  {
    blog_title: "Impatiens",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Impatiens.webp",
    blog_description: "Impatiens plants are portrayed by their plump stems, delicious leaves, and bountiful blossoms. The blossoms arrive in many tones, including shades of pink, red, orange, purple, white, and bi-variety mixes. The blossoms have an unmistakable shape, with five petals organized lopsidedly, frequently looking like little butterflies.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Impatiens.webp",
    blog_desc: "Impatiens flourish in inclined toward full shade, making them ideal for establishing in regions with restricted daylight, for example, under trees or along north-bound walls. They favor well-depleting soil rich in natural matter and the advantage of normal watering to keep the dirt reliably soggy. Be that as it may, they don't endure waterlogged circumstances.",
    blog_bold_desc: "Here is a novice's manual for developing Impatiens:",
    link: "impatiens",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Impatiens flourish in inclined toward full shade, making them ideal for filling in Indian environments where serious daylight is normal. Pick an area in your nursery or overhang that gets circuitous daylight or dappled concealed over the day. Try not to put them in regions with delayed openness to coordinate daylight, as this can cause leaf burning and shrinking.",
      },
      {
        title: "Setting up the Dirt",
        description: "Impatiens favor well-depleting, rich soil with a somewhat acidic to unbiased pH. Before planting, set up the dirt by relaxing it to a profundity of around 6-8 inches utilizing a nursery fork or turner. Consolidate natural matter like fertilizer, all-around spoiled excrement, or coconut coir to further develop soil structure, dampness maintenance, and supplement content.",
      },
      {
        title: "Establishing Impatiens",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_impatiens.webp",
        description: "Impatiens are typically developed from nursery-purchased seedlings or cuttings. In the case of beginning from seedlings, dig little openings in the pre-arranged soil separated around 8-10 inches. Eliminate the seedlings from their holders, tenderly relax the roots, and spot them in the openings at similar profundity they were filling in their pots. Refill the openings with soil and tenderly firm it around the plants. Water completely in the wake of planting to settle the dirt and hydrate the roots.",
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_Impatien.webp",
        description: "Keep the dirt reliably damp yet not waterlogged, as Impatiens favor equally clammy circumstances. Water the plants profoundly at whatever point the top inch of soil feels dry to the touch. In blistering and dry environments, you might have to water all the more as often as possible to keep the dirt from drying out totally. Abstain from above watering, as it can advance contagious sicknesses. All things being equal, water at the foundation of the plants to limit dampness on the foliage.",
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch layer of natural mulch, like destroyed leaves, grass clippings, or straw, around the foundation of the Impatiens plants. Mulching holds soil dampness, stifles weed development, and directs soil temperature, making ideal developing circumstances for the plants. Guarantee that the mulch doesn't come into direct contact with the stems to forestall decay.",
      },
      {
        title: "Preparing",
        description: "Impatiens are moderate feeders and advantage from customary treatment to help with sound development and productive blooming. Apply a reasonable, water-solvent compost planned for blossoming plants each 2-3 weeks during the developing season. On the other hand, utilize a sluggish delivery granular compost applied by the maker's guidelines. Stay away from overfertilizing, as it can prompt unreasonable foliage development to the detriment of blossoms.",
      },
      {
        title: "Support",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/maintenance_impatien.webp",
        description: "Eliminate spent blossoms routinely to empower ceaseless sprouting and keep the plants from redirecting energy into seed creation. Squeeze back leggy stems or prune stuffed development to advance bushier, more conservative plants. Look out for irritations like aphids, bug parasites, and whiteflies, and treat invasions quickly with insecticidal cleanser or neem oil.",
      },
    ]
  },
  {
    blog_title: "Orchid",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_orchid.webp",
    blog_description: "Orchids are a different and charming gathering of blooming plants having a place with the family Orchidaceae, which is one of the biggest groups of blossoming plants, with north of 25,000 reported species and innumerable mixtures. Eminent for their lovely magnificence, complicated frames, and tempting scents, orchids have charmed human interest for quite a long time, procuring them a spot as one of the dearest and sought-after elaborate plants around the world.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/orchid.webp",
    blog_desc: "Orchids are impeccable blossoming plants prestigious for their staggering sprouts and exquisite appearance. While numerous orchid species are local to tropical districts, they can be effectively filled at home in India with the right consideration and consideration.",
    blog_bold_desc: "Here is a novice's manual for developing Orchid:",
    link: "orchid",
    list: [
      {
        title: "Choosing the Right Orchid",
        description: "Pick orchid assortments that are appropriate to the developing circumstances in your district. Phalaenopsis orchids, otherwise called moth orchids, are famous and moderately simple to fill inside India because of their capacity to bear warm temperatures and low light levels. Other reasonable orchids incorporate Dendrobium, Vanda, and Oncidium species. Select solid plants with firm, green leaves and no indications of irritations or sicknesses.",
      },
      {
        title: "Giving the Right Developing Circumstances",
        description: "Orchids flourish in warm, damp conditions with great airflow and roundabout daylight. Place your orchids close to east or west-bound windows where they can get brilliant, sifted light for 6-8 hours every day. Try not to put them in that frame of mind, as this can sear their fragile leaves. Keep a temperature scope of 18-25°C (65-80°F) during the day and somewhat cooler temperatures around evening time",
      },
      {
        title: "Picking the Preparing Blend",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container_orchid.webp",
        description: "Orchids require a well-depleting preparing blend that gives great air circulation and dampness maintenance. Utilize a particular orchid-preparing blend comprising materials like bark, sphagnum greenery, perlite, and charcoal. Abstain from utilizing standard fertilized soil, as it holds a lot of dampness and can prompt root decay. Repot orchids every 1-2 years or while the preparing blend separates and becomes compacted.",
      },
      {
        title: "Watering Orchid",
        description: "Orchids have explicit watering needs and ought not to be overwatered. Water your orchids on more than one occasion per week, permitting the preparing blend to dry out somewhat between waterings. Use room temperature water and try not to get water on the leaves or crown of the plant, as this can advance parasitic infections. In regions with high mugginess, you might have to water less as often as possible. Think about utilizing a stickiness plate or humidifier to increment dampness levels around your orchids.",
      },
      {
        title: "Preparing Orchid",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/furtilize-orchid.webp",
        description: "Orchids are light feeders and require standard treatment to help sound development and blossoming. Utilize a reasonable orchid compost weakened to half strength and apply it each 2 a month during the developing season (spring and summer). Decrease treatment recurrence to one time each month or less during the lethargic period (fall and winter). Flush the preparing blend in with plain water like clockwork to keep salt development from compost buildups."
      }
    ]
  },
  {
    blog_title: "Purslanes",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Purslanes.webp",
    blog_description: "Purslane, deductively known as Portulaca oleracea, is a delicious plant that is normally found as a weed in many regions of the planet, including India. In any case, purslane is likewise developed and consumed as a verdant vegetable in different foods, including Indian cooking.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Purslanes.webp",
    blog_desc: "Purslane is esteemed for its dietary substance, as it is plentiful in nutrients (counting vitamin A, C, and B-complex nutrients), minerals (like calcium, magnesium, and potassium), and cancer prevention agents. It likewise contains omega-3 unsaturated fats, making it a significant expansion to a sound eating regimen.",
    blog_bold_desc: "Here's a detailed guide on how to grow Purslanes:",
    link: "purslanes",
    list: [
      {
        title: "Choosing an Establishing Area",
        description: "Purslane flourishes in full sun and all-around depleted soil. Pick a spot in your nursery that gets no less than 6-8 hours of daylight each day. Guarantee the dirt is free, prolific, and depletes well to forestall waterlogging, as purslane doesn't endure wet circumstances.",
      },
      {
        title: "Setting up the Dirt",
        description: "Before planting, relax the dirt to a profundity of around 6-8 inches utilizing a nursery fork or turner. Eliminate any weeds, rocks, or trash from the area. Integrate natural matter, for example, fertilizer or matured excrement into the dirt to work on its ripeness and construction.",
      },
      {
        title: "Establishing Purslane Seeds or Transfers",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_purslane.webp",
        description: "Purslane can be grown from seeds or transplants. If planting seeds, sow them directly into the prepared soil in spring after the danger of frost has passed. Scatter the seeds thinly and cover them lightly with soil, then water gently. If using transplants, space them about 6-12 inches apart, depending on the variety, and plant them at the same depth they were growing in their containers.",
      },
      {
        title: "Watering and Mulching",
        description: "Keep the dirt reliably damp but not waterlogged, particularly during hot and dry periods. Water purslane profoundly at whatever point the top inch of soil feels dry to the touch. Apply a layer of natural mulch, for example, straw or destroyed leaves, around the plants to assist with holding soil dampness, smother weeds, and direct soil temperature.",
      },
      {
        title: "Preparing",
        description: "Purslane is a somewhat low-upkeep plant and doesn't need weighty treatment. Nonetheless, you can side-dress the plants with a decent compost a few times during the developing season to advance sound development and efficiency. Adhere to the maker's guidelines for application rates.",
      },
      {
        title: "Reaping and Upkeep",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/harvesting_purslane.webp",
        description: "Purslane is normally prepared to reap around 6 two months in the wake of planting. You can gather the delicate youthful leaves and stems on a case-by-case basis for servings of mixed greens, sautés, or sandwiches. To intermittently energize bushier development and ceaseless gathering, squeeze back the developing tips of the plants. Eliminate any yellowing or harmed leaves consistently to advance airflow and forestall sickness.",
      },
    ]
  },
  {
    blog_title: "Jasmine",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Jasmine.webp",
    blog_description: "Jasmine, known for its lovely scent and sensitive white blossoms, is a darling plant esteemed for a long time across societies and landmasses. Having a place with the sort Jasminum inside the family Oleaceae, Jasmine incorporates a different gathering of animal categories, each with its special qualities and utilizations.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Jasmine.webp",
    blog_desc: "Jasmine holds huge social and representative implications in numerous social orders. It is frequently connected with affection, virtue, and otherworldliness, making it a famous decision for wedding functions, strict ceremonies, and improving purposes. In certain societies, jasmine blossoms are utilized to make festoons, embellishments, or fragrances, while in others, they are saturated with tea or utilized in customary medication for their implied helpful properties.",
    blog_bold_desc: "Here is a novice's manual for developing Jasmine:",
    link: "jasmine",
    list: [
      {
        title: "Choosing the Right Assortment",
        description: "The most normally developed assortment of Jasmine is Jasminum sambac, otherwise called Bedouin Jasmine or Mogra. Pick solid, infection-safe cultivars from a respectable nursery or nursery focus. Consider factors, for example, scent power, bloom tone, and development propensity while choosing your Jasmine plant.",
      },
      {
        title: "Picking a Reasonable Area",
        description: "Jasmine plants flourish in warm, heat, and humidities and require a lot of daylight to bountifully sprout. Select an area in your nursery that gets full daylight for something like 6-8 hours per day. Guarantee that the region is protected areas of strength from and has well-depleting soil to forestall waterlogging, as Jasmine plants favor marginally acidic to nonpartisan soil pH.",
      },
      {
        title: "Setting up the Dirt",
        description: "Before planting, set up the dirt by slackening it to a profundity of around 12 inches utilizing a nursery fork or turner. Consolidate natural matter like fertilizer, all-around decayed excrement, or coconut coir to further develop soil fruitfulness and waste. Jasmine plants flourish in well-depleting soil with great dampness maintenance.",
      },
      {
        title: "Establishing Jasmine",
        description: "Jasmine can be established either in compartments or straightforwardly in the ground. In the case of establishing in compartments, pick a pot with waste openings and fill it with a well-depleting prepared blend. Dig an opening two times as wide and marginally more profound than the root chunk of the Jasmine plant. Tenderly eliminate the plant from its compartment and release the roots. Place the plant in the focal point of the opening and refill with soil, pushing down tenderly to eliminate air pockets. Water completely after planting to settle the dirt around the roots.",
      },
      {
        title: "Watering and Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_jasmine.webp",
        description: "Keep the dirt reliably soggy however not waterlogged, particularly during the plant's foundation time frame. Water Jasmine profoundly a few times per week, contingent upon weather patterns and soil dampness levels. Apply a 2-3 inch layer of natural mulch, for example, coconut husk or dried leaves, around the foundation of the plant to assist with holding dampness, manage soil temperature, and stifle weed development. Stay away from mulch contact with the plant's stem to forestall decay.",
      },
      {
        title: "Pruning and Support",
        description: "Jasmine plants can be prepared to climb lattices, arbors, or fences, or permitted to spread as ground covers. Train the plants by delicately binding them to uphold utilizing delicate nursery twine. Prune Jasmine plants consistently to keep up with shape, eliminate dead or unhealthy branches, and advance shaggy development. Pruning ought to be finished after the plant has done with blossoming to support new development and bloom creation.",
      },
      {
        title: "Preparing",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/furtilizer_jasmine.webp",
        description: "Jasmine plants benefit from customary taking care of during the developing season to help sound development and bountiful blossoming. Apply a decent manure formed for blooming plants, like 10-10-10 or 5-10-5, each 4 a month and a half from spring to fall. Adhere to the maker's guidelines for application rates and keep away from overfertilizing, as unreasonable nitrogen can advance rich foliage development to the detriment of blossoms.",
        additional_desc: "By following these means, you can effectively develop Jasmine and partake in its fragrant sprouts and lavish foliage in your nursery or porch."
      }
    ]
  },
  {
    blog_title: "Money Plant",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_money_plant.webp",
    blog_description: "The Cash Plant, experimentally known as Epipremnum aureum, is a famous and simple-to-develop houseplant that is esteemed for its appealing foliage and air-decontaminating characteristics. Broadly perceived for its capacity to bring favorable luck and flourishing, especially in Feng Shui rehearses, the Cash Plant is number one among indoor landscapers and plant fans.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Money-plant.webp",
    blog_desc: "The Cash Plant is accepted to bring karma, riches, and success to its guardians. As per Feng Shui standards, setting a Cash Plant in the southeast corner of a room or home is said to draw in certain energy and monetary overflow.",
    blog_bold_desc: "Here is a novice's manual for developing Cash Plant",
    link: "money-plant",
    list: [
      {
        title: "Choosing an Area",
        description: "Choose a location in your home that receives bright, indirect sunlight for most of the day. While Money Plants can tolerate low light conditions, they thrive best in moderate to bright light. Avoid placing them in direct sunlight, as it can scorch their leaves, especially during the hot afternoon hours.",
      },
      {
        title: "Picking the Right Pot and Soil",
        description: "Utilize a well-depleting preparing blend for your Cash Plant. A blend of peat greenery, perlite, and coarse sand functions admirably. Guarantee that the pot has waste openings at the base to forestall waterlogging. Cash Plants can fill in different kinds of holders, including plastic, earthenware, or ceramic pots.",
      },
      {
        title: "Planting or Preparing the Cash Plant",
        description: "If you're establishing Cash Plant cuttings, select solid stem cuttings with no less than two hubs. Eliminate any leaves from the lower hub and spot the cutting in a glass of water. When roots are created, move the slicing to a pot with the wet preparing blend. If you're beginning with a nursery-purchased plant, delicately eliminate it from its holder and release the roots before preparing it in the new compartment.",
      },
      {
        title: "Watering",
        description: "Cash Plants favor reliably soggy soil yet can endure incidental drying out between waterings. Water the plant completely when the top inch of soil feels dry to the touch. Keep away from overwatering, as it can prompt root decay. Guarantee that an abundance of water channels out of the pot to forestall waterlogging.",
      },
      {
        title: "Offering Help",
        description: ".Cash Plants are plants that trip and trail, so offering help for them to climb can upgrade their development. You can utilize a little lattice, or greenery shaft, or even let them overflow from a hanging container. ",
        additional_desc: "As the plant develops, delicately train the stems along the help construction to empower up development."
      },
      {
        title: "Treating",
        description: "Cash Plants are not weighty feeders, but rather they benefit from incidental preparation during the developing season (spring and summer). Utilize a reasonable fluid compost weakened to half-strength and apply it each 2 a month. Lessen or stop treatment during the lethargic cold weather months.",
      },
      {
        title: "Pruning and Upkeep",
        description: "Customary pruning keeps up with the ideal shape and size of the Cash Plant. Trim any leggy or congested stems to support bushier development. Eliminate yellowing or dead leaves speedily to forestall the spread of illness. Examine the plant consistently for bothers like bug vermin or mealybugs, and treat them immediately with insecticidal cleanser if essential.",
      },
    ]
  },
  {
    blog_title: "Sansevieria",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_sansevieria.webp",
    blog_description: "Their upstanding portrays Sansevierias, blade molded leaves filling a rosette structure. The leaves might be strong green, variegated with stripes or mottled designs, or have round, hollow, contorted shapes. A few assortments produce fragrant blossoms on tall stalks, albeit blooming is generally uncommon in indoor circumstances.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Sansevieria.webp",
    blog_desc: "Sansevierias are flexible plants that can be filled in holders or as a component of indoor finishing plans. They can be set in parlors, rooms, workplaces, or even washrooms, as they endure low mugginess levels and dry air well. In India, they are ordinarily utilized as beautifying complements in homes, workplaces, lodgings, and other indoor spaces.",
    blog_bold_desc: "Here is a nitty gritty aide on the most proficient method to develop Sansevieria:",
    link: "sansevieria",
    list: [
      {
        title: "Choosing Area",
        description: "Sansevieria plants flourish in an extensive variety of lighting conditions, from splendid roundabout light to low light. Pick an area in your home that gets some regular light, like close to a north or east-bound window in any event. Be that as it may, they can likewise endure low-light circumstances, making them reasonable for workplaces or rooms with insignificant normal light.",
      },
      {
        title: "Picking Pot and Soil",
        description: "Select a pot with seepage openings to forestall waterlogging, as Sansevieria plants are helpless to establish decay in excessively wet circumstances. Utilize a well-depleting preparing blend figured out for prickly plants and succulents or a blend of fertilized soil, perlite, and coarse sand. This will guarantee appropriate seepage and forestall water maintenance around the roots.",
      },
      {
        title: "Establishing Sansevieria",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_sansevieria.webp",
        description: "While planting or repotting Sansevieria, guarantee the rhizomes (flat underground stems) are situated just beneath the dirt surface. Delicately search the dirt around the roots and water completely to settle the plant in its new holder. Try not to cover the rhizomes too profoundly, as this can prompt spoiling.",
      },
      {
        title: "Watering",
        description: "Sansevieria plants are calm open-minded and like to dry out between waterings. Water sparingly, permitting the dirt to dry out before watering once more. Contingent upon ecological circumstances and the size of the pot, this might go from once every 2-3 weeks to one time each month. Decide in favor of underwatering as opposed to overwatering, as an abundance of dampness can prompt root decay.",
      },
      {
        title: "Lighting and Temperature",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/lightning_Sansevieria.webp",
        description: "While Sansevieria can endure low light, they incline toward brilliant circuitous light for ideal development and well-being. Pivot the plant incidentally to guarantee even development. They can likewise endure a large number of temperatures, yet they favor temperatures between 60-85°F (15-29°C). Shield them from drafts and abstain from putting them close to warming or cooling vents.",
      },
      {
        title: "Upkeep and Care",
        list_Data: ["Sansevieria plants require insignificant upkeep however benefit from infrequent cleaning to eliminate residue and garbage from the leaves. Trim any yellow or harmed leaves utilizing spotless, sharp scissors or pruning shears.", "Treat sparingly during the developing season (spring and summer) with a decent, weakened compost planned for houseplants, adhering to the maker's guidelines."],
        additional_desc: "By following these means, you can effectively develop Sansevieria plants at home and partake in their striking foliage and air-cleaning properties with negligible exertion."
      },
    ]
  },
  {
    blog_title: "Peace Lily",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Peace-lily.webp",
    blog_description: "The Harmony Lily, logically known as Spathiphyllum, is a famous and broadly developed indoor plant valued for its exquisite appearance, rich foliage, and fragile white blossoms. Beginning from tropical districts of the Americas and Southeast Asia, the Harmony Lily has turned into a darling staple in homes, workplaces, and indoor spaces all over the planet.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Peace-lily.webp",
    blog_desc: "Harmony Lilies can prosper inside, adding a dash of serenity and regular excellence to any inside setting. Whether utilized as an independent explanation plant or integrated into a bigger indoor nursery, the Harmony Lily stays an immortal #1 among plant lovers, giving pleasure and serenity any place it is developed.",
    blog_bold_desc: "Here is an itemized guide on the most proficient method to grow a Harmony Lily:",
    link: "peace-lily",
    list: [
      {
        title: "Picking Right Area",
        description: "Harmony Lilies flourish in brilliant, circuitous light, making them ideal indoor plants. In India, place your Tranquility Lily close to a window that gets sifted daylight or in a sufficiently bright room where it can profit from circuitous daylight. Try not to put it in direct daylight, particularly during the blistering evening hours, as this can sear its leaves.",
      },
      {
        title: "Choosing the Right Pot and Soil",
        list_Data: [
          "Utilize a well-depleting preparing blend in with great water maintenance for your Tranquility Lily. A combination of peat greenery, perlite, and coarse sand functions admirably.", "Guarantee that the pot has waste openings to forestall waterlogging, as Harmony Lilies are helpless to root decay. Pick a pot that is somewhat bigger than the plant's root ball, as Harmony Lilies lean toward cozy circumstances."
        ],

      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering_peace-lily.webp",
        description: "Keep the dirt reliably damp yet not waterlogged. Water your Tranquility, Lily, when the top inch of soil feels dry to the touch. In India's environment, you might have to water all the more often, particularly during the hot and dry months. Be that as it may, be mindful not to overwater, as saturated soil can prompt root decay. Use room temperature water to try not to stun the plant.",
      },
      {
        title: "Moistness and Temperature",
        description: "Harmony Lilies favor moderate to high moistness levels. In India, where the environment can be hot and dry, increment dampness around your Tranquility Lily by clouding its leaves consistently or putting a humidifier close by. Keep the room temperature between 18-24°C (65-75°F) during the day and try not to open the plant to drafts or abrupt temperature changes.",
      },
      {
        title: "Preparing",
        description: "Feed your Tranquility Lily with a reasonable fluid compost weakened to half-strength once every month during the developing season (spring and summer). In India, where the developing season can be longer because of the gentle environment, you might keep preparing over time. Nonetheless, diminish or quit preparing during the plant's torpid period in fall and winter.",
      },
      {
        title: "Pruning and Support",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning_peace-lily.webp",
        description: "Harmony Lilies are somewhat low-upkeep plants yet benefit from intermittent preparation to keep them sound and appealing. Eliminate yellowing or dead leaves when they seem to empower new development and forestall infection spread. Wipe the leaves with a sodden material occasionally to eliminate residue and keep them clean, which works on the plant's capacity to photosynthesize.",
      },
      {
        title: "Repotting",
        description: "Report your Tranquility Lily each 1-2 years, ideally in spring, to revive the dirt and give it extra space to develop. Select a marginally bigger pot and cautiously move the plant, slackening the roots on the off chance that they have become root-bound. Fill the new pot with a crisp preparing blend, plant the Harmony Lily at a similar profundity as in the past, and water completely. Permit the plant to adapt to its new pot for a couple of days before continuing typical consideration.",
        additional_desc: "By following these means, you can effectively grow a wonderful Harmony Lily inside India, adding a dash of vegetation and style to your home."
      },
    ]
  },
  {
    blog_title: "Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_hibiscus.webp",
    blog_description: "Hibiscus plants can bring dynamic tones, lavish foliage, and a bit of tropical magnificence to your indoor or outdoor spaces. Hibiscus, having a place with the variety Hibiscus and the family Malvaceae, includes a different gathering of blossoming plants known for their enormous, ostentatious sprouts and fancy allure.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Hibiscus.webp",
    blog_desc: 'Hibiscus plants are profoundly esteemed for their social importance, as well as their fancy and therapeutic properties. The Hibiscus bloom, frequently alluded to as "Jaswand" or "Gudhal" in Hindi, holds an exceptional spot in Indian customs, ceremonies, and merriments. It is usually utilized in strict functions, weddings, and as a proposition to gods.',
    blog_bold_desc: "Here is a fledgling's manual for developing Hibiscus:",
    link: "hibiscus",
    list: [
      {
        title: "Choosing Area",
        list_Data: ["Pick a bright area for your Hibiscus, as it flourishes in full daylight. Select a spot that gets no less than 6 hours of direct daylight every day. In more blazing environments, some midday shade might be helpful to keep the plant from withering in outrageous intensity.",
          "In the case of developing Hibiscus inside, place the plant close to a south or west-bound window where it can get adequate daylight. On the other hand, give supplemental lighting to develop lights to guarantee adequate light openness."],
      },
      {
        title: "Picking the Right Pot and Soil",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/container_Hibiscus.webp",
        list_Data: [
          "Select a pot with seepage openings to forestall waterlogging, as Hibiscus favors well-depleting soil. Pick a pot that is marginally bigger than the plant's root ball to consider root development..",
          "Utilize a well-depleting preparing blend formed for blossoming plants or make your own by joining equivalent pieces of fertilized soil, perlite, and manure. This combination gives the supplements and seepage that Hibiscus requires."
        ],

      },
      {
        title: "Establishing Hibiscus",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting_Hibiscus.webp",
        list_Data: [
          "Cautiously eliminate the Hibiscus from its nursery compartment, taking into consideration not to harm the roots. Place the plant in the focal point of the pot and refill with preparing blend, guaranteeing that the highest point of the root ball is level with the dirt surface.",
          "Delicately firm the dirt around the foundation of the plant and water completely to settle the dirt and eliminate any air pockets. Try not to cover the crown of the plant, as this can prompt decay."
        ],

      },
      {
        title: "Watering and Preparation",
        list_Data: [
          "Keep the dirt reliably damp yet not waterlogged, permitting the top inch of soil to dry out somewhat between waterings. Water the Hibiscus profoundly, guaranteeing that an abundance of water empties unreservedly out of the lower part of the pot.",
          "Treat Hibiscus consistently during the developing season (spring and summer) with a reasonable compost planned for blooming plants. Apply manure every 4 months and a half as per the maker's guidelines."],

      },
      {
        title: "Pruning and Upkeep",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning_hibiscus.webp",
        list_Data: [
          "Prune Hibiscus consistently to advance thick development and support blooming. Eliminate dead, harmed, or congested branches utilizing perfect, sharp pruning shears. Pruning should be possible all through the developing season to shape the plant and eliminate spent blossoms.",
          "Screen the plant for vermin like aphids, bug parasites, or whiteflies, and treat any invasions immediately utilizing natural strategies, for example, insecticidal cleanser or neem oil."],

      },
      {
        title: "Overwintering",
        list_Data: [
          "In locales with cold winters, bring open-air Hibiscus inside before the principal ice. Place the plant in a radiant area inside and diminish watering to permit it to enter lethargy.",
          "Get the plant far from drafts and give satisfactory moistness by setting a plate loaded up with water and rocks underneath the pot. Continue ordinary watering and preparation in spring when new development arises."
        ],
        additional_desc: "By following these itemized advances and giving appropriate consideration, you can effectively develop Hibiscus at home, partaking in its energetic blossoms and tropical appeal in your nursery or indoor space. With their striking blossoms and rich foliage, Hibiscus plants add a hint of intriguing magnificence to any scene."
      },
    ]
  },
  {
    blog_title: "Brackenridgei Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-brackenridgei.webp" ,
    blog_description: "Hibiscus Brackenridge, otherwise called the Maʻo hau hele, is an uncommon and jeopardized type of hibiscus local to the Hawaiian Islands. This plant is famous for its strikingly gorgeous blossoms, which are enormous and flashy with radiant yellow petals and a profound maroon community. The blossoms can compare 5 creeps in width and ordinarily have five petals that cross over exquisitely, making a shocking difference between the yellow and maroon tones.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/brackenridgei.webp" ,
    blog_desc: "Because of environmental misfortune and intrusive species, Hibiscus Brackenridge is fundamentally imperiled and is safeguarded under state and government regulations. Endeavors are in progress to ration and reestablish its regular territory and guarantee the endurance of this notorious Hawaiian plant. It is viewed as an image of versatility and preservation endeavors in Hawaiʻi, and its magnificence keeps on moving endeavors to safeguard local verdure in the islands.",
    link: "brackenridgei-hibiscus",
    list: [

      {
        title: "Choosing the Right Area",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Choosing-Right-brackenridgei.webp" ,
        description: "Hibiscus Brackenridge flourishes in full sun. Plant it in an area that gets no less than 6-8 hours of direct daylight daily. It favors warm temperatures and is reasonable for USDA Zones 10-11. It can't endure ice. Plant Hibiscus Brackenridge in very much depleted, ripe soil. It favors somewhat acidic to impartial soil with a great natural substance."
      },
      {
        title: "Pruning",
        description: "Prune Hibiscus Brackenridge daintily all through the year to shape the plant and support expansion. Eliminate dead, harmed, or crossing branches. Remove spent blossoms to empower constant sprouting and forestall seed development."
      },
      {
        title: "Feeding",
        description: "Blend a fair compost into the dirt while planting. Feed Hibiscus Brackenridge each 4 a month and a half during the developing season with a fair, water-solvent compost (e.g., 10-10-10 or comparable). Diminish taking care of in pre-fall to try not to animate new development before winter."
      },
      {
        title: "Disease and Bug Control",
        description: "Hibiscus brackenridgei is by and large impervious to nuisances and illnesses. Guarantee great airflow and try not to above water to limit sickness risk. Monitor for normal vermin like aphids and insect bugs. Utilize insecticidal cleanser or neem oil to control pervasions."
      },
      {
        title: "Mulching",
        description: "Apply a 2-3 inch layer of mulch around the foundation of the plant to save dampness, control soil temperature, and smother weeds. Utilize natural mulch like wood chips or destroyed bark."
      },
      {
        title: "Watering",
        description: "Water the plant completely in the wake of planting to settle the dirt around the roots. Keep the dirt equitably clammy, particularly during the primary year of development. Once settled, Hibiscus Brackenridge can endure brief times of dry spells, yet it performs best with standard watering."
      },
      {
        title: "Planting",
        description: "Plant Hibiscus Brackenridge in late winter or late summer when the weather conditions are mild. Prepare the establishing site by digging an opening two times as wide and as profound as the root ball. Alter the dirt with fertilizer or very much spoiled compost to further develop seepage and fertility. Space different plants 10-15 feet separated to take into consideration their experienced size and to guarantee great air flow."
      },
    
      {
        title: "Landscape Purposes",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Landscape- brackenridgei.webp" ,
        description: "In cooler environments, shield Hibiscus Brackenridge from ice. Cover the plant with an ice fabric or bring pruned plants inside during chilly climates. Use Hibiscus Brackenridge as an example plant in nurseries or scenes to exhibit its staggering flowers. Plant numerous Hibiscus Brackenridge plants to make a fence or screen for security and a tropical tasteful. Develop Hibiscus Brackenridge in compartments on porches, decks, or galleries in hotter environments. Guarantee the holder has seepage openings and utilizes a well-depleting preparing blend."
      },
      {
        title: "Propagation",
        description: "Take 4-6 inch cuttings from new development in spring or late spring. Dunk the cut closures in establishing chemicals and plant them in a well-depleting establishing medium. Keep them in a warm, moist spot until roots develop. Harvest seeds from mature cases. Sow seeds in a seed plate loaded up with a well-depleting seed beginning blend. Keep the dirt reliably damp and keep a temperature of 70-80°F (21-27°C) for germination."
      },
      {
        title: "Regular Support",
        description: "Consistently investigate the plant for indications of stress, sickness, or pests. Keep the region around Hibiscus Brackenridge liberated from flotsam and jetsam weeds to diminish the gamble of irritations and infections. Apply mulch to save dampness and manage soil temperature."
      },
    ]
  },
  {
    blog_title: "Roselle Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Roselle.webp"  ,
    blog_description: "Roselle blossoms, otherwise called Hibiscus sabdariffa, are unmistakable and wonderful, highlighting huge, dazzling red or profound burgundy sepals that structure a ringer molded calyx. These sepals encase a focal yellowish-green cylindrical blossom, giving the general sprout a striking and eye-getting appearance. Roselle blossoms are normally borne separately or in little bunches along the parts of the plant.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Roselle.webp" ,
    blog_desc: "Roselle plants are by and large tropical or subtropical in nature, favoring warm environments with full daylight and all-around depleted soil. They are not difficult to develop and can arrive at levels of up to 6 feet or more, with lobed, dim green leaves that give a lavish setting to the lively sprouts. The plant is frequently developed for two its fancy worth and its culinary and restorative purposes, making it a flexible expansion to nurseries and scenes in reasonable environments.",
    link: "roselle-hibiscus",
    list: [
      {
        title: "Choosing the Right Area",
        description: "Roselle flourishes in full sun. Plant it in an area that gets something like 6-8 hours of direct daylight day to day Roselle favors warm temperatures and is delicate to ice. It fills best in USDA Zones 8-11 however can become a yearly in cooler climates. Roselle favors very much depleted, fruitful soil with a marginally acidic to unbiased pH (6.0-7.5). It can endure different soil types yet performs best in loamy soil advanced with natural matter."
      },
      {
        title: "Planting",
        description: "Plant roselle outside after the last ice date when the dirt has heated up. In cooler environments, begin seeds inside 6 two months before the last expected ice date. Prepare the establishing site by relaxing the dirt to a profundity of 12-15 inches. Consolidate fertilizer or very much spoiled compost to further develop soil ripeness and drainage. Space roselle plants 12-18 inches separated to consider their experienced size and to guarantee a great air course."
      },
      {
        title: "Starting from Seeds",
        description: "Begin roselle seeds inside 6 two months before the last expected ice date. Plant seeds in a seed plate loaded up with a well-depleting seed beginning blend. Press seeds gently into the dirt and cover them with a meager layer of soil. Keep the dirt reliably wet and keep a temperature of 70-85°F (21-29°C). Seeds ought to grow in 7-14 days. Transplant seedlings outside after the last ice date when the seedlings have a few arrangements of genuine leaves. Solidify off seedlings by continuously presenting them to outside conditions for seven days before planting."
      },
      {
        title: "Watering",
        description: "Water roselle is established completely after planting to settle the dirt around the roots. Keep the dirt equitably clammy but not waterlogged. Water profoundly, particularly during blistering, dry climates. Diminish watering as the plants mature."
      },
      {
        title: "Feeding",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Feeding-Roselle.webp" ,
        description: "Blend a reasonable compost into the dirt when planting. Feed roselle establishes each 4 a month and a half during the developing season with fair, water-dissolvable manure (e.g., 10-10-10 or comparative). On the other hand, utilize a compost formed for blooming plants."
      },
      {
        title: "Pruning",
        description: "Squeeze back the developing tips of youthful plants to urge bushier growth. Prune branches to collect the calyces. Cut the calyces from the plant as they mature and become red."
      },
      {
        title: "Mulching",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Mulching-Roselle.webp"  ,
        description: "Apply a 2-3 inch layer of mulch around the foundation of the plants to moderate dampness, control soil temperature, and stifle weeds. Natural mulches like straw, dried leaves, or grass clippings are great."
      },
      {
        title: "Disease and Bug Control",
        description: "Roselle is by and large impervious to most irritations and sicknesses. In any case, it tends to be defenseless to contagious illnesses like fine buildup. Guarantee great airflow and try not to above water to limit illness risk. Screen for normal irritations like aphids and insect parasites. Utilize insecticidal cleanser or neem oil to control pervasions."
      },
      {
        title: "Harvesting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Harvesting-Roselle.webp" ,
        description: "Gather calyces when they are stout, completely created, and dark red in variety. Cut the calyx from the plant, leaving a short stem joined. Collect consistently to empower persistent blossoming and calyx creation."
      },
      {
        title: "Winter Consideration",
        description: "In cooler environments, roselle is become a yearly. Gather the excess calyces before the primary ice and dispose of the plant. On the other hand, you can begin new seeds inside every year."
      },
      {
        title: "Regular Support",
        description: "Routinely examine the plants for indications of stress, infection, or bugs. Keep the region around the roselle plants liberated from flotsam and jetsam and weeds to lessen the gamble of irritations and sicknesses. Apply mulch to save dampness and direct soil temperature Utilize new or dried calyces to make tea, jams, jams, sauces, and beverages. Grow roselle for its alluring blossoms and foliage in nurseries or holders."
      },
    ]
  },
  {
    blog_title: "Syriacus Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-syriacus.webp"  ,
    blog_description: "Hibiscus syriacus, regularly known as the rose of Sharon or bush althea, is a flexible and lovely deciduous bush local to eastern Asia. It is generally developed for its dazzling blossoms, which sprout plentifully from mid-summer to fall. The blossoms of Hibiscus syriacus are huge and flashy, normally estimating 3-4 crawls in breadth. They have a particular trumpet-like shape with five covering petals that arrive in a scope of varieties, including shades of white, pink, purple, and blue. A few assortments even component bi-shaded or striped petals, adding to their visual allure.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/syriacus-hibiscus.webp"  ,
    blog_desc: "Rose of Sharon is moderately low-support and versatile, flourishing in an extensive variety of soil types and enduring both full sun and halfway shade. It benefits from normal watering, particularly during dry periods, to empower constant blossoming. Pruning in pre-spring or late winter assists with keeping up with its shape and advances fiery development and sprouting.",
    link: "syriacus-hibiscus",
    list: [
      {
        title: "Planting",
        description: "Plant in spring after the last ice date or in late summer. This permits the plant to lay out its underlying foundations before the intensity of summer or the cold of winter. Dig an opening two times as wide and as profound as the root ball. Blend the eliminated soil with fertilizer or very much decayed compost to further develop soil ripeness and drainage. Space various plants 6-10 feet separated to consider their adult size and to guarantee great air dissemination."
      },
      {
        title: "Choosing the Right Area",
        description: "Plant Hibiscus syriacus in an area that gets full sun to fractional shade. It likes no less than 6 hours of direct daylight every day except can endure some shade, particularly in sweltering environments. Strong in USDA Zones 5-9, Hibiscus syriacus is versatile to a large number of environments and soil conditions. Prefers very much depleted soil however can endure different soil types, including dirt and sandy soils. It flourishes in somewhat acidic to nonpartisan soil pH (6.0-7.0)."
      },
      {
        title: "Feeding",
        description: "Blend a decent compost into the dirt when planting. Feed Hibiscus syriacus with fair, slow-discharge manure (e.g., 10-10-10) in late winter before new development starts. On the other hand, utilize a water-solvent compost every 4 months and a half during the developing season."
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Watering-syriacus.webp"  ,
        description: "Water the plant completely in the wake of planting to settle the dirt around the roots and keep the dirt reliably clammy, particularly during the primary developing season. Water profoundly energizes profound root development. Once settled, Hibiscus syriacus can endure droughts yet performs best with ordinary watering."
      },
      {
        title: "Mulching And Pruning",
        description: "Apply a 2-3 inch layer of mulch around the foundation of the plant to save dampness, manage soil temperature, and stifle weeds. Utilize natural mulch like wood chips or destroyed bark. Prune daintily in late winter before new development starts to eliminate dead, harmed, or crossing branches. Shape the plant as wanted. Eliminate spent blossoms to empower nonstop sprouting all through the season."
      },
      {
        title: "Disease and Vermin Control",
        description: "Hibiscus syriacus is by and large impervious to bugs and infections. Guarantee great airflow and try not to above water to limit the gamble of parasitic diseases. Monitor for normal nuisances like aphids and bug vermin. Utilize insecticidal cleanser or neem oil to control invasions."
      },
      {
        title: "Regular Upkeep",
        description: "Consistently examine the plants for indications of stress, sickness, or pests. Keep the region around Hibiscus syriacus liberated from garbage and weeds to decrease the gamble of irritations and infections. Apply mulch to monitor dampness and control soil temperature."
      },
      {
        title: "Propagation",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Propagation-syriacus.webp"  ,
        description: "Take 4-6 inch cuttings from new development in pre-summer or late spring. Dunk the cut finishes in establishing chemicals and plant them in a well-depleting establishing medium. Keep them in a warm, damp spot until roots develop. Collect seeds from mature seed cases in the fall. Sow seeds straightforwardly in the nursery or begin them inside in a seed plate loaded up with a well-depleting seed beginning blend. Keep the dirt reliably clammy and keep a temperature of 70-75°F (21-24°C) for germination."
      },
      {
        title: "Winter Consideration",
        description: "Hibiscus syriacus is strong yet may bite the dust back to the ground in colder environments. Mulch around the foundation of the plant to shield the roots from frigid temperatures. Prune softly in spring and ordinary water."
      },
      {
        title: "Landscape Purposes",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Landscape-Hibiscus-syriacus.webp" ,
        description: "Use as a point of convergence in the scene to feature its wonderful flowers. Plant different Hibiscus syriacus plants to make a fence or screen for protection and a sprinkle of summer tones. Use in blended lines or establishment plantings to give vertical interest and occasional variety."
      },
    ]
  },
  {
    blog_title: "Arnottianus Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-arnottianus.webp"  ,
    blog_description: "Hibiscus arnottianus, otherwise called the Kokiʻo keʻokeʻo or white hibiscus, is an uncommon and endemic type of hibiscus local to the Hawaiian Islands. It is portrayed by its strikingly lovely, enormous white blossoms that have a sensitive, papery surface. The blossoms ordinarily have a conspicuous yellow staminal segment at the middle and can compare 5 creeps across. The petals are frequently marginally crinkled, adding to the blossom's interesting appeal.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/arnottianus-Hibiscus.webp"  ,
    blog_desc: "Because of territory misfortune, intrusive species, and perusing by presented creatures, Hibiscus arnottianus is thought of as basically imperiled and is safeguarded under state and government regulations. Endeavors are in progress to save and reestablish its normal natural surroundings and guarantee the endurance of this famous Hawaiian plant. The Kokiʻo keʻokeʻo is praised for its magnificence as well as for its social importance in Hawaiian custom and its part in the protection of local verdure in the islands.",
    link: "arnottianus-hibiscus",
    list: [
      {
        title: "Choosing the Right Area ",
        description: "Hibiscus arnottianus flourishes in full sun to halfway shade. Plant it in an area that gets something like 6 hours of direct daylight every day. It can endure some shade, particularly in more sizzling climates. It is the most appropriate for USDA Zones 10-11. Safeguard from temperatures beneath 50°F (10°C) as it is delicate to cold. Hibiscus arnottianus inclines toward very much depleted, rich soil. It can endure different soil types however performs best in loamy soil enhanced with natural matter."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Planting-arnottianus.webp"  ,
        description: "Plant Hibiscus arnottianus in spring or late spring when the dirt has warmed up. Prepare the establishing site by digging an opening two times as wide and as profound as the root ball. Revise the dirt with fertilizer or all-around decayed compost to further develop waste and fertility. Space numerous plants 10-15 feet separated to take into consideration their experienced size and to guarantee a great air course."
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Watering-arnottianus.webp"  ,
        description: "Water the plant completely after planting to settle the dirt around the roots. Keep the dirt reliably damp, particularly during the main year of development. Once settled, Hibiscus arnottianus is tolerably dry-season open-minded however performs best with standard watering during dry periods."
      },
      {
        title: "Feeding",
        description: "Blend a reasonable compost into the dirt when planting. Feed Hibiscus arnottianus each 4 a month and a half during the developing season with decent, water-solvent manure (e.g., 10-10-10 or comparative). Lessen taking care of in pre-fall to try not to animate new development before winter."
      },
      {
        title: "Treating",
        description: " Utilize a fair compost (like 10-10-10) each 4 a month and a half during the developing season. Fertilizer or very much decayed excrement can likewise be utilized to improve the dirt."
      },
      {
        title: "Pruning",
        description: "Prune to shape the plant and eliminate dead or sick branches. Pruning empowers bushier development and more blossoms. Prune in pre-spring or late winter before new development starts."
      },
      {
        title: "Pests and Infections",
        description: "Watch for aphids, insect bugs, and whiteflies. Utilize insecticidal cleanser or neem oil to control infestations. Root decay can happen on the off chance that the dirt is excessively wet. Guarantee legitimate seepage and keep away from overwatering."
      },
      {
        title: "Mulching",
        description: "Apply a layer of mulch around the foundation of the plant to hold dampness and stifle weeds. Keep the mulch a couple of inches away from the stem to forestall decay."
      },
      {
        title: "Propagation",
        description: "Other than seeds and cuttings, air layering is another strategy. This includes injuring a branch, applying establishing chemicals, and wrapping it with sodden sphagnum greenery until roots are created."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Care-arnottianus.webp"  ,
        description: "Keep up with high mugginess, particularly in dry environments, by clouding the plant or utilizing a dampness plate. If you live in a cooler environment, bring pruned plants inside throughout the cold weather months or give ice security."
      },
     
    ]
  },
  {
    blog_title: "Clematis Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-clematis.webp"  ,
    blog_description: "Clematis is a family of famous climbing plants known for their energetic and various blossoms, which can add a striking presentation of variety to any garden. These lasting plants have a place with the Ranunculaceae family and element various species and cultivars that produce sprouts in shades of purple, blue, pink, red, white, and yellow. Clematis plants are ordinarily woody and can go from little, sensitive assortments to huge, powerful climbers that arrive at amazing levels.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Clematis-hibiscus.webp"  ,
    blog_desc: "They favor very much depleted soil and a radiant area with a shade at the roots to flourish. Clematis blossoms differ in shape and size, from little, star-like blooms to huge, gaudy sprouts, frequently showing up in spring and summer, albeit a few assortments sprout in pre-winter. These plants are #1 among groundskeepers for their capacity to cover fences, lattices, and walls, giving vertical interest and an enduring flower show. With legitimate consideration, clematis can be staggering and getting through expansion to any scene.",
    link: "clematis-hibiscus",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "Clematis assortments range from early blossoming to late sprouting, and from little bloomed to huge bloomed. Select an assortment that suits your nursery conditions and wants tasteful."
      },
      {
        title: "Environment and Area",
        description: "Clematis can fill in different environments however by and large favors mild zones. Choose an area with full sun to fractional shade. Clematis flourishes with its underlying foundations in the shade and its foliage in the sun."
      },
      {
        title: "Soil Necessities",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Soil-necessities-clematis.webp"  ,
        description: "Well-depleting soil is critical. Clematis favors loamy soil improved with natural matter. The soil pH ought to be nonpartisan to somewhat antacid (around 6.5 to 7.5)."
      },
      {
        title: "Planting",
        description: "Plant clematis in the spring or late summer when temperatures are moderate. Dig an opening two times as wide and profound as the root ball. Plant the clematis with the goal that the crown is around 2-3 crawls underneath the dirt surface. This safeguards the foundation of the plant and urges new shoots to develop. Space plants 24 to 36 inches separated to take into account development and air dissemination."
      },
      {
        title: "Support Designs",
        description: "Offer a strong help construction like a lattice, arbor, or wall. Clematis moves by twining its leaf stems around supports. Gently attach the stems to the help to direct their development."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably clammy, particularly during the main developing season. Water profoundly and regularly. Apply a layer of mulch around the base to hold dampness and keep the roots cool."
      },
      {
        title: "Preparing",
        description: "Blend manure or a reasonable sluggish delivery compost into the dirt at establishing time. Feed clematis month to month during the developing season with a reasonable compost (like 10-10-10). Decrease taking care of in pre-fall to permit the plant to get ready for winter."
      },
      {
        title: "Pruning",
        description: "Clematis are sorted into three pruning bunches in light of their blossoming habits.Bloom on old wood. Prune softly in the wake of blooming. Sprout on old and new wood. Prune softly in late winter and after the primary flush of flowersBloom on new wood. Scale back to 12-18 crawls in early spring. Remove dead or powerless stems and shape the plant depending on the situation."
      },
      {
        title: "Bugs and Illnesses",
        description: "Aphids, slugs, and bug vermin can influence clematis. Utilize insecticidal cleanser or neem oil to control infestations. Clematis shrink, a contagious infection, can cause unexpected shriveling. Eliminate and annihilate impacted stems. Guarantee great airflow and try not to above water to forestall contagious infections."
      },
      {
        title: "Winter Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Winter-care-Clematis.webp" ,
        description: "Apply a thick layer of mulch in pre-winter to shield the roots from frosty temperatures. In colder environments, furnish extra security with burlap or ice covers."
      },
      {
        title: "Proliferation",
        description: "Clematis can be engendered by seeds, cuttings, or layering Plant seeds in a virus outline or inside in late winter. Take softwood or semi-hardwood cuttings in pre-summer or early summer. Bury is a low-developing stem in soil while still connected to the parent plant. When roots are created, cut off the new plant from the parent."
      },
      {
        title: "Care Tips",
        description: "Plant ground cover or spot stones around the base to keep the roots cool. Clematis might require a couple of years to become laid out and arrive at their full blossoming potential. Be patient and give predictable consideration."
      },
    ]
  },
  {
    blog_title: "Anemone Spp Hibiscus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Anemone-spp.webp" ,
    blog_description: "Anemone spp., ordinarily known as anemones or windflowers, are a distinctive gathering of sprouting plants having a put with the Ranunculaceae family. These herbaceous perennials are valued for their wonderful and sensitive sprouts, which arrive in different tones including white, pink, purple, blue, and red. Anemones are tracked down in a scope of territories, from forests to snow-capped glades, and can be filled in gardens as elaborate plants. They regularly have basic, lobed leaves and produce their beguiling blossoms in spring or harvest time, contingent upon the species.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Anemone-spp.webp" ,
    blog_desc: "The blossoms are in many cases cup-formed or star-like, with a focal group of yellow stamens that add to their charm. Anemones favor all-around depleted soil and flourish in both full sun and halfway shade, making them adaptable augmentations to many nursery settings. Their effortless appearance and simplicity of care make anemones a #1 among nursery workers hoping to add a dash of class and normal excellence to their scenes.",
    link: "anemone-spp-hibiscus",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "Clematis assortments range from early blossoming to late sprouting, and from little bloomed to huge blossomed. Select an assortment that suits your nursery conditions and is stylish."
      },
      {
        title: "Environment and Area",
        description: "Clematis can fill in different environments however by and large lean towards mild zones. Pick an area with full sun to fractional shade. Clematis flourishes with its underlying foundations in the shade and its foliage in the sun."
      },
      {
        title: "Soil Necessities",
        description: "Well-depleting soil is essential. Clematis favors loamy soil improved with natural matter. The soil pH ought to be unbiased to somewhat antacid (around 6.5 to 7.5)."
      },
      {
        title: "Planting",
        description: "Plant clematis in the spring or late summer when temperatures are direct. Dig an opening two times as wide and profound as the root ball. Plant the clematis with the goal that the crown is around 2-3 creeps underneath the dirt surface. This safeguards the foundation of the plant and urges new shoots to develop. Space plants 24 to 36 inches separated to take into consideration development and airflow."
      },
      {
        title: "Support Designs",
        description: "Offer a strong help construction like a lattice, arbor, or wall. Clematis moves by twining its leaf stems around upholds. Delicately attach the stems to help direct their development"
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Watering-Anemone-spp.webp" ,
        description: "Keep the dirt reliably soggy, particularly during the primary developing season. Water profoundly and regularly. Apply a layer of mulch around the base to hold dampness and keep the roots cool."
      },
      {
        title: "Treating",
        description: "Blend manure or a reasonably sluggish delivery compost into the dirt at establishing time. Feed clematis month to month during the developing season with a decent compost (like 10-10-10). Lessen taking care of in pre-fall to permit the plant to get ready for winter."
      },
      {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Pruning-Anemone-spp.webp" ,
        description: "Clematis are sorted into three pruning bunches given their blossoming propensities. Sprout on old wood. Prune gently after flowering. Bloom on old and new wood. Prune delicately in late winter and after the primary flush of flowers. Bloom on new wood. Scale back to 12-18 crawls in late winter. Eliminate dead or feeble stems and shape the plant depending on the situation."
      },
      {
        title: "Vermin and Infections",
        description: "Aphids, slugs, and insect bugs can influence clematis. Utilize insecticidal cleanser or neem oil to control infestations. Clematis shrivel, a parasitic illness, can cause unexpected shrinking. Eliminate and annihilate impacted stems. Guarantee great air course and try not to above water to forestall contagious sicknesses"
      },
      {
        title: "Winter Care",
        description: "Apply a thick layer of mulch in pre-winter to shield the roots from frigid temperatures. In colder environments, give extra security burlap or ice covers."
      },
      {
        title: "Engendering",
        description: "Clematis can be proliferated by seeds, cuttings, or layering seeds in a virus outline or inside in pre-spring. Take softwood or semi-hardwood cuttings in pre-summer or early summer. Bury is a low-developing stem in the soil while still joined to the parent plant. When roots are created, cut off the new plant from the parent."
      },
      {
        title: "Care Tips",
        description: "Plant ground cover or spot stones around the base to keep the roots cool. Clematis might require a couple of years to become laid out and arrive at their full sprouting potential. Be patient and give reliable consideration."
      },
    ]
  },
  {
    blog_title: "Hellebores",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-hellebores.webp"  ,
    blog_description: "Hellebores, frequently alluded to as Lenten roses or Christmas roses, are a gathering of solid, enduring plants having a place with the Ranunculaceae family. Prestigious for their initial blossoming period, these plants frequently bloom from pre-spring to late winter, giving a necessary sprinkle of variety during the colder months. Hellebores highlight striking, gesturing blossoms that arrive in various varieties, including white, pink, purple, green, and, surprisingly, profound maroon. The blossoms are ordinarily bowl-molded with a focal group of yellow stamens and can keep going for a long time.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Hellebores.webp" ,
    blog_desc: "Their weathered, evergreen foliage adds all-year interest to gardens. Hellebores flourish in all-around depleted, humus-rich soil and favor to some extent concealed areas, making them ideal for forest gardens or concealed borders. They are known for their flexibility and capacity to endure different circumstances, including chilly climates and dry spells once settled. With their dependable sprouts and alluring foliage, hellebores are treasured by groundskeepers hoping to upgrade their scenes with excellence and sturdiness.",
    link: "hellebores",
    list: [
      {
        title: "Garden Plan",
        description: "Hellebores pair well with other shade-cherishing perennials like greeneries, hostas, and pulmonarias. Their initial blossoming period additionally makes them incredible allies for spring bulbs like snowdrops and crocuses. Hellebores can act as ground cover in concealed regions, making a lavish, green rug with delightful blossoms in late winter."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Hellebores.webp"  ,
        description: "Hellebores are inclined toward full shade. An excessive amount of sun can singe the leaves, while too minimal light can lessen flowering. Maintain reliable soil dampness, particularly during dry periods. Hellebores don't endure dry season well. Mulching preserves dampness as well as stifles weeds and keeps the dirt temperature stable."
      },
      {
        title: "Propagation",
        description: "Hellebores can be engendered by division or seeds. Divide mature bunches in the fall or late winter. Cautiously discrete the crowns and replant them at a similar profundity as the first plants.Sow seeds in pre-summer or late spring. New seeds have better germination rates. Plant them in a seed plate with well-depleting soil and keep them in a concealed region until germination."
      },
      {
        title: "Winter Care",
        description: "Apply a thick layer of mulch in pre-winter to safeguard the roots from frigid temperatures. In colder environments, cover the establishing region with straw or leaves to give extra protection."
      },
      {
        title: "Pests and Sicknesses",
        description: "Watch for aphids, slugs, and snails. Utilize insecticidal cleansers or regular hunters like ladybugs to control aphids, and put out snares or hindrances for slugs and snails. Hellebores can be defenseless to leaf spot, wool buildup, and dark decay. Guarantee a great air course, abstain from above watering, and eliminate any impacted leaves to forestall infection spread."
      },
      {
        title: "Pruning and Deadheading",
        description: "Eliminate spent blossoms to empower new development and keep up with plant appearance. Cut back old and harmed leaves in pre-spring or late winter before new development starts. This diminishes the gamble of sickness and further develops air dissemination."
      },
      {
        title: "Fertilizing",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/fertilizer-Hellebores.webp"  ,
        description: "Integrate manure or a fair sluggish delivery compost into the dirt at establishing time. Feed hellebores with a decent compost (like 10-10-10) in late winter and again in pre-fall."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably sodden, particularly during the primary developing season. Water profoundly and consistently, however, keeps away from waterlogging. Apply a layer of mulch around the base to hold dampness and direct soil temperature."
      },
      {
        title: "Planting",
        description: "Plant hellebores in the fall or late winter when the dirt is workable. Dig an opening two times as wide and profound as the root ball. Plant hellebores with the goal that the crown is at soil level. Space plants 18-24 inches apart. Mix fertilizer or all-around decayed excrement into the dirt to further develop fruitfulness and seepage."
      },
      {
        title: "Soil Necessities",
        description: "Well-depleting soil is fundamental. Hellebores favor rich, loamy soil improved with natural matter. The soil pH ought to be nonpartisan to somewhat soluble (around 7.0 to 7.5)"
      },
      {
        title: "Climate and Area",
        description: "Hellebores flourish in mild environments. They favor cool winters and gentle summers. Select an area inclined toward full shade. Hellebores truly do well under deciduous trees or in concealed garden beds."
      },
      {
        title: "Choosing the Right Assortment",
        description: "Hellebores come in different species and crossovers, including Helleborus orientalis (Lenten rose) and Helleborus niger (Christmas rose). Choose an assortment that suits your nearby environment. Most hellebores are strong in USDA zones 4-9."
      },
    ]
  },
  {
    blog_title: "Amaryllis",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Amaryllis.webp" ,
    blog_description: "Amaryllis, logically known as Hippeastrum, is a dearest bulbous plant prestigious for its dazzling, trumpet-molded blossoms that carry an energetic variety to indoor and open-air spaces. Starting from South America, these plants are especially well known during the Christmas season, frequently sprouting in pre-spring or late winter. Amaryllis blossoms arrive in various striking tones, including red, pink, white, orange, and bi-shaded designs. Every tall, durable stem can deliver different huge blooms, making an emotional and eye-getting show",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Amaryllis.webp" ,
    blog_desc: "The lustrous, tie-like leaves arise after the blossoms, adding to the plant's elaborate allure. Amaryllis bulbs are not difficult to develop and can be compelled to sprout inside by establishing them in pots with very much depleted soil and setting them in a radiant area. With negligible consideration, these bulbs can be urged to sprout a large number of years, making them #1 among both fledgling and experienced nursery workers. Their great blossoms and simplicity of development make amaryllis a loved expansion to any flower assortment.",
    link: "amaryllis",
    list: [
      {
        title: "Climate and Area",
        description: "Amaryllis can be filled inside any environment. Outside, they flourish in USDA zones 9-11. Pick an area with splendid, circuitous light inside. Outside, select a spot with incomplete shade to full sun."
      },
      {
        title: "Choosing the Right Bulbs",
        description: "Pick solid, firm bulbs liberated from form or harm. Bigger bulbs normally produce more blossoms. Amaryllis assortments incorporate single-blossoming, twofold blooming, and small-scale types, with a scope of varieties from red and pink to white and orange."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Planting-Amaryllis.webp" ,
        description: "Plant amaryllis bulbs in the fall or late fall for indoor sprouts during the Christmas season, or in the spring for open-air planting. Plant the bulb with the sharp wind up, allowing the top third to stay uncovered over the dirt. Space bulbs 6-12 inches separated assuming establishing various bulbs. Use a pot with waste openings that are around 1-2 inches more extensive than the bulb. Amaryllis bulbs like to be somewhat packed in their pots."
      },
      {
        title: "Soil Necessities",
        description: "Well-depleting soil is critical. Utilize a preparing blend in with great seepage or a combination of peat, perlite, and sand. The soil pH ought to be nonpartisan to marginally acidic (around 6.0 to 6.5)."
      },
      {
        title: "Fertilizing",
        description: "Consolidate a decent, slow-discharge manure into the dirt at establishing time. Feed amaryllis with a reasonable fluid compost (like 10-10-10) each 2 a month during the developing season."
      },
      {
        title: "Watering",
        description: "After planting, water the bulb completely, then, at that point, permit the dirt to dry out somewhat before watering again. Keep the dirt reliably soggy yet not waterlogged. Water sparingly until development starts, then increment watering on a case-by-case basis."
      },

      {
        title: "Post-Blossom Care",
        description: "Eliminate spent blossoms to forestall seed development and support more blooms. Allow the passes on to keep developing after the sprouts blur. The foliage renews the bulb for the following blossoming cycle."
      },
      {
        title: "Growth and Sprouting",
        description: "When the bulb begins to develop, place it in a brilliant area with circuitous light. Pivot the pot incidentally to keep the stem developing straight. Amaryllis commonly sprouts 6 two months after planting. Every bulb can create different blossoms follows, each with 2-6 sprouts."
      },
      {
        title: "Pests and Illnesses",
        description: "Watch for aphids, bug parasites, and mealybugs. Utilize insecticidal cleanser or neem oil to control infestations. Amaryllis can be vulnerable to contagious infections, for example, bulb decay and red smudge. Guarantee great waste and stay away from overwatering to forestall these issues."
      },
      {
        title: "Dormancy",
        description: "In pre-fall or late summer, lessen watering and permit the foliage to bite the dust back normally. Scale back the dead leaves and store the bulb in a cool, dry spot (50-60°F) for 8-10 weeks. After the lethargy time frame, repot the bulb in new soil and resume standard watering to start new development and sprouts."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_amaryllis.webp"  ,
        description: "Amaryllis needs splendid, roundabout light for best development. Outside, safeguard them from immediate, unforgiving sunlight. Maintain a temperature of 60-75°F (15-24°C) during the developing season. Try not to open the plants to outrageous cold or hot temperatures. Tall bloom stalks might require marking to keep them from bringing down."
      },
      {
        title: "Proliferation",
        description: "Amaryllis bulbs produce counterbalances (child bulbs) that can be isolated and planted. Delicately eliminate the balances when repotting and plant them separately. Amaryllis can likewise be developed from seeds, however, this technique is slow and may require quite a long while to create sprouting plants."
      },
      {
        title: "Garden Plan",
        description: "Amaryllis bulbs are famous for indoor occasion shows. Plant them in embellishing pots and join various varieties for a shocking impact. In appropriate environments, amaryllis can be established in garden beds or lines. Bunch them in groups for a sensational presentation of variety."
      },
    ]
  },
  {
    blog_title: "Azalea",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Azalea.webp"  ,
    blog_description: "Azaleas are blossoming bushes in the family Rhododendron, known for their bountiful and bright sprouts that decorate gardens and scenes in spring. These deciduous or evergreen bushes produce groups of trumpet-formed blossoms that arrive in a wide exhibit of varieties, including shades of pink, red, purple, white, and orange. The blossoms frequently cover the bush totally, making a terrific presentation of variety. Azalea blossoms have unmistakable stamens that project from the middle, adding to their particular appearance.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Azalea.webp" ,
    blog_desc: "The foliage of azaleas is generally dull green and weathered, giving a rich scenery to the lively blossoms. Azaleas favor acidic, very much depleted soil and flourish in halfway shade, albeit a few assortments can endure full sun with adequate dampness. They are famous decisions for borders, forest nurseries, and establishment plantings because of their garish blossoms and alluring foliage. With legitimate consideration, azaleas can give long periods of magnificence and variety to gardens, making them number one among groundskeepers around the world.",
    link: "azalea",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "Azaleas come in two primary sorts: deciduous and evergreen. Pick an assortment that suits your environment and nursery design.Azaleas are strong in USDA zones 5-9. Select an assortment that accommodates your nearby environmental conditions."
      },   
      {
        title: "Environment and Area",
        description: "Azaleas flourish in calm environments with cool, wet conditions. Choose an area with fractional shade. Morning sun and evening concealment are ideal to safeguard the plants from the warm noontime sun."
      },
      {
        title: "Soil Prerequisites",
        description: "Well-depleting, acidic soil is urgent. Azaleas incline toward loamy soil enhanced with natural matter. The soil pH ought to be somewhere in the range of 4.5 and 6.0. Test the dirt and alter it with sulfur or peat greenery is important to accomplish the right acridity."
      },
      {
        title: "Planting",
        description: "Plant azaleas in the spring or decrease when temperatures are moderate. Dig an opening two times as wide and as profound as the root ball. Plant azaleas so the highest point of the root ball is level with the dirt surface. Space plants 3-5 feet separated to consider mature growth. Mix fertilizer or very much decayed excrement into the dirt to further develop richness and waste."
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Watering-azalea.webp" ,
        description: "Keep the dirt reliably clammy but not waterlogged. Water profoundly and routinely, particularly during dry periods. Apply a 2-3 inch layer of mulch, (for example, pine needles or bark chips) around the base to hold dampness, manage soil temperature, and smother weeds."
      },
      {
        title: "Treating",
        description: "Consolidate an azalea-explicit compost or reasonable, slow-discharge manure into the dirt at establishing time. Feed azaleas with a corrosive framing compost, (for example, one figured out for rhododendrons) in late winter and again in pre-summer or late spring. Try not to treat in pre-fall or tumble to forestall animating new development that could be harmed by ice."
      },
      {
        title: "Pruning",
        description: "Prune azaleas following blossoming, as they set the following year's buds soon after the ongoing year's sprouts blur. Eliminate dead or ailing branches, meager out stuffed regions to further develop airflow, and shape the plant on a case-by-case basis. Abstain from weighty pruning, which can diminish blossoming."
      },
      {
        title: "Vermin and Infections",
        description: "Watch for trim bugs, insect parasites, and azalea caterpillars. Utilize insecticidal cleanser or neem oil to control infestations. Azaleas can be vulnerable to root decay, petal scourge, and leaf nerve. Guarantee great seepage, abstain from above watering, and eliminate any impacted plant parts to forestall the spread of sickness."
      },
      {
        title: "Winter Care",
        description: "Apply a thick layer of mulch in pre-winter to safeguard the roots from frigid temperatures. In colder environments, cover the establishing region with burlap or ice fabric to give extra protection."
      },
      {
        title: "Spread",
        description: "Azaleas can be engendered by cuttings, layering, or seedsTake semi-hardwood cuttings in pre-fall. Dunk the cut closures in establishing chemicals and plant them in a well-depleting preparing mix. Bend a low-developing branch to the ground and cover a segment with soil, allowing the tip to be uncovered. When roots are created, cut off the new plant from the parent and relocate it. Sow seeds in a well-depleting seed beginning blend and keep them sodden until they grow."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Azalea.webp" ,
        description: "Azaleas favor dappled light or halfway shade. A lot of direct daylight can sear the leaves, while too minimal light can decrease flowering. Maintain steady soil dampness, particularly during dry periods. Azaleas don't endure dry spells well. Mulching saves dampness as well as keeps up with the dirt acridity that azaleas need."
      },
      {
        title: "Garden Plan",
        description: "Azaleas pair well with other corrosive adoring plants like rhododendrons, camellias, and greeneries. Their late-winter blossoms make them great allies for spring bulbs. Azaleas are well known for establishment planting because of their appealing foliage and blossoms. They can likewise be utilized in forest nurseries and as line plants."
      },
    ]
  },
  {
    blog_title: "Balloon Flower",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Balloon-flower.webp"  ,
    blog_description: "Inflatable Bloom (Platycodon grandiflorus) is an enchanting lasting herbaceous plant known for its extraordinary inflatable-like buds that open into star-molded blossoms. Local to East Asia, expand blossoms are named for the stout, expand-molded buds that swell and afterward burst open to uncover lovely, ringer-molded sprouts. These blossoms come in shades of blue, pink, purple, and white, and they can compare a few creeps in measurement. The foliage is ordinarily dull green and profoundly lobed, looking like that of a youthful oak tree, adding to the plant's visual interest in any event, when not in blossom.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Balloon-Flower.webp"  ,
    blog_desc: "Swell blossoms are not difficult to develop and keep up with, flourishing in very much depleted soil and full sun to halfway shade. They are dry spell lenient once settled and are somewhat bother-free. Expand blossoms are in many cases utilized in borders, rock nurseries, and holders, where their exceptional buds and appealing blossoms add an unusual touch. With their long blossom period in summer, swell blossoms are a wonderful option for any garden, drawing in butterflies and adding an explosion of variety and surface to the scene.",
    link: "balloon-flower",
    list: [
      {
        title: "Climate and Area",
        description: "Inflatable blossoms favor mild environments with cool summers. They can endure heat yet favor some shade in more sizzling regions. Plant swell blossoms in a spot that gets full sun to fractional shade. Morning sun and evening conceal are great."
      },
      {
        title: "Choosing the Right Assortment",
        description: "Inflatable blossoms come in a few assortments with various bloom tones, including blue, white, and pink. They are strong in USDA zones 3-9, making them reasonable for a great many environments."
      },
      {
        title: "Soil Prerequisites",
        description: "Well-depleting soil is fundamental. Swell blossoms favor loamy, sandy soil enhanced with natural matter. They incline toward marginally acidic to impartial soil (pH 6.0-7.0)."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/plant-Baloon-flower.webp"  ,
        description: "Plant swell blossoms in the spring after the last ice date. Plant the roots or seedlings at similar profundity as they were in their nursery holders, around 12-18 inches apart. Balloon blossoms can likewise be developed in compartments with great waste."
      },
      {
        title: "Fertilizing",
        description: "Blend manure or a reasonable compost into the dirt at establishing time. Feed expand blossoms with a decent compost (like 10-10-10) in late winter and again in the wake of blooming."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably clammy, particularly during the main developing season. Water profoundly and permit the dirt to dry somewhat between waterings. Apply a layer of mulch around the plants to hold dampness and stifle weeds."
      },
      {
        title: "Pruning And Support",
        description: "Eliminate spent blossoms to support constant sprouting and forestall self-seeding. Cut back the foliage after blooming to advance a second blossom period later in the season. Tall assortments might profit from marking to keep them from bowing or breaking major areas of strength for in."
      },
      {
        title: "Bugs and Sicknesses",
        description: "Watch for aphids, insect vermin, and slugs. Utilize insecticidal cleanser or neem oil to control infestations. Balloon blossoms can be defenseless to parasitic illnesses like fine mold. Guarantee great airflow and abstain from above watering."
      },
      {
        title: "Winter Consideration",
        description: "Apply a layer of mulch in pre-winter to shield the roots from frigid temperatures. In colder environments, cover the establishing region with a layer of straw or leaves for extra protection."
      },
      {
        title: "Propagation",
        description: "Inflatable blossoms can be proliferated by seeds or division seeds inside in late winter or outside after the last ice date. They might require a couple of years to sprout from seed. Divide mature clusters in the spring or fall. Separate the root ball into more modest areas and replant them."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Balloon-flower.webp"  ,
        description: "Inflatable blossoms favor full sun yet can endure fractional shade, particularly in more sultry climates. Maintain steady soil dampness, particularly during warm, dry periods. Mulching holds dampness and controls soil temperature, helping inflatable blossoms."
      },
      {
        title: "Garden Plan",
        description: "Inflatable blossoms pair well with other sun-cherishing perennials like coneflowers, daylilies, and dark looked Susan's. They are perfect for lines, edging, and rock gardens because of their minimized size and appealing blossoms."
      },
    ]
  },
  {
    blog_title: "Bell Flowers",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Bell-Flower.webp" ,
    blog_description: "Bellflowers, having a place with the sort Campanula, incorporate a different gathering of blossoming plants known for their ringer molded sprouts. These herbaceous perennials are local to numerous districts all over the planet and are loved for their enchanting blossoms that reach in variety from white and blue to purple and pink. The blossoms regularly hang or gesture from upstanding stems and can be lone or organized in bunches, adding a fragile and elegant appearance to gardens.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/bellflower.webp" ,
    blog_desc: "Campanula plants fluctuate broadly in size, from low-developing ground covers to tall, upstanding species. They have heart-formed or lanceolate leaves that are frequently toothed or lobed, and their development propensity can be rambling or clustering, contingent upon the species. Bellflowers favor very much depleted soil and full sun to halfway shade, making them flexible for various nursery settings. They are for the most part simple to develop and keep up with, requiring moderate watering and intermittent treatment.",
    link: "bell-flowers",
    list: [
      {
        title: "Garden Plan",
        description: "Bellflowers pair well with other sun-adoring perennials like lavender, salvia, and coreopsis. They are magnificent for lines, edging, and rock gardens because of their smaller size and alluring blossoms."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care_Bell-Flower.webp"  ,
        description: "Bellflowers favor full sun to halfway shade. In more sizzling environments, evening conceal is advantageous. Keep up with reliable soil dampness, particularly during hot, dry periods. Mulching holds dampness and directs soil temperature, helping bellflowers."
      },
      {
        title: "Propagation",
        description: "Bellflowers can be spread by seeds, division, or cuttings. Plant seeds inside in late winter or outside after the last ice date. A few animal types might require a couple of years to blossom from seed. Divide mature clusters in the spring or fall. Separate the root ball into more modest segments and replant them. Take softwood cuttings from pre-summer to summer early. Plunge the cut finishes in establishing chemicals and plant them in a well-depleting prepared blend."
      },
      {
        title: "Winter Consideration",
        description: "Apply a layer of mulch in pre-winter to safeguard the roots from frosty temperatures. In colder environments, cover the establishing region with a layer of straw or leaves for extra protection"
      },
      {
        title: "Pests and Sicknesses",
        description: "Watch for aphids, bug vermin, and slugs. Utilize insecticidal cleanser or neem oil to control infestations. Bellflowers can be vulnerable to parasitic infections like fine mold. Guarantee great airflow and abstain from above watering."
      },
      {
        title: "Pruning",
        description: "Eliminate spent blossoms to empower nonstop sprouting and forestall self-seeding. Cut back the foliage in the wake of blooming to advance a second sprout period later in the season."
      },
      {
        title: "Fertilizing",
        description: "Taller assortments might profit from marking to keep them from bowing or breaking major areas of strength for in. Blend manure or a decent compost into the dirt at establishing time. Feed bellflowers with fair manure (like 10-10-10) in late winter and again in the wake of blossoming."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably sodden, particularly during the main developing season. Water profoundly and permit the dirt to dry somewhat between waterings. Apply a layer of mulch around the plants to hold dampness, control soil temperature, and stifle weeds."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/plant_Bell Flowers.webp",
        description: "Plant bellflowers in the spring after the last ice date or in early fall. Plant the roots or seedlings at similar profundity as they were in their nursery compartments, separating them as per their full-grown size (commonly 12-18 inches apart). Plant the roots or seedlings at similar profundity as they were in their nursery holders, dividing them as per their developed size (normally 12-18 inches separated)."
      },
      {
        title: "Soil Necessities",
        description: "Well-depleting soil is fundamental. Bellflowers favor loamy, sandy soil advanced with natural matter. They favor marginally acidic to unbiased soil (pH 6.0-7.0)."
      },   {
        title: "Environment and Area",
        description: "Bellflowers by and large favor mild environments with cool summers.Plant bellflowers in a spot that gets full sun to fractional shade. Morning sun and evening conceal are great, particularly in more sizzling locales."
      },
      {
        title: "Choosing the Right Assortment",
        description: "There are numerous species and cultivars of Campanula. Normal sorts incorporate Campanula carpatica, Campanula lactiflora, Campanula persicifolia, and Campanula portenschlagiana (Dalmatian bellflower).Most bellflowers are solid in USDA zones 3-8, yet this can fluctuate by species and assortment."
      },

    ]
  },
  {
    blog_title: "Freesia",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Freesia.webp"  ,
    blog_description: "Freesias are fragrant, blossoming plants known for their rich and bright sprouts. Local to South Africa, these delicate cormous plants have a place with the family Iridaceae and are famous in gardens and as cut blossoms. Freesia blossoms are channel-formed and regularly show up in groups along the highest point of a slim, wiry stem. They arrive in many tones, including white, yellow, orange, red, pink, lavender, and bi-colors, frequently with a yellow throat.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/freesias.webp"  ,
    blog_desc: "Freesias favor very much depleted soil and full sun to fractional shade. They are usually developed from corms, which are established in the fall in cooler environments or in spring in hotter regions. Freesias are moderately simple to develop and can naturalize in reasonable circumstances, delivering bigger clusters of sprouts after some time. They are much of the time utilized in lines, compartments, and cutting nurseries, where their brilliant and fragrant blossoms can be delighted in very close. With their magnificence and brilliant scent, freesias add a dash of appeal and tastefulness to any garden or botanical presentation.",
    link: "freesia",
    list: [
      {
        title: "Choosing the Right Assortment",
        description: "Freesias arrive in different varieties including white, yellow, pink, red, purple, and blue. A few well-known cultivars incorporate 'Single Blue', 'Single Red', 'Single White', and 'Twofold Mixed'.Freesias are normally developed as annuals in cooler environments (zones 9-10) and as perennials in hotter environments (zones 8-10)."
      },
      {
        title: "Environment and Area",
        description: "Freesias favors cool to calm environments. They truly do well in USDA strength zones 9-11.Plant freesias in a spot that gets full sun to halfway shade. In hotter environments, give them evening shade to shield them from serious intensity."
      },
      {
        title: "Soil Necessities",
        description: "Freesias lean toward well-depleting, sandy topsoil soil. They can endure somewhat acidic to unbiased soil (pH 6.0-7.0). Set up the dirt by adding natural matter, like fertilizer or very much spoiled compost, to further develop soil fruitfulness and waste."
      },
      {
        title: "Planting",
        description: "Freesias are developed from corms. Plant corms in the fall in hotter environments for spring sprouts. In cooler environments, plant them in late winter after the last ice date. Plant freesia corms 1-2 inches down and 2-4 inches separated. Plant them with the sharp end confronting up. Freesias additionally fill well in holders. Utilize a well-depleting preparing blend and plant corms at a similar profundity as in the ground."
      },
      {
        title: "Watering",
        description: "Keep the dirt reliably damp but not waterlogged. Water freesias consistently, particularly during dry periods. Apply a layer of mulch around the plants to hold dampness and stifle weeds."
      },
      {
        title: "Treating",
        description: "Blend in a decent manure, like 10-10-10, into the dirt at establishing time. Feed freesias with a compost high in phosphorus (the center number in N-P-K proportions), like 5-10-5, to advance blossoming. Apply the manure when the blossom buds show up and after blooming."
      },
      {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning_Freesia.webp" ,
        description: "Eliminate spent blossoms to energize constant sprouting and forestall self-seeding. Cut back the foliage in the wake of blooming to advance bulb improvement for the following developing season."
      },
      {
        title: "Irritations and Sicknesses",
        description: "Watch for aphids, thrips, and bug vermin. Utilize insecticidal cleanser or neem oil to control infestations. Freesias can be vulnerable to parasitic infections like botrytis and fusarium. Guarantee a great air course and try not to above water to forestall these issues."
      },
      {
        title: "Winter Care",
        description: "Apply a layer of mulch in pre-winter to shield the corms from frigid temperatures. In colder environments, lift and store freesia corms inside for the colder time of year, replanting them in the spring."
      },
      {
        title: "Engendering",
        description: "Freesias can be spread by corm division or seedsDivide corms at regular intervals when they become stuffed. Separate the corms and replant them at a similar profundity as the first corms. Collect seeds in the wake of blossoming, plant them in a seed plate loaded up with a well-depleting preparing blend, and keep them sodden until they grow."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Freesia.webp" ,
        description: "Freesias lean toward full sun but can endure halfway shade, particularly in more blazing climates. Maintain predictable soil dampness, particularly during warm, dry periods. Mulching holds dampness and directs soil temperature, helping freesias."
      },
      {
        title: "Garden Plan",
        description: "Freesias pair well with other spring-sprouting bulbs like tulips, daffodils, and hyacinths. They are superb for borders, rock nurseries, and holders because of their bright blossoms and sweet aroma."
      }
    ]
  },
  {
    blog_title: "Sunflower",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Sunflower.webp"  ,
    blog_description: "Sunflowers (Helianthus annuus) are notorious, yearly blooming plants known for their huge, daisy-like blossoms that point toward the sun. Local to North and Focal America, sunflowers have become famous overall for their lively appearance and different purposes. The blossoms comprise of a focal plate encompassed by dazzling yellow, orange, or red petals, which can compare 12 crawls in measurement in certain cultivars. Sunflower heads can be single or different, with each plant creating various sprouts over its life expectancy.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/sunflower.webp" ,
    blog_desc: "Sunflowers are not difficult to develop and flourish in full sun and all-around depleted soil. They are in many cases filled in gardens for their elaborate worth, as well as in fields for business seed creation. Sunflowers are likewise alluring to pollinators and give food and environment to birds and other natural life. With their lively sprouts and natural advantages, sunflowers are a cherished expansion to nurseries, fields, and scenes all over the planet.",
    link: "sunflower",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "There are numerous assortments of sunflowers, going in size from overshadow (1-2 feet) to monster (10-15 feet) and in colors from yellow and orange to red and bi-colored. Sunflowers flourish in radiant areas with very much depleted soil. They are annuals that fill well in USDA strength zones 1-11."
      },
      {
        title: "Environment and Area",
        description: "Sunflowers incline toward full sun (6-8 hours of direct daylight each day). They are intense and dry season open-minded however best in moderate temperatures. Pick an area with very much depleted soil. Correct weighty soils with fertilizer to further develop waste."
      },
      {
        title: "Soil Prerequisites",
        description: "Sunflowers fill best in supplement-rich, loamy soil. They can endure an extensive variety of soil types however favor marginally acidic to unbiased soil (pH 6.0-7.5). Set up the dirt by plowing it to a profundity of 12-18 inches. Integrate fertilizer or matured excrement to further develop soil fruitfulness."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Sunflower.webp" ,
        description: "Plant sunflower seeds straightforwardly into the nursery after the last ice date in spring when soil temperatures reach something like 55°F (13°C).Plant seeds 1 inch down and 6-12 inches separated, contingent upon the assortment (check seed parcel for explicit instructions).Plant seeds 1 inch down and 6-12 inches separated, contingent upon the assortment (really look at seed bundle for explicit directions)."
      },
      {
        title: "Watering",
        description: "Water the dirt completely after planting. Keep the dirt reliably wet until seedlings are established. Once laid out, water profoundly yet inconsistently. Sunflowers are dry season lenient yet will profit from normal watering during droughts."
      },
      {
        title: "Preparing",
        description: "Blend manure or a decent compost (like 10-10-10) into the dirt at establishing time.Side-dress sunflowers with fair manure when they are 12 inches tall and again when they start to blossom."
      },     
       {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning-Sunflower.webp" ,
        description: "Eliminate spent blossoms to energize ceaseless sprouting and keep the plant from focusing on seed production. Cut back the bloom stalks to the ground after the main ice if you are not reaping seeds. Tall assortments might require marking to help their stems and keep them from twisting or breaking major areas of strength for in."
      },
      {
        title: "Bugs and Sicknesses",
        description: "Watch for aphids, caterpillars, and birds that might eat the seeds. Utilize insecticidal cleanser or actual hindrances to safeguard your plantsSunflowers are moderately sickness safe yet can be impacted by parasitic illnesses in damp circumstances. Guarantee a great air course and abstain from above watering."
      },
      {
        title: "Gathering Seeds",
        description: "Reap sunflower seeds when the rear of the blossom head becomes yellow or brown and the seeds are stout and completely developed. Cut the bloom and take off the stalks, leaving a couple of crawls of stem joined. Hang them topsy turvy in a warm, dry spot to complete drying. Rub the seeds out of the blossom head with your hands or a solid brush. Flush and dry the seeds before putting away them."
      },
      {
        title: "Winter Care",
        description: "Eliminate dead plant material from the nursery to lessen the gamble of irritations and infections overwintering. Apply a layer of mulch around the foundation of the plants to safeguard the roots from frosty temperatures. Save seeds from your best plants for establishing one year from now. Store seeds in a cool, dry spot until you are prepared to establish them."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-sunflower.webp"  ,
        description: "Sunflowers need full sun to flourish and create enormous, solid blossoms. Stay away from overwatering, as sunflowers favor drier circumstances once established. Mulch holds dampness, manages soil temperature, and stifles weeds."
      },
      {
        title: "Garden Plan",
        description: "Sunflowers make superb allies for vegetables like cucumbers, beans, and squash.Plant sunflowers along walls, walls, or as a setting for more limited blossoms in garden beds."
      },

    ]
  },
  {
    blog_title: "Tithonia",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Tithonia.webp" ,
    blog_description: "Tithonia, regularly known as Mexican Sunflower, is an energetic yearly plant local to Mexico and Focal America. It is valued for its stunning presentation of enormous, daisy-like blossoms that reach in variety from blazing orange to dazzling yellow, drawing in pollinators like honey bees and butterflies all through the late spring and fall. Tithonia flourishes in full sun and very much depleted soil and it is a moderately dry spell open-minded once settled.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Tithonia.webp" ,
    blog_desc: "Growing up to 5-6 feet tall, Tithonia makes a striking option to cultivate lines and beds, adding tropical energy with its lavish foliage and plentiful blossoms. This simple to-develop plant requires negligible support, profiting from standard deadheading to delay blooming. With its vigorous development and eye-getting blossoms, Tithonia is #1 among nursery workers hoping to add level, variety, and untamed life appeal to their scenes.",
    link: "tithonia",
    list: [
      {
        title: "Picking the Right Assortment",
        description: "Tithonia rotundifolia is the most well-known species, and it comes in different cultivars, for example, 'Light', 'Goldfinger', and 'Holiday del Sol'. These assortments differ in level and blossom tone, regularly going from orange to radiant yellow. Tithonia is local to Mexico and Focal America yet can be developed yearly in a more extensive scope of environments."
      },
      {
        title: "Environment and Area",
        description: "Tithonia flourishes in full sun and warm climate. It can endure intensity and dry season once established. Plant Tithonia in an area that gets full sun, as the need might arise for no less than 6-8 hours of direct daylight every day."
      },
      {
        title: "Soil Necessities",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/soil-Tithonia.webp" ,
        description: "Tithonia leans towards well-depleting soil with moderate ripeness. It can endure different soil types yet flourishes in loamy soil improved with natural matter. Tithonia favors somewhat acidic to nonpartisan soil (pH 6.0-7.0)."
      },
      {
        title: "Planting",
        description: "Plant Tithonia seeds straightforwardly into the nursery after the last ice date in spring. They need warm soil to sprout, so hold on until daytime temperatures are reliably above 70°F (21°C). Plant seeds 1/4 inch down and 12-18 inches separated. Flimsy seedlings to a last dispersing of 18-24 inches separated once they are a couple of inches tall. Tithonia can likewise be filled in huge holders loaded up with a well-depleting preparing blend."
      },
      {
        title: "Watering",
        description: "Water the dirt completely in the wake of planting. Keep the dirt reliably damp until seedlings are established. Once laid out, Tithonia is modestly dry spell lenient. Water profoundly when the dirt is dry, particularly during hot, droughts"
      },
      {
        title: "Treating",
        description: "Before planting, integrate manure or a fair compost (like 10-10-10) into the dirt to give nutrients. Tithonia for the most part doesn't need extra preparation during the developing season if the dirt is as of now enhanced."
      },
      {
        title: "Pruning",
        description: "Eliminate spent blossoms routinely to advance consistent sprouting and keep the plant from setting seeds too soon. Taller assortments of Tithonia might profit from marking to help their stems and keep them from bowing or breaking areas of strength for in.",
      },
      {
        title: "Vermin and Sicknesses",
        description: "Tithonia is moderately bug-safe, yet watch for aphids, insect parasites, and caterpillars. Utilize insecticidal cleanser or neem oil to control pervasions. Tithonia can be defenseless to contagious sicknesses like fine mold in sticky circumstances. Guarantee great airflow and abstain from above watering."
      },
      {
        title: "Gathering Seeds",
        description: "Permit a few blossoms to stay on the plant until they experienced and the seeds grow completely. Gather the seeds by gathering the dried blossom heads and eliminating the seeds."
      },
      {
        title: "Winter Care",
        description: "Eliminate dead plant material from the nursery to decrease the gamble of nuisances and infections overwintering. Apply a layer of mulch around the foundation of the plants to safeguard the roots from frosty temperatures."
      },
      {
        title: "Engendering",
        description: "Save seeds from your best plants for establishing one year from now. Store seeds in a cool, dry spot until you are prepared to establish them."
      },
      {
        title: "Care Tips",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Tithonia.webp",
        description: "Tithonia requires full sun to flourish and create huge, lively flowers. Maintain predictable soil dampness, particularly during warm, dry periods. Mulch holds dampness, controls soil temperature, and smothers weeds."
      },
      {
        title: "Garden Plan",
        description: "Tithonia coordinates well with different annuals and perennials that flourish in full sun, like zinnias, marigolds, and cosmos.Plant Tithonia along walls, walls, or as a scenery for more limited blossoms in garden beds."
      },
    ]
  },
  {
    blog_title: "Marigold",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-merigold.webp"  ,
    blog_description: "Marigolds are merry and lively yearly blossoms that light up any nursery with their warm shades and particular aroma. These solid plants, having a place with the sort Tagetes, regularly develop to levels going from 6 crawls to 3 feet, contingent upon the assortment. Their blossoms, which come in shades of yellow, orange, and red, are portrayed by thick, pom-like heads made out of firmly pressed petals. The foliage is for the most part thick and greenery-like, discharging an impactful fragrance that goes about as a characteristic hindrance to many nursery bugs.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/merigold.webp" ,
    blog_desc: "Marigolds favor full sun and very much depleted soil, flourishing in the two beds and holders. They sprout ceaselessly from pre-summer to the primary ice, settling on them a famous decision for lines, edging, and mass plantings. Simple to develop and low-support, marigolds are brightening as well as filling useful needs, for example, buddy planting to discourage bugs from vegetables like tomatoes. Whether developed from seed or transfers, marigolds reward nursery workers with their distinctive varieties and dependable blossoms all through the cultivating season.",
    link: "marigold",
    list: [
      {
        title: "Pick the Right Area",
        description: "Marigolds flourish in full sun, so pick an area that gets no less than 6 hours of daylight every day. Ensure the dirt is very much depleted. Marigolds can endure unfortunate soil conditions however favor ripe, very much depleted soil."
      },
      {
        title: "Planting",
        description: "Sow seeds straightforwardly into the dirt after the last ice date in your space. Marigold seeds can be planted within 4 months and a half before the last expected frost. Sow seeds around 1 inch separated and 1/4 inch deep. Keep the dirt damp until seeds grow in around 4-14 days. You can likewise purchase marigold transfers from nurseries. Plant them at similar profundity as they were in their compartments."
      },

      {
        title: "Watering And Preparing",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-Marigold.webp" ,
        description: "Water marigolds routinely, permitting the dirt to dry out between waterings. Try not to above water to forestall contagious sicknesses. Marigolds are not weighty feeders. If your diet is poor, you can add a reasonable manure one time each month." 
      },

      {
        title: "Mulching And Deadheading",
        description: "Adding a layer of mulch around the plants assists with holding dampness and holding weeds down. Squeeze off dead blossom heads to support more sprouts all through the season."
      },

      {
        title: "Irritations and Illnesses",
        description: "Marigolds are for the most part impervious to nuisances and infections, yet infrequently they can be impacted by bug parasites, aphids, or mold. Treat as important with proper techniques."
      },

      {
        title: "Marking And Reaping Seeds",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/seed-merigold.webp" ,
        description: "Tall assortments might profit from marking to keep them from tumbling over. Marigold blossoms produce seeds that you can collect for the following year's planting. Permit the blossoms to develop and dry on the plant, then, at that point, gather the seeds."
      },

      {
        title: "Friend Planting",
        description: "Marigolds are known to repulse a few irritations, making them great buddy plants for vegetables like tomatoes. In ice-free regions, marigolds might make due through the colder time of year. In colder environments, you can gather seeds or take cuttings to overwinter inside Marigolds will sprout persistently from pre-summer until the principal ice. Partake in their dynamic tones and fragrant foliage!"
      },
    

    ]
  },
  {
    blog_title: "Gaillardia",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Gaillardia.webp" ,
    blog_description: "Gaillardia, usually known as cover blossom, is a striking lasting that carries a dynamic tone to gardens with its strong, daisy-like sprouts. These blossoms are portrayed by their special and distinctive variety blends, frequently including dazzling red, orange, and yellow petals that make a staggering difference against the green foliage. The petals can be strong or bi-hued, for certain assortments showing a rich slope from the middle outwards. Gaillardia plants ordinarily develop to a level of 12 to 18 inches, making them ideal for borders, rock nurseries, and holders.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Gaillardia.webp" ,
    blog_desc: "Their long blossoming period, from late spring to fall, guarantees a consistent showcase of variety. The foliage is dim green and marginally shaggy, giving a satisfying scenery to the distinctive blossoms. Known for their toughness, Gaillardia blossoms flourish in full sun and very much depleted soil, and they are surprisingly dry season open-minded once settled. Moreover, these blossoms draw in pollinators like honey bees and butterflies, enhancing their tasteful allure. Simple to develop and keep up with, Gaillardia is a superb expansion to any garden, offering an explosion of variety and life all through the hotter months.",
    link: "gaillardia",
    list: [
      {
        title: "Pick the Right Area",
        description: "Gaillardia flourishes in full sun, requiring no less than 6-8 hours of direct daylight daily. Select a spot with very much depleted soil. These blossoms are open-minded toward unfortunate soil conditions yet will perform best in sandy or loamy soil."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Gardenias.webp" ,
        description: "Plant seeds straightforwardly into the nursery after the risk of ice has passed. You can likewise begin seeds inside 4 a month and a half before the keep-going ice date. Sow seeds on the dirt surface and delicately press them down. Try not to cover them with soil as they need light to germinate. Keep the dirt damp until germination, which as a rule takes 10-15 days. If utilizing nursery transfers, plant them at a similar profundity as they were in their pots, separating them around 12-15 inches."
      },
      {
        title: "Watering",
        description: "Water youthful plants consistently until they are laid out. Once settled, Gaillardia is dry-spell open-minded and just requires infrequent watering. Permit the dirt to dry out between waterings to forestall root decay."
      },
      {
        title: "Preparing And Mulching",
        description: "Gaillardia for the most part doesn't need a lot of treatment. On the off chance that your dirt is exceptionally poor, you can apply a reasonable, slow-discharge manure once in the spring. Apply a meager layer of mulch around the plants to hold dampness, smother weeds, and keep the roots cool. Be that as it may, don't allow mulch to contact the stems to forestall decay."
      },
      {
        title: "Deadheading",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Deadheading-gaillardia.webp"  ,
        description: "Routinely eliminate spent blossoms to support consistent sprouting. Deadheading will advance more blossoms and expand the blooming time frame. Taller assortments might require marking to keep them from tumbling over, particularly in breezy regions."
      },
      {
        title: "Irritations and Illnesses",
        description: "Gaillardia is somewhat vermin and infection-safe yet can at times be impacted by aphids, insect bugs, or parasitic sicknesses like fine buildup. Treat pervasions with insecticidal cleanser or neem oil, and guarantee a great air course to forestall contagious issues."
      },
      {
        title: "Division",
        description: "Each 2-3 years, partition the plants in late winter or tumble to keep up with their power. Uncover the bunches, separate them into more modest areas, and replant them. In colder environments, apply a layer of mulch or straw over the plants in pre-winter to safeguard them from winter temperatures. In milder environments, Gaillardia frequently endures the colder time of year without extra assurance." 
      },
      {
        title: "Sidekick Planting",
        description: "Gaillardia can be matched with other sun-cherishing perennials like coneflowers, dark-looked Susans, and coreopsis to make a dynamic and various nursery bed."
      },
      {
        title: "Partaking in the Blossoms",
        description: "Gaillardia sprouts from late spring to fall, creating daisy-like blossoms in dazzling red, orange, and yellow tints. Their durable sprouts draw in pollinators like honey bees and butterflies, enhancing your nursery."
      },

    ]
  },
  {
    blog_title: "Coreopsis",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-coreopsis.webp" ,
    blog_description: "Coreopsis, ordinarily known as tickseed, is a beguiling perpetual that lights up gardens with its bounty of daisy-like blossoms. These blossoms are most frequently found in lively shades of yellow, however a few assortments exhibit tints of pink, red, and orange. The petals encompass a hazier focal circle, making a striking differentiation. Coreopsis plants commonly arrive at levels of 1 to 3 feet, making them ideal for garden borders, rock nurseries, and wildflower knolls. Their finely finished, green foliage gives a rich scenery to the splendid blossoms.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Coreopsis.webp" ,
    blog_desc: "Blossoming from late spring through fall, Coreopsis is known for its enduring and plentiful showcase. This tough plant flourishes in full sun and very much depleted soil, and once settled, it shows great dry season resistance. Coreopsis is likewise a number one among pollinators, drawing in honey bees and butterflies, which improves the biological worth of any nursery. Simple to develop and support, Coreopsis adds perseverance through magnificence and lively variety to cultivate scenes.",
    link: "coreopsis",
    list: [
      {
        title: "Pick the Right Area",
        description: "Coreopsis flourishes in full sun, expecting something like 6-8 hours of direct daylight daily. Choose an area with very much depleted soil. Coreopsis can endure different soil types, including sandy and rough soils, however, it inclines toward respectably rich soil that channels well."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Coreopsis.webp" ,
        description: "Plant Coreopsis seeds straightforwardly in the nursery after the peril of ice has passed, or begin seeds inside 6 two months before the last expected frost. Scatter the seeds on the dirt surface and delicately press them down without covering them, as they need light for germination. Keep the dirt reliably wet until the seeds develop, which commonly takes 2-3 weeks. If you buy transfers from a nursery, plant them at similar profundity as they were in their pots, dividing them 12-18 inches to consider development."
      },
      {
        title: "Watering",
        description: "Water youthful Coreopsis plants routinely to assist them with laying major areas of strength for out. When laid out, these plants are dry spell open-minded and just require periodic watering. Guarantee the dirt dries out between waterings to keep away from root decay."
      },

      {
        title: "Preparing",
        description: "Coreopsis for the most part doesn't require weighty preparation. If your diet is extremely poor, you can apply a decent, slow-discharge manure in the spring. Over-treating can prompt inordinate foliage development to the detriment of blossoms."
      },
      {
        title: "Mulching",
        description: "Apply a slim layer of mulch around the plants to assist with holding dampness, smother weeds, and keep a steady soil temperature. Guarantee the mulch doesn't contact the plant stems to forestall decay."
      },

      {
        title: "Deadheading",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning-Corepsis.webp"  ,
        description: "Routinely eliminate spent blossoms to support persistent sprouting all through the developing season. Deadheading advances the development of new sprouts and expands the blooming time frame."
      },
      {
        title: "Irritations and Infections",
        description: "Coreopsis is a moderate nuisance and sickness however can once in a while be impacted by aphids, bug vermin, or parasitic illnesses like fine buildup. Treat invasions with insecticidal cleanser or neem oil, and guarantee great air dissemination to forestall parasitic issues."
      },

      {
        title: "Marking and Division",
        description: "A few taller assortments of Coreopsis might profit from marking to keep them from floundering over, particularly in breezy circumstances. Gap Coreopsis establishes each 2-3 years in late winter or tumbles to keep up with their life and forestall packing. Uncover the clusters, separate them into more modest segments, and replant them in pre-arranged soil."
      },
      {
        title: "Overwintering",
        description: "In colder environments, apply a layer of mulch or straw over the plants in pre-winter to safeguard them from unforgiving winter temperatures. In milder environments, Coreopsis frequently endures the colder time of year without extra security."
      },
      {
        title: "Friend Planting",
        description: "coordinates well with other sun-cherishing perennials, for example, coneflowers, dark-looked Susans, and gaillardia, making an energetic and different nursery bed."
      },

      {
        title: "Partaking in the Blossoms",
        description: "Coreopsis sprouts from late spring to fall, creating a bounty of dazzling yellow, orange, pink, or red blossoms that draw in pollinators like honey bees and butterflies. Their dependable blossoms make them ideal for cutting nurseries and decorative layouts."
      },

    ]
  },
  {
    blog_title: "Coneflower",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Coneflower.webp"  ,
    blog_description: "Coneflowers, or Echinacea, are striking perennials known for their daisy-like sprouts and unmistakable, noticeable focal cones. These strong plants normally develop to levels of 2 to 4 feet, with solid, upstanding stems and dull green, spear-molded leaves. The blossoms arrive in various energetic varieties, including shades of purple, pink, white, and yellow. The enormous, adjusted petals transmit from a focal cone that starts green and develops to a spiky, dim brown or orange, adding visual interest.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Coneflower.webp" ,
    blog_desc: "Sprouting from late spring to fall, coneflowers are delightful as well as exceptionally alluring to pollinators like honey bees, butterflies, and birds. Their durable blossoms make them number one for both nursery beds and decorative designs. Coneflowers are not difficult to develop and keep up with, flourishing in full sun and all around depleted soil, and displaying amazing dry spell resistance once settled. These versatile plants are an important expansion to any garden, giving getting through variety and natural advantages all through the developing season.",
    link: "coneflower",
    list: [
      {
        title: "Planting",
        description: "Plant coneflower seeds straightforwardly in the nursery after the last ice, or begin seeds inside 6 two months before the last expected ice date. Scatter the seeds on the dirt surface and daintily press them into the dirt. Try not to cover them with soil as they need light to germinate. Keep the dirt reliably soggy until the seeds develop, which for the most part takes 10-20 days. If establishing nursery-purchased transfers, place them in the ground at a similar profundity as they were in their pots, dispersing them around 12-24 inches separated to consider development."
      },
      {
        title: "Choose the Right Area",
        description: "Coneflowers flourish in full sun, requiring something like 6-8 hours of direct daylight each day. Select a site with very much depleted soil. While they can endure unfortunate soil conditions, they perform best in reasonably fruitful soil that channels well."
      },
      {
        title: "Fertilizing",
        description: "Coneflowers by and large don't need weighty preparation. If your dirt is especially poor, you can apply a decent, slow-discharge compost in the spring. Over-treating can prompt unreasonable foliage development to the detriment of blossoms."
      },
      {
        title: "Watering",
        description: "Water youthful plants routinely to assist them with laying out. Once settled, coneflowers are calm open-minded, and just need intermittent watering. Permit the dirt to dry out between waterings to forestall root decay."
      },
      {
        title: "Deadheading",
        description: "Eliminate spent blossoms to support ceaseless sprouting all through the season. Deadheading will advance new blossoms and broaden the blooming time frame."
      },
      {
        title: "Mulching and Staking",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/stake-coneflower.webp" ,
        description: "Apply a slight layer of mulch around the plants to hold dampness, smother weeds, and keep the roots cool. Ensure the mulch doesn't contact the stems to forestall decay. Taller assortments might profit from marking to keep them upstanding, particularly in breezy circumstances."
      },
      {
        title: "Pests and Illnesses",
        description: "Coneflowers are by and large impervious to bugs and infections, yet they can sometimes be impacted by aphids, Japanese scarabs, or contagious sicknesses like fine buildup. Treat invasions with insecticidal cleanser or neem oil, and guarantee great air dissemination to forestall parasitic issues."
      },
      {
        title: "Overwintering",
        description: "In colder environments, leave the seed heads on the plants through winter to give food to birds. Apply a layer of mulch around the plants in pre-winter to shield them from cruel winter temperatures."
      },
      {
        title: "Division",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/divine-coneflower.webp" ,
        description: "Gap coneflowers each 3-4 years in late winter or tumble to keep up with plant wellbeing and forestall packing. Uncover the clusters, separate them into more modest areas, and replant them."
      },
      {
        title: "Partaking in the Blossoms",
        description: "Coneflowers sprout from late spring to fall, delivering huge, daisy-like blossoms with unmistakable focal cones that draw in honey bees, butterflies, and birds. The sprouts arrive in various varieties, including purple, pink, white, and yellow, adding dependable excellence to your nursery."
      },
      {
        title: "Companion Planting",
        description: "Coneflowers pair well with other sun-cherishing perennials, for example, dark peered toward Susans, lavender, and yarrow, making a dynamic and different nursery bed."
      },
    ]
  },
  {
    blog_title: "Black Eyed Susan",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Black-Eyed-Susan.webp"  ,
    blog_description: "Dark peered toward Susans, experimentally known as Rudbeckia, are famous and strong perennials that light up gardens with their happy, daisy-like blossoms. These strong plants ordinarily arrive at levels of 2 to 3 feet and element coarse, furry leaves that are spear-molded. The sprouts are described by their radiant yellow or gold petals encompassing a dull brown or dark focal cone, making a striking differentiation. Blossoming from mid-summer to late-summer, Dark looked at Susan's giving a durable presentation of variety.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Black Eyed Susan.webp" ,
    blog_desc: "They are exceptionally appealing to pollinators like honey bees, butterflies, and birds, improving the environmental worth of any nursery. Flourishing in full sun and all-around depleted soil, dark-skinned Susans are additionally dry spell open-minded once settled, making them simple to develop and keep up with. Their tough stems make them ideal for borders, wildflower glades and cut bloom courses of action. With their dynamic sprouts and strong nature, Dark looked at Susans are a great expansion to any garden scene.",
    link: "black-eyed-susan",
    list: [
      {
        title: "Enjoying the Blossoms",
        description: "Dark-looked Susan's sprouts from mid-summer to late-summer, delivering radiant yellow or gold blossoms with dim brown or dark focal cones that draw in pollinators like honey bees, butterflies, and birds. Their durable sprouts make them ideal for cutting nurseries and decorative layouts."
      },
      {
        title: "Sidekick Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Planting-Black-Susan.webp" ,
        description: "Dark peered toward Susans pair well with other sun-cherishing perennials like coneflowers, lavender, and yarrow, making a dynamic and various nursery bed."
      },  {
        title: "Overwintering",
        description: "In colder environments, leave the seed heads on the plants through winter to give food to birds. Apply a layer of mulch around the plants in pre-winter to shield them from cruel winter temperatures."
      },
      {
        title: "Division",
        description: "Gap Dark looked at Susans each 3-4 years in late winter or tumble to keep up with plant wellbeing and forestall packing. Uncover the bunches, separate them into more modest segments, and replant them. Taller assortments might profit from marking to keep them upstanding, particularly in breezy circumstances."
      },
      {
        title: "Pests and Illnesses",
        description: "Dark-looked-at Susans are for the most part impervious to vermin and sicknesses however can once in a while be impacted by aphids, bug parasites, or contagious sicknesses like fine mold and rust. Treat invasions with insecticidal cleanser or neem oil, and guarantee great airflow to forestall contagious issues."
      },
      {
        title: "Deadheading",
        description: "Eliminate spent blossoms to support persistent sprouting all through the season. Deadheading will advance new blossoms and broaden the blooming time frame. Apply a meager layer of mulch around the plants to hold dampness, smother weeds, and keep the roots cool. Ensure the mulch doesn't contact the stems to forestall decay."
      },
      {
        title: "Fertilizing",
        description: "Dark peered toward Susans for the most part don't need weighty treatment. On the off chance that your dirt is especially poor, you can apply a reasonable, slow-discharge compost in the spring. Over-preparing can prompt extreme foliage development to the detriment of blossoms."
      },
      {
        title: "Watering",
        description: "Water youthful plants consistently to assist them with laying out. Once settled, Dark peered Susans are dry-season lenient and just need incidental watering. Guarantee the dirt dries out between waterings to forestall root decay."
      },
      {
        title: "Planting",
        description: "Plant seeds straightforwardly in the nursery after the peril of ice has passed, or begin seeds inside 6 two months before the last expected ice date. Scatter the seeds on the dirt surface and gently press them into the dirt. Try not to cover them with soil as they need light to germinate. Keep the dirt reliably soggy until the seeds sprout, which normally takes 7-30 days. If establishing nursery-purchased transfers, plant them at similar profundity they were in their pots, dispersing them 18-24 inches separated to consider development."
      },  {
        title: "Choose the Right Area",
        description: "Dark peered toward Susans flourish in full sun, requiring something like 6-8 hours of direct daylight each day. Select a site with very much depleted soil. They can endure an assortment of soil types, including dirt, topsoil, and sandy soils, however lean toward decently rich, very much depleted soil."
      },
    
    ]
  },
  {
    blog_title: "Daisy",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Daisy.webp" ,
    blog_description: "Daisies are notable and beguiling blossoms that are adored for their straightforwardness and lively appearance. These perennials, having a place with the sort Bellis or Leucanthemum, are portrayed by their exemplary daisy shape: a conspicuous yellow plate encompassed by white, pink, or yellow petals. Daisies normally fill in thick groups on solid stems, arriving at levels of 6 to 12 inches. Their dim green leaves are much of the time spoon-molded and structure a basal rosette.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/daisy.webp" ,
    blog_desc: "These versatile blossoms draw in pollinators like honey bees and butterflies, making them significant for biological variety in gardens. Daisies are moderately low upkeep, requiring customary watering to keep the dirt uniformly damp but not waterlogged. Deadheading spent blossoms can support delayed sprouting. In general, daisies bring a hint of caprice and immortal magnificence to any garden, making them #1 among nursery workers of all expertise levels.",
    link: "daisy",
    list: [
      {
        title: "Pick the Right Sort of Daisy",
        description: "Frequently utilized as ground cover or in borders, with white or pink petals and yellow centers. Larger and more decorative, with white petals and yellow focuses. These are perfect for cut blossoms."
      },
      {
        title: "Choosing an Area",
        description: "Daisies flourish in full sun to fractional shade. Guarantee they get no less than 4-6 hours of direct daylight daily. Choose a very depleted area. Daisies can endure different soil types yet lean toward loamy soil."
      },
      {
        title: "Planting",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/planting-Daisy.webp" ,
        description: "Plant seeds straightforwardly into the nursery after the last ice date. Scatter seeds over the dirt surface and delicately press them down. They need light to germinate. Keep the dirt reliably wet until seeds develop, which regularly takes 10-20 days. Plant nursery-purchased transfers in spring or fall, separating them as per the particular assortment's prerequisites (normally 6-12 inches separated)."
      },
      {
        title: "Soil Necessities",
        description: "Daisies favor very much depleted soil with a pH level of 6.0 to 7.5. Alter weighty soils with natural matter like fertilizer before planting. Daisies are not weighty feeders. Apply a reasonable manure in spring if your dirt is poor. An excessive amount of compost can prompt rich foliage yet less blossoms."
      },
      {
        title: "Watering and Mulching",
        description: "Water daisies consistently, particularly during droughts, to keep the dirt equitably sodden. Try not to above water to forestall leaf illnesses Apply a layer of mulch around daisy plants to hold dampness and stifle weeds. Get mulch far from the stems to forestall decay."
      },
      {
        title: "Deadheading",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Deadheading-Daisy.webp"  ,
        description: "Eliminate spent blossoms routinely to energize nonstop sprouting all through the developing season. Daisies are by and large impervious to bugs and illnesses. Nonetheless, watch for aphids, slugs, and fine mold. Treat bugs with insecticidal cleanser and eliminate impacted leaves immediately."
      },
      {
        title: "Marking",
        description: "Tall assortments of daisies might require marking to keep them from slumping over, particularly in blustery circumstances. Separation bunches of daisies every 2-3 years in late winter or tumble to restore the plants and forestall packing.Daisies are tough perennials in many environments. Apply a layer of mulch in pre-winter to shield the roots from ice."
      },
      {
        title: "Partaking in the Blossoms",
        description: "Daisies pair well with other sun-cherishing perennials like lavender, salvia, and coneflowers, making an energetic and different nursery bed. Daisies sprout from pre-summer to late-summer, delivering a bounty of enchanting blossoms that draw in pollinators like honey bees and butterflies. They make magnificent cut blossoms, adding a new and regular focus on decorative layouts."
      },
    ]
  },
  {
    blog_title: "Cosmos",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Cosmos.webp"  ,
    blog_description: "Universe blossoms, known for their fragile, daisy-like appearance and dynamic tones, are a famous decision for gardens because of their simplicity of development and striking excellence. These yearly plants flourish in full sun, expecting no less than 6-8 hours of direct daylight every day, and are versatile to different soil types for however long it is very much depleted. They can be handily developed from seeds, either straightforwardly planted into the ground after the last ice date or began inside in biodegradable pots before relocating.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Cosmos.webp" ,
    blog_desc: "Universe blossoms from late spring to fall, delivering a wealth of blossoms in shades of pink, white, red, and orange, contingent upon the assortment. Their ferny foliage is breezy and light, making them an alluring expansion to boundaries, beds, and wildflower gardens. Once settled, Universe is dry season lenient and requires negligible upkeep, however deadheading spent blossoms can empower delayed sprouting. These blossoms are wonderful as well as draw-in pollinators like honey bees and butterflies, making them a gainful expansion to any garden scene.",
    link: "cosmos",
    list: [
      {
        title: "Picking an Area",
        description: "The universe leans toward full sun, so select a spot that gets no less than 6-8 hours of direct daylight daily. They can endure different soil types yet favor very much depleted soil. They are not demanding about soil ripeness."
      },
      {
        title: "Establishing Universe",
        description: "The universe is best planted straightforwardly into the ground after the last ice date. You can likewise plant them in early spring. Prepare the dirt by relaxing it to a profundity of around 6-8 inches. Scatter the seeds on the dirt surface and delicately press them into the dirt. Universe seeds need light to sprout, so don't cover them too deeply. Water delicately after planting.If beginning inside, plant seeds in biodegradable pots half a month before the last ice date. Relocate outside after all risk of ice has passed."
      },
      {
        title: "Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-cosmos.webp" ,
        description: "Universe are dry spell open-minded once settled. Water them consistently during droughts, particularly while they're being laid out. Universe for the most part doesn't require treating on the off chance that the dirt is sensibly fruitful. An excess of manure can result in fewer flowers. Remove spent blossoms to empower persistent sprouting all through the season. Taller assortments might profit from marking to keep them from tumbling over."
      },
      {
        title: "Nuisances and Infections",
        description: "Universe are somewhat irritation and illness-safe. Be that as it may, look out for aphids, bug parasites, and thrips. Neem oil or insecticidal cleanser can be utilized on the off chance that nuisances become an issue."
      },

        {
        title: "Reaping Seeds",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Reaping-Cosmos.webp" ,
        description: "To save seeds for the following season, permit a few blossoms to dry on the plant. Collect the seeds once the bloom heads have become brown and dry. Universe bipinnatus is the most widely recognized assortment, with blossoms in shades of pink, white, and crimson. Cosmos sulphureus has yellow, orange, and red blossoms."
      },
      {
        title: "Overwintering",
        description: "Universe are annuals however can self-seed. Pass on blossoms on the plant to deliver seeds for the following season. Universe blossoms draw in honey bees, butterflies, and birds to your garden. Cut roses are great for flower bundles and plans."
      },
    
    ]
  },
  {
    blog_title: "Gazania",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Gazania.webp" ,
    blog_description: "Gazania, otherwise called African daisy, is a shocking and versatile yearly bloom that adds a sprinkle of variety to nurseries and scenes. These plants are local to South Africa and flourish in hot, radiant circumstances, making them ideal for bright boundaries, rock nurseries, and compartments. Gazanias highlight dynamic, daisy-like blossoms that arrive in a scope of varieties including yellow, orange, red, pink, and white, frequently with striking examples and differentiating colors on the petals.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Gazania.webp" ,
    blog_desc: "The foliage is brilliant green and frequently marginally fluffy, adding to its engaging quality. Gazanias blossom abundantly from pre-summer through summer, shutting their blossoms around evening time and on overcast days. They are dry spell open-minded once settled and require all-around depleted soil to forestall root decay. Gazanias are low-support establishes that truly do well in seaside regions and warm, dry environments, pursuing them is a famous decision for nursery workers hoping to add tone and surface to their nurseries with negligible consideration.",
    link: "gazania",
    list: [
      {
        title: "Enjoying Your Gazanias",
        description: "Gazanias are amazing for adding energetic variety to lines, holders, and shake gardens. They draw in honey bees and butterflies to your nursery, adding to fertilization. There are numerous assortments of Gazania accessible, with colors going from dazzling yellow, orange, red, and pink, to white. A few assortments have striped or bicolored blossoms."
      },
      {
        title: "Overwintering",
        description: "Gazanias are normally developed as annuals, yet they may self-seed in positive circumstances. On the off chance that you wish to save seeds, permit a few blossoms to dry on the plant and gather the seeds once the bloom heads have become brown."
      },
      {
        title: "Nuisances and Sicknesses",
        description: "Gazanias are for the most part bother free, however, they can incidentally be impacted by aphids and insect vermin. Treat any pervasions immediately with an insecticidal cleanser or neem oil."
      },
      {
        title: "Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Gazania.webp" ,
        description: "Water Gazanias consistently in the wake of planting until they are laid out. Once settled, they are dry season lenient and just need watering during delayed droughts. Gazanias don't need a lot of preparation. A light use of a reasonable compost once in late winter is normally sufficient. Remove spent blossoms consistently to empower ceaseless sprouting all through the season. Mulch around Gazanias to assist with holding dampness and keep the dirt cool."
      },
      {
        title: "Planting Gazanias",
        description: "Gazanias can be developed from seeds. Plant the seeds inside in late winter or straightforwardly into the nursery after the last ice date. Prepare the dirt by slackening it to a profundity of around 6-8 inches. Scatter the seeds on the dirt surface and daintily press them into the dirt. Gazania seeds need light to sprout, so don't cover them with soil. Water tenderly in the wake of planting."
      },
      {
        title: "Picking an Area",
        description: "Gazanias flourish in full sun. Pick a spot in your nursery that gets no less than 6-8 hours of direct daylight daily. They favor very much depleted soil and are lenient toward unfortunate soil conditions, making them reasonable for sandy or rough soils."
      },
    
    ]
  },
  {
    blog_title: "Chrysanthemum",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Chrysanthemum.webp" ,
    blog_description: "Chrysanthemums, normally known as mums, are valued for their dynamic and different sprouts, making them the dearest decision for gardens and decorative layouts. These lasting blooming plants arrive in a wide cluster of varieties, including shades of white, yellow, orange, red, pink, and purple, with both single and twofold blossomed assortments accessible. Their sprouts can be daisy-like, tuft-molded, or look like little fastens, adding variety to any garden scene.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Chrysanthemum.webp" ,
    blog_desc: "Chrysanthemum foliage is normally dim green and serrated, giving an appealing background to the brilliant blossoms. They flourish in full sun to halfway shade and require all-around emptied soil, profiting from standard watering and preparation during the developing season. Chrysanthemums are fantastic for cut plans and draw in useful pollinators like butterflies, making them an esteemed expansion to gardens all through pre-fall and fall.",
    link: "chrysanthemum",
    list: [
      {
        title: "Choosing an Area",
        description: "Chrysanthemums flourish in full sun to halfway shade, contingent upon the climate. They favor very much depleted soil with great natural substances."
      },
      {
        title: "Planting Chrysanthemums",
        description: "Chrysanthemums can be proliferated from cuttings taken in spring or late spring. Plant the cuttings in ready soil and keep them reliably clammy until they lay out roots. Young chrysanthemum plants can likewise be bought from nurseries and relocated into the nursery after the last ice date."
      },
      {
        title: "Care",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/care-Chrysanthemum.webp" ,
        description: "Keep the dirt uniformly clammy, particularly during droughts, however, stay away from waterlogged conditions. Feed chrysanthemums with fair manure every 4 months and a half during the developing season to energize solid development and bountiful blossoming. Squeeze back youthful plants in spring to advance shaggy development and more bloom buds."
      },
      {
        title: "Vermin and Infections",
        description: "Chrysanthemums might draw in bothers like aphids and bug parasites, as well as illnesses like fine buildup. Screen plants intently and treat invasions quickly with proper cures."
      },
      {
        title: "Overwintering",
        description: "In colder environments, safeguard chrysanthemum plants during winter by mulching around the base or bringing pruned plants inside. With a large number of cultivars accessible, chrysanthemums offer different bloom structures and varieties reasonable for different nursery styles and inclinations."
      },
      {
        title: "Utilizes",
        description: "Chrysanthemum blossoms are great for cutting game plans, and adding variety and surface to indoor displays. They draw in gainful pollinators like butterflies, improving biodiversity in the nursery."
      },
    
    ]
  },
  {
    blog_title: "Lotus",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner-Lotus.webp" ,
    blog_description: "Lotus blossoms (Nelumbo spp.) are notorious oceanic plants known for their magnificence and social importance. They develop from tubers established in sloppy, dirt-like soil that holds water well, normally in full sun and warm environments. Lotus plants flourish in holders or lakes with something like 6 hours of daylight day to day. Their huge, round leaves and superb blossoms arise over the water's surface on lengthy stalks. Lotus blossoms sprout in summer, showing perfect petals that reach in variety from white to pink and yellow. Each blossom endures a couple of days, supplanted by new sprouts all through the season. Lotus plants require customary pruning to eliminate dead leaves and spent blossoms.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Lotus.webp" ,
    blog_desc: "They can be proliferated by isolating tubers in late winter and require saving preparation. Lotus plants are generally bother-free yet can be impacted by aphids or bug parasites, made do with insecticidal cleanser or neem oil. In colder environments, lotus plants ought to be safeguarded during winter, either by moving holders inside or sinking pots to the lower part of lakes. Lotus blossoms add excellence to water gardens as well as hold social and emblematic importance in many societies all over the planet.",
    link: "lotus",
    list: [
      {
        title: "Environment and Daylight",
        description: "Lotus plants flourish in warm environments with full sun. They need something like 6 hours of daylight every day to appropriately blossom."
      },
      {
        title: "Soil Prerequisites",
        description: "Lotus plants favor sloppy, mud-like soil that is rich in natural matter. The dirt ought to be weighty and hold water well. Stay away from sandy or free soils."
      },
      {
        title: "Establishing Lotus Tubers",
        description: "Utilize an enormous holder (something like 16 inches down and wide) loaded up with weighty topsoil soil. Dig a shallow lake (6-12 inches down), line it with weighty soil, and plant tubers straightforwardly into the mud."
      },
      {
        title: "Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-Lotus.webp" ,
        description: "Lotus plants are water-adoring and ought to be filled in compartments or lakes where they can be lowered in water. This is the way to water them. Keep the holder lowered in water so the highest point of the pot is 2-4 creeps beneath the water surface. Plant tubers in 6-12 crawls of water and increment the profundity as the plant develops."
      },
      {
        title: "Preparing",
        description: "Treat lotus plants sparingly during the developing season with a broadly useful manure. Try not to prepare recently established tubers until they have been laid out."
      },
      {
        title: "Pruning and Upkeep",
        description: "Lotus plants can spread rapidly and should be pruned consistently to keep them from assuming control over a lake. This is the way to keep up with them. Eliminate dead leaves and spent blossoms regularly. Thin-out-packed plants to keep them from gagging one another."
      },
      {
        title: "Overwintering",
        description: "Move compartments inside to a cool, ice-free area. Cut back foliage and sink the pot to the lower part of the lake to overwinter. In late winter, partition tubers, and plant new segments."
      },
      {
        title: "Vermin and Sickneses",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Vermin-Lotus.webp" ,
        description: "Lotus plants are moderately bother-free however can every so often be impacted by aphids or insect parasites. This is the way to oversee brothers. Utilize insecticidal cleanser or neem oil."
      },
      {
        title: "Blossoming",
        description: "Lotus blossoms generally sprout in the mid-year and go on until late summer. Each blossom endures a couple of days however is supplanted by new blossoms all through the season."
      },
      {
        title: "Gathering Seeds",
        description: "Lotus plants produce seeds that can be collected when the seed unit becomes brown and begins to part. This is the way to reap them. Gather seeds from the unit and dry them before storing them. Ensure your lotus plant gets a lot of sunlight. Maintain a reliable water level. Use weighty soil with a lot of natural matter. Use manure sparingly. Regularly prune to keep up with sound development."
      },
    
    ]
  },
  {
    blog_title: "Salvia",
    banner_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/banner_Salvia.webp" ,
    blog_description: "Salvia, generally known as savvy, is a different sort of plant eminent for their sweet-smelling leaves and striking blossoms. These strong perennials or annuals arrive in different tones, including energetic blues, purples, reds, pinks, and whites, frequently shaping thick spikes that draw in pollinators like honey bees, butterflies, and hummingbirds. Salvia plants can differ fundamentally in level, going from conservative, ground-embracing assortments to tall, rugged structures.",
    blog_image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/Salvia.webp" ,
    blog_desc: "They flourish in all-around depleted soil and lean toward radiant areas, however, a few animal varieties can endure halfway shade. Known for their dry spell opposition, Salvias are number one in water-wise gardens and add a dependable sprinkle of variety all through the developing season. Their foliage is frequently fragrant, adding one more tactile aspect to their allure in nurseries and scenes.",
    link: "salvia",
    list: [
      {
        title: "Choosing the Right Salvia",
        description: "These assortments complete their life cycle in one developing season. Normal sorts incorporate Salvia splendens (Red Sage). These return a large number of years. Well-known assortments incorporate Salvia nemorosa, Salvia greggii, and Salvia officinalis (Culinary Sage). Salvias are strong in USDA zones 4-10, contingent upon the variety. Ensure you select an assortment fit to your particular environment."
      },
      {
        title: "Location",
        description: "Pick an area with full sun, however a few assortments can endure halfway shade. Ensure the site has well-depleting soil to forestall root decay."
      },
      {
        title: "Soil Readiness",
        description: "Salvias favors marginally acidic to nonpartisan soil (pH 6.0-7.0). Amend the dirt with fertilizer or all-around decayed excrement to further develop fruitfulness and seepage.Plant perpetual Salvias in the spring or fall. Annual Salvias ought to be established after the last ice in spring."
      },
      {
        title: "Spacing and Watering",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/watering-Salvia.webp" ,
        description: "Space plants 12-24 inches separated, contingent upon the experienced size of the assortment. Water youthful plants routinely to lay out roots. Once laid out, Salvias are dry-spell open-minded, and require less incessant watering. Avoid above watering to forestall parasitic infections."
      },

      {
        title: "Fertilizing and Mulching" ,
        description: "Salvias for the most part don't need weighty fertilization. A light use of adjusted manure in the spring can advance growth. Avoid extreme nitrogen, which can prompt lavish foliage yet fewer blossoms. Apply a layer of mulch around the plants to preserve dampness and stifle weeds. Keep mulch away from the stems to forestall decay."
      },

      {
        title: "Pruning",
        image: "https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/pruning-Salvia.webp" ,
        description: "Deadhead spent blossoms to support constant blooming. Prune lasting Salvias in late winter to eliminate dead development and shape the plant. Some assortments benefit from a hard scaled back after the main flush of sprouts to advance a subsequent blossom."
      },
      {
        title: "Pest and Infectious prevention",
        description: "Salvias are somewhat bother-safe however can sometimes experience the ill effects of aphids, bug vermin, or whiteflies. Treat invasions with insecticidal cleanser or neem oil. Ensure great airflow to forestall parasitic sicknesses like a fine buildup."
      },
      {
        title: "Seeds and Cuttings",
        description: "Plant seeds inside 6 two months before the last ice date.Transplant seedlings outside after the risk of ice has passed. Take 4-6 inch cuttings from sound plants in pre-summer or early summer. Remove the lower leaves and plunge the cut end in establishing hormone. Plant the cuttings in a pot with a blend of perlite and peat greenery and keep them damp until roots are created."
      },
      {
        title: "Dividing",
        description: "Gap enduring Salvias each 3-4 years in late winter or tumble to revive the plant and increment your stock. Salvias pair well with other sun-cherishing perennials like Echinacea (Coneflower), Rudbeckia (Dark looked at Susan), and Coreopsis. Salvias in borders, house gardens, spice gardens, or as compartment plants. Their lively blossoms draw in pollinators like honey bees, butterflies, and hummingbirds."
      },
    ]
  },
 

]

export default gardeningAllData