import React, { useState } from "react";
import { FaBars, FaTimes } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Ad728_90 from "../Ad728_90";
import { IoIosSearch } from "react-icons/io";

const Header = ({
  setSearchTerm,
  searchTerm,
  searchResults,
  handleKeyPress,
  handleScroll,
}) => {
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [isSearchOpen, setIsSearchOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
    setIsSearchOpen(false);
  };
  const closeMobileMenu = () => {
    setIsMobileMenuOpen(false);
  };
  const toggleSearch = () => {
    setIsSearchOpen(!isSearchOpen);
    closeMobileMenu();
  };
  const handleClick = (link) => {
    if (link) {
      navigate(link);
      handleScroll();
    }
  };

  return (
    <>
      <Ad728_90 />
      <div
        className="d-flex justify-content-center"
        style={{ backgroundColor: "#094709" }}
      >
          <div className="header_div">
          <header className="header">
            <div className="logo d-flex align-items-center">
              {" "}
              <img
                src="https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/gardening logo-03.webp"
                className="d-flex align-items-center"
                style={{ height: "64px" }}
                onClick={() => navigate("/")}
              />
                 <h4 className="ps-2">Plantscares</h4>
            </div>
            <div className="header"> 
              <nav className={`nav ${isMobileMenuOpen ? "nav--open" : ""} me-5`}>
                <ul>
                  <li>
                    <Link
                      className="nav-links"
                      to="/"
                      onClick={closeMobileMenu}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-links"
                      to="/about-us"
                      onClick={closeMobileMenu}
                    >
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link
                      className="nav-links"
                      to="/feedback"
                      onClick={closeMobileMenu}
                    >
                      FeedBack
                    </Link>
                  </li>
                </ul>
              </nav>
              <div className="ui search">
                <div className={`ui-icon input ${isSearchOpen ? "open" : ""}`}>
                  <input
                    className={`prompt ${isSearchOpen ? "open" : ""}`}
                    type="text"
                    placeholder="Search...."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <IoIosSearch className="searchbar-icon" />
                  <IoIosSearch
                    className="searchbar-div-1"
                    onClick={toggleSearch}
                  />
                </div>
                {searchTerm ? (
                  <div className="searchbar-div">
                    <div className="searchbar-inner-div">
                      {searchResults.length > 0 ? (
                        searchResults?.map((a) => {
                          return (
                            <p
                              className="my-3"
                              style={{ cursor: "pointer", fontSize: "18px" }}
                              onClick={() => {
                                handleClick(a?.link);
                                setSearchTerm("");
                              }}
                            >
                              {a?.blog_title}
                            </p>
                          );
                        })
                      ) : (
                        <p
                          className="my-2"
                          style={{ cursor: "pointer", fontSize: "18px" }}
                        >
                          No Record Found
                        </p>
                      )}
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
            <button className="mobile-menu-toggle" onClick={toggleMobileMenu}>
              {isMobileMenuOpen ? <FaTimes /> : <FaBars />}
            </button>
          </header>
        </div>
      </div>
      {isSearchOpen ? (
        <div className="mt-1 search_bar">
          <div style={{ height: "100%", width: "100%" }}>
            <div className="ui search">
              <div className={`ui-icon input`}>
                <input
                  className={`prompt1`}
                  type="text"
                  placeholder="Search...."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
              </div>
              {searchTerm ? (
                <div className="searchbar_div_1">
                  {searchResults.length > 0 ? (
                    searchResults?.map((a) => {
                      return (
                        <p
                          className="my-3"
                          style={{ cursor: "pointer", fontSize: "18px" }}
                          onClick={() => {
                            handleClick(a?.link);
                            setSearchTerm("");
                          }}
                        >
                          {a?.blog_title}
                        </p>
                      );
                    })
                  ) : (
                    <p
                      className="my-3"
                      style={{ cursor: "pointer", fontSize: "18px" }}
                    >
                      No Record Found
                    </p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Header;
