import React, { useEffect } from 'react'
import Ad300_250 from '../Components/Ad300_250'

const AboutUs = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }, [])
  return (
    <div className='about-us-main-div mb-5' style={{ paddingTop: "10px" }}>
      <div className=''>
        <h1 className='d-flex justify-content-center my-3' style={{ fontSize: "45px", fontWeight: "700" }}>About Us</h1>
        <div className='about-us-image-div'>
          <div className=''>
            <img src="https://thinkerpick.s3.eu-north-1.amazonaws.com/garden/about-img.webp" alt='aboutUs_img' className='about-us-img w-100' />
          </div>

          <div className='container'>
            <div className='px-5 m-5 about-inner-div'>
              <h5 className='px-5 about-content' style={{ lineHeight: "33px" }}>Welcome to Gardening, your ultimate online resource for all things green and growing. Our mission is to inspire and empower gardening enthusiasts of all levels with the knowledge and tools they need to create beautiful, thriving gardens. Whether you're a seasoned horticulturist or a budding plant lover, you'll find a wealth of information, tips, and product recommendations to help you cultivate your perfect garden. At Gardening, we believe in the joy of nurturing nature, and we're here to support you every step of the way. Let's grow together!</h5>

              <div className='mt-5 px-5 about-content'>
                <div>
                  <h3 className='about-que'>Are you passionate about gardening? If yes then you are at right place.</h3>
                  <h6 className='about-content'>Everyone is welcome at Gardening, regardless of whether they are an experienced gardener starting their first edible garden, an apartment dweller with a fondness for houseplants or a master gardener looking to learn how to turn your produce into delectable dishes.</h6>
                  <h6 className='about-content'>We are familiar with the sensation of dirt under our fingertips, the excitement that accompanies the appearance of your first seedling or flower of the year, and the delight that comes from biting into a freshly harvested fruit straight from your own garden. And since we also love to plant, we have a straightforward objective: we want to make sure you get to share in wonderful moments!</h6>
                  <h6 className='about-content'>Above all, gardening is enjoyable, and we hope to assist you in making your garden more joyful!</h6>
                </div>
                <Ad300_250 />
                <div className='mt-5'>
                  <h3>What's available here?</h3>
                  <h6 className='about-content'>More than 5 million gardening lovers visit our website year in search of the knowledge and ideas they need to create the garden of their dreams. Our website has many articles, and more are added every day, making it a virtual encyclopedia of gardening knowledge.</h6>
                  <h6 className='about-content'>Have a query about gardening? It's likely that we know the solution. You can browse the questions we've already answered or submit your own and receive professional guidance by leaving feedback on our website.</h6>
                  <h6 className='about-content'>Our blog has weekly promotions, freebies, sweepstakes, garden tours, guest blogs from international gardening experts, and the newest DIY projects and gardening hacks.</h6>

                  <h6 className='about-content'>Our learning portal, where you can enroll in classes on a variety of topics taught by knowledgeable gardeners from the United States and abroad, will also be available soon. Discover how to build a kitchen garden that produces no waste, a pollinator garden, and more. Want to ensure you don't miss any of this fantastic content?</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AboutUs