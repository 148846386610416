import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import Ad300_250 from "../Components/Ad300_250";

const Feedback = () => {
  const onSubmit = async (e) => {
    const formData = new FormData();
    formData.append("topic_name", values?.topic_name);
    formData.append("desc", values?.desc);
    values.image.forEach((file) => {
      formData.append("image", file);
    });
    formData.append("name", values?.name);
    formData.append("email", values?.email);
    if (values) {
      await axios.post(
        "http://feedback.web-gamer.com/api/feedback/gardening_web",
        formData
      );
    }
    resetForm();
  };
  const uploadImage = async (e) => {
    const files = Array.from(e.target.files);
    setFieldValue("image", files);
  };
  const initialValues = {
    topic_name: "",
    desc: "",
    image: [],
    name: "",
    email: "",
  };
  const validationSchema = Yup.object().shape({
    topic_name: Yup.string().required("TopicName is Required"),
    desc: Yup.string().required("Description is Required"),
    image: Yup.array()
      .required("Image is Required")
      .min(1, "Please select at least one image"),
    name: Yup.string().required("Name is Required"),
    email: Yup.string().email("Invalid email").required("Email is Required"),
  });

  const {
    values,
    touched,
    errors,
    setFieldValue,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
  } = useFormik({
    initialValues,
    validationSchema,  
    onSubmit,
  });
  return (
    <>
      <div className="d-flex justify-content-center align-items-center feedback-main-div"
        style={{ height: "calc(100vh - 7rem)", paddingTop: "10px" }}
      >
        <div>
          <h3 className="mx-3 text-center feedback_div">
            Please Use the Form Below to Submit Your FeedBack:{" "}
          </h3>

          <div
            className="d-flex justify-content-center align-items-center"
          >
            <div>
              <form onSubmit={handleSubmit}>
                <h1
                  className="d-flex justify-content-center mb-3"
                  style={{ fontFamily: "math, cursive" }}
                >
                  FeedBack Form
                </h1>
                <div className="form-group mb-2">
                  <label className="label-name">
                    Topic Name<span style={{ color: "red" }}>*</span>
                  </label>
                  <input
                    name="topic_name"
                    type="text"
                    className="form-input"
                    errors={errors.topic_name}
                    value={values.topic_name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched?.topic_name && errors?.topic_name && (
                    <h6 className="error">{errors?.topic_name}</h6>
                  )}
                </div>
                <div className="form-group mb-3">
                  <label className="label-name">
                    Description<span style={{ color: "red" }}>*</span>
                  </label>
                  <textarea
                    name="desc"
                    type="text"
                    className="form-input"
                    value={values.desc}
                    onChange={handleChange}
                    onBlur={handleBlur}
                  />
                  {touched?.desc && errors?.desc && (
                    <h6 className="error">{errors?.desc}</h6>
                  )}
                  {values?.desc && (
                    <div className="d-flex justify-content-end text-gray">
                      {values?.desc?.length}/200
                    </div>
                  )}
                </div>
                <div className="form-group mb-2">
                  <div className="">
                    <label className="label-name me-3">
                      Upload Image<span style={{ color: "red" }}>*</span>
                    </label>
                    <input
                      name="image"
                      type="file"
                      multiple
                      className="form-input"
                      onChange={uploadImage}
                      onBlur={handleBlur}
                    />
                  </div>
                  {touched?.image && errors?.image && (
                    <h6 className="error">{errors?.image}</h6>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mb-2">
                      <label className="label-name">
                        Your Name<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="name"
                        type="text"
                        className="form-input"
                        errors={errors.name}
                        value={values.name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.name && errors?.name && (
                        <h6 className="error">{errors?.name}</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-12">
                    <div className="form-group mb-2">
                      <label className="label-name">
                        Your Email<span style={{ color: "red" }}>*</span>
                      </label>
                      <input
                        name="email"
                        type="email"
                        className="form-input"
                        errors={errors?.email}
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {touched?.email && errors?.email && (
                        <h6 className="error">{errors?.email}</h6>
                      )}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-2">
                  <button className="submit-button" type="submit">
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <Ad300_250 />
    </>
  );
};

export default Feedback;
