import React, { useState } from "react";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import GoogleAdsScript from "../GoogleAdsScript"

const Banner = ({gardeningAllData,handleScroll}) => {
  const navigate = useNavigate();
  var settings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 1500,
    pauseOnHover: true,
  };

  const [adDisplayed, setAdDisplayed] = useState(false);

  const handleClick = (link) => {
    if (link) {
      handleCardClick();
      navigate(link);
      handleScroll()
    }
  };

  const handleCardClick = () => {
    let clickCount = localStorage.getItem("clickCount") || 0;
    let click = Number(clickCount);
    localStorage.setItem("clickCount", click + 1);

    if (clickCount == 2) {
      setAdDisplayed(true);
    }
    if (clickCount >= 2) {
      localStorage.setItem("clickCount", "0");
    }
  };

  return (
    <div
      className="banner-main-div d-flex justify-content-center container"
    >
      {adDisplayed && <GoogleAdsScript />}
      <div className="slider-div">
        <Slider
          {...settings}
          className="p-0 top-position1 banner-style2"
          style={{ overflow: "hidden" }}
        >
          {gardeningAllData?.map((x, i) => {
            return (
              <div className="banner_main-div" key={i}>
                <div data-overlay-dark={7} className="banner_images">
                  <img src={x?.banner_image} alt="" className="w-100 banner-img" />
                </div>
                <div className="d-flex justify-content-center">
                  <div className="banner_content d-flex justify-content-center align-items-center flex-column">
                    <h1 className="banner_content_title font-weight-bold mb-3 text-shadow">
                      {x?.blog_title}
                    </h1>
                    <Link
                      to={"/" + x?.link}
                      className="butn-style4 my-1"
                      onClick={() => handleClick(x?.link)}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            );
          })}
        </Slider>
      </div>
    </div>
  );
};

export default Banner;
