import "./App.css";
import { Navigate, Route, Routes, useNavigate } from "react-router-dom";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import AboutUs from "./Pages/AboutUs";
import Feedback from "./Pages/Feedback";
import Layout from "./Components/Layout/Layout";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import GardeningData from "./Pages/Gardning/Gardening-data";
import { useEffect, useRef, useState } from "react";
import NotFound from "./Pages/NotFound";
import gardeningAllData from "./Components/Garden-data/data";

const App = () => {
  const navigate = useNavigate()
  const [value, setValue] = useState()
  // const gareningData = 
  const [searchTerm, setSearchTerm] = useState('');
  const [searchResults, setSearchResults] = useState(gardeningAllData);
  const filterData = () => {
    const results = gardeningAllData?.filter(item =>
      item.blog_title.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setSearchResults(results);
  }
  useEffect(() => {
    filterData()
  }, [searchTerm]);
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      searchResults.map((x) => {
        return setValue(x?.link)
      })
      filterData();
    }
  };
  useEffect(() => {
    if (value) {
      navigate(value);
      setSearchTerm("")
   handleScroll()
    }
  }, [value])
  const targetRef = useRef(null);

  const handleScroll = () => {
    targetRef.current.scrollIntoView({ behavior: 'smooth' });
  };
  return (
    <>
      <Header
        searchTerm={searchTerm}
        setSearchTerm={setSearchTerm}
        searchResults={searchResults}
        handleKeyPress={handleKeyPress} 
        handleScroll ={handleScroll}
        />
      <Routes>
        <Route element={<Layout gardeningAllData={gardeningAllData} handleScroll ={handleScroll}/>}>
          <Route exact path="/:id" element={<GardeningData gardeningAllData={gardeningAllData} targetRef={targetRef} handleScroll={handleScroll}/>
          } 
          />
          <Route index element={<Navigate to="/organic-gardening" />} />
        </Route>
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/feedback" element={<Feedback />} />
         <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer gardeningAllData={gardeningAllData} handleScroll={handleScroll} />
    </>
  );
};

export default App;
